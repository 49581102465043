import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AuthorizedCreator } from 'src/assets/images/store/authorizedCreator.svg';
import { formateDateTime } from 'src/utils/dateTime';
import * as S from './Reply.styles';

type Props = {
  author: string;
  date: string;
  content: string;
  isCreator: boolean;
};

const TEXT_LENGTH_LIMIT = 150;

const Reply = ({ author, date, content, isCreator }: Props) => {
  const { t } = useTranslation();

  const isOverLimit = content.length >= TEXT_LENGTH_LIMIT;
  const [isExpanded, setIsExpanded] = useState(!isOverLimit);

  const handleExpandButtonClick = () => {
    setIsExpanded(true);
  };

  const text = isExpanded ? content : content.slice(0, TEXT_LENGTH_LIMIT) + '...';

  return (
    <S.Container>
      <S.Reply>
        <S.ReplyHeader>
          <S.ReplyUserName>
            {author}
            {isCreator && <AuthorizedCreator />}
          </S.ReplyUserName>
          <S.ReplyDate>{formateDateTime(date, 'YY.MM.DD')}</S.ReplyDate>
        </S.ReplyHeader>
        <S.ReplyText>
          {text}
          {!isExpanded && (
            <S.ExpandButton onClick={handleExpandButtonClick}>
              {t('store.productDetailPage.reviewSection.more')}
            </S.ExpandButton>
          )}
        </S.ReplyText>
      </S.Reply>
    </S.Container>
  );
};

export default Reply;
