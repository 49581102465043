import styled from 'styled-components';

export const Seperator = styled.div`
  margin-top: 24px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.p`
  ${({ theme }) => theme.font.H2_SEMIBOLD};
  color: ${({ theme }) => theme.color.black};
`;

export const LogoutText = styled.span`
  ${({ theme }) => theme.font.H2_SEMIBOLD};
  color: ${({ theme }) => theme.color.warning_500};
`;

export const ModalDescription = styled.p`
  ${({ theme }) => theme.font.B2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_600};
`;

export const ModalButtonContainer = styled.div`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.color.gray_200}`};
`;

export const ModalButton = styled.button<{ $hasBorder?: boolean }>`
  width: 50%;
  border: none;
  background: none;
  border-right: ${({ theme, $hasBorder }) =>
    $hasBorder && `1px solid ${theme.color.gray_200}`};
  height: 56px;
  :hover {
    opacity: 0.6;
  }
`;

export const ModalButtonText = styled.p<{ $isRed?: boolean }>`
  ${({ theme }) => theme.font.SUB2_SEMIBOLD};
  color: ${({ theme, $isRed }) =>
    $isRed ? theme.color.warning_500 : theme.color.gray_600};
`;
