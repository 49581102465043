import { queryOptions } from '@tanstack/react-query';
import {
  getExistingReviews,
  getProductReviews,
  getProductReviewsLoggedIn,
  getProductReviewSummary,
  getReviewDeletionInfo,
  getSalesData,
} from 'src/apis/products';
import { getAccessToken } from 'src/utils/request';

const productQueryOptions = {
  getSalesData: (productId: string) =>
    queryOptions({
      queryKey: ['salesData', productId],
      queryFn: () => getSalesData(productId),
      select: (data) => data.data.data,
      staleTime: Infinity,
    }),

  getReviewDeletionInfo: (reviewId: string) =>
    queryOptions({
      queryKey: ['getReviewDeletionInfo', reviewId],
      queryFn: () => getReviewDeletionInfo(reviewId),
      select: (data) => data.data.data,
      staleTime: 1000 * 60 * 5,
    }),

  getProductReviewSummary: (productId: string) =>
    queryOptions({
      queryKey: ['productReviewSummary', productId],
      queryFn: () => getProductReviewSummary(productId),
      select: (data) => data.data.data,
    }),

  getProductReviews: (productId: string) =>
    queryOptions({
      queryKey: ['productReviews', productId],
      queryFn: () => getProductReviews(productId),
      select: (data) => data.data.data,
    }),

  getExistingReviews: (reviewId: string) =>
    queryOptions({
      queryKey: ['getExistingReviews', reviewId],
      queryFn: () => getExistingReviews(reviewId),
      select: (data) => data.data.data,
    }),

  getProductReviewsLoggedIn: (productId: string) =>
    queryOptions({
      queryKey: ['getProductReviewsLoggedIn', productId],
      queryFn: () => getProductReviewsLoggedIn(productId),
      select: (data) => data.data.data,
      enabled: !!getAccessToken(),
    }),
};

export default productQueryOptions;
