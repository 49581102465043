import { ButtonHTMLAttributes } from 'react';
import DotsLoader from 'src/shared/components/Loader/DotsLoader';
import styled, { css, DefaultTheme } from 'styled-components';

export type ButtonType = 'primary' | 'outline' | 'ghost' | 'warning' | 'disabled';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  buttonType?: ButtonType;
  width?: string | number;
  height?: string | number;
};

const CommonButton = ({
  onClick,
  children,
  disabled,
  isLoading,
  buttonType = 'primary',
  width,
  height,
  ...props
}: Props) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled || isLoading}
      $buttonType={buttonType}
      aria-busy={isLoading}
      width={width}
      height={height}
      {...props}
    >
      {isLoading ? <DotsLoader /> : children}
    </StyledButton>
  );
};

export default CommonButton;

const buttonStyles = {
  primary: (theme: DefaultTheme) => css`
    background-color: ${theme.color.primary_500};
    color: ${theme.color.white};
    &:hover {
      background-color: ${theme.color.primary_400};
    }
    &:active {
      background-color: ${theme.color.primary_300};
    }
  `,

  outline: (theme: DefaultTheme) => css`
    background-color: ${theme.color.white};
    color: ${theme.color.primary_500};
    border: 1px solid ${theme.color.primary_500};
    &:hover {
      color: ${theme.color.primary_400};
      border: 1px solid ${theme.color.primary_400};
    }
    &:active {
      color: ${theme.color.primary_300};
      border: 1px solid ${theme.color.primary_300};
    }
  `,

  ghost: (theme: DefaultTheme) => css`
    background-color: ${theme.color.primary_50};
    color: ${theme.color.primary_500};

    &:hover {
      color: ${theme.color.primary_400};
    }
    &:active {
      color: ${theme.color.primary_300};
    }
  `,

  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.color.warning_400};
    color: ${theme.color.white};
    &:hover {
      background-color: ${theme.color.warning_500};
    }
    &:active {
      background-color: ${theme.color.warning_600};
    }
  `,

  disabled: (theme: DefaultTheme) => css`
    background-color: ${theme.color.gray_200};
    color: ${theme.color.gray_500};
    cursor: not-allowed;
    &:hover,
    &:active {
      background-color: ${theme.color.gray_200};
    }
  `,
};

const StyledButton = styled.button<{
  $buttonType: ButtonType;
  width?: string | number;
  height?: string | number;
}>`
  width: ${({ width }) =>
    width ? (typeof width === 'number' ? `${width}px` : width) : '100%'};
  height: ${({ height }) =>
    height && (typeof height === 'number' ? `${height}px` : height)};
  border-radius: 6px;
  cursor: pointer;
  ${({ theme }) => theme.font.BUTTON};
  ${({ theme, $buttonType }) => buttonStyles[$buttonType](theme)}
`;
