import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TossComplete = () => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const orderNoFromParam = urlParams.get('orderNo');
  const statusFromParam = urlParams.get('status');

  useEffect(() => {
    if (orderNoFromParam && statusFromParam === 'PAY_COMPLETE') {
      window.opener.postMessage('Toss payment is completed!');
      window.close();
    }
  }, [orderNoFromParam, statusFromParam]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100wh',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          fontFamily: 'Pretendard-Medium',
          fontSize: '16px',
          lineHeight: '22px',
        }}
      >
        {t('store.purpleChargingModal.tossPayComplete')}
      </span>
    </div>
  );
};

export default TossComplete;
