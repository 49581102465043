import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import Modal from '../../../../../../components/common/Modal';
import * as S from './LogoutModal.styles';

type Props = {
  onClose: () => void;
};

const LogoutModal = ({ onClose }: Props) => {
  const queryClient = useQueryClient();

  const { t, i18n } = useTranslation();

  const { data: currentUser } = useCurrentUserQuery();
  const platform = localStorage.getItem('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const handleLogout = () => {
    queryClient.resetQueries();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    window.location.href = '/store';
    trackEvent(eventNames.logout, currentUser, { platformType });
    onClose();
  };

  return (
    <Modal onClose={onClose} modalSheetStyle={{ width: '490px' }}>
      <S.Seperator />
      <S.ModalContentContainer>
        {i18n.language === 'en' ? (
          <S.ModalTitle>
            Do you want to <S.LogoutText>log out</S.LogoutText> of HAPL?
          </S.ModalTitle>
        ) : (
          <S.ModalTitle>
            하플에서 <S.LogoutText>로그아웃</S.LogoutText> 할까요?
          </S.ModalTitle>
        )}
        <S.ModalDescription>{t('store.logoutModal.description')}</S.ModalDescription>
      </S.ModalContentContainer>
      <S.Seperator />
      <S.ModalButtonContainer>
        <S.ModalButton $hasBorder onClick={() => onClose()}>
          <S.ModalButtonText>{t('store.logoutModal.no')}</S.ModalButtonText>
        </S.ModalButton>
        <S.ModalButton>
          <S.ModalButtonText $isRed onClick={handleLogout}>
            {t('store.logoutModal.yes')}
          </S.ModalButtonText>
        </S.ModalButton>
      </S.ModalButtonContainer>
    </Modal>
  );
};

export default LogoutModal;
