import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ChannelTalkContext } from 'src/components/Layout/ChannelTalkContext';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { ReactComponent as HaplLogo } from '../../../assets/images/store/haplLogo.svg';
import language from '../../../assets/images/store/language.svg';
import { ReactComponent as WelcomeMessageEn } from '../../../assets/images/store/welcomeMessageEn.svg';
import { ReactComponent as WelcomeMessageKr } from '../../../assets/images/store/welcomeMessageKr.svg';
import AppleOAuthHandler from '../../../components/common/auth/AppleOAuthHandler';
import GoogleOAuthHandler from '../../../components/common/auth/GoogleOAuthHandler';
import KakaoOAuthHandler from '../../../components/common/auth/KakaoOAuthHandler';
import * as G from '../../../styles/commonStyles';
import { theme } from '../../../styles/theme';
import * as S from './LoginPage.styles';
import SocialLoginButton from './components/SocialLoginButton/SocialLoginButton';

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const { hideChannelButton, showChannelButton } = useContext(ChannelTalkContext);
  const { data: currentUser } = useCurrentUserQuery();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const showLanguageModal = useModalManager('LanguageModal');

  useEffect(() => {
    hideChannelButton();
    return () => {
      showChannelButton();
    };
  }, [hideChannelButton, showChannelButton]);

  useEffect(() => {
    trackEvent(eventNames.loginPage, currentUser, {
      platformType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.LoginPageContainer>
      <HaplLogo />

      <G.Separator $separatorHeight={49} />

      {i18n.language === 'en' ? <WelcomeMessageEn /> : <WelcomeMessageKr />}

      <G.Separator $separatorHeight={21} />

      <S.LoginButtonContainer>
        <AppleOAuthHandler redirectPath={state?.redirectPath}>
          <SocialLoginButton
            icon={<S.AppleIcon />}
            backgroundColor='#050708'
            label={t('store.loginPage.continueWithApple')}
            labelColor={theme.color.white}
          />
        </AppleOAuthHandler>

        <GoogleOAuthHandler redirectPath={state?.redirectPath}>
          <SocialLoginButton
            icon={<S.GoogleIcon />}
            backgroundColor='#fff'
            label={t('store.loginPage.continueWithGoogle')}
            labelColor={theme.color.gray_600}
            hasBorder
          />
        </GoogleOAuthHandler>

        <KakaoOAuthHandler
          redirectPath={state?.redirectPath}
          render={({ onClick }) => (
            <SocialLoginButton
              icon={<S.KakaoIcon />}
              backgroundColor='#FEE500'
              label={t('store.loginPage.continueWithKakao')}
              labelColor={theme.color.black}
              onClick={onClick}
            />
          )}
        />
      </S.LoginButtonContainer>

      <G.Separator $separatorHeight={71} />

      <S.LanguageButtonContainer onClick={showLanguageModal}>
        <img src={language} alt='language' width={18} height={18} />
        <S.LanguageButtonLabel>
          {i18n.language === 'en' ? 'English' : '한국어'}
        </S.LanguageButtonLabel>
        <S.ArrowDown />
      </S.LanguageButtonContainer>
      <G.Separator $separatorHeight={35} />
    </S.LoginPageContainer>
  );
};

export default LoginPage;
