import mixpanel, { Dict } from 'mixpanel-browser';
import { calculatePricingDetail } from '../helpers/products';
import { MIXPANEL_TOKEN } from './configs';
import { calDiff, formateDateTime } from './dateTime';

mixpanel.init(MIXPANEL_TOKEN);

const actions = {
  identify: (id: string) => mixpanel.identify(id),
  alias: (id: string) => mixpanel.alias(id),
  track: (name: string, props: Dict) => mixpanel.track(name, props),
  people: {
    set: (props: Dict) => mixpanel.people.set(props),
  },
};

export const Mixpanel = actions;

export const mixpanelProps = (user, props = {}) => {
  let language = localStorage.getItem('lang');
  language = language && language === 'en' ? 'english' : 'korean';

  return {
    name: user?.nickName ?? user?.name ?? user?.username ?? '',
    gender: user?.gender,
    birthday: user?.dob ? formateDateTime(user?.dob, 'YYYY-MM-DD') : '',
    age: user?.dob ? calDiff(new Date(), user?.dob) : '',
    language,
    'hapl1.0_user': user?.oldId ? true : false,
    signup_date: user?.createdAt ? formateDateTime(user?.createdAt, 'YYYY-MM-DD') : '',
    point_balance: user?.points ?? 0,
    distinct_id: user?.id ?? '',
    id: user?.id ?? '',
    email: user?.email ?? '',
    ...props,
  };
};

export const productProps = (product) => {
  if (!product) {
    return;
  }
  const itemBrand = product.brand ?? product.brandId;
  const { salePrice, isDiscount } = calculatePricingDetail(product);
  return {
    productId: product._id ?? '',
    productName: product.korName ?? product.engName ?? '',
    productType: product.productType ?? '',
    brandId: itemBrand?._id ?? '',
    brandName: itemBrand?.name ?? itemBrand?.nameEN ?? '',
    discountRate: isDiscount ? product.discountPercentage : 0,
    point: salePrice,
    // wishCount: 0,
  };
};

export const brandProps = (brand) => {
  return {
    brandId: brand?._id || '',
    brandName: brand?.nameEN || brand?.name || '',
    // stickerCount: 0,
    // templateCount: 0,
    // wishCount: 0
  };
};

export const bannerProps = (item) => ({
  bannerId: item?.id || '',
  bannerName: item?.titleEng || item?.titleKor || '',
  link: item.link || '',
});

export const recommendedCardProps = (item) => ({
  cardId: item?.id || '',
  cardName: item?.titleEng || item?.titleKor || '',
  link: item.link && item.link !== 'null' ? item.link : '',
});

export const eventNames = {
  clickGoBack: 'clickGoBack',

  //exhibition
  exhibitionPage: '/exhibition',
  clickSampleImage: 'exhibition_sampleImage',
  goToExhibitionBrand: 'exhibition_goToBrand',
  GoToExternal: 'exhibition_goToExternal',

  //store
  todayTab: '/storeTodayMain',
  stickerTab: '/storeStickerMain',
  templateTab: '/storeTemplateMain',
  brandsTab: '/storeBrandMain',
  pointsPage: '/pointStore',
  pointProductClick: 'clickPurchasePoint',
  pointPaymentClick: 'clickContinuePurchasePoint',
  pointPaymentComplete: 'purchasePoint',
  goToStoreClick: 'clickGoToStore',
  goToStorageClick: 'clickGoToStorage',
  pointHistoryPage: '/pointHistory',
  wishListPage: '/storeWishlist',
  productLikeClick: 'likeProduct',
  productUnlikeClick: 'likeProduct_undo',
  brandLikeClick: 'likeBrand',
  brandUnlikeClick: 'likeBrand_undo',
  stickerDetailPage: '/storeStickerDetail',
  templateDetailPage: '/storeTemplateDetail',
  productBuyNowClick: 'clickPurchaseProduct',
  productContinuePaymentClick: 'clickContinuePurchaseProduct',
  clickChargePoint: 'clickChargePoint',
  productPurchaseComplete: 'purchaseProduct',
  brandDetailPage: '/storeBrandDetail',
  productLinkShareClick: 'clickProductLinkShare',
  brandLinkShareClick: 'clickBrandLinkShare',
  bannerClick: 'clickStoreMainBanner',
  recommendCardClick: 'clickStoreRecommendCard',
  stickerCategoryClick: 'clickStoreStickerCategory',
  templateCategoryClick: 'clickStoreTemplateCategory',
  brandCategoryClick: 'clickStoreBrandCategory',
  storeProduct: 'clickStoreProduct',
  clickAppInstallBanner: 'clickAppInstallBanner',
  clickAppInstallModalButton: 'clickAppInstallModalButton',
  clickGoToLogin: 'clickGoToLogin',

  // Search
  searchPage: '/search',
  recentSearchQueryClick: 'clickRecentSearchQuery',
  searchResultViewMoreClick: 'clickSearchResultViewMore',
  toggleMyStorageOnly_on: 'toggleMyStorageOnly_on',
  toggleMyStorageOnly_off: 'toggleMyStorageOnly_off',
  searchStickerFilter: 'searchByStickerFilter',
  searchTemplateFilter: 'searchByTemplateFilter',
  searchResultItem: 'clickSearchResultItem',
  search: 'search',

  //Login
  loginPage: '/login',
  socialConnectComplete: 'socialAccount_connect_complete',
  logout: 'logout',

  //Signup
  signupAgreementPage: '/signup_agreement',
  signupNicknamePage: '/signup_nickname',
  signupBirthdayPage: '/signup_birthday',
  signupGenderPage: '/signup_gender',
  signupCompletePage: '/signup_complete',
  signupComplete: 'signup_complete',

  //MyPage
  myPage: '/mypage',
  clickGoToCreatorStudio: 'clickGoToCreatorStudio',
  changeLang: 'changeLang',

  //Review
  postReview: 'postReview',
  postReviewScreen: '/postReview',
  editReview: 'editReview',
  editReviewScreen: '/editReview',
  clickPostReview: 'clickPostReview',
  clickEditReview: 'clickEditReview',
  clickReportReview: 'clickReportReview',
  reviewImageFullScreen: '/reviewImageFullScreen',

  purchaseHistory: '/purchaseHistory',

  reportReview: 'reportReview',
  reportReviewScreen: '/reportReview',
} as const;

export const goStoreSources = {
  purchasePoint_complete: 'purchasePoint_complete',
  wishlist: 'wishlist',
  purpleHistory: 'purpleHistory',
  brandDetail: 'brandDetail',
  store: 'store',
};

export const goStorageSources = {
  purchaseProduct_complete: 'purchaseProduct_complete',
  productDetail: 'productDetail',
  search_productDetail: 'search_productDetail',
  search: 'search',
};

export const appInstallModalSources = {
  storageTab: 'storageTab',
  purchaseMoveToStorage: 'purchaseMoveToStorage',
};

export const storeProductDetailSources = {
  exhibition: 'exhibition',

  todayPopular: 'todayPopular',
  todayNew: 'todayNew',
  todayRecommend: 'todayRecommend',
  stickerPopular: 'stickerPopular',
  stickerCategory: 'stickerCategory',
  stickerDetailRecommmendByCategory: 'stickerDetailRecommmendByCategory',
  stickerDetailRecommendByBrand: 'stickerDetailRecommendByBrand',
  templatePopular: 'templatePopular',
  templateCategory: 'templateCategory',
  templateDetailRecommendByCategory: 'templateDetailRecommendByCategory',
  templateDetailRecommendByBrand: 'templateDetailRecommendByBrand',
  brand: 'brand',
  brandDetail: 'brandDetail',
  wishlist: 'wishlist',
  search: 'search',
  purchaseHistory: 'purchaseHistory',
  stickerDetail: 'stickerDetail',
  templateDetail: 'templateDetail',
  brandPopular: 'brandPopular',
  brandCategory: 'brandCategory',
};

export const storeBrandDetailSources = {
  stickerDetail: 'stickerDetail',
  templateDetail: 'templateDetail',
  brandPopular: 'brandPopular',
  brandCategory: 'brandCategory',
  wishlist: 'wishlist',
} as const;
