import styled from 'styled-components';
import LazyImage from '../../../../common/LazyImage/LazyImage';

export const StickerThumbnail = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.gray_100};
`;

export const ThumbnailResize = styled(LazyImage)`
  width: 90%;
  height: 90%;
  padding: 5px;
  object-fit: contain;
  position: absolute;
`;

export const StickerTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

//for index.js

export const StickerCategoryContainer = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${({ $selected, theme }) =>
    $selected
      ? `1px solid ${theme.color.gray_400}`
      : `1px solid ${theme.color.gray_200}`};
  border-radius: 99px;
  padding: 6px 10px;
  transition: border 0.5s ease;
`;

export const StickerCategoryText = styled.span<{ $selected: boolean }>`
  ${({ theme }) => theme.font.B2_MEDIUM}
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.gray_600 : theme.color.gray_400};
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 0.5s;
`;

export const ResetFilterText = styled.span`
  ${({ theme }) => theme.font.B2_REGULAR}
  color: ${({ theme }) => theme.color.gray_600};
  text-transform: none;
`;
