import styled from 'styled-components';

export const Container = styled.div`
  height: 100dvh;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const ContentContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
`;

export const StretchContainer = styled.div`
  flex-grow: 1;
`;

export const TabBarWrapper = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
`;
