import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../../assets/images/search/images';
import * as S from './TagContainer.styles';

type TagContainerProps = {
  tags: string[];
  deleteTag: (tag: string) => void;
  onTagSelect: (tag: string) => void;
};

const TagContainer = ({ tags, deleteTag, onTagSelect }: TagContainerProps) => {
  const { t } = useTranslation();

  const limitedTags = useMemo(() => tags.slice(0, 10), [tags]);

  const deleteTagHandler = useCallback(
    (tag: string) => (e) => {
      e.stopPropagation();
      deleteTag(tag);
    },
    [deleteTag]
  );

  if (tags.length === 0) {
    return <S.NoTagText>{t('search.mainPage.noRecentTag')}</S.NoTagText>;
  }

  return (
    <S.TagLayout $noTag={tags.length === 0}>
      {limitedTags.map((tag) => (
        <S.TagContainer key={tag} onClick={() => onTagSelect(tag)}>
          <S.TagText>{tag.length > 10 ? `${tag.slice(0, 10)}...` : tag}</S.TagText>
          <S.DeleteTagIcon
            src={images.closeRight}
            alt='closeRightIcon'
            onClick={deleteTagHandler(tag)}
            role='button'
          />
        </S.TagContainer>
      ))}
    </S.TagLayout>
  );
};

export default TagContainer;
