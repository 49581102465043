import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CircleClose = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: ${({ theme }) => theme.color.gray_800};
  border-radius: 99px;
`;

export const Close = styled(CloseIcon)`
  width: 10px;
  height: 10px;
  fill: white;
`;
