import { ReactComponent as ArrowDown } from 'src/assets/images/store/arrowDown.svg';
import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const TOP_BAR_HEIGHT = 72;

export const Container = styled.div`
  width: 100%;
  height: ${TOP_BAR_HEIGHT}px;
  background: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 10;

  ${responsivePadding}
`;

export const ArrowLeft = styled(ArrowDown)`
  width: 24px;
  height: 24px;
  left: 0px;
  cursor: pointer;
  fill: black;
  rotate: 90deg;
`;

export const BarText = styled.span`
  display: flex;
  width: auto;
  height: 24px;
  color: ${({ theme }) => theme.color.black};
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.font.H3_BOLD}

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
