import styled from 'styled-components';
import LazyImage from '../../../../common/LazyImage/LazyImage';
import { theme } from '../../../../../styles/theme';

export const RenderStickerContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 999px;
  cursor: pointer;
`;

export const BrandThumbnail = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  overflow: hidden;
  object-fit: cover;
  border-radius: 999px;
  border: 1px solid ${theme.color.gray_200};
`;

export const BrandThumbnailResize = styled(LazyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const BrandTextContainer = styled.div`
  padding: 8px 6px 0 6px;
`;

export const BrandTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
