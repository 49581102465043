import { ReactComponent as StarIcon } from 'src/assets/icons/Star.svg';
import styled from 'styled-components';
import LazyImage from '../../common/LazyImage/LazyImage';
import BREAK_POINTS from './../../../constants/_common/breakPoints';
interface IsStickerProps {
  $isSticker: boolean;
}

export const StickerItemContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const StickerImageContainer = styled.div`
  aspect-ratio: 1;
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.color.gray_100};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.color.gray_100};
`;

export const StickerTextContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 8px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const StickerTextBrand = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_500};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.font.B2_REGULAR}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_REGULAR}
  }
`;

export const StyledImage = styled(LazyImage)<IsStickerProps>`
  width: ${({ $isSticker }) => ($isSticker ? '90%' : '100%')};
  height: ${({ $isSticker }) => ($isSticker ? '90%' : '100%')};
  padding: ${({ $isSticker }) => ($isSticker ? '5%' : '0')};
  justify-content: ${({ $isSticker }) => ($isSticker ? 'center' : 'initial')};
  align-items: ${({ $isSticker }) => ($isSticker ? 'center' : 'initial')};
  object-fit: ${({ $isSticker }) => ($isSticker ? 'contain' : 'cover')};
  position: absolute;
  cursor: pointer;
`;

export const StickerFavoriteImage = styled.img`
  position: absolute;
  display: flex;
  width: 25%;
  height: 25%;
  right: 6px;
  bottom: 6px;
  cursor: pointer;
`;

export const StickerTextPd = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_700};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.font.SUB2_MEDIUM}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;

export const StickerTextPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  gap: 4px;
`;

export const StickerTextPrice = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;

export const StickerTextPercentage = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B3_SEMIBOLD}
`;

export const ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Star = styled(StarIcon)`
  width: 16px;
  height: 16px;
  fill: #ffc107;
`;

export const AverageRating = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.B2_REGULAR};
`;

export const ReviewCount = styled.div`
  color: ${({ theme }) => theme.color.gray_500};
  ${({ theme }) => theme.font.B2_MEDIUM};
`;
