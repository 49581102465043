import { ReactElement, ReactNode, createContext, useState } from 'react';

type Modal = {
  component: ReactElement;
  id: string;
};

type ModalContextValue = {
  modals: Modal[];
  openModal: (modal: Modal) => void;
  closeModal: (name: Modal['id']) => void;
};

export const ModalContext = createContext<ModalContextValue>({
  modals: [],
  openModal: () => {},
  closeModal: () => {},
});

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<Modal[]>([]);

  const openModal = (modal: Modal) => {
    setModals((prevModals) => [...prevModals, modal]);
  };

  const closeModal = (deleteId: Modal['id']) => {
    setModals((prevModals) => prevModals.filter(({ id }) => id !== deleteId));
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
