import { useTranslation } from 'react-i18next';
import GiftCard from 'src/components/store/Card/GiftCard';
import * as S from './GiftProducts.styles';

type Props = {
  giftProducts: any;
};

const GiftsProducts = ({ giftProducts }: Props) => {
  const { t } = useTranslation();

  return (
    <S.TemplateGifts>
      <S.TemplateGiftsKey>{t('store.template.gift')}</S.TemplateGiftsKey>
      <S.TemplateGiftsSlideContainer>
        <GiftCard items={giftProducts} />
      </S.TemplateGiftsSlideContainer>
    </S.TemplateGifts>
  );
};

export default GiftsProducts;
