import { REVIEW_SUBMIT_CONTENT_MAX_WIDTH } from 'src/constants/_common/layout';
import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${REVIEW_SUBMIT_CONTENT_MAX_WIDTH}px;
  margin: 24px 0;
  text-align: left;

  ${responsivePadding}
`;

export const HeadingContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_SEMIBOLD};
`;

export const WarningText = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR};
`;

export const ReviewIncentive = styled.div`
  padding: 0 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B3_MEDIUM};
  background-color: ${({ theme }) => theme.color.primary_50};
`;
