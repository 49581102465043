import colors from 'src/utils/colors';
import styled from 'styled-components';

export const NoReviewMessage = styled.div`
  color: ${colors.$gray900};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;

  ${({ theme }) => theme.font.SUB2_MEDIUM};
`;

export const ReviewPrompt = styled.div`
  color: ${colors.$gray600};
  text-align: center;
  margin-bottom: 64px;

  ${({ theme }) => theme.font.B2_REGULAR};
`;
