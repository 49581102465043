import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

interface TemplateAlignProps {
  selected: boolean;
}

export const TemplatePartBar = styled.div`
  display: flex;
  width: 100%;
  margin-right: 0px;
  margin-bottom: -4px;
  flex-direction: row;
  justify-content: space-between;
`;

export const TemplatePartTitle = styled.span`
  color: #212121;
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 28px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 16px;
  }
`;

export const TemplateViewContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const TemplateViewBoard = styled.div`
  display: grid;
  width: calc(100% - 24px);
  grid-auto-columns: calc(16.666% - 17px);
  grid-auto-flow: column;
  white-space: nowrap;
  gap: 20px;

  @media screen and (max-width: ${BREAK_POINTS.DESKTOP}px) {
    grid-auto-columns: calc(20% - 16px);
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    grid-auto-columns: calc(25% - 16px);
  }
  @media screen and (max-width: 560px) {
    grid-auto-columns: calc(33.3% - 12px);
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const TemplateAlignmentContainer = styled.div`
  width: 186px;
  height: 22px;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

export const TemplateAlignment = styled.div<TemplateAlignProps>`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => (props.selected ? '#7c4dff' : '#bdbdbd')};
  cursor: pointer;
`;
