import styled from 'styled-components';

export const Title = styled.p`
  margin-bottom: 69px;
  ${({ theme }) => theme.font.H1_BOLD};
  color: ${({ theme }) => theme.color.black};
`;

export const PurpleTitle = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
`;

export const CheckAllButton = styled.button<{ $isAllSelected: boolean }>`
  width: 100%;
  height: 56px;
  border-radius: 6px;
  border: ${({ theme, $isAllSelected }) =>
    $isAllSelected
      ? `1px solid ${theme.color.primary_500}`
      : `1px solid ${theme.color.gray_500}`};
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const AgreeAllBtnText = styled.span<{ $isAllSelected: boolean }>`
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  color:${({ theme, $isAllSelected }) =>
    $isAllSelected ? theme.color.primary_400 : theme.color.gray_900}
`;

export const AgreementList = styled.li<{ $isDescription?: boolean }>`
  justify-content: center;
  flex-direction: row;
  display: flex;
  column-gap: 8px;
  list-style: ${({ $isDescription }) => ($isDescription ? 'inside' : 'none')};
`;
