import styled from 'styled-components';

interface IsBrandCoverVisibleProps {
  $isBrandCoverVisible: boolean;
}

export const BackBarContainer = styled.div<IsBrandCoverVisibleProps>`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 222;
  top: 0;
  background-color: ${({ $isBrandCoverVisible, theme }) =>
    $isBrandCoverVisible ? '' : theme.color.white};
`;

export const BackBar = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
`;

export const ArrowResize = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
`;
