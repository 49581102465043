import styled from 'styled-components';
import LazyImage from '../../../components/common/LazyImage/LazyImage';

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductLayout = styled.div`
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  flex-direction: row;
  row-gap: 40px;
  column-gap: 24px;
  margin-bottom: 40px;

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 512px) {
    column-gap: 8px;
    row-gap: 16px;
  }
`;

export const ProductContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

export const StickerResultTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

export const SearchResultText = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const AllContainer = styled.div`
  width: 100%;
  max-width: 576px;
`;

export const FromStorageContainer = styled.div`
  display: flex;
  position: absolute;
  padding: 6px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.gray_800};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  top: 8px;
  left: 8px;
  z-index: 100;
`;

export const FromStorageText = styled.span`
  ${({ theme }) => theme.font.B3_REGULAR}
  color: ${({ theme }) => theme.color.gray_50};
`;

export const FragTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 6px 0 6px;
  gap: 2px;
`;

export const ProductTitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 400px) {
    width: 60px;
  }
`;

export const ProductTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}

  @media screen and (max-width: 400px) {
    ${({ theme }) => theme.font.B2_SEMIBOLD}
  }
`;

export const CreatorAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const CreatorImageContainer = styled.div`
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 999px;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_100};
`;

export const CreatorImageResize = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;

export const CreatorContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 400px) {
    width: 50px;
  }
`;

export const CreatorName = styled.span`
  ${({ theme }) => theme.font.B2_REGULAR}
  color: ${({ theme }) => theme.color.gray_500};
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: 400px) {
    ${({ theme }) => theme.font.B3_REGULAR}
  }
`;
