import { ReactComponent as StarIcon } from 'src/assets/icons/Star.svg';
import { ReactComponent as StarHalfIcon } from 'src/assets/icons/StarHalf.svg';
import styled from 'styled-components';

export const RatingSection = styled.div`
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StarContainer = styled.div`
  display: inline;
  background-color: red;
`;

export const Stars = styled.div`
  display: flex;
  gap: 12px;
`;

export const Star = styled(StarIcon)<{ $isFilled: boolean }>`
  width: 28px;
  height: 28px;
  fill: ${({ theme, $isFilled }) =>
    $isFilled ? theme.color.review_star : theme.color.gray_200};
`;

export const StarHalf = styled(StarHalfIcon)``;
