import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../constants/_common/path';
import * as S from './index.styles';

const AccessDenied = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToStore = () => {
    navigate(PATH.STORE);
  };

  return (
    <S.Container>
      <S.Image />
      <S.Title>{t('accessDenied.title')}</S.Title>
      <S.Text>{t('accessDenied.text')}</S.Text>
      <S.Button onClick={goToStore}>{t('accessDenied.buttonText')}</S.Button>
    </S.Container>
  );
};

export default AccessDenied;
