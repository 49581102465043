import * as S from './CommonTopBar.styles';

type Props = {
  onBackButtonClick?: () => void;
  barName?: string;
  rightSide?: React.ReactNode;
};

const CommonTopBar = ({ onBackButtonClick, barName, rightSide }: Props) => {
  return (
    <S.Container>
      {onBackButtonClick && <S.ArrowLeft onClick={onBackButtonClick} />}
      {barName && <S.BarText>{barName}</S.BarText>}
      {rightSide}
    </S.Container>
  );
};

export default CommonTopBar;
