import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/store/arrowLeft.svg';

export const FullPage = styled.div`
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
`;

export const TopBar = styled.div`
  padding: 20px 24px;
  display: flex;
`;

export const ArrowLeft = styled(ArrowLeftIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
