import React from 'react';
import colors from './colors';

export const highlightedText = (text: string, highlight: string) => {
  if (!text) return '';
  if (!highlight) return text;
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, i) => (
    <span
      key={i}
      style={{
        color:
          part.toLowerCase() === highlight.toLowerCase()
            ? colors.$purple400
            : colors.$gray900,
      }}
    >
      {part}
    </span>
  ));
};
