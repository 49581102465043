import { ReactComponent as NoneIcon } from 'src/assets/icons/none.svg';
import CommonButton from 'src/components/common/CommonButton';
import { TOP_BAR_HEIGHT } from 'src/components/store/Bar/CommonTopBar.styles';
import styled from 'styled-components';
import { NAVIGATION_BAR_HEIGHT } from './NavigationBar.styles';

export const Container = styled.div`
  height: calc(100dvh - ${TOP_BAR_HEIGHT}px - ${NAVIGATION_BAR_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const None = styled(NoneIcon)`
  width: 120px;
  height: 120px;
  margin-bottom: 12px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
`;

export const Description = styled.h2`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_REGULAR};
`;

export const Button = styled(CommonButton)`
  width: 142px;
  height: 42px;
  ${({ theme }) => theme.font.B2_MEDIUM};
`;
