import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary_50};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  margin: 12px 0 12px 20px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RightSection = styled.div`
  margin: 8px 20px 8px 0;
`;

export const LogoImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 6px;
`;

export const Text = styled.div`
  ${({ theme }) => theme.font.B2_SEMIBOLD};
  color: ${({ theme }) => theme.color.gray_900};
`;

export const Button = styled.button`
  width: 100%;
  ${({ theme }) => theme.font.B3_SEMIBOLD};
  color: ${({ theme }) => theme.color.white};
  padding: 7px 20px;

  background-color: ${({ theme }) => theme.color.primary_500};
  border-radius: 44px;
  border: none;
  cursor: pointer;
`;
