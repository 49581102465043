import { useEffect, useState } from 'react';

const useUserAgent = () => {
  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    setUserAgent(navigator.userAgent.toLowerCase());
  }, []);

  return userAgent;
};

export default useUserAgent;
