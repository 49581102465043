const store_images = {
  arrowBadge: require('../store/arrow_badge.png'),
  arrowLeft: require('../store/arrow_left_ios.png'),
  whiteArrowLeft: require('../store/white_arrow_left.png'),
  arrowRight: require('../store/arrow_right_ios.png'),
  brandFavorite: require('../store/brandFavorite.png'),
  checkboxFilled: require('../store/Check-box-filled.png'),
  checkboxRed: require('../store/Check-box-red.png'),
  checkOff: require('../store/Check-off.svg'),
  complete: require('../store/complete_icon.png'),
  favoriteBlack: require('../store/favorite_black.png'),
  favoriteFilled: require('../store/favorite_filled.png'),
  favoriteUnfilled: require('../store/favorite_unfilled.png'),
  favorite: require('../store/favorite.svg'),
  instagram: require('../store/instagram.png'),
  miniHeart: require('../store/miniheart.png'),
  mintChip: require('../store/mint_chip.png'),
  moka: require('../store/moka.png'),
  purpleIcon: require('../store/purple-icon.png'),
  purpleGrayIcon: require('../store/purple_gray_icon.png'),
  search: require('../store/search.png'),
  shareBlack: require('../store/share_black.png'),
  share: require('../store/share.png'),
  iOSShare: require('../store/ic_share_ios.png'),
  twitterX: require('../store/twitterX.png'),
  whiteChip: require('../store/white_chip.png'),
  youtube: require('../store/youtube.png'),
  defaultProfile: require('../store/defaultProfile@3x.png'),
  defaultProfileSquare: require('../store/defaultProfileTemplate.png'),
  defaultBackground: require('../store/defaultBackground.png'),
  oneLink: require('../store/onelink_1nnmbfnb_qr_code.png'),
  comingSoon: require('../store/Campaign.svg'),
  storeHome: require('../store/storeHome.png'),
  mobileLottie: require('../store/mobile_lottie.png'),
  mediumLottie: require('../store/medium_lottie.png'),
  downloadIcon: require('../store/download.png'),
  watermark: require('../store/watermark.png'),
  profileImage: require('../store/profileImage.svg'),
};

export default store_images;
