import { ReactComponent as ArrowDown } from 'src/assets/images/store/arrowDown.svg';
import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 0 16px;
  }
`;

export const ReviewTitle = styled.div`
  display: flex;
  gap: 8px;
  ${({ theme }) => theme.font.H3_BOLD};

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 16px;
  }
`;

export const ReviewTotalCount = styled.span`
  ${({ theme }) => theme.font.H3_MEDIUM};
  color: #bababa;
`;

export const WriteReviewButton = styled.button`
  color: ${colors.$purple400};
  ${({ theme }) => theme.font.SUB2_MEDIUM};

  display: flex;
  align-items: center;
`;

export const ArrowRight = styled(ArrowDown)`
  width: 24px;
  height: 24px;
  fill: ${colors.$purple400};
  transform: rotate(-90deg);
  margin-left: -3px;
  margin-right: -8px;
`;

export const AverageRatingContainer = styled.div`
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const StarRatingContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const AverageRatingText = styled.span`
  color: ${colors.$gray400};
  ${({ theme }) => theme.font.H2_BOLD};
`;

export const AverageRatingBoldText = styled.span`
  color: ${colors.$gray900};
`;
