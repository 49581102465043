import { useTranslation } from 'react-i18next';
import ImageSection from './ImageSection';
import * as S from './index.styles';
import RatingSection from './RatingSection';
import TextareaSection from './TextareaSection';

type Props = {
  rating: number;
  handleRatingChange: (value: number) => void;
  reviewText: string;
  handleReviewTextChange: (value: string | string[]) => void;
  isInvalidText: boolean;
  previewImages: string[];
  handleImageChange: (images: File[]) => void;
  handleImageRemove: (targetIndex: number) => void;
  isAllImagesRemoved: boolean;
};

const ReviewForm = ({
  rating,
  handleRatingChange,
  reviewText,
  handleReviewTextChange,
  isInvalidText,
  previewImages,
  handleImageChange,
  handleImageRemove,
  isAllImagesRemoved,
}: Props) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <RatingSection rating={rating} handleRatingChange={handleRatingChange} />

      <S.HeadingContainer>
        <S.Heading>{t('store.submitReview.reviewRequest')}</S.Heading>
        <S.ReviewIncentive>{t('store.submitReview.reviewIncentive')}</S.ReviewIncentive>
      </S.HeadingContainer>
      <S.WarningText>{t('store.submitReview.imageUploadHint')}</S.WarningText>

      <ImageSection
        previewImages={previewImages}
        handleImageChange={handleImageChange}
        handleImageRemove={handleImageRemove}
        isAllImagesRemoved={isAllImagesRemoved}
      />

      <TextareaSection
        reviewText={reviewText}
        handleReviewTextChange={handleReviewTextChange}
        isInvalidText={isInvalidText}
      />
    </S.Container>
  );
};

export default ReviewForm;
