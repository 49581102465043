import LazyImage from '../../common/LazyImage/LazyImage';
import styled from 'styled-components';

export const RecommendProductBox = styled.div`
  width: 265px;
  flex-direction: row;
  display: flex;
  margin-bottom: 12px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;

export const RecommendProductImage = styled.div`
  display: flex;
  position: relative;
  width: 72px;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 12px;
  background: ${({ theme }) => theme.color.gray_100};
  object-fit: contain;
  border: 1px solid ${({ theme }) => theme.color.gray_100};
`;

export const RecommendProductImageResize = styled(LazyImage)`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  object-fit: contain;
`;

export const RecommendProductTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 185px;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
`;

export const RecommendBrandName = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_500};
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.B2_MEDIUM}
`;

export const RecommendProductTextName = styled.span`
  width: 185px;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_700};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.font.B2_MEDIUM}
`;

export const RecommendTextPriceContainer = styled.div`
  width: 95px;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const RecommendTextPrice = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_BOLD}
  text-overflow: ellipsis;
`;

export const RecommendTextPercentage = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B2_SEMIBOLD}
`;
