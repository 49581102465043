import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import LazyImage from '../../../components/common/LazyImage/LazyImage';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import { theme } from './../../../styles/theme';

interface ActiveProps {
  $active: boolean;
}
interface IsSelectedProps {
  $isSelected: boolean;
}

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 510px;
`;

export const Progress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.color.primary_500};
  }
`;
export const BrandTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const BrandCoverImageContainer = styled.div`
  width: 100%;
  height: 185px;
  position: relative;
`;

export const BrandBackImageResize = styled(LazyImage)`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BrandBackImageGradient = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const BrandProfileContainer = styled.div`
  width: 154px;
  height: 154px;
  top: 164px;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-shrink: 0;
  position: absolute;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 999px;
  overflow: hidden;
  object-fit: contain;
`;

export const BrandProfileImageResize = styled(LazyImage)`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BrandInfoContainer = styled.div`
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: calc(100% - 48px);
  margin-inline: auto;
`;

export const BrandName = styled.span`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_900};
  text-align: center;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.H2_BOLD}
  margin-bottom: 8px;
`;

export const BrandInfo = styled.span`
  color: ${({ theme }) => theme.color.gray_500};
  text-align: center;
  ${({ theme }) => theme.font.B2_MEDIUM}
  margin-bottom: 20px;
`;

export const EntireBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    gap: 16px;
  }
`;

export const IconBar = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

export const IconButtonResize = styled.button`
  width: 48px;
  height: 42px;
  background-color: ${({ theme }) => theme.color.white};
  border: none;
  padding: 0;
  margin: 0;
`;

export const IconImgResize = styled.img`
  width: 100%;
`;

export const LikeIcon = styled.div<ActiveProps>`
  display: flex;
  width: 142px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: ${({ theme }) => theme.color.white};
  cursor: pointer;
  border: 1px solid
    ${({ $active }) =>
      $active ? `${theme.color.primary_500}` : `${theme.color.gray_300}`};
`;

export const LikeIconResize = styled.img`
  width: 14px;
  height: 14px;
`;

export const LikeNumber = styled.span<ActiveProps>`
  color: ${({ $active }) =>
    $active ? `${theme.color.primary_500}` : `${theme.color.gray_800}`};
  ${({ theme }) => theme.font.B2_SEMIBOLD_SHORT}
`;

export const SocialButton = styled.button`
  width: 48px;
  height: 44px;
  padding: 0px;
  border: 0px;
  background: none;
`;

export const BrandDetailPart = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const BrandDetailPartInner = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const BrandStickerBoard = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: auto;
  gap: 20px;
  margin-top: 16px;

  @media screen and (max-width: ${BREAK_POINTS.DESKTOP}px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(4, minmax(102px, 1fr));
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    grid-template-columns: repeat(3, minmax(102px, 1fr));
    gap: 16px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    grid-template-columns: repeat(3, minmax(90px, 1fr));
    gap: 10px;
  }
`;

export const ChipBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Chip = styled.div<IsSelectedProps>`
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: ${({ $isSelected }) =>
    $isSelected
      ? `1px solid ${theme.color.gray_800}`
      : `1px solid ${theme.color.gray_300}`};
  background: ${({ $isSelected }) => ($isSelected ? `${theme.color.gray_800}` : '')};
  cursor: pointer;
`;

export const ChipText = styled.span<IsSelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isSelected }) =>
    $isSelected ? `${theme.color.white}` : `${theme.color.gray_700}`};
  ${({ theme }) => theme.font.SUB2_MEDIUM}

  @media screen and (max-width: 495px) {
    // font-size: 14px;
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;
