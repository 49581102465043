import React from 'react';
import { Box } from '@mui/material';
import { images } from '../../../../../../assets/images/search/images';
import colors from '../../../../../../utils/colors';

type Props = {
  color: string;
  onClick: () => void;
  selected: string[];
  id: string;
};

const ColorCircleWrapper = ({ color, onClick, selected, id }: Props) => {
  return (
    <div onClick={onClick} style={{ width: 50, position: 'relative' }}>
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: '999px',
          background:
            color === 'gradient'
              ? `linear-gradient(311deg,
          #9F7FF8 13.58%,
          #DCC0C0 13.58%,
          #9F7FF8 13.58%,
          #8F94EF 31.03%,
          #C0EEC5 45.07%,
          #F6E4A2 54.93%,
          #FFA26E 70.11%,
          #FB7A7A 83.01%
        )`
              : color,
          border: color === '#FFF' ? `1px solid ${colors.$gray300}` : 'transparent',
        }}
      />
      {selected.includes(id) && (
        <img
          alt='check'
          src={
            color === '#FFF' || color === 'gradient' ? images.blackTick : images.whiteTick
          }
          style={{
            width: 14,
            height: 14,
            position: 'absolute',
            alignSelf: 'center',
            top: 13.5,
            objectFit: 'contain',
            left: 14,
          }}
        />
      )}
    </div>
  );
};

export default ColorCircleWrapper;
