import { useTranslation } from 'react-i18next';
import { ProductCategory } from 'src/types/product';
import * as S from './NavigationBar.styles';

type Props = {
  tab: ProductCategory;
  handleTab: (tab: ProductCategory) => void;
};

const NavigationBar = ({ tab, handleTab }: Props) => {
  const { t } = useTranslation();

  return (
    <S.Navigation>
      <ul>
        <S.NavigationTab $isSelected={tab === 'all'} onClick={() => handleTab('all')}>
          {t('store.purchaseHistory.tabs.all')}
        </S.NavigationTab>
        <S.NavigationTab
          $isSelected={tab === 'sticker'}
          onClick={() => handleTab('sticker')}
        >
          {t('store.purchaseHistory.tabs.sticker')}
        </S.NavigationTab>
        <S.NavigationTab
          $isSelected={tab === 'template'}
          onClick={() => handleTab('template')}
        >
          {t('store.purchaseHistory.tabs.template')}
        </S.NavigationTab>
      </ul>
    </S.Navigation>
  );
};

export default NavigationBar;
