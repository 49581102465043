import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import BREAK_POINTS from '../../constants/_common/breakPoints';

export const AdvertContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  position: absolute;
`;

export const AdvertBadgeList = styled.div`
  display: inline-flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding: 6px 8px;
  align-items: flex-start;
  border-radius: 999px;
  background: rgba(39, 39, 39, 0.7);
  backdrop-filter: blur(2px);
  flex-direction: row;
  z-index: 100;
`;

export const AdvertBadgeCurrent = styled.span`
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.B3_MEDIUM_SHORT}
  text-transform: capitalize;
`;

export const AdvertBadgeAll = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B3_MEDIUM_SHORT}
  text-transform: capitalize;
`;

export const AdvertBadgeArrow = styled.img`
  width: 6px;
  height: 12px;
  margin-left: 3px;
`;

export const AdvertViewContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const AdvertItemsBelt = styled.div`
  position: relative;
  width: 100%;
`;

export const AdvertItemContainer = styled(SwiperSlide)`
  cursor: pointer;

  max-width: 512px;
`;

export const AdvertImageResize = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-fit: cover;
  }
`;

export const AdvertTextContainer = styled.div`
  display: flex;
  left: 32px;
  bottom: 24px;
  position: absolute;
  flex-direction: column;
  text-align: left;
  width: 403px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 260px;
  }
`;

export const AdvertImageText = styled.span`
  color: ${({ theme }) => theme.color.gray_800};
  ${({ theme }) => theme.font.H1_BOLD}
  text-transform: capitalize;
  white-space: pre-wrap;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.H2_BOLD}
  }
`;

export const AdvertImageTextSub = styled.span`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_MEDIUM}
  margin-top: 6px;
  white-space: pre-wrap;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 12px;
  }
`;
