import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const SeeMore = styled.span`
  color: #8064fc;
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
`;

export const Top10ViewContainer = styled.div`
  overflow-x: auto;
  width: calc(100% - 24px);
  grid-auto-columns: calc(16.666% - 17px);
  grid-auto-flow: column;
  white-space: nowrap;
  margin-top: 16px;
  display: grid;
  gap: 20px;

  @media screen and (max-width: ${BREAK_POINTS.DESKTOP}px) {
    grid-auto-columns: calc(20% - 16px);
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    grid-auto-columns: calc(25% - 16px);
  }
  @media screen and (max-width: 560px) {
    grid-auto-columns: calc(33.3% - 12px);
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: calc(100% - 16px);
    grid-auto-columns: calc(33.3% - 14px);
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const RecommendViewContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 16px;
  display: flex;
  flex-direction: row;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
