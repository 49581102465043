import { useEffect, useMemo, useState } from 'react';
import NoResult from '../../NoResult/NoResult';
import SearchResultHeader from '../../SearchResultHeader/SearchResultHeader';
import FragmentStickerData from './components/FragmentStickerData';

import { ProductDataProps } from '../../../../types/search/commonPropsTypes';
import * as G from '../ProductCommon.styles';

const FragmentStickerMenu = ({ text, data }: ProductDataProps) => {
  const userId = localStorage.getItem('userId') ?? '';

  const total = data?.total || 0;
  const allData = useMemo(() => (data?.data ? [...data?.data] : []), [data]);

  const [showStorageOnly, setShowStorageOnly] = useState(false);
  const [filteredData, setFilteredData] = useState([...allData]);

  const filteredDataResult = filteredData.length;

  const filterByStorage = () => {
    let list = [...allData];
    if (showStorageOnly)
      list = list.filter(
        (item) =>
          item?.userId === userId ||
          item?.brand?.connectedHaplAccount === userId ||
          item?.isPurchased === true
      );
    setFilteredData(list);
  };

  useEffect(() => {
    if (total > 0) filterByStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStorageOnly]);

  useEffect(() => {
    setFilteredData(allData);
  }, [allData]);

  return (
    <G.AllContainer>
      <SearchResultHeader
        total={filteredDataResult}
        showStorageOnly={showStorageOnly}
        setShowStorageOnly={setShowStorageOnly}
      />

      {filteredDataResult > 0 ? (
        <FragmentStickerData text={text} data={filteredData} />
      ) : (
        <NoResult text={text} showStorageOnly={showStorageOnly} />
      )}
    </G.AllContainer>
  );
};

export default FragmentStickerMenu;
