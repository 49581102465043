import styled from 'styled-components';

export const NextButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  height: 56px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.color.gray_200 : theme.color.primary_500};
  border: none;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextButtonText = styled.div<{ disabled: boolean }>`
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.gray_500 : theme.color.white};
`;
