import { DragEvent, ReactNode } from 'react';
import '../../App.css';

type ScrollableWrapperProps = {
  children: ReactNode;
};

const WebViewWrapper = ({ children }: ScrollableWrapperProps) => {
  const handleDragStart = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className='scrollable-container no-drag' onDragStart={handleDragStart}>
      {children}
    </div>
  );
};

export default WebViewWrapper;
