import React from 'react';
import verticalAlignTop from '../../../assets/images/store/exhibition/vertical-align-top.svg';
import smoothScrollToTop from '../../../utils/_common/smoothScrollToTop';
import * as S from './ScrollTopButton.styles';

export type ScrollTopButtonSize = 'small' | 'large';

type Props = {
  size: ScrollTopButtonSize;
  onClick?: () => void;
};

const ScrollTopButton = ({ size, onClick }: Props) => {
  return (
    <S.ScrollTopButton
      $size={size}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          smoothScrollToTop();
        }
      }}
    >
      <S.Icon src={verticalAlignTop} alt='화면 맨 위로 버튼' $size={size} />
    </S.ScrollTopButton>
  );
};

export default ScrollTopButton;
