import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import SEARCH_PARAM_KEYS from 'src/constants/searchParamKeys';
import { getPlatform } from 'src/utils/platformUtils';
import useNavigation from '../useNavigation';

const useKeywordSearch = () => {
  const navigate = useNavigate();
  const { navigateToSearchScreen } = useNavigation();
  const location = useLocation();

  const handleKeywordSearch = (keyword: string) => {
    const isApp = !!getPlatform();
    const isOnSearchPage = location.pathname === PATH.SEARCH;

    if (isApp && !isOnSearchPage) {
      navigateToSearchScreen(keyword);
    } else {
      const encodedKeyword = encodeURIComponent(keyword);
      navigate(`${PATH.SEARCH}?${SEARCH_PARAM_KEYS.KEYWORD}=${encodedKeyword}`);
    }
  };

  return { handleKeywordSearch };
};

export default useKeywordSearch;
