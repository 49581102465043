import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SEARCH_PARAM_KEYS from 'src/constants/searchParamKeys';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useKeywordSearch from 'src/hooks/search/useKeywordSearch';
import useSearchWithTracking from 'src/hooks/search/useSearchWithTracking';
import useSearchHistory from 'src/hooks/useSearchHistory';
import { getPlatform } from 'src/utils/platformUtils';
import RecentSearch from '../../../components/Search/RecentSearch/RecentSearch';
import SearchInput from '../../../components/Search/SearchInput/SearchInput';
import SearchMenu from '../../../components/Search/SearchMenu/SearchMenu';
import SearchResult from '../../../components/Search/SearchResult/SearchResult';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import smoothScrollToTop from '../../../utils/_common/smoothScrollToTop';
import { eventNames } from '../../../utils/mixpanel';
import { getAppConfigs } from '../../../utils/request';
import { theme } from '../../../utils/theme';
import * as S from './SearchScreen.styles';

const SearchScreen = () => {
  const { data: currentUser } = useCurrentUserQuery();

  const platform = getPlatform();
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchedKeyword = searchParams.get(SEARCH_PARAM_KEYS.KEYWORD) ?? '';
  const [searchTerm, setSearchTerm] = useState(searchedKeyword);
  const [showRecentSearch, setShowRecentSearch] = useState(searchedKeyword === '');
  const { addUserHistory } = useSearchHistory();
  const { handleKeywordSearch } = useKeywordSearch();

  const onSearch = (keyword: string) => {
    setSearchTerm(keyword);
    setShowRecentSearch(false);
    addUserHistory(keyword);

    if (keyword !== searchedKeyword) {
      handleKeywordSearch(keyword);
    }
  };

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onEnterPress = () => {
    if (searchTerm === '') return;

    onSearch(searchTerm);
  };

  const [currentTab, setCurrentTab] = useState('all');

  const { isLoading: allLoading, data: allData } = useSearchWithTracking(
    {
      keyword: searchedKeyword,
      type: '',
    },
    currentTab
  );

  const { isLoading: stickerLoading, data: stickers } = useSearchWithTracking(
    {
      keyword: searchedKeyword,
      type: 'sticker',
    },
    currentTab
  );

  const { isLoading: fragmentStickerLoading, data: fragmentStickers } =
    useSearchWithTracking(
      {
        keyword: searchedKeyword,
        type: 'fragmentSticker',
      },
      currentTab
    );

  const { isLoading: templateLoading, data: templates } = useSearchWithTracking(
    {
      keyword: searchedKeyword,
      type: 'template',
    },
    currentTab
  );

  const { isLoading: brandLoading, data: brands } = useSearchWithTracking(
    {
      keyword: searchedKeyword,
      type: 'brand',
    },
    currentTab
  );

  const [appConfigs, setAppConfigs] = useState({});

  const handleTabChange = (_event, newValue) => {
    setCurrentTab(newValue);
    smoothScrollToTop();
  };

  const fetchAppConfigs = async () => {
    const response = await getAppConfigs();
    if (response?.data?.data) {
      setAppConfigs(JSON.parse(JSON.stringify(response?.data?.data)));
    }
  };

  useEffect(() => {
    fetchAppConfigs();
  }, []);

  useLayoutEffect(() => {
    setSearchTerm(searchedKeyword);
    setShowRecentSearch(searchedKeyword === '');
    addUserHistory(searchedKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setShowRecentSearch(true);
    }
  }, [searchTerm]);

  return (
    <ThemeProvider theme={theme}>
      <S.MainContainer>
        <S.MainContainerLayout>
          <S.TopBarContainer>
            <SearchInput
              handleSearchTermChange={handleSearchTermChange}
              onEnterPress={onEnterPress}
              searchTerm={searchTerm}
            />
            {!showRecentSearch && (
              <S.TabMenuDivider>
                <SearchMenu value={currentTab} handleTabChange={handleTabChange} />
              </S.TabMenuDivider>
            )}
          </S.TopBarContainer>
          {showRecentSearch ? (
            <RecentSearch
              onTagSelect={(tag) => {
                onSearch(tag);

                trackEvent(eventNames.recentSearchQueryClick, currentUser, {
                  searchQuery: tag,
                  platformType,
                  isUser,
                });
              }}
            />
          ) : (
            <SearchResult
              setValue={setCurrentTab}
              tab={currentTab}
              searchedKeyword={searchedKeyword}
              allLoading={allLoading}
              allData={allData}
              fragmentStickerLoading={fragmentStickerLoading}
              fragmentStickers={fragmentStickers?.fragmentSticker}
              stickerLoading={stickerLoading}
              stickers={stickers?.sticker}
              templateLoading={templateLoading}
              templates={templates?.template}
              brandLoading={brandLoading}
              brands={brands?.brand}
              appConfigs={appConfigs}
            />
          )}
        </S.MainContainerLayout>
      </S.MainContainer>
    </ThemeProvider>
  );
};

export default SearchScreen;
