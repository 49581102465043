import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

const PURCHASE_ITEM_MAX_WIDTH = 768;

export const Ul = styled.ul`
  width: 100%;
  max-width: ${PURCHASE_ITEM_MAX_WIDTH}px;
  margin: 0 auto;
  ${responsivePadding};
`;
