import { Button } from '@mui/material';
import styled from 'styled-components';

export const StickerLayout = styled.div`
  gap: 24px;
  justify-content: space-between;
  overflow-x: auto;
`;

export const SearchResultNum = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR}
`;

export const ViewMoreContainer = styled(Button)`
  display: flex;
  padding: 0px;
  flex-direction: row;
  align-items: center;
`;

export const ViewMoreText = styled.span`
  ${({ theme }) => theme.font.B2_REGULAR}
  text-transform: none;
`;
