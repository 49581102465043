import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoContentBox from 'src/components/common/NoContentBox/NoContentBox';
import {
  NoContentMainText,
  NoContentSubText,
} from 'src/components/common/NoContentBox/NoContentBox.styles';
import CategoryBar from 'src/components/store/Bar/CategoryBar';
import ProductCard from 'src/components/store/Card/ProductCard';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useFetchAppConfigs from 'src/hooks/store/useFetchAppConfigs';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useResolveData from 'src/hooks/store/useResolveData';
import { eventNames, storeProductDetailSources } from 'src/utils/mixpanel';
import * as G from '../Store.styles';
import * as S from './index.styles';

const pageName = 'template';
const SORT_BY_ENUM = {
  resent: 'resent',
  popular: 'popular',
};

const TemplatePage = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  const [selectedCategory, setSelectedCategory] = useState(0);

  const { appConfigs } = useFetchAppConfigs('types');
  const { filterData, sortBy, setSortBy } = useResolveData(
    true,
    data,
    selectedCategory,
    appConfigs,
    'types'
  );

  const { data: currentUser } = useCurrentUserQuery();

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  useEffect(() => {
    trackEvent(eventNames.templateTab, currentUser, { platformType, isUser });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <G.FullView>
      {/* <Advertisement arrowBadge={store_images.arrowBadge} banners={data?.banners || []} /> */}

      <G.Part>
        <div>
          <S.TemplatePartBar>
            <S.TemplatePartTitle>{t('store.common.popularTemplate')}</S.TemplatePartTitle>
          </S.TemplatePartBar>
          <S.TemplateViewContainer>
            <S.TemplateViewBoard>
              <ProductCard
                items={
                  data?.popularTemplates?.length > 0
                    ? data?.popularTemplates?.slice(0, 6)
                    : []
                }
                pageInfo={pageName}
                source={storeProductDetailSources.templatePopular}
              />
            </S.TemplateViewBoard>
            <S.TemplateViewBoard>
              <ProductCard
                items={
                  data?.popularTemplates?.length > 0
                    ? data?.popularTemplates?.slice(6, 12)
                    : []
                }
                pageInfo={pageName}
                source={storeProductDetailSources.templatePopular}
              />
            </S.TemplateViewBoard>
          </S.TemplateViewContainer>
        </div>

        <div>
          <CategoryBar
            items={appConfigs['types'] || []}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            pageName={pageName}
          />

          <S.TemplateAlignmentContainer>
            <S.TemplateAlignment
              selected={sortBy === SORT_BY_ENUM.resent}
              onClick={() => setSortBy(SORT_BY_ENUM.resent)}
            >
              {t('store.common.mostRecent')}
            </S.TemplateAlignment>

            <S.TemplateAlignment
              selected={sortBy === SORT_BY_ENUM.popular}
              onClick={() => setSortBy(SORT_BY_ENUM.popular)}
            >
              {t('store.common.mostPopular')}
            </S.TemplateAlignment>
          </S.TemplateAlignmentContainer>

          {filterData.length > 0 && (
            <G.TodayNewProductBoard>
              <ProductCard
                items={filterData}
                pageInfo={pageName}
                source={storeProductDetailSources.templateCategory}
              />
            </G.TodayNewProductBoard>
          )}

          {!filterData.length && (
            <NoContentBox height={288} paddingTop={60} paddingBottom={174}>
              <NoContentMainText>
                {t('store.noItem.sticker.noItemInCategory')}
              </NoContentMainText>
              <NoContentSubText>{t('store.noItem.sticker.comment')}</NoContentSubText>
            </NoContentBox>
          )}
        </div>
      </G.Part>
    </G.FullView>
  );
};

export default TemplatePage;
