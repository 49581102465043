import styled from 'styled-components';

export const StoreViewTitle = styled.p`
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.H2_SEMIBOLD}
`;

export const StoreViewDescription = styled.p`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B1_REGULAR}
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.B2_REGULAR}
  }
`;

export const StoreViewButton = styled.button`
  border: 1px solid ${({ theme }) => theme.color.primary_300};
  border-radius: 56px;
  margin-top: 24px;
  padding: 14px 26.5px;
  background: none;
  ${({ theme }) => theme.font.BUTTON}
  color: ${({ theme }) => theme.color.primary_300};
`;
