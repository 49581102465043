import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const MenuButton = styled.button<{ onClick?: () => void }>`
  width: 100%;
  padding: 17px 8px;
  background: none;
  border: none;
  ${({ onClick }) => onClick && 'cursor: pointer'};

  &:hover {
    opacity: 0.6;
  }

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 24px;
  }

  display: flex;
  justify-content: space-between;
`;

export const ProfileMenuTitle = styled.p<{ $isBlur?: boolean }>`
  ${({ theme }) => theme.font.H3_BOLD};
  color: ${({ theme, $isBlur }) =>
    $isBlur ? theme.color.gray_400 : theme.color.gray_900};
`;
