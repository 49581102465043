import { useTranslation } from 'react-i18next';
import { productLabel } from 'src/helpers/products';
import { thousandsFormate } from '../../../utils/amount';
import { formateDateTime } from '../../../utils/dateTime';
import * as S from './PurpleHistoryUnit.styles';

type Props = {
  purpleHistoryData: {
    balance: number;
    bonusPoints: number;
    brandId?: string | null;
    category:
      | 'Deduct'
      | 'Error'
      | 'Event'
      | 'Gift'
      | 'Issue'
      | 'Point Purchase'
      | 'Product Purchase'
      | 'Retrieve'
      | 'Review Reward'
      | 'Sponsored'
      | 'Restore';
    content?: string;
    createdAt: string;
    fee?: number;
    isImageReview: boolean;
    isPointPurchased: boolean;
    isProductPurchased: boolean;
    issueType: 'Issue' | 'Deduct' | null;
    orderId?: string;
    points: number;
    productId: {
      engName?: string;
      korName?: string;
      _id?: string;
      brandId?: {
        name?: string;
        nameEN?: string;
        _id?: string;
      };
    };
    purplePointId?: string;
    updatedAt: string;
    userId: string;
    _id: string;
  }[];
};

const PurpleHistoryUnit = ({ purpleHistoryData }: Props) => {
  const { t, i18n } = useTranslation();

  const getTitle = (category: string, isImageReview: boolean) => {
    switch (category) {
      case 'Deduct':
        return t('store.purpleStation.deduction');

      case 'Error':
        return t('store.item.error');

      case 'Event':
        return t('store.item.event');

      case 'Gift':
        return t('store.item.gift');

      case 'Issue':
        return t('store.purpleStation.issued');

      case 'Point Purchase':
        return t('store.item.isPointPurchased');

      case 'Product Purchase':
        return t('store.item.isProductPurchased');

      case 'Retrieve':
        return isImageReview
          ? `${t('store.item.photoReview')} (${t('store.item.cancelled')})`
          : `${t('store.item.textReview')} (${t('store.item.cancelled')})`;

      case 'Review Reward':
        return isImageReview ? t('store.item.photoReview') : t('store.item.textReview');

      case 'Sponsored':
        return t('store.item.sponsored');

      case 'Restore':
        return isImageReview
          ? `${t('store.item.photoReview')} (${t('store.item.restored')})`
          : `${t('store.item.textReview')} (${t('store.item.restored')})`;

      default:
        return '';
    }
  };

  const getAmountSign = (item: any) => {
    switch (true) {
      case item.isProductPurchased:
      case item.issueType === 'Deduct':
      case item.category === 'Retrieve':
        return '-';

      default:
        return '+';
    }
  };

  const getDetail = (item: Props['purpleHistoryData'][number]) => {
    switch (true) {
      case item.category === 'Error':
      case item.category === 'Event':
      case item.category === 'Gift':
      case item.category === 'Sponsored':
        return item.content;

      case item.isProductPurchased:
      case item.category === 'Review Reward':
      case item.category === 'Retrieve':
      case item.category === 'Restore':
        return productLabel(item.productId, i18n);

      case item.isPointPurchased:
        return `${thousandsFormate(item.points - item.bonusPoints)} ${t(
          'store.purpleStation.purplePurchased'
        )} ${
          item.bonusPoints
            ? `(${thousandsFormate(item.bonusPoints)}P ${t('common.bonus')})`
            : ''
        }`;

      case item.issueType === 'Issue':
        return `${thousandsFormate(item.points)} ${t('store.purpleStation.issued')}`;

      case item.issueType === 'Deduct':
        return `${thousandsFormate(item.points)} ${t('store.purpleStation.deduction')}`;

      default:
        return '';
    }
  };

  return (
    <>
      {purpleHistoryData.map((item, index) => {
        const title = getTitle(item.category, item.isImageReview);
        const amountSign = getAmountSign(item);
        const detail = getDetail(item);

        return (
          <S.HistoryUnitBox key={index}>
            <S.HistoryUnitItemContainer>
              <S.HistoryUnitDetailContainer>
                <S.HistoryUnitDetailSmall>
                  <S.HistoryUnitDetailText>
                    {formateDateTime(item.createdAt)}
                  </S.HistoryUnitDetailText>
                  <S.HistoryUnitDetailText>{title}</S.HistoryUnitDetailText>
                </S.HistoryUnitDetailSmall>
                <S.HistoryUnitDetailBig>{detail}</S.HistoryUnitDetailBig>
              </S.HistoryUnitDetailContainer>

              <S.HistoryUnitPointCount>
                {amountSign === '+' ? (
                  <S.PlusPointContainer>
                    <S.PlusPointText>+{thousandsFormate(item.points)}P</S.PlusPointText>
                  </S.PlusPointContainer>
                ) : (
                  <S.MinusPointText>-{thousandsFormate(item.points)}P</S.MinusPointText>
                )}
              </S.HistoryUnitPointCount>
            </S.HistoryUnitItemContainer>
          </S.HistoryUnitBox>
        );
      })}
    </>
  );
};

export default PurpleHistoryUnit;
