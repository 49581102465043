import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const DetailItemContainer = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-bottom: 20px;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const DetailItemLeftContainer = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DetailItemRightContainer = styled.div`
  width: 50%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const DetailItemBox = styled.div`
  flex-direction: row;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DetailItemKey = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  height: 22px;
  margin-right: 20px;
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.21px;
  display: flex;
  justify-content: flex-start;
`;

export const DetailItemValue = styled.span`
  color: ${({ theme }) => theme.color.gray_600};
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.21px;
  display: flex;
  justify-content: flex-start;
`;
