import React from 'react';
import * as S from './BottomModalSheet.styles';

type Props = {
  children: React.ReactNode;
  onBackDropClick?: () => void;
};

const BottomModalSheet = ({ children, onBackDropClick }: Props) => {
  return (
    <>
      <S.BackDrop onClick={onBackDropClick} />
      <S.Content>{children}</S.Content>
    </>
  );
};

export default BottomModalSheet;
