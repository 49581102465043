import { useEffect, useMemo } from 'react';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useSearchQuery from 'src/hooks/apis/search/useSearchQuery';
import { eventNames } from 'src/utils/mixpanel';
import { FetchSearchResultParams } from '../../utils/request';
import { trackEvent } from '../store/useMixpanel';

const useSearchWithTracking = (params: FetchSearchResultParams, currentTab: string) => {
  const { data: currentUser } = useCurrentUserQuery();
  const queryResult = useSearchQuery(params);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const totalCount = useMemo(() => {
    if (!queryResult.data) return 0;
    const { sticker, template, fragmentSticker, brand } = queryResult.data ?? {};
    if (params.type === '') {
      return (
        (sticker?.total ?? 0) +
        (template?.total ?? 0) +
        (fragmentSticker?.total ?? 0) +
        (brand?.total ?? 0)
      );
    }
    return queryResult.data?.[params.type]?.total ?? 0;
  }, [queryResult.data, params.type]);

  const dataType = params.type || 'all';
  useEffect(() => {
    if (
      currentUser &&
      queryResult.data &&
      dataType === currentTab &&
      params.keyword.trim() !== ''
    ) {
      trackEvent(eventNames.search, currentUser, {
        count: totalCount,
        categoryName: currentTab,
        searchQuery: params.keyword,
        platformType,
        isUser,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data, currentTab]);

  return queryResult;
};

export default useSearchWithTracking;
