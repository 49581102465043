import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 24px;
  box-sizing: border-box;

  position: fixed;
  bottom: 0;

  @media (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 16px;
  }
`;
