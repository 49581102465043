import React, { useState, useEffect, useMemo, memo } from 'react';
import useReactNativeEvents from '../../../../../hooks/useReactNativeEvents';
import { useTranslation } from 'react-i18next';
import * as S from './StickerCategoryModal.styles';

const StickerCategoryModal = ({
  open,
  setOpen,
  categories,
  selectedCategory,
  getFilteredList,
  filteredData,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState([...selectedCategory]);
  const [filtered, setFiltered] = useState([...filteredData]);

  const { hideBottomTabs } = useReactNativeEvents();

  useEffect(() => {
    setSelected([...selectedCategory]);
    setFiltered([...filteredData]);
  }, [selectedCategory, filteredData]);

  const handleModalClose = () => {
    setOpen(false);
    hideBottomTabs({ hide: false });
  };

  const handleCategoryClick = (id) => {
    setSelected((prevSelected) => {
      const updated = prevSelected.includes(id)
        ? prevSelected.filter((c) => c !== id)
        : [...prevSelected, id];
      setFiltered(getFilteredList(updated));
      return updated;
    });
  };

  const submitLabelKey =
    selected.length > 0
      ? 'search.stickerCategoryModal.submitWithCount'
      : 'search.stickerCategoryModal.submit';

  const submitLabel = t(submitLabelKey, {
    count: filtered.length,
  });

  const renderedCategories = useMemo(
    () =>
      categories.map((category) => {
        const isSelected = selected.includes(category._id);
        const categoryName = i18n.language === 'en' ? category.engName : category.korName;

        return (
          <S.CategoryItemBtn
            key={category._id}
            onClick={() => handleCategoryClick(category._id)}
            $selectedCategory={isSelected}
          >
            <S.CategoriesText $selectedCategory={isSelected}>
              {categoryName}
            </S.CategoriesText>
          </S.CategoryItemBtn>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, selected, i18n.language]
  );

  return (
    <S.ModalWrapper
      aria-labelledby='Sticker-Category-Modal'
      open={open}
      onClose={handleModalClose}
      role='dialog'
    >
      <S.ModalTitle>{t('search.stickerCategoryModal.title')}</S.ModalTitle>

      <S.ModalContent dividers>
        <S.ModalContentWrapper>
          <S.ModalContentTitle>
            {t('search.stickerCategoryModal.title')}
          </S.ModalContentTitle>
          <S.MultipleSelectionText>
            {t('search.stickerCategoryModal.multipleSelection')}
          </S.MultipleSelectionText>
        </S.ModalContentWrapper>

        <S.CategoryContainer>{renderedCategories}</S.CategoryContainer>

        <S.SubmitBtn
          onClick={() => onSubmit(selected, filtered)}
          disabled={selected.length === 0}
          $selected={selected.length > 0}
        >
          {submitLabel}
        </S.SubmitBtn>
      </S.ModalContent>
    </S.ModalWrapper>
  );
};

export default memo(StickerCategoryModal);
