import { useTranslation } from 'react-i18next';
import store_images from 'src/assets/images/store/images';
import Modal from 'src/components/common/Modal';
import { POINT_PHOTO_REVIEW, POINT_REVIEW } from '../_constants';
import * as S from './index.styles';

type Props = {
  onClose: () => void;
  isPhotoReview: boolean;
};

const ReviewSuccessModal = ({ onClose, isPhotoReview }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal modalSheetStyle={{ width: '490px' }}>
      <S.PurpleIconContainer>
        <S.PurpleIcon src={store_images.purpleIcon} alt='purples' />
        <S.PurpleText>P</S.PurpleText>
      </S.PurpleIconContainer>
      <S.TextContainer>
        <S.SuccessText>
          {t('store.submitReview.successModal.title1')}
          <S.SuccessColorText>
            {t('store.submitReview.successModal.colorTitle', {
              point: isPhotoReview ? POINT_PHOTO_REVIEW : POINT_REVIEW,
            })}
          </S.SuccessColorText>
          {t('store.submitReview.successModal.title2')}
        </S.SuccessText>
        <S.SuccessSubText>
          {t('store.submitReview.successModal.description')}
        </S.SuccessSubText>
      </S.TextContainer>
      <S.ButtonContainer>
        <S.Button onClick={onClose}>{t('common.great')}</S.Button>
      </S.ButtonContainer>
    </Modal>
  );
};

export default ReviewSuccessModal;
