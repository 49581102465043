import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import BrandDetailTopBar from '../../../components/store/Bar/BrandDetailTopBar';
import PurchaseUnderBar from '../../../components/store/Bar/PurchaseUnderBar';
import ProductCard from '../../../components/store/Card/ProductCard';
// import { Document, Page, pdfjs } from "react-pdf";
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { Divider } from 'src/components/common/Divider';
import Category from 'src/components/store/SharedComponents/Category';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useKeywordSearch from 'src/hooks/search/useKeywordSearch';
import productQueryOptions from 'src/queries/productQueryOptions';
import { getAccessToken } from 'src/utils/request';
import PaymentWarningList from '../../../components/store/Pay/PaymentWarningList';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useRelatedProducts from '../../../hooks/store/useRelatedProducts';
import smoothScrollToTop from '../../../utils/_common/smoothScrollToTop';
import { APP_STORE, BASE_URL, GOOGLE_PLAY_STORE } from '../../../utils/configs';
import {
  eventNames,
  productProps,
  storeBrandDetailSources,
  storeProductDetailSources,
} from '../../../utils/mixpanel';
import * as G from '../Store.styles';
import NavigationBar from '../_components/Navigation';
import ReviewSection from '../_components/ReviewSection';
import DescriptionView from './DescriptionView';
import DetailItem from './DetailItem';
import GiftsProducts from './GiftProducts';
import TemplateTopPart from './TemplateTopPart';
import { COMPONENT_IDS } from './constants';
import * as S from './index.styles';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const TemplateDetail = (props) => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const params = useParams();
  const id = params?.id || props?.id;

  const { data: currentUser } = useCurrentUserQuery();
  const { data: reviewSummary } = useQuery(
    productQueryOptions.getProductReviewSummary(id)
  );
  const accessToken = getAccessToken();
  const { data: reviews } = useQuery(
    accessToken
      ? productQueryOptions.getProductReviewsLoggedIn(id)
      : productQueryOptions.getProductReviews(id)
  );
  const { handleKeywordSearch } = useKeywordSearch();

  const [product, setProduct] = useState<any>(null);
  const [pdfDetails, setPdfDetails] = useState<any>(null);

  const source = location.state?.source || storeProductDetailSources.search;
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const title = i18n.language === 'en' ? product?.engName : product?.korName;
  const url = `${BASE_URL}/store/template/detail/${product?._id}?lang=${
    i18n.language
  }&type=shared&redirectTo=${platform === 'ios' ? APP_STORE : GOOGLE_PLAY_STORE}`;
  const { relatedProducts, moreProducts } = useRelatedProducts(
    setProduct,
    id,
    setPdfDetails
  );

  const productDetailRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const recommendProductsRef = useRef<HTMLDivElement>(null);

  const onPurchase = () => {
    setProduct({ ...product, isPurchased: true });
  };

  useEffect(() => {
    smoothScrollToTop();
  }, [location.pathname]);

  useEffect(() => {
    if (product && currentUser)
      trackEvent(eventNames.templateDetailPage, currentUser, {
        ...productProps(product),
        source: source,
        platformType,
        isUser: !!currentUser,
      });
  }, [currentUser, product, source, platformType]);

  if (!product) return null;

  return (
    <S.DetailFullView>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
        <link rel='canonical' href={url} />
        <meta name='description' content={title} />
        <meta property='og:type' content={product?.productType || 'product'} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={product?.description || title} />
        <meta property='og:image' content={pdfDetails?.thumbnailSignUrl} />
        <meta property='og:url' content={url} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={product?.description || title} />
        <meta name='twitter:image' content={pdfDetails?.thumbnailSignUrl} />
      </Helmet>

      {product && (
        <BrandDetailTopBar
          barName={
            i18n.language === 'en' ? product?.brandId?.nameEN : product?.brandId?.name
          }
          brandId={product?.brandId ?? ''}
          product={product}
          onClose={props?.onClose}
          url={url}
          sourceScreen='templateDetail'
          source={storeBrandDetailSources.templateDetail}
        />
      )}

      {product && (
        <TemplateTopPart
          product={product}
          pdfDetails={pdfDetails}
          reviewSummary={reviewSummary}
        />
      )}

      <NavigationBar
        tabs={[
          {
            id: COMPONENT_IDS.PRODUCT_DETAIL,
            label: t('store.productDetailPage.productDetail'),
            ref: productDetailRef,
          },
          {
            id: COMPONENT_IDS.REVIEW,
            label: `${t('store.productDetailPage.reviews')} ${reviewSummary?.reviewNum}`,
            ref: reviewRef,
          },
          {
            id: COMPONENT_IDS.RECOMMEND_PRODUCTS,
            label: t('store.productDetailPage.recommend'),
            ref: recommendProductsRef,
          },
        ]}
      />

      {pdfDetails && <DetailItem pdfDetails={pdfDetails} ref={productDetailRef} />}

      {pdfDetails?.giftProducts?.length > 0 && (
        <GiftsProducts giftProducts={pdfDetails.giftProducts} />
      )}

      {pdfDetails?.description && (
        <DescriptionView dangerouslySetInnerHTML={{ __html: pdfDetails.description }} />
      )}

      {product?.tags?.length > 0 && (
        <G.TagContainer>
          <S.TemplatePartTitle>{t('store.stickerPage.tags')}</S.TemplatePartTitle>
          <S.TagsRow>
            {product?.tags?.map((tag, i) => (
              <Category
                onClick={() => {
                  handleKeywordSearch(tag);
                }}
                category={tag}
                key={`type_${i}`}
              />
            ))}
          </S.TagsRow>
        </G.TagContainer>
      )}

      <Divider height={12} $maxWidth={`${PRODUCT_DETAIL_MAX_WIDTH}px`} />
      <ReviewSection
        productId={id}
        productName={title}
        productType={product.productType}
        brandName={product.brandId.name}
        elementId={COMPONENT_IDS.REVIEW}
        totalReviewCount={reviewSummary?.reviewNum ?? 0}
        averageRating={reviewSummary?.averageRating ?? 0}
        reviews={reviews?.reviews ?? []}
        ref={reviewRef}
        isPurchased={product?.isPurchased ?? false}
        userReviewExists={reviews?.userReviewExists ?? false}
      />

      {(relatedProducts?.length > 0 || moreProducts?.length > 0) && (
        <>
          <Divider height={12} $maxWidth={`${PRODUCT_DETAIL_MAX_WIDTH}px`} />
          <S.RecommendProducts
            ref={recommendProductsRef}
            id={COMPONENT_IDS.RECOMMEND_PRODUCTS}
          >
            {relatedProducts?.length > 0 && (
              <>
                <S.TemplateDetailPartBar>
                  <S.TemplatePartTitle>
                    {t('store.stickerPage.similarProduct')}
                  </S.TemplatePartTitle>
                </S.TemplateDetailPartBar>

                <G.OtherProductShow>
                  <ProductCard
                    items={relatedProducts}
                    pageInfo={'template'}
                    source={storeProductDetailSources.templateDetailRecommendByCategory}
                  />
                </G.OtherProductShow>
              </>
            )}

            {moreProducts?.length > 0 && (
              <>
                <S.TemplateDetailPartBar>
                  <S.TemplatePartTitle>
                    {t('store.stickerPage.sameBrand')}
                  </S.TemplatePartTitle>
                </S.TemplateDetailPartBar>

                <G.OtherProductShow>
                  <ProductCard
                    items={moreProducts}
                    pageInfo={'template'}
                    source={storeProductDetailSources.templateDetailRecommendByBrand}
                  />
                </G.OtherProductShow>
              </>
            )}
          </S.RecommendProducts>
        </>
      )}

      <G.WarningContainer>
        <G.WarningTextContainer>
          <G.WarningTitle>{t('store.paymentModal.noteTitle')}</G.WarningTitle>
          <G.WarningContentsContainer>
            <G.Ul>
              <PaymentWarningList />
            </G.Ul>
          </G.WarningContentsContainer>
        </G.WarningTextContainer>
      </G.WarningContainer>

      <PurchaseUnderBar product={product} onPurchase={onPurchase} />
    </S.DetailFullView>
  );
};

export default TemplateDetail;
