import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MixpanelScreenLogger from 'src/components/Logger/MixpanelScreenLogger';
import CommonTopBar from 'src/components/store/Bar/CommonTopBar';
import useReactNativeEvents from 'src/hooks/useReactNativeEvents';
import userQueryOptions from 'src/queries/userQueryOptions';
import { ProductCategory } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import NavigationBar from './NavigationBar';
import NoItem from './NoItem';
import PurchaseItems from './PurchaseItems';

const PurchaseHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { postMessageToRN } = useReactNativeEvents();

  const [tab, setTab] = useState<ProductCategory>('all');

  const { data: purchasedProducts } = useQuery(
    userQueryOptions.getPurchasedProducts(tab)
  );

  const platformType = getPlatformType();
  const isApp = platformType === 'app';

  const handleBackButtonClick = () => {
    if (isApp) {
      postMessageToRN('navigateTo', { screen: 'MyPage' });
      return;
    }
    navigate(-1);
  };

  const handleTab = (tab: ProductCategory) => setTab(tab);

  return (
    <MixpanelScreenLogger
      params={{ eventName: eventNames.purchaseHistory, options: { platformType } }}
    >
      <CommonTopBar
        barName={t('store.myPage.purchaseHistory')}
        onBackButtonClick={handleBackButtonClick}
      />
      <NavigationBar tab={tab} handleTab={handleTab} />
      {purchasedProducts && purchasedProducts.length > 0 ? (
        <PurchaseItems items={purchasedProducts} />
      ) : (
        <NoItem />
      )}
    </MixpanelScreenLogger>
  );
};

export default PurchaseHistory;
