import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import { VIEW_MORE_HEIGHT } from './DescriptionView';

export const DescriptionView = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BREAK_POINTS}px) {
    padding: 0 16px;
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
      overflow: hidden;
      height: ${VIEW_MORE_HEIGHT}px;
      position: relative;

      &::after {
        width: 100%;
        height: 80px;
        background: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));
        content: '';
        position: absolute;
        bottom: 0;
      }
  `}
`;
