import CommonButton from 'src/components/common/CommonButton';
import styled from 'styled-components';

export const PurpleIconContainer = styled.div`
  padding: 26px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray_50};
`;

export const PurpleIcon = styled.img`
  width: 100px;
  height: 100px;
  display: block;
`;

export const PurpleText = styled.span`
  color: ${({ theme }) => theme.color.white};
  font-family: 'Pretendard-Medium';
  font-size: 50px;
  line-height: 100%;
  position: absolute;
`;

export const TextContainer = styled.div`
  padding: 30px 0 7px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;
`;

export const SuccessText = styled.div`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H3_BOLD};
  white-space: pre-wrap;
`;

export const SuccessColorText = styled.span`
  color: ${({ theme }) => theme.color.primary_500};
`;

export const SuccessSubText = styled.div`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_MEDIUM};
`;

export const ButtonContainer = styled.div`
  padding: 16px 24px 24px 24px;
`;

export const Button = styled(CommonButton)`
  padding: 20px 0;
`;
