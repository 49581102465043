import { styled } from 'styled-components';
import { theme } from '../../../styles/theme';

export const MainContainer = styled.div`
  display: flex;
`;

export const MainContainerLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TopBarContainer = styled.div`
  width: 100%;
  padding-top: 16px;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 1300;
`;

export const TabMenuDivider = styled.div`
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid ${theme.color.gray_200};
  display: flex;
  align-items: center;
  justify-content: center;
`;
