import BREAK_POINTS from 'src/constants/_common/breakPoints';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;
  gap: 8px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const UserImage = styled.img`
  width: 48px;
  height: 48px;
  background-color: ${colors.$gray300};
  border-radius: 50%;
  object-fit: cover;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserInfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UserNameSection = styled.div`
  color: ${colors.$gray900};
  ${({ theme }) => theme.font.H3_SEMIBOLD};
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tag = styled.div`
  border-radius: 4px;
  background-color: ${colors.$gray800};
  color: ${colors.$gray50};
  padding: 0 6px;
  ${({ theme }) => theme.font.B3_MEDIUM};
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewDate = styled.div`
  color: ${colors.$gray500};
  padding-left: 8px;
  ${({ theme }) => theme.font.B2_REGULAR};
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;
