import React from 'react';
import * as S from './Header.styles';

export const Header = () => {
  return (
    <S.Header>
      <S.SubHeader>
        하플 X 클럽제제
        <br />
        유기견 기부 콜라보
      </S.SubHeader>
      <S.MainHeader>가족을 찾아줘요</S.MainHeader>
    </S.Header>
  );
};
