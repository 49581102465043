import { Outlet } from 'react-router-dom';
import TabBar from '../common/TabBar';
import AppInstallBanner from '../store/AppInstallBanner';
import * as S from './WebStoreLayout.styles';

const WebStoreLayout = () => {
  const isWeb = !localStorage.getItem('platform');

  return (
    <S.Container>
      <S.ContentContainer>
        {isWeb && <AppInstallBanner />}
        <S.StretchContainer>
          <Outlet />
        </S.StretchContainer>
      </S.ContentContainer>

      {isWeb && (
        <S.TabBarWrapper>
          <TabBar />
        </S.TabBarWrapper>
      )}
    </S.Container>
  );
};

export default WebStoreLayout;
