import { useContext, useState } from 'react';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { ExhibitionContext } from '../../../../context/exhibitionContext';
import { UserContext } from '../../../../context/userContext';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import { eventNames, productProps } from '../../../../utils/mixpanel';
import { purchaseProduct } from '../../../../utils/request';

export const useProductPurchase = (
  currentImageIndex,
  setCurrentImageIndex,
  product,
  onPurchase,
  onClose,
  setIsBottomSheetOpen,
  setIsDonateCertificateModalOpen
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentBalance, refreshUserBalance }: any = useContext(UserContext);
  const affordAble = parseFloat(currentBalance) >= parseFloat(product?.salePrice ?? 0);
  const { exhibitionProductIds, refetchExhibitionData } = useContext(ExhibitionContext);
  const { data: currentUser } = useCurrentUserQuery();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const buyProduct = async () => {
    setIsLoading(true);
    const response = await purchaseProduct(product._id);
    if (response?.data?.status) {
      await refreshUserBalance();
      onPurchase();
      onClose();
      setCurrentImageIndex(0);

      if (currentUser) {
        trackEvent(eventNames.productPurchaseComplete, currentUser, {
          ...productProps(product),
          platformType,
        });
      }
      const isExhibitionProduct = exhibitionProductIds.includes(product._id);
      if (isExhibitionProduct) {
        refetchExhibitionData();
        setIsDonateCertificateModalOpen(true);
      } else {
        setIsBottomSheetOpen(true);
      }
    }
    setIsLoading(false);
  };

  const handleCharge = () => {
    if (isLoading) return;
    if (affordAble) {
      if (currentUser) {
        trackEvent(eventNames.productContinuePaymentClick, currentUser, {
          ...productProps(product),
          platformType,
        });
      }

      if (currentImageIndex === 0) setCurrentImageIndex(2);
      else if (currentImageIndex === 1) buyProduct();
    }
  };

  return { affordAble, handleCharge, isLoading };
};
