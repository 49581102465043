import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../constants/_common/breakPoints';

export const PartBar = styled.div`
  display: flex;
  width: 97%;
  min-height: 28px;
  flex-direction: row;
  justify-content: space-between;
`;

export const PartTitle = styled.span`
  color: #212121;
  font-family: 'Pretendard-Bold';
  font-size: 18px;
  line-height: 28px;
`;

export const TodayNewProductBoard = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: auto;
  width: calc(100% - 24px);
  gap: 20px;
  margin-top: 16px;

  @media screen and (max-width: ${BREAK_POINTS.DESKTOP}px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 18px;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: repeat(3, minmax(102px, 1fr));
    gap: 18px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: calc(100% - 16px);
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    grid-template-columns: repeat(3, minmax(90px, 1fr));
    gap: 12px;
  }
`;

export const FullView = styled.div`
  width: 100%;
  margin: 0 auto 100px auto;

  display: inline-flex;
  flex-direction: column;
`;

export const Part = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 20px auto 0 auto;
  box-sizing: border-box;
  padding: 0 24px;

  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 16px;
  }
`;

export const ProductDetailPart = styled.div`
  width: 100%;
  margin: 0 auto 60px auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    max-width: 92%;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    max-width: 95%;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    max-width: 100%;
  }
`;

export const TagContainer = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 24px;
  box-sizing: border-box;
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const OtherProductPart = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
`;

export const OtherProductShow = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  display: grid;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 22.5px;
  grid-auto-columns: 17%;
  grid-auto-flow: column;
  white-space: nowrap;

  padding: 0 24px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (max-width: 640px) {
    grid-auto-columns: 22%;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    grid-auto-columns: 30%;
    gap: 12px;
  }
`;

export const WarningContainer = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  margin-top: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WarningTextContainer = styled.div`
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  padding-top: 27px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: left;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0px 16px;
    padding-top: 24px;
  }
`;

export const WarningTitle = styled.span`
  color: #616161;
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
  padding-top: 24px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Ul = styled.ul`
  list-style-type: disc;
  color: #bdbdbd;
  margin-left: 16px;
`;

export const WarningContentsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
`;

export const WarningContents = styled.span`
  color: #bdbdbd;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  line-height: 22px;
  align-self: stretch;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
