import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 576px;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color.gray_50};
  border-radius: 16px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 32px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 999px;
  width: 80px;
  height: 80px;
  object-fit: cover;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 100px;
    height: 100px;
  }
`;

export const UserDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    align-items: stretch;
    gap: 13px;
  }
`;

export const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const UserName = styled.p`
  ${({ theme }) => theme.font.H2_BOLD};
  color: ${({ theme }) => theme.color.black};
`;

export const UserEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    flex-direction: row;
    gap: 12px;
  }
`;

export const UserEmail = styled.p`
  ${({ theme }) => theme.font.H3_MEDIUM};
  color: ${({ theme }) => theme.color.gray_500};
`;

export const UserSocialIconContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;
