import { useTranslation } from 'react-i18next';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import useModalControl from 'src/hooks/_common/useModalControl';
import useModalManager from 'src/hooks/modal/useModalManager';
import useNavigation from 'src/hooks/useNavigation';
import useReactNativeEvents from 'src/hooks/useReactNativeEvents';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './index.styles';
import ReviewDeletionInfoModal from './ReviewDeletionInfoModal';

type Props = {
  image: string;
  productName: string;
  brandName: string;
  productType: ProductTypes;
  productId: string;
  reviewId?: string;
  isDeleted?: boolean;
};

const ActionButtons = ({
  image,
  productName,
  brandName,
  productType,
  productId,
  reviewId,
  isDeleted,
}: Props) => {
  const { t } = useTranslation();
  const platformType = getPlatformType();

  const { navigateToCreateReview, navigateToEditReview } = useNavigation();
  const { postMessageToRN } = useReactNativeEvents();

  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');

  const {
    isOpen: isReviewDeletionModalOpen,
    open: openReviewDeletionModal,
    close: closeReviewDeletionModal,
  } = useModalControl();

  const handleCreateReview = () => {
    navigateToCreateReview(productId);
  };

  const handleGoToStorage = async () => {
    if (platformType === 'app') {
      postMessageToRN('navigateTo', { screen: 'Storage' });
    } else {
      showUseStorageInAppPopUp();
    }
  };

  return (
    <>
      <S.Buttons>
        {isDeleted && reviewId ? (
          <S.Button onClick={openReviewDeletionModal} buttonType='warning' height={42}>
            {t('store.purchaseHistory.item.deletedReview')}
          </S.Button>
        ) : !reviewId ? (
          <MixpanelClickLogger
            params={{
              eventName: eventNames.clickPostReview,
              options: {
                source: '/purchaseHistory',
                productName,
                productType,
                brandName,
                platformType,
              },
            }}
          >
            <S.Button onClick={handleCreateReview} buttonType='primary' height={42}>
              {t('store.purchaseHistory.item.postReview')}
            </S.Button>
          </MixpanelClickLogger>
        ) : (
          <MixpanelClickLogger
            params={{
              eventName: eventNames.clickEditReview,
              options: {
                source: '/purchaseHistory',
                productName,
                productType,
                brandName,
                platformType,
              },
            }}
          >
            <S.Button
              onClick={() => navigateToEditReview(productId, reviewId)}
              buttonType='ghost'
              height={42}
            >
              {t('store.purchaseHistory.item.editReview')}
            </S.Button>
          </MixpanelClickLogger>
        )}
        <MixpanelClickLogger
          params={{
            eventName: eventNames.goToStorageClick,
            options: { source: '/purchaseHistory', platformType },
          }}
        >
          <S.Button onClick={handleGoToStorage} buttonType='outline' height={42}>
            {t('store.purchaseHistory.item.goToStorage')}
          </S.Button>
        </MixpanelClickLogger>
      </S.Buttons>
      {isReviewDeletionModalOpen && reviewId && (
        <ReviewDeletionInfoModal
          image={image}
          productName={productName}
          productType={productType}
          reviewId={reviewId}
          brandName={brandName}
          onClose={closeReviewDeletionModal}
          onConfirm={() => {
            navigateToEditReview(productId, reviewId);
            closeReviewDeletionModal();
          }}
        />
      )}
    </>
  );
};

export default ActionButtons;
