import { PurchasedProduct } from 'src/apis/types/user.types';
import { ProductTypes } from 'src/types/product';
import ActionButtons from './ActionButtons';
import * as S from './index.styles';
import PurchaseItemCard from './PurchaseItemCard';
import StarRatingSection from './StarRatingSection';

type Props = {
  image: string;
  soldAt: string;
  productName: string;
  purchasePrice: number;
  productType: ProductTypes;
  brandName: string;
  productId: string;
  review: PurchasedProduct['review'];
};

const PurchaseItem = ({
  image,
  soldAt,
  productName,
  purchasePrice,
  productType,
  brandName,
  productId,
  review,
}: Props) => {
  return (
    <S.List>
      <PurchaseItemCard
        productId={productId}
        productType={productType}
        image={image}
        brandName={brandName}
        soldAt={soldAt}
        title={productName}
        purchasePrice={purchasePrice}
      />
      <StarRatingSection
        rating={review?.rating}
        size={20}
        isReviewed={Boolean(review?.rating)}
        reviewText={review?.content}
        productId={productId}
      />
      <ActionButtons
        image={image}
        productName={productName}
        brandName={brandName}
        productType={productType}
        productId={productId}
        reviewId={review?.id}
        isDeleted={review?.isDeleted}
      />
    </S.List>
  );
};

export default PurchaseItem;
