import { ReactComponent as ArrowDownIcon } from 'src/assets/images/store/arrowDown.svg';
import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const Footer = styled.footer`
  width: 100%;
  padding: 24px 0;
  background-color: ${({ theme }) => theme.color.gray_50};

  position: relative;
`;

export const Details = styled.details`
  width: 100%;
  max-width: 624px;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.gray_400};
  font-family: 'Pretendard-Bold';
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  text-align: start;
  box-sizing: border-box;
  padding: 0 16px;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0px 24px;
  }
`;

export const Summary = styled.summary`
  list-style: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
`;

export const ArrowUp = styled(ArrowDownIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.color.gray_400};
  transform: rotate(0deg);
  transition: transform 0.3s;

  ${Details}[open] & {
    transform: rotate(-180deg);
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: -12px;
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B3_REGULAR};
  background-color: ${({ theme }) => theme.color.gray_50};

  position: absolute;
  top: 100%;
  left: 0;
`;

export const ContentInner = styled.div`
  max-width: 624px;
  margin: 0 auto;
  padding: 20px 16px 0 16px;
  box-sizing: border-box;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 20px 24px 0 24px;
  }
`;

export const BusinessInfo = styled.div`
  white-space: pre-wrap;
`;

export const TermsAndConditions = styled.div`
  white-space: pre-wrap;
  font-family: 'Pretendard-Bold';
  font-size: 12px;
  line-height: 18px;
  margin: 18px 0;

  a {
    color: ${({ theme }) => theme.color.gray_400};
    text-decoration: none;
    display: inline;
  }
`;

export const Instructions = styled.div`
  white-space: pre-wrap;
  font-family: 'Pretendard-Regular';
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin: 12px 0;
`;
