export const ONE_LINK_URL = 'https://hapl-app.onelink.me/TJ3L/1nnmbfnb';

export const TERMS_AND_CONDITIONS_URL = {
  en: 'https://hapl.notion.site/Terms-Conditions-0f05df39f6444261a874a623ca1a7336?pvs=4',
  ko: 'https://hapl.notion.site/7b86576fdcf84daba160acff02246444?pvs=4',
};

export const PRIVACY_POLICY_URL = {
  en: 'https://hapl.notion.site/Privacy-Policy-9375c0643701424780b0bfec1fdd1974?pvs=4',
  ko: 'https://hapl.notion.site/97926a3a1db24e62b296bf16fef43c64?pvs=4',
};
