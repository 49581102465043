import { ReactComponent as ArrowDownIcon } from 'src/assets/images/store/arrowDown.svg';
import styled from 'styled-components';
import { ReactComponent as Apple } from '../../../assets/images/store/appleIcon.svg';
import { ReactComponent as Google } from '../../../assets/images/store/googleIcon.svg';
import { ReactComponent as Kakao } from '../../../assets/images/store/kakaoIcon.svg';

export const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LoginButtonContainer = styled.div`
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  width: 576px;

  @media screen and (max-width: 576px) {
    width: 95%;
  }
`;

export const LoginButton = styled.button<{
  $backgroundColor?: string;
  $hasBorder?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
  border: ${({ theme, $hasBorder }) =>
    $hasBorder ? `1px solid ${theme.color.gray_300}` : 'none'};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  // TODO: in later use -> 한번에 바꾸자
  /* &:hover {
    transition: 20ms all ease-in;
    opacity: 0.9;
  }
  &:active {
    scale: 0.985;
    opacity: 0.8;
  } */
`;

export const AppleIcon = styled(Apple)`
  width: 18px;
  height: 18px;
`;
export const GoogleIcon = styled(Google)`
  width: 18px;
  height: 18px;
`;
export const KakaoIcon = styled(Kakao)`
  width: 18px;
  height: 18px;
`;

export const LoginButtonLabel = styled.span<{ $labelColor: string }>`
  margin-left: 20px;
  color: ${({ $labelColor }) => $labelColor};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD};
`;

export const LanguageButtonContainer = styled.button`
  display: flex;
  height: 30px;
  width: 99px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: ${({ theme }) => `1px solid ${theme.color.gray_300}`};
  background: none;
`;

export const LanguageButtonLabel = styled.p`
  ${({ theme }) => theme.font.B3_REGULAR};
  color: ${({ theme }) => theme.color.gray_500};
  margin-left: 2px;
`;

export const ArrowDown = styled(ArrowDownIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.color.gray_500};
`;
