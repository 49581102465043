import { useEffect, useState } from 'react';

export const useStickyState = (
  observerRef: React.MutableRefObject<HTMLElement | null>
) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!observerRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.boundingClientRect.top < 0);
      },
      { threshold: [1] }
    );
    observer.observe(observerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [observerRef]);

  return isSticky;
};
