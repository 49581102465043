import * as ChannelIO from '@channel.io/channel-web-sdk-loader';
import { ReactNode, RefObject, createContext } from 'react';

type ChannelTalkContextValue = {
  hideChannelButton: () => void;
  showChannelButton: () => void;
};

export const ChannelTalkContext = createContext<ChannelTalkContextValue>({
  hideChannelButton: () => {},
  showChannelButton: () => {},
});

export const ChannelTalkContextProvider = ({
  children,
  buttonRef,
}: {
  children: ReactNode;
  buttonRef: RefObject<HTMLButtonElement>;
}) => {
  const isCustomChannelTalk =
    !localStorage.getItem('platform') ??
    !new URLSearchParams(window.location.search).get('platform');
  // web일 때 커스텀 채널톡을 사용
  // use custom channel talk when it is web

  const hideChannelButton = isCustomChannelTalk
    ? () => {
        if (buttonRef.current) {
          buttonRef.current.style.display = 'none';
        }
      }
    : ChannelIO.hideChannelButton;

  const showChannelButton = isCustomChannelTalk
    ? () => {
        if (buttonRef.current) {
          buttonRef.current.style.display = 'block';
        }
      }
    : ChannelIO.showChannelButton;

  return (
    <ChannelTalkContext.Provider value={{ hideChannelButton, showChannelButton }}>
      {children}
    </ChannelTalkContext.Provider>
  );
};
