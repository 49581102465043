import { useNavigate } from 'react-router-dom';

export const usePreviousLocation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const navigate = useNavigate();

  const handleBack = () => {
    if (type === 'shared') navigate('/store');
    else navigate(-1);
  };

  return { handleBack };
};
