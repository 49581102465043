import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import { PATH } from 'src/constants/_common/path';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './NoItem.styles';

const NoItem = () => {
  const { t } = useTranslation();
  const platformType = getPlatformType();
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.None />
      <S.Content>
        <S.Title>{t('store.purchaseHistory.noItem.title')}</S.Title>
        <S.Description>{t('store.purchaseHistory.noItem.description')}</S.Description>
        <MixpanelClickLogger
          params={{
            eventName: eventNames.goToStoreClick,
            options: { platformType },
          }}
        >
          <S.Button onClick={() => navigate(PATH.STORE)}>
            {t('store.purchaseHistory.noItem.button')}
          </S.Button>
        </MixpanelClickLogger>
      </S.Content>
    </S.Container>
  );
};

export default NoItem;
