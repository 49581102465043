import styled from 'styled-components';

const Button = styled.button<{ $borderColor?: string; $isMobile: boolean }>`
  background-color: transparent;
  border-radius: 99px;
  border: ${({ $borderColor }) => `3px solid ${$borderColor ?? '#ffffff'}`};
  color: ${({ $borderColor }) => `${$borderColor ?? '#ffffff'}`};
  cursor: pointer;
  font-family: 'GmarketSansBold';

  font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '24px')};
  line-height: ${({ $isMobile }) => ($isMobile ? '14px' : '24px')};
  padding: ${({ $isMobile }) => ($isMobile ? '16px 20px' : '24px 40px')};
`;

export default Button;
