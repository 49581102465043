import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackTextTopBar from '../../../components/store/Bar/BackTextTopBar';
import BuyPurplePage from './BuyPurple';
import PurpleHistory from './PurpleHistory';
import * as S from './PurplePage.styles';

const PurplePage = () => {
  const { t } = useTranslation();

  const [alignmentSelectedTab, setAlignmentSelectedTab] = useState(
    t('store.purpleStation.buy')
  );

  return (
    <S.PurpleFullView>
      <S.StickyContainer>
        <BackTextTopBar
          barName={t('store.storeTopBar.purpleStation')}
          beforePage='/store'
        />

        {/* Tabs */}
        <S.PurpleTabMenuUnderLine>
          <S.PurpleTabMenuCustomTab>
            <S.PurpleTabTextContainer>
              <S.PurpleTab
                selected={alignmentSelectedTab === t('store.purpleStation.buy')}
                onClick={() => {
                  setAlignmentSelectedTab(t('store.purpleStation.buy'));
                }}
              >
                {t('store.purpleStation.buy')}
              </S.PurpleTab>

              <S.PurpleTab
                selected={alignmentSelectedTab === t('store.purpleStation.history')}
                onClick={() => {
                  setAlignmentSelectedTab(t('store.purpleStation.history'));
                }}
              >
                {t('store.purpleStation.history')}
              </S.PurpleTab>
            </S.PurpleTabTextContainer>
          </S.PurpleTabMenuCustomTab>
        </S.PurpleTabMenuUnderLine>
      </S.StickyContainer>

      {alignmentSelectedTab === t('store.purpleStation.buy') && <BuyPurplePage />}

      {alignmentSelectedTab === t('store.purpleStation.history') && (
        <PurpleHistory setAlignmentSelectedTab={setAlignmentSelectedTab} />
      )}
    </S.PurpleFullView>
  );
};

export default PurplePage;
