import CommonButton from 'src/components/common/CommonButton';
import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.gray_800};
  ${({ theme }) => theme.font.H2_SEMIBOLD};
  margin-bottom: 8px;
`;

export const Description = styled.h1`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B1_REGULAR};
  margin-bottom: 20px;
`;

export const Actions = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Button = styled(CommonButton)`
  width: 142px;
  padding: 14px 0;
`;
