import styled from 'styled-components';

export const RollingNumberContainer = styled.div<{ $fontSize: number }>`
  height: ${({ $fontSize }) => $fontSize}px;
  overflow: hidden;
`;

export const RollingNumberWindow = styled.div<{
  $rollingNumber: number;
  $fontSize: number;
}>`
  transition: transform 1s;
  transform: translateY(
    ${({ $rollingNumber, $fontSize }) => $rollingNumber * -$fontSize}px
  );
`;

export const Num = styled.div<{ $fontSize: number }>`
  height: ${({ $fontSize }) => $fontSize}px;
`;
