import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import engTranslation from './en/en.json';
import korTranslation from './ko/kor.json';

const normalizeLanguageCode = (lng) => {
  if (!lng) return FALLBACK_LNG;
  const lang = lng?.split('-')?.[0];
  if (lang === 'kor') return 'ko';
  return lang;
};

const FALLBACK_LNG = 'ko';
export const resources = {
  en: { translation: engTranslation },
  ko: { translation: korTranslation },
} as const;

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,

    fallbackLng: FALLBACK_LNG,

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      convertDetectedLanguage: (lng) => normalizeLanguageCode(lng),
    },
  });
