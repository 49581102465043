import styled from 'styled-components';

export const WelcomeText = styled.p`
  ${({ theme }) => theme.font.H1_BOLD};
  color: ${({ theme }) => theme.color.black};
`;

export const Title = styled.p`
  ${({ theme }) => theme.font.H2_SEMIBOLD};
  color: ${({ theme }) => theme.color.gray_800};
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_600};
  white-space: pre-wrap;
`;
