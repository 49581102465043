import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useNavigate from '../../../hooks/useNavigation';
import {
  eventNames,
  recommendedCardProps,
  storeProductDetailSources,
} from '../../../utils/mixpanel';
import * as S from './RecommendCard.styles.ts';
import RecommendEachProduct from './RecommendEachProduct';

const RecommendCard = ({ item }) => {
  const { i18n } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const { navigateToInternalLink } = useNavigate();

  const goToBrandDetail = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    trackEvent(eventNames.recommendCardClick, currentUser, {
      ...recommendedCardProps(item),
      platformType,
      isUser: !!currentUser,
    });

    if (item?.link && item?.link !== 'null') {
      navigateToInternalLink({ link: item?.link });
    }
  };

  return (
    <S.RecommendItemContainer>
      {item?.image ? (
        <S.RecommendImageResize
          src={item?.image}
          alt={
            item?.showTitles && i18n.language === 'en' ? item?.titleEng : item?.titleKor
          }
          onClick={goToBrandDetail}
        />
      ) : (
        <S.RecommendImageResizeContainer onClick={goToBrandDetail} />
      )}

      <S.RecommendTextContainer onClick={goToBrandDetail}>
        <S.RecommendTextBrand>
          {item?.showSubTitles && i18n.language === 'en'
            ? item?.subTitleEng
            : item?.subTitleKor}
        </S.RecommendTextBrand>
        <S.RecommendTextDetail>
          {item?.showTitles && i18n.language === 'en' ? item?.titleEng : item?.titleKor}
        </S.RecommendTextDetail>
      </S.RecommendTextContainer>

      {item?.products?.length > 0 && (
        <RecommendEachProduct
          recommendItem={item?.products}
          source={storeProductDetailSources.todayRecommend}
        />
      )}
    </S.RecommendItemContainer>
  );
};

export default RecommendCard;
