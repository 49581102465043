import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { getPlatform } from 'src/utils/platformUtils';
import favorite from '../../../assets/images/store/favorite.svg';
import NoContentBox from '../../../components/common/NoContentBox/NoContentBox';
import {
  NoContentMainButton,
  NoContentMainText,
} from '../../../components/common/NoContentBox/NoContentBox.styles';
import BackTabBar from '../../../components/store/Bar/BackTabBar';
import ProductCard from '../../../components/store/Card/ProductCard';
import { BAR_ITEMS, BRAND } from '../../../constants/store/brandDetailBarItems';
import { UserContext } from '../../../context/userContext';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { copyToClipboard } from '../../../utils/_common/clipboard';
import { showToast } from '../../../utils/_common/showToast';
import smoothScrollToTop from '../../../utils/_common/smoothScrollToTop';
import { thousandsFormate } from '../../../utils/amount';
import { APP_STORE, BASE_URL, GOOGLE_PLAY_STORE } from '../../../utils/configs';
import {
  brandProps,
  eventNames,
  goStoreSources,
  storeProductDetailSources,
} from '../../../utils/mixpanel';
import {
  addToWishlist,
  getBrandDetails,
  removeFromWishlist,
} from '../../../utils/request';
import * as S from './BrandDetail.styles';

const BrandDetail = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id || props?.id;
  const { t, i18n } = useTranslation();
  const showLoginPopUp = useModalManager('LoginPopUp');
  const { wishlist, refreshWishlist } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const userId = localStorage.getItem('userId') ?? '';
  const accessToken = localStorage.getItem('accessToken') ?? '';
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const source = location.state?.source || 'searchResult';

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const [loading, setLoading] = useState(true);
  const [brandInfo, setBrand] = useState<any>({});
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);
  // const [selectedTab, setSelectedTab] = useState(t("store.brandPage.products"));

  const fetchBrandInfo = async () => {
    const response = await getBrandDetails(id);
    if (response?.data?.status) setBrand(response?.data?.data);
    setLoading(false);
  };

  const handleClick = (idx) => {
    setSelectedItemIndex(idx);
  };

  const resolveProducts = () => {
    let list: any = brandInfo?.products ? [...brandInfo?.products] : [];
    const filterBy = BAR_ITEMS[selectedItemIndex]
      ? BAR_ITEMS[selectedItemIndex].barName
      : 'all';

    if (filterBy !== 'all') list = list.filter((item) => item.productType === filterBy);

    setFilterData(list);
  };

  // const handleTabClick = (tabName) => {
  //   setSelectedTab(tabName);
  // };

  const items = BAR_ITEMS.map((item, idx) => (
    <S.Chip
      $isSelected={idx === selectedItemIndex}
      onClick={() => handleClick(idx)}
      key={idx}
    >
      <S.ChipText
        $isSelected={idx === selectedItemIndex}
        onClick={() => handleClick(idx)}
        key={idx}
      >
        {i18n.language === 'en' ? item.enBarName : item.korBarName}
      </S.ChipText>
    </S.Chip>
  ));

  useEffect(() => {
    smoothScrollToTop();
    fetchBrandInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resolveProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemIndex, brandInfo]);

  const url = `${BASE_URL}/store/brand/detail/${id}?lang=${
    i18n.language
  }&type=shared&redirectTo=${platform === 'ios' ? APP_STORE : GOOGLE_PLAY_STORE}`;
  const title =
    i18n.language === 'en' ? brandInfo?.brandId?.nameEN : brandInfo?.brandId?.name;

  const onShare = async () => {
    if (platform) {
      if (brandInfo) {
        trackEvent(eventNames.brandLinkShareClick, currentUser, {
          ...brandProps(brandInfo),
          link: url,
          platformType,
          isUser: !!currentUser,
        });
      }

      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'onShareButtonClicked',
          data: {
            url,
          },
        })
      );
    } else {
      const isSuccess = await copyToClipboard(url);
      trackEvent(eventNames.brandLinkShareClick, currentUser, {
        ...brandProps(brandInfo),
        link: url,
        platformType,
        isUser: !!currentUser,
      });
      showToast(
        isSuccess
          ? { toastMessage: t('store.linkCopiedMessage'), toastType: 'success' }
          : { toastMessage: t('store.linkNotCopiedMessage'), toastType: 'error' }
      );
    }
  };

  const toggleWishList = async () => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ hideBottomTabs: false });
      return;
    }

    if (brandInfo && userId && accessToken) {
      const isRemove = wishlist[brandInfo?._id];
      const platform = getPlatform();
      const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

      if (currentUser) {
        trackEvent(
          isRemove ? eventNames.brandUnlikeClick : eventNames.brandLikeClick,
          currentUser,
          {
            ...brandProps(brandInfo),
            platformType,
          }
        );
      }

      const response = isRemove
        ? await removeFromWishlist(wishlist[brandInfo?._id].id)
        : await addToWishlist(null, brandInfo?._id);

      if (response?.data?.status) {
        refreshWishlist();
        fetchBrandInfo();
      }
    }
  };

  const handleSocialButtonClick = (socialMedia) => {
    const platform = localStorage.getItem('platform');
    let link = '';
    if (socialMedia === 'instagram') {
      link = brandInfo.instagram;
    }
    if (socialMedia === 'youtube') {
      link = brandInfo.youtube;
    }
    if (socialMedia === 'twitter') {
      link = brandInfo.twitter;
    }

    if (link && (platform === 'ios' || platform === 'android')) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'openExternalLink',
          data: {
            link,
          },
        })
      );
    } else {
      window.location.href = link;
    }
  };

  const onGoToStore = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
    const isUser = currentUser ? true : false;

    trackEvent(eventNames.goToStoreClick, currentUser, {
      source: goStoreSources.brandDetail,
      platformType,
      isUser,
    });

    navigate(PATH.STORE);
  };

  useEffect(() => {
    const source = location.state?.source || storeProductDetailSources.search;

    if (brandInfo && Object.keys(brandInfo).length > 0) {
      trackEvent(eventNames.brandDetailPage, currentUser, {
        ...brandProps(brandInfo),
        source,
        platformType,
        isUser: !!currentUser,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, platformType, brandInfo.id]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
        <link rel='canonical' href={url} />
        <meta name='description' content={title} />
        <meta property='og:type' content={'brand'} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={brandInfo.description || title} />
        <meta
          property='og:image'
          content={brandInfo?.logoImage?.location || brandInfo?.logoImageSignUrl || null}
        />
        <meta property='og:url' content={url} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={brandInfo.description || title} />
        <meta
          name='twitter:image'
          content={brandInfo?.logoImage?.location || brandInfo?.logoImageSignUrl || null}
        />
      </Helmet>

      {loading ? (
        <S.ProgressContainer>
          <S.Progress size={64} />
        </S.ProgressContainer>
      ) : (
        <>
          <BackTabBar onClose={props?.onClose} />
          <S.BrandTop>
            <S.BrandCoverImageContainer>
              <S.BrandBackImageGradient />
              <S.BrandBackImageResize
                src={
                  brandInfo?.backgroundImage?.location
                    ? brandInfo?.backgroundImage?.location
                    : brandInfo?.backgroundImageSignUrl
                    ? brandInfo?.backgroundImageSignUrl
                    : BRAND[0].defaultBackground
                }
                alt='background'
              />
            </S.BrandCoverImageContainer>

            <S.BrandProfileContainer>
              <S.BrandProfileImageResize
                src={
                  brandInfo?.logoImage?.location ||
                  brandInfo?.logoImageSignUrl ||
                  BRAND[0].defaultProfile
                }
                alt='profile'
              />
            </S.BrandProfileContainer>
            <S.BrandInfoContainer>
              <S.BrandName>
                {i18n.language === 'en' ? brandInfo?.nameEN : brandInfo?.name}
              </S.BrandName>

              {(brandInfo?.introduction || brandInfo.description) && (
                <S.BrandInfo>
                  {brandInfo.introduction ?? brandInfo.description}
                </S.BrandInfo>
              )}

              <S.EntireBar>
                <S.IconBar>
                  <S.IconButtonResize type='button' onClick={onShare}>
                    <S.IconImgResize src={BRAND[0].share} alt='share' />
                  </S.IconButtonResize>

                  <S.LikeIcon onClick={toggleWishList} $active={wishlist[brandInfo?._id]}>
                    <S.LikeIconResize
                      src={wishlist[brandInfo?._id] ? BRAND[0].favoriteFilled : favorite}
                      alt='miniheart'
                    />

                    <S.LikeNumber $active={wishlist[brandInfo?._id]}>
                      {thousandsFormate(brandInfo?.wishlistCount || 0)}
                      {/* {wishlist[brandInfo?._id]
                        ? thousandsFormate(brandInfo?.wishlistCount || 0)
                        : t("store.brandPage.addToWishlist")} */}
                    </S.LikeNumber>
                  </S.LikeIcon>
                </S.IconBar>

                <S.IconBar>
                  {brandInfo?.instagram && (
                    <S.SocialButton onClick={() => handleSocialButtonClick('instagram')}>
                      <S.IconImgResize src={BRAND[0].instagram} alt='instagram' />
                    </S.SocialButton>
                  )}

                  {brandInfo?.youtube && (
                    <S.SocialButton onClick={() => handleSocialButtonClick('youtube')}>
                      <S.IconImgResize src={BRAND[0].youtube} alt='youtube' />
                    </S.SocialButton>
                  )}

                  {brandInfo?.twitter && (
                    <S.SocialButton onClick={() => handleSocialButtonClick('twitter')}>
                      <S.IconImgResize src={BRAND[0].twitterX} alt='twitter' />
                    </S.SocialButton>
                  )}
                </S.IconBar>
              </S.EntireBar>
            </S.BrandInfoContainer>
            {/* <div className='tab-menu-bar'>
                <span
                className={`tab-menu-content${selectedTab === '전체' ? '-selected' : ''}`}
                onClick={() => handleTabClick('프로필')}
                style={{cursor:'pointer'}}
                >
                전체
                </span>
                <span
                className={`tab-menu-content${selectedTab === '판매 상품' ? '-selected' : ''}`}
                onClick={() => handleTabClick('판매 상품')}
                style={{cursor:'pointer'}}
                >
                판매 상품 {brandInfo?.products?.length}
                </span>
                <span
                className={`tab-menu-content${selectedTab === '커뮤니티' ? '-selected' : ''}`}
                onClick={() => handleTabClick('커뮤니티')}
                style={{cursor:'pointer'}}
                >
                커뮤니티
                </span>
            </div> */}
          </S.BrandTop>

          <S.BrandDetailPart>
            <S.BrandDetailPartInner>
              <S.ChipBar>{items}</S.ChipBar>
              {filterData?.length > 0 ? (
                <S.BrandStickerBoard>
                  <ProductCard
                    items={filterData}
                    pageInfo={'brandDetail'}
                    // pageInfo={BAR_ITEMS[selectedItemIndex].barName}
                    source={storeProductDetailSources.brandDetail}
                  />
                </S.BrandStickerBoard>
              ) : (
                <NoContentBox height={288} paddingTop={60} paddingBottom={174}>
                  <NoContentMainText>
                    {t('store.noItem.sticker.noItemInCategory')}
                  </NoContentMainText>
                  <NoContentMainButton onClick={onGoToStore}>
                    {t('store.noItem.brand.button')}
                  </NoContentMainButton>
                </NoContentBox>
              )}
            </S.BrandDetailPartInner>
          </S.BrandDetailPart>
        </>
      )}
    </>
  );
};
export default BrandDetail;
