import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import store_images from '../../../assets/images/store/images';
import { UserContext } from '../../../context/userContext';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { thousandsFormate } from '../../../utils/amount';
import { eventNames, goStoreSources } from '../../../utils/mixpanel.ts';
import * as S from './PurpleChargingCompleteModal.styles';

function PurpleChargingCompleteModal({ isOpen, onClose, data, totalChargedPoints }) {
  const { currentBalance, currentUser } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;
  const onConfirm = () => {
    onClose();
  };

  const onGoToStore = () => {
    trackEvent(eventNames.goToStoreClick, currentUser, {
      source: goStoreSources.purchasePoint_complete,
      platformType,
      isUser,
    });

    navigate('/store');
  };

  return (
    <S.PurpleChargingComplete $isOpen={isOpen}>
      <S.CompleteBottomSheetContent>
        <S.ChargingDetailContainer>
          <S.ChargingComplete>
            <S.ChargingCompleteIconResize
              src={store_images.complete}
              alt='complete-icon'
            />

            <S.ChargingCompleteText>
              {/* {thousandsFormate(data?.price)} */}
              {platform === 'ios' || platform === 'android'
                ? thousandsFormate(data?.price)
                : thousandsFormate(data?.priceInWeb)}{' '}
              {t('store.purpleChargingModal.purchaseComplete')}
            </S.ChargingCompleteText>
          </S.ChargingComplete>

          <S.ChargingCompleteDetailText>
            {totalChargedPoints} {t('store.purpleChargingModal.purchaseDescription')}
          </S.ChargingCompleteDetailText>
        </S.ChargingDetailContainer>

        <S.AfterPurchaseBottomPart>
          <S.AfterPurchaseBar>
            <S.AfterPurchaseText>
              {t('store.purpleChargingModal.myPurple')}
            </S.AfterPurchaseText>

            <S.AfterPurchaseContainer>
              <S.AfterPurchaseIconContainer>
                <S.AfterPurchaseIconResize
                  src={store_images.purpleIcon}
                  alt='purple icon'
                />

                <S.AfterPurchaseIconText>P</S.AfterPurchaseIconText>
              </S.AfterPurchaseIconContainer>

              <S.AfterPurchasePrice>
                {thousandsFormate(currentBalance)}
              </S.AfterPurchasePrice>
            </S.AfterPurchaseContainer>
          </S.AfterPurchaseBar>

          <S.ChargingCompleteButtonContainer>
            <S.CheckStorageButton onClick={onConfirm}>
              <S.CheckStorageText>
                {t('store.purpleChargingModal.confirm')}
              </S.CheckStorageText>
            </S.CheckStorageButton>

            <S.LookAroundStoreButton onClick={onGoToStore}>
              <S.LookAroundStoreText>
                {t('store.purpleChargingModal.goStore')}
              </S.LookAroundStoreText>
            </S.LookAroundStoreButton>
          </S.ChargingCompleteButtonContainer>
        </S.AfterPurchaseBottomPart>
      </S.CompleteBottomSheetContent>
    </S.PurpleChargingComplete>
  );
}

export default PurpleChargingCompleteModal;
