import { useTranslation } from 'react-i18next';
import * as G from './../StoreComponent.styles';

const PaymentWarningList = () => {
  const { t } = useTranslation();
  return (
    <G.Ul>
      <li>
        <G.WarningTextContainer>
          <G.WarningTextList>
            {t('store.paymentModal.note1Begin')}
            <G.WarningTextHighlight>
              {t('store.paymentModal.note1Highlight')}
            </G.WarningTextHighlight>
            {t('store.paymentModal.note1End')}
          </G.WarningTextList>
        </G.WarningTextContainer>
      </li>
      <li>
        <G.WarningTextContainer>
          <G.WarningTextList>
            {t('store.paymentModal.note2Begin')}
            <G.WarningTextHighlight>
              {t('store.paymentModal.note2Highlight')}
            </G.WarningTextHighlight>
            {t('store.paymentModal.note2End')}
          </G.WarningTextList>
        </G.WarningTextContainer>
      </li>
      <li>
        <G.WarningTextContainer>
          <G.WarningTextList>
            {t('store.paymentModal.note3Begin')}
            <G.WarningTextHighlight>
              {t('store.paymentModal.note3Highlight')}
            </G.WarningTextHighlight>
            {t('store.paymentModal.note3Middle')}
            <G.WarningTextHighlight>
              {t('store.paymentModal.note3Highlight2')}
            </G.WarningTextHighlight>
            {t('store.paymentModal.note3End')}
          </G.WarningTextList>
        </G.WarningTextContainer>
      </li>
      <li>
        <G.WarningTextContainer>
          <G.WarningTextList>
            {t('store.paymentModal.note4Begin')}
            <G.WarningTextHighlight>
              {t('store.paymentModal.note4Highlight')}
            </G.WarningTextHighlight>
            {t('store.paymentModal.note4End')}
          </G.WarningTextList>
        </G.WarningTextContainer>
      </li>
      <li>
        <G.WarningTextList>
          {t('store.paymentModal.note5Begin')}
          <G.WarningTextHighlight>
            {t('store.paymentModal.note5Highlight')}
          </G.WarningTextHighlight>
          {t('store.paymentModal.note5End')}
        </G.WarningTextList>
      </li>
    </G.Ul>
  );
};

export default PaymentWarningList;
