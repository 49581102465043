import { ReactComponent as StarIcon } from 'src/assets/icons/Star.svg';
import LazyImage from 'src/components/common/LazyImage/LazyImage';
import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-bottom: 20px;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 0 16px;
    gap: 8px;
  }
`;

export const ImageResize = styled(LazyImage)`
  width: 134px;
  height: 134px;
  border-radius: 20px;
  background: ${({ theme }) => theme.color.gray_200};
  object-fit: cover;
  flex-shrink: 0;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 90px;
    height: 90px;
  }
`;

export const ProductInfo = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ProductName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.gray_900};
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 22px;
  text-align: left;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 14px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
`;

export const DiscountPercentage = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primary_400};
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  line-height: 22px;
`;

export const ProductPrice = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  font-family: 'Pretendard-Bold';
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 18px;
  }
`;

export const RemainingSaleDays = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.warning_500};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  line-height: 22px;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Star = styled(StarIcon)`
  fill: ${({ theme }) => theme.color.review_star};
`;

export const RatingValue = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  margin-left: 4px;
`;

export const ReviewCount = styled.div`
  color: ${({ theme }) => theme.color.gray_500};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  margin-left: 6px;
`;
