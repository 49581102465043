import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import store_images from '../../../assets/images/store/images';
import { thousandsFormate } from '../../../utils/amount';
import { UserContext } from '../../../context/userContext';
import * as S from './PurpleUnit.styles';

const PurpleUnit = ({ purplePoints, onPurchase }) => {
  const { i18n } = useTranslation();
  const { currentUser } = useContext(UserContext);
  const platform = localStorage.getItem('platform');

  return purplePoints.map((item, index) => (
    <S.UnitBox key={index}>
      <S.UnitItemContainer>
        <S.PurplePointContainer>
          <S.UnitPurpleIconContainer>
            <S.UnitPurpleIconResize src={store_images.purpleIcon} alt='purple icon' />
            <S.UnitPurpleIconText>P</S.UnitPurpleIconText>
          </S.UnitPurpleIconContainer>

          <S.UnitPurplePrice>{thousandsFormate(item.points)}</S.UnitPurplePrice>

          {currentUser?.purchaseCount === 0 ? (
            <S.UnitPurpleExtra>
              <S.UnitPurpleExtraText>
                {i18n.language === 'en' ? `2X on First Purchase!` : `첫 결제 시 2배!`}
              </S.UnitPurpleExtraText>
            </S.UnitPurpleExtra>
          ) : item.bonusPoints > 0 && currentUser?.purchaseCount !== 0 ? (
            <S.UnitPurpleExtra>
              <S.UnitPurpleExtraText>
                +{thousandsFormate(item.bonusPoints)}
                {i18n.language === 'en' ? `% Bonus` : `% 추가`}
              </S.UnitPurpleExtraText>
            </S.UnitPurpleExtra>
          ) : null}
        </S.PurplePointContainer>
        <S.PriceButton onClick={() => onPurchase(item)} style={{ cursor: 'pointer' }}>
          <S.PriceButtonText>
            {platform === 'ios' || platform === 'android'
              ? thousandsFormate(item.price)
              : thousandsFormate(item.priceInWeb)}
            {i18n.language === 'en' ? ` KRW` : `원`}
          </S.PriceButtonText>
        </S.PriceButton>
      </S.UnitItemContainer>
    </S.UnitBox>
  ));
};

export default PurpleUnit;
