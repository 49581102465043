import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import LazyImage from '../../common/LazyImage/LazyImage';

export const BrandEachCard = styled.div`
  width: calc(50% - 58px);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.color.gray_100};
  padding: 20px 24px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    width: calc(100% - 18px);
    padding: 24px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 8px;
  }
`;

export const BrandCardTitlePart = styled.div`
  width: 100% !important;
  flex-direction: row;
  align-content: center;
  display: flex;
  cursor: pointer;
`;

export const BrandCardTextContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const BrandCardImageResize = styled(LazyImage)`
  display: flex;
  flex-shrink: 0;
  border-radius: 999px;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.color.gray_200};

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 90px;
    height: 90px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 72px;
    height: 72px;
  }
`;

export const BrandCardNameBar = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const BrandCardBrandName = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_BOLD}
`;

export const BrandCardBrandDetail = styled.span`
  width: 100%;
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR}
`;

export const BrandCardArrow = styled.img`
  display: flex;
  width: 24px;
  height: 24px;
`;

export const BrandCardProdBoard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  width: 100%;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    gap: 11px;
    overflow-x: scroll;
  }
`;
