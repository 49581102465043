import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarRating from 'src/components/common/StarRating';
import { MAX_RATING } from '../_constants';
import * as S from './RatingSection.styles';
import * as G from './index.styles';

type Props = {
  rating: number;
  handleRatingChange: (value: number) => void;
};

const STAR_WIDTH = 38;

const RatingSection = ({ rating, handleRatingChange }: Props) => {
  const { t } = useTranslation();

  const [previewRating, setPreviewRating] = useState(0);

  const calculateRating = (mousePositionInContainer: number) => {
    const starIndex = Math.floor(mousePositionInContainer / STAR_WIDTH);
    const fraction = (mousePositionInContainer % STAR_WIDTH) / STAR_WIDTH;
    return Math.min(Math.max(starIndex + (fraction > 0.5 ? 1 : 0.5), 0.5), MAX_RATING);
  };

  const handleMouseEvent = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    setRating: (rating: number) => void
  ) => {
    const { currentTarget: starWrapper, clientX: mousePosition } = event;
    const { left } = starWrapper.getBoundingClientRect();
    const mousePositionInContainer = mousePosition - left;
    const newRating = calculateRating(mousePositionInContainer);
    setRating(newRating);
  };

  const handleRatingClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleMouseEvent(event, handleRatingChange);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (rating !== 0) return;
    handleMouseEvent(event, setPreviewRating);
  };

  const handleMouseLeave = () => {
    setPreviewRating(0);
  };

  return (
    <S.RatingSection>
      <G.Heading>{t('store.submitReview.ratingQuestion')}</G.Heading>
      <S.Stars
        onClick={handleRatingClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <StarRating rating={previewRating || rating} size={28} />
      </S.Stars>
    </S.RatingSection>
  );
};

export default RatingSection;
