import React, { useRef, useState } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { images } from '../../../../../assets/images/search/images';

import useReactNativeEvents from '../../../../../hooks/useReactNativeEvents';
import { useTranslation } from 'react-i18next';
import * as S from './TemplateCategoryModal.styles';
import ColorCircleWrapper from './components/ColorCircleWrapper';

const TemplateCategoryModal = (props) => {
  const { t, i18n } = useTranslation();
  const {
    open,
    setOpen,
    scroll,
    appConfigs,
    filters,
    getFilteredList,
    filteredData,
    onSubmit,
    filterTypes,
  } = props;
  const categoryRef = useRef(null);
  const hyperlinkRef = useRef(null);
  const datedTitleRef = useRef(null);
  const startingDayRef = useRef(null);
  const directionRef = useRef(null);
  const colorRef = useRef(null);

  const [activeText, setActiveText] = useState(t('search.stickerCategoryModal.title'));
  const [filtered, setFiltered] = useState([...filteredData]);
  const [selectedCategory, setSelectedCategory] = useState([...filters.selectedCategory]);
  const [hyperlink, setHyperlink] = useState(filters.hyperlink);
  const [dated, setDated] = useState(filters.dated);
  const [startingDay, setStartingDay] = useState(filters.startingDay);
  const [direction, setDirection] = useState(filters.direction);
  const [color, setColor] = useState([...filters.color]);

  const { hideBottomTabs } = useReactNativeEvents();
  const handleModalClose = () => {
    setOpen(false);
    hideBottomTabs({ hide: false });
  };
  const handleFilterChange = (value, type) => {
    let obj = { selectedCategory, hyperlink, dated, startingDay, direction, color };

    if (type === filterTypes.category) {
      const updated = selectedCategory.includes(value)
        ? selectedCategory.filter((c) => c !== value)
        : [...selectedCategory, value];
      obj = { ...obj, selectedCategory: updated };
      setSelectedCategory(updated);
    }

    if (type === filterTypes.hyperlink) {
      obj = { ...obj, hyperlink: value };
      setHyperlink(value);
    }

    if (type === filterTypes.dated) {
      obj = { ...obj, dated: value };
      setDated(value);
    }

    if (type === filterTypes.startingDay) {
      obj = { ...obj, startingDay: value };
      setStartingDay(value);
    }

    if (type === filterTypes.direction) {
      obj = { ...obj, direction: value };
      setDirection(value);
    }

    if (type === filterTypes.color) {
      const updated = color.includes(value)
        ? color.filter((c) => c !== value)
        : [...color, value];
      obj = { ...obj, color: updated };
      setColor(updated);
    }

    setFiltered(getFilteredList(obj));
  };

  const handleClick = (text) => {
    setActiveText(text);
  };

  const handleMenuClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const isEnabled =
    selectedCategory.length > 0 ||
    hyperlink ||
    dated ||
    startingDay ||
    direction ||
    color.length > 0;

  const submitLabelKey = isEnabled
    ? 'search.stickerCategoryModal.submitWithCount'
    : 'search.stickerCategoryModal.submit';

  const submitLabel = t(submitLabelKey, {
    count: filtered.length,
  });

  return (
    <S.TemplateCategoryModalWrapper
      onClose={handleModalClose}
      scroll={scroll}
      open={open}
    >
      <S.TemplateCategoryModalTitle>
        <S.TemplateCategoryModalTitleText
          $isActive={t('search.stickerCategoryModal.title') === activeText}
          onClick={() => {
            handleClick(t('search.stickerCategoryModal.title'));
            handleMenuClick(categoryRef);
          }}
        >
          {t('search.stickerCategoryModal.title')}
        </S.TemplateCategoryModalTitleText>

        <S.TemplateCategoryModalTitleText
          $isActive={t('search.templateCategoryModal.hyperlink') === activeText}
          onClick={() => {
            handleClick(t('search.templateCategoryModal.hyperlink'));
            handleMenuClick(hyperlinkRef);
          }}
        >
          {t('search.templateCategoryModal.hyperlink')}
        </S.TemplateCategoryModalTitleText>

        <S.TemplateCategoryModalTitleText
          $isActive={t('search.templateCategoryModal.datedTitle') === activeText}
          onClick={() => {
            handleClick(t('search.templateCategoryModal.datedTitle'));
            handleMenuClick(datedTitleRef);
          }}
        >
          {t('search.templateCategoryModal.datedTitle')}
        </S.TemplateCategoryModalTitleText>

        <S.TemplateCategoryModalTitleText
          $isActive={t('search.templateCategoryModal.startingDay') === activeText}
          onClick={() => {
            handleClick(t('search.templateCategoryModal.startingDay'));
            handleMenuClick(startingDayRef);
          }}
        >
          {t('search.templateCategoryModal.startingDay')}
        </S.TemplateCategoryModalTitleText>

        <S.TemplateCategoryModalTitleText
          $isActive={t('search.templateCategoryModal.direction') === activeText}
          onClick={() => {
            handleClick(t('search.templateCategoryModal.direction'));
            handleMenuClick(directionRef);
          }}
        >
          {t('search.templateCategoryModal.direction')}
        </S.TemplateCategoryModalTitleText>

        <S.TemplateCategoryModalTitleText
          $isActive={t('search.templateCategoryModal.color') === activeText}
          onClick={() => {
            handleClick(t('search.templateCategoryModal.color'));
            handleMenuClick(colorRef);
          }}
        >
          {t('search.templateCategoryModal.color')}
        </S.TemplateCategoryModalTitleText>
      </S.TemplateCategoryModalTitle>

      <S.TemplateCategoryModalContentWrapper dividers={scroll === 'paper'}>
        <div>
          <S.TemplateCategoryModalContent $isFirst={true} ref={categoryRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.stickerCategoryModal.title')}
            </S.TemplateCategoryModalContentTitle>
            <S.TemplateCategoryMultipleSelection>
              {t('search.stickerCategoryModal.multipleSelection')}
            </S.TemplateCategoryMultipleSelection>
          </S.TemplateCategoryModalContent>
          <S.CategoryContainer>
            {(appConfigs['types'] ?? []).map((category) => (
              <S.CategoryBtn
                $isSelected={selectedCategory.includes(category._id)}
                onClick={() => handleFilterChange(category._id, filterTypes.category)}
              >
                <S.CategoryBtnText $isSelected={selectedCategory.includes(category._id)}>
                  {i18n.language === 'en' ? category.engName : category.korName}
                </S.CategoryBtnText>
              </S.CategoryBtn>
            ))}
          </S.CategoryContainer>

          <S.TemplateCategoryModalContent $isFirst={false} ref={hyperlinkRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.templateCategoryModal.hyperlink')}
            </S.TemplateCategoryModalContentTitle>
          </S.TemplateCategoryModalContent>
          <S.HyperlinkContainerWrapper $isDirection={false}>
            <S.RadioWrapper $isFirst={true}>
              <FormControlLabel
                value='yes'
                control={
                  <Radio
                    checked={hyperlink === 'yes'}
                    onChange={() => handleFilterChange('yes', filterTypes.hyperlink)}
                  />
                }
                label={
                  <S.RadioText $isSelected={hyperlink === 'yes'}>
                    {t('search.templateCategoryModal.hyperlinkYes')}
                  </S.RadioText>
                }
              />
              <FormControlLabel
                value='no'
                control={
                  <Radio
                    checked={hyperlink === 'no'}
                    onChange={() => handleFilterChange('no', filterTypes.hyperlink)}
                  />
                }
                label={
                  <S.RadioText $isSelected={hyperlink === 'no'}>
                    {t('search.templateCategoryModal.hyperlinkNo')}
                  </S.RadioText>
                }
              />
            </S.RadioWrapper>
          </S.HyperlinkContainerWrapper>

          <S.TemplateCategoryModalContent $isFirst={false} ref={datedTitleRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.templateCategoryModal.datedTitle')}
            </S.TemplateCategoryModalContentTitle>
          </S.TemplateCategoryModalContent>
          <S.SelectionWrapper>
            <S.RadioWrapper $isFirst={false}>
              {appConfigs?.date?.map((item) => (
                <FormControlLabel
                  value={item._id}
                  control={
                    <Radio
                      checked={item._id === dated?._id}
                      onChange={() => handleFilterChange(item, filterTypes.dated)}
                    />
                  }
                  label={
                    <S.RadioText $isSelected={item._id === dated?._id}>
                      {i18n.language === 'en' ? item.engName : item.korName}
                    </S.RadioText>
                  }
                />
              ))}
            </S.RadioWrapper>
          </S.SelectionWrapper>

          <S.TemplateCategoryModalContent $isFirst={false} ref={startingDayRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.templateCategoryModal.startingDay')}
            </S.TemplateCategoryModalContentTitle>
          </S.TemplateCategoryModalContent>
          <S.SelectionWrapper>
            <S.RadioWrapper $isFirst={false}>
              {appConfigs?.startDate?.map((item) => (
                <FormControlLabel
                  value={item._id}
                  control={
                    <Radio
                      checked={item._id === startingDay?._id}
                      onChange={() => handleFilterChange(item, filterTypes.startingDay)}
                    />
                  }
                  label={
                    <S.RadioText $isSelected={item._id === startingDay?._id}>
                      {i18n.language === 'en' ? item.engName : item.korName}
                    </S.RadioText>
                  }
                />
              ))}
            </S.RadioWrapper>
          </S.SelectionWrapper>

          <S.TemplateCategoryModalContent $isFirst={false} ref={directionRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.templateCategoryModal.direction')}
            </S.TemplateCategoryModalContentTitle>
          </S.TemplateCategoryModalContent>
          <S.SelectionWrapper>
            <S.RadioWrapper $isFirst={false}>
              {appConfigs?.direction?.map((item) => {
                const directionImg = images[item.engName];

                return (
                  <S.HyperlinkContainerWrapper $isDirection={true}>
                    <S.DirectionImg
                      src={directionImg}
                      alt={directionImg}
                      $isHorizontal={item.engName === 'Horizontal'}
                      $isVertical={item.engName === 'Vertical'}
                    />
                    <FormControlLabel
                      value={item._id}
                      control={
                        <Radio
                          checked={item._id === direction?._id}
                          onChange={() => handleFilterChange(item, filterTypes.direction)}
                        />
                      }
                      label={
                        <S.RadioText $isSelected={item._id === direction?._id}>
                          {i18n.language === 'en' ? item.engName : item.korName}
                        </S.RadioText>
                      }
                    />
                  </S.HyperlinkContainerWrapper>
                );
              })}
            </S.RadioWrapper>
          </S.SelectionWrapper>

          <S.TemplateCategoryModalContent $isFirst={false} ref={colorRef}>
            <S.TemplateCategoryModalContentTitle>
              {t('search.templateCategoryModal.color')}
            </S.TemplateCategoryModalContentTitle>
            <S.TemplateCategoryMultipleSelection>
              {t('search.stickerCategoryModal.multipleSelection')}
            </S.TemplateCategoryMultipleSelection>
          </S.TemplateCategoryModalContent>
          <S.ColorWrapper>
            {appConfigs?.color?.map((item, index) => (
              <ColorCircleWrapper
                key={index}
                color={item.color}
                onClick={() => handleFilterChange(item._id, filterTypes.color)}
                selected={color}
                id={item._id}
              />
            ))}
          </S.ColorWrapper>
        </div>
      </S.TemplateCategoryModalContentWrapper>

      <S.ModalButtonWrapper>
        <S.ModalBtn
          $isEnabled={isEnabled}
          onClick={() =>
            onSubmit(
              { selectedCategory, hyperlink, dated, startingDay, direction, color },
              filtered
            )
          }
          disabled={!isEnabled}
        >
          {submitLabel}
        </S.ModalBtn>
      </S.ModalButtonWrapper>
    </S.TemplateCategoryModalWrapper>
  );
};

export default TemplateCategoryModal;
