import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../../assets/images/store/images';
import Modal from '../../../components/common/Modal';
import * as S from './AppInstallQRPopUp.styles';

type Props = {
  onClose: () => void;
};

const AppInstallQrPopUp = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} modalSheetStyle={{ width: '490px' }}>
      <S.Container>
        <S.CloseButton onClick={onClose} />
        <S.QRCodeImage src={images.oneLink} alt='QR code' />
        <S.BoldText>{t('store.appInstallQRCodePopUp.borderText')}</S.BoldText>
        <S.Text>{t('store.appInstallQRCodePopUp.text')}</S.Text>
        <S.Button onClick={onClose}>
          {t('store.appInstallQRCodePopUp.buttonText')}
        </S.Button>
      </S.Container>
    </Modal>
  );
};

export default AppInstallQrPopUp;
