import styled from 'styled-components';
import LazyImage from '../../../components/common/LazyImage/LazyImage';
import { BREAK_POINTS } from './constants';

export const Backdrop = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
`;

export const Content = styled.main`
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const ScrollTopButtonContainer = styled.div<{
  $isShow: boolean;
  $isMobile: boolean;
}>`
  position: fixed;
  bottom: ${({ $isShow, $isMobile }) =>
    !$isShow ? '-100px' : $isMobile ? '56px' : '80px'};
  right: ${({ $isMobile }) => ($isMobile ? '16px' : '20px')};
  z-index: 100;
  transition: bottom 0.5s;
`;

export const ImageWrapper = styled.div<{
  $imageSize: {
    width: number;
    height: number;
  };
}>`
  width: 100%;
  position: relative;
  padding-bottom: ${({ $imageSize }) =>
    `${($imageSize.height / $imageSize.width) * 100}%`};
`;

export const Image = styled(LazyImage)<{ $hasLink?: boolean }>`
  width: 100%;
  vertical-align: top;
  cursor: ${({ $hasLink }) => ($hasLink ? 'pointer' : 'default')};
  position: absolute;
  top: 0;
  left: 0;
`;

export const ButtonContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 100px;
  background-color: #000000;
`;

export const ExhibitionProductContainer = styled.div`
  padding: 0 120px;
  background-color: #000000;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE - 1}px) {
    padding: 0 24px;
  }
`;
