import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import useModalControl from 'src/hooks/_common/useModalControl';
import useModalManager from 'src/hooks/modal/useModalManager';
import userQueryOptions from 'src/queries/userQueryOptions';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import ReviewReportModal from './ReviewReportModal';
import * as S from './index.styles';

type Props = {
  productType: ProductTypes;
  productName: string;
  brandName: string;
  reviewId: string;
  authorId: string;
  authorNickname: string;
};

const ReportReviewButton = ({
  productType,
  productName,
  brandName,
  reviewId,
  authorId,
  authorNickname,
}: Props) => {
  const { t } = useTranslation();

  const {
    isOpen: isReviewReportModalOpen,
    open: openReviewReportModal,
    close: closeReviewReportModal,
  } = useModalControl();
  const showLoginPopUp = useModalManager('LoginPopUp');
  const { data: currentUser } = useQuery(userQueryOptions.getCurrentUser());

  const handleReviewReportClick = () => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ hideBottomTabs: false, source: 'clickReviewReport' });
      return;
    }
    openReviewReportModal();
  };

  return (
    <>
      <MixpanelClickLogger
        params={{
          eventName: eventNames.clickReportReview,
          options: {
            source:
              productType === 'sticker' ? '/storeStickerDetail' : '/storeTemplateDetail',
            productName,
            productType,
            brandName,
            authorId,
            authorNickname,
            platformType: getPlatformType(),
          },
        }}
      >
        <S.Action onClick={handleReviewReportClick}>
          {t('store.productDetailPage.reviewSection.report')}
        </S.Action>
      </MixpanelClickLogger>
      {isReviewReportModalOpen && (
        <ReviewReportModal
          reviewIdToReport={reviewId}
          productName={productName}
          productType={productType}
          brandName={brandName}
          authorId={authorId}
          authorNickname={authorNickname}
          onBackButtonClick={closeReviewReportModal}
        />
      )}
    </>
  );
};

export default ReportReviewButton;
