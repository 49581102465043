import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import LazyImage from '../../common/LazyImage/LazyImage';

export const ProductCard = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  aspect-ratio: 1;
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #f6f6f6;
  border-radius: 20px;
  border: 1px solid #f6f6f6;
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 126px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
    width: 100px;
  }
  @media screen and (max-width: 850px) {
    width: 90px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 80px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 90px;
  }
`;

export const BrandName = styled.span`
  overflow: hidden;
  color: #9e9e9e;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Pretendard-Regular';
  font-size: 14px;
  line-height: 22px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const RemainingDcDate = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.warning_500};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Pretendard-Medium';
  font-size: 12px;
  line-height: 18px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 11px;
  }
`;

export const ProductImage = styled(LazyImage)<{ $isSticker: boolean }>`
  width: ${({ $isSticker }) => ($isSticker ? '90%' : '100%')};
  height: ${({ $isSticker }) => ($isSticker ? '90%' : '100%')};
  padding: ${({ $isSticker }) => ($isSticker ? '5%' : '0')};
  object-fit: ${({ $isSticker }) => ($isSticker ? 'contain' : 'cover')};
  position: absolute;
  cursor: pointer;
`;

export const FavoriteIcon = styled.img`
  position: absolute;
  display: flex;
  width: 25%;
  height: 25%;
  right: 6px;
  bottom: 6px;
  cursor: pointer;
`;

export const Name = styled.span`
  overflow: hidden;
  color: #616161;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    font-size: 14px;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Price = styled.span`
  color: #212121;
  font-family: 'Pretendard-Bold';
  font-size: 17px;
  line-height: 26px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    font-size: 16px;
  }
`;

export const DiscountPercentage = styled.span`
  color: #7c4dff;
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  line-height: 18px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    font-size: 11px;
  }
`;
