import { useQuery } from '@tanstack/react-query';
import { getAccessToken, me } from '../../../utils/request';

const useCurrentUserQuery = () => {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: me,
    select: (data) => data?.data.data,
    enabled: !!getAccessToken(),
    staleTime: Infinity,
  });
};

export default useCurrentUserQuery;
