import { useTranslation } from 'react-i18next';
import Modal from 'src/components/common/Modal';
import * as S from './CloseConfirmModal.styles';

const CloseConfirmModal = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose}>
      <S.TextContainer>
        <S.Title>{t('store.submitReview.closeConfirmModal.title')}</S.Title>
        <S.Description>
          {t('store.submitReview.closeConfirmModal.description')}
        </S.Description>
      </S.TextContainer>
      <S.ButtonContainer>
        <S.Button onClick={onClose}>
          {t('store.submitReview.closeConfirmModal.continue')}
        </S.Button>
        <S.Button onClick={onConfirm} $isWarning>
          {t('store.submitReview.closeConfirmModal.exit')}
        </S.Button>
      </S.ButtonContainer>
    </Modal>
  );
};

export default CloseConfirmModal;
