import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import LazyImage from '../../common/LazyImage/LazyImage';

export const TOP_BAR_HEIGHT = 72;

export const BrandDetailTopBarContainer = styled.div`
  width: 100%;
  height: ${TOP_BAR_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  z-index: 10;
  top: 0;
`;

export const BrandDetailTopBar = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ArrowResize = styled.img`
  width: 24px;
  height: 24px;
  left: 0;
  position: absolute;
  cursor: pointer;
`;

export const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
`;

export const BrandProfile = styled(LazyImage)`
  width: 24px;
  height: 24px;
  border-radius: 999px;
  border: 0.5px solid ${({ theme }) => theme.color.gray_200};
  object-fit: cover;
`;

export const BarText = styled.span`
  color: ${({ theme }) => theme.color.black};
  align-items: center;
  ${({ theme }) => theme.font.H3_BOLD}
  overflow: hidden;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    max-width: 74px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  width: 68px;
  right: 0px;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  gap: 20px;
`;

export const GoToStoreHome = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;

  img {
    width: 100%;
    height: 24px;
    cursor: pointer;
  }
`;

export const ShareResize = styled.button`
  width: 24px;
  height: 24px;
  //   right: 0;
  //   position: absolute;
  background: none;
  border: none;
  padding: 0;

  img {
    width: 100%;
  }
`;
