import styled from 'styled-components';
import { ReactComponent as ReportProblem } from '../../assets/icons/report_problem.svg';

export const Container = styled.div`
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled(ReportProblem)`
  width: 90px;
  height: 90px;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  ${({ theme }) => theme.font.H2_BOLD}
`;

export const Text = styled.div`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
  white-space: pre-wrap;
`;

export const Button = styled.button`
  padding: 14px 17.5px;
  border: none;
  background: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.primary_500};
  border-radius: 56px;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.TAG_REGULAR}
`;
