import { ReactComponent as ArrowRight } from 'src/assets/images/store/arrowRight.svg';
import { theme } from 'src/styles/theme';
import * as S from './ProfileMenuItem.styles';

type Props = {
  title: string;
  onClick: () => void;
  isBlur?: boolean;
};

const ProfileMenuItem = ({ title, onClick, isBlur }: Props) => {
  return (
    <li>
      <S.MenuButton onClick={onClick}>
        <S.ProfileMenuTitle $isBlur={isBlur}>{title}</S.ProfileMenuTitle>
        <ArrowRight fill={isBlur ? theme.color.gray_300 : theme.color.gray_600} />
      </S.MenuButton>
    </li>
  );
};

export default ProfileMenuItem;
