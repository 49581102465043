import { useQuery } from '@tanstack/react-query';
import { getAccessToken, getUserBalance } from '../../../utils/request';

const useUserBalanceQuery = () => {
  return useQuery({
    queryKey: ['getUserBalance'],
    queryFn: async () => {
      const response = await getUserBalance();
      if (response?.data?.status) {
        return response?.data?.data || 0;
      }
    },
    enabled: !!getAccessToken(),
  });
};

export default useUserBalanceQuery;
