import { createContext, useEffect, useState } from 'react';
import { getHaplStoreData, getUserBalance, me } from '../utils/request';

type UserContextType = {
  wishlist: Record<string, any>;
  currentBalance: number;
  currentUser: any;
  refreshWishlist: () => Promise<void>;
  refreshUserBalance: () => Promise<void>;
  getLoginUser: () => Promise<void>;
};

export const UserContext = createContext<UserContextType>({
  wishlist: {},
  currentBalance: 0,
  currentUser: null,
  refreshWishlist: async () => {},
  refreshUserBalance: async () => {},
  getLoginUser: async () => {},
});

const UserContextProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState({});
  const [currentBalance, setCurrentBalance] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  const refreshWishlist = async () => {
    const response = await getHaplStoreData('brand');
    if (response?.data?.status) setWishlist(response?.data?.data?.wishLists ?? {});
  };

  const refreshUserBalance = async () => {
    const response = await getUserBalance();
    if (response?.data?.status) setCurrentBalance(response?.data?.data || 0);
  };

  const getLoginUser = async () => {
    const response = await me();
    if (response?.data?.status) setCurrentUser(response?.data?.data || null);
  };

  useEffect(() => {
    refreshWishlist();
    refreshUserBalance();
    getLoginUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        wishlist,
        currentBalance,
        currentUser,
        refreshWishlist,
        refreshUserBalance,
        getLoginUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
