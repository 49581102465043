import StarRating from 'src/components/common/StarRating';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { ProductTypes } from 'src/types/product';
import { formateDateTime } from 'src/utils/dateTime';
import EditReviewButton from './EditReviewButton';
import * as S from './index.styles';
import ReportReviewButton from './ReportReviewButton';

type Props = {
  productId: string;
  productName: string;
  productType: ProductTypes;
  brandName: string;
  reviewId: string;
  userProfileImage: string;
  nickName: string;
  reviewAuthorId: string;
  reviewDate: string;
  rating: number;
};

const Header = ({
  productId,
  productName,
  productType,
  brandName,
  reviewId,
  userProfileImage,
  nickName,
  reviewAuthorId,
  reviewDate,
  rating,
}: Props) => {
  const { data: currentUser } = useCurrentUserQuery();

  const isMyReview =
    currentUser?.id === reviewAuthorId || currentUser?._id === reviewAuthorId;

  return (
    <S.Container>
      <S.UserImage src={userProfileImage} />
      <S.Content>
        <S.TopBar>
          <S.UserNameSection>
            {nickName}
            {isMyReview && <S.Tag>MY</S.Tag>}
          </S.UserNameSection>
          <S.ActionSection>
            {isMyReview ? (
              <EditReviewButton
                productType={productType}
                productName={productName}
                brandName={brandName}
                productId={productId}
                reviewId={reviewId}
              />
            ) : (
              <ReportReviewButton
                productType={productType}
                productName={productName}
                brandName={brandName}
                reviewId={reviewId}
                authorId={reviewAuthorId}
                authorNickname={nickName}
              />
            )}
            <S.ReviewDate>{formateDateTime(reviewDate, 'YY.MM.DD')}</S.ReviewDate>
          </S.ActionSection>
        </S.TopBar>
        <S.BottomBar>
          <StarRating rating={rating} size={20} />
        </S.BottomBar>
      </S.Content>
    </S.Container>
  );
};

export default Header;
