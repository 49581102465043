export const API_URL = process.env.REACT_APP_API_URL || 'https://stage-api.hapl.shop'; // Live
// export const API_URL = 'http://192.168.100.161:4000'; // Local

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://staging.hapl.shop';

export const ADMIN_URL =
  process.env.REACT_APP_ADMIN_URL || 'https://stage-admin.hapl.shop/login/';

export const APP_URL = 'hapl://app';
export const DEV_MODE = process.env.REACT_APP_ENV_MODE || 'dev'; // prod|dev
export const APP_STORE =
  'https://apps.apple.com/us/app/hapl-digital-goods-platform/id1588993589';
export const GOOGLE_PLAY_STORE = APP_STORE; // 'https://play.google.com/store/apps/details?id=com.ailo_android.hapl';
export const MIXPANEL_TOKEN =
  DEV_MODE === 'prod'
    ? '0e5e506b2c4e7125cbe28984d36c430f'
    : '4a96e78a09409db424e4837aa90d671d';
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const KAKAO_OAUTH_TOKEN = 'd29a0b90fa3d8ac086145948e772720e';
export const GOOGLE_OAUTH_TOKEN =
  '653188375848-cm0vhig3egi27tn67q7m4anvj28imb7f.apps.googleusercontent.com';
