import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/common/Modal';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import productQueryOptions from 'src/queries/productQueryOptions';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './ReviewDeletionInfoModal.styles';

type Props = {
  image: string;
  productName: string;
  productType: ProductTypes;
  reviewId: string;
  brandName: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ReviewDeletionInfoModal = ({
  image,
  productName,
  productType,
  reviewId,
  brandName,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(productQueryOptions.getReviewDeletionInfo(reviewId));

  return (
    <Modal onClose={onClose}>
      <S.Container>
        <S.ImageContainer>
          <S.Image src={image} alt={productName} $productType={productType} />
        </S.ImageContainer>
        <S.TextContainer>
          <S.Title>{t('store.purchaseHistory.reviewDeletionInfoModal.title')}</S.Title>
          {data?.deleteMessage && <S.Description>{data.deleteMessage}</S.Description>}
        </S.TextContainer>
        <S.ButtonContainer>
          <S.CancelButton onClick={onClose}>{t('common.later')}</S.CancelButton>
          <MixpanelClickLogger
            params={{
              eventName: eventNames.clickEditReview,
              options: {
                source: '/purchaseHistory',
                productName,
                productType,
                brandName,
                platformType: getPlatformType(),
              },
            }}
          >
            <S.ConfirmButton onClick={onConfirm}>
              {t('store.purchaseHistory.item.editReview')}
            </S.ConfirmButton>
          </MixpanelClickLogger>
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  );
};

export default ReviewDeletionInfoModal;
