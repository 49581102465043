import { useTranslation } from 'react-i18next';
import { ProblemType } from 'src/apis/types/products.types';
import Textarea from 'src/components/common/Textarea';
import { MAX_REPORT_LENGTH, validateReportText } from '../_validate';
import * as S from './index.styles';
import ProblemTypeRadio from './ProblemTypeRadio';

export const PROBLEM_TYPES = [
  'irrelevant',
  'advertisement',
  'privacy',
  'inappropriate',
  'ipViolation',
  'other',
] as const;

type Props = {
  reportText: string;
  handleReportTextChange: (text: string) => void;
  problemType: ProblemType | null;
  handleProblemTypeChange: (type: ProblemType) => void;
};

const Content = ({
  reportText,
  handleReportTextChange,
  problemType,
  handleProblemTypeChange,
}: Props) => {
  const { t } = useTranslation();

  const isOtherSelected = problemType === 'other';

  return (
    <S.Container>
      <S.Inner>
        <S.Heading>{t('store.reviewReport.title')}</S.Heading>
        <S.Description>{t('store.reviewReport.prompt')}</S.Description>
        <ul>
          {PROBLEM_TYPES.map((type) => (
            <ProblemTypeRadio
              key={type}
              value={type}
              checked={problemType === type}
              onChange={() => handleProblemTypeChange(type)}
              label={t(`store.reviewReport.${type}`)}
            />
          ))}
        </ul>
        <Textarea
          value={reportText}
          onChange={handleReportTextChange}
          placeholder={t('store.reviewReport.placeholder')}
          characterLimit={MAX_REPORT_LENGTH}
          disabled={!isOtherSelected}
          isError={isOtherSelected && !validateReportText(reportText)}
        />
      </S.Inner>
    </S.Container>
  );
};

export default Content;
