import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from '../../../../context/userContext';
import NoResult from '../../NoResult/NoResult';
import SearchResultHeader from '../../SearchResultHeader/SearchResultHeader';
import BrandData from '../BrandMenu/components/BrandData';
import FragmentStickerData from '../FragmentStickerMenu/components/FragmentStickerData';
import * as G from '../ProductCommon.styles';
import StickerData from '../StickerMenu/components/StickerData';
import TemplateData from '../TemplateMenu/components/TemplateData';
import * as S from './AllMenu.styles';
import DefaultInfoHeader from './components/DefaultInfoHeader';

//TODO : total number shows too slow for fragment sticker only
const FragmentStickerSection = (props) => {
  const { text, t, showNumber: sliceCount, setValue, data, counts, currentUser } = props;
  const list = data?.data ? [...data.data].slice(0, sliceCount) : [];
  const showButton = data.total > list.length;

  return (
    <>
      <DefaultInfoHeader
        label={t('search.tab.fragmentSticker')}
        total={counts} // {data.total}
        onViewMore={() => setValue('fragmentSticker')}
        showButton={showButton}
        category='fragmentSticker'
        currentUser={currentUser}
      />

      <S.StickerLayout>
        <FragmentStickerData text={text} data={list} />
      </S.StickerLayout>
    </>
  );
};

const StickerSection = (props) => {
  const { text, t, showNumber: sliceCount, setValue, data, counts, currentUser } = props;

  const list = data?.data ? [...data.data].slice(0, sliceCount) : [];
  const showButton = data.total > list.length;

  return (
    <>
      <DefaultInfoHeader
        label={t('search.tab.sticker')}
        total={counts} // {data.total}
        onViewMore={() => setValue('sticker')}
        showButton={showButton}
        category='sticker'
        currentUser={currentUser}
      />

      <S.StickerLayout>
        <StickerData text={text} data={list} />
      </S.StickerLayout>
    </>
  );
};

const TemplateSection = (props) => {
  const { text, t, showNumber: sliceCount, setValue, data, counts, currentUser } = props;

  const list = data?.data ? [...data.data].slice(0, sliceCount) : [];
  const showButton = data.total > list.length;

  return (
    <G.MainDiv>
      <DefaultInfoHeader
        label={t('search.tab.template')}
        total={counts} // {data.total}
        onViewMore={() => setValue('template')}
        showButton={showButton}
        category='template'
        currentUser={currentUser}
      />

      <S.StickerLayout>
        <TemplateData text={text} data={list} />
      </S.StickerLayout>
    </G.MainDiv>
  );
};

const BrandSection = (props) => {
  const { text, t, showNumber: sliceCount, setValue, data, counts, currentUser } = props;

  const list = data?.data ? [...data.data].slice(0, sliceCount) : [];
  const showButton = data.total > list.length;
  return (
    <>
      <DefaultInfoHeader
        label={t('search.tab.brand')}
        total={counts} // {data.total}
        onViewMore={() => setValue('brand')}
        showButton={showButton}
        category='brand'
        currentUser={currentUser}
      />

      <BrandData text={text} data={list} />
    </>
  );
};

const AllMenu = ({ text, setValue, data, counts }) => {
  const { currentUser }: any = useContext(UserContext);
  const { t } = useTranslation();

  const defaultData = { total: 0, data: [] };

  const fragmentStickers = data?.fragmentSticker || defaultData;
  const stickers = data?.sticker || defaultData;
  const templates = data?.template || defaultData;
  const brands = data?.brand || defaultData;
  const [showStorageOnly, setShowStorageOnly] = useState(false);
  const isShow3 = useMediaQuery({ maxWidth: 576 });
  const showNumber = isShow3 ? 3 : 4;

  return (
    <G.AllContainer>
      {fragmentStickers.total === 0 &&
      stickers.total === 0 &&
      templates.total === 0 &&
      brands.total === 0 ? (
        <>
          <SearchResultHeader
            total={0}
            showStorageOnly={showStorageOnly}
            setShowStorageOnly={setShowStorageOnly}
          />

          <NoResult text={text} showStorageOnly={showStorageOnly} />
        </>
      ) : (
        <>
          {fragmentStickers.total > 0 && (
            <FragmentStickerSection
              text={text}
              t={t}
              setValue={setValue}
              data={fragmentStickers}
              counts={counts.totalFragments}
              showNumber={showNumber}
              currentUser={currentUser}
            />
          )}

          {stickers.total > 0 && (
            <StickerSection
              text={text}
              t={t}
              setValue={setValue}
              data={stickers}
              counts={counts.totalStickers}
              showNumber={showNumber}
              currentUser={currentUser}
            />
          )}

          {templates.total > 0 && (
            <TemplateSection
              text={text}
              t={t}
              setValue={setValue}
              data={templates}
              counts={counts.totalTemplates}
              showNumber={showNumber}
              currentUser={currentUser}
            />
          )}

          {brands.total > 0 && (
            <BrandSection
              text={text}
              t={t}
              setValue={setValue}
              data={brands}
              counts={counts.totalBrands}
              showNumber={showNumber}
              currentUser={currentUser}
            />
          )}
        </>
      )}
    </G.AllContainer>
  );
};

export default AllMenu;
