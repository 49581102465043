import BREAK_POINTS from 'src/constants/_common/breakPoints';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 24px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const Reply = styled.div`
  border-radius: 16px;
  background-color: ${colors.$gray100};
  padding: 20px 24px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ReplyHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReplyUserName = styled.div`
  color: ${colors.$gray900};
  ${({ theme }) => theme.font.H3_SEMIBOLD};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ReplyDate = styled.div`
  color: ${colors.$gray500};
  ${({ theme }) => theme.font.B2_REGULAR};
`;

export const ReplyText = styled.div`
  color: ${colors.$gray800};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  text-align: left;
`;

export const ExpandButton = styled.button`
  color: ${colors.$gray400};
  margin-left: 5px;
`;
