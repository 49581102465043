import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const ModalTitle = styled.p`
  ${({ theme }) => theme.font.H2_BOLD};

  color: ${({ theme }) => theme.color.black};
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
`;

export const ModalButton = styled.button<{ $hasBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 410px;
  height: 60px;
  background: none;
  border: none;
  border-bottom: ${({ theme, $hasBorder }) =>
    $hasBorder && `1px solid ${theme.color.gray_200}`};
  :hover {
    opacity: 0.6;
  }
`;

export const ModalButtonText = styled.p<{ $isSelected: boolean }>`
  ${({ theme }) => theme.font.SUB1_MEDIUM};

  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.color.primary_500 : theme.color.gray_900};
`;
