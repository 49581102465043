import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as ArrowDownIcon } from 'src/assets/images/store/arrowDown.svg';
import { theme } from 'src/styles/theme';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import * as S from './FullScreenImageSlider.styles';

type Props = {
  images: string[];
  onClose: () => void;
  initialIndex?: number;
};

const FullScreenImageSlider = ({ images, onClose, initialIndex }: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      {createPortal(
        <S.Container>
          <S.DimSection onClick={onClose} />
          <S.CloseButton onClick={onClose} />
          <S.Swiper
            modules={[Navigation]}
            initialSlide={initialIndex}
            navigation={{
              prevEl: '.swiper-prev',
              nextEl: '.swiper-next',
            }}
          >
            {images.map((image, index) => (
              <S.SwiperSlide key={`${image}-${index}`}>
                <S.SliderImage src={image} alt='' />
              </S.SwiperSlide>
            ))}
          </S.Swiper>
          <S.ArrowButton className='swiper-prev'>
            <ArrowDownIcon fill={theme.color.white} />
          </S.ArrowButton>
          <S.ArrowButton className='swiper-next'>
            <ArrowDownIcon fill={theme.color.white} />
          </S.ArrowButton>
        </S.Container>,
        document.body
      )}
    </>
  );
};

export default FullScreenImageSlider;
