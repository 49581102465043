import { useContext, useEffect, useMemo, useState } from 'react';
import NoResult from '../../NoResult/NoResult';

import { Button } from '@mui/material';
import { images } from '../../../../assets/images/search/images';
import StickerCategoryModal from '../../../../shared/components/Modals/search/StickerCategoryModal/StickerCategoryModal';
import SearchResultHeader from '../../SearchResultHeader/SearchResultHeader';
import * as G from '../ProductCommon.styles';
import StickerData from './components/StickerData';
import * as S from './components/StickerData.styles';

import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import useReactNativeEvents from '../../../../hooks/useReactNativeEvents';
import { ProductDataProps } from '../../../../types/search/commonPropsTypes';
import { eventNames } from '../../../../utils/mixpanel';

type Props = ProductDataProps & {
  categories: any;
};

const StickerMenu = ({ text, data, categories }: Props) => {
  const { currentUser } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showStorageOnly, setShowStorageOnly] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const allData = useMemo(() => (data?.data ? [...data.data] : []), [data]);
  const [filteredData, setFilteredData] = useState(allData);

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const filterByStorage = (list, isStorage) => {
    if (isStorage) return list.filter((item) => item?.isPurchased === true);
    return list;
  };

  const { hideBottomTabs } = useReactNativeEvents();
  const getFilteredList = (ids, isStorage) => {
    if (allData.length === 0) return [];
    const dataList = filterByStorage([...allData], isStorage);
    if (ids.length === 0) return dataList;

    const list: any = [];
    dataList.forEach((product) => {
      const status = product?.categories?.some((category) => ids.includes(category._id));
      if (status) list.push(product);
    });

    return list;
  };

  const onSubmit = (selected, filtered) => {
    setSelectedCategory([...selected]);
    setFilteredData(filtered);
    setOpen(false);

    hideBottomTabs({ hide: false });

    const selectedIds = categories.filter((item) => selected.includes(item._id));
    const categoryName = selectedIds
      .map((s) => (i18n.language === 'en' ? s.engName : s.korName))
      .toString();
    trackEvent(eventNames.searchStickerFilter, currentUser, {
      categoryName,
      platformType,
      isUser,
    });
  };

  const onToggle = (value) => {
    setShowStorageOnly(value);
    setFilteredData(getFilteredList(selectedCategory, value));
  };

  const resetFilter = () => {
    setSelectedCategory([]);
    setFilteredData(filterByStorage([...allData], showStorageOnly));
  };

  useEffect(() => {
    setFilteredData(allData);
  }, [allData]);

  return (
    <G.AllContainer>
      <G.StickerResultTopBar>
        <S.StickerCategoryContainer
          $selected={selectedCategory.length > 0}
          onClick={() => {
            setOpen(true);
            hideBottomTabs({ hide: true });
          }}
        >
          <S.StickerCategoryText $selected={selectedCategory.length > 0}>
            {t('search.stickerTab.category')}{' '}
            {selectedCategory.length > 0 ? selectedCategory.length : ''}
          </S.StickerCategoryText>
          <img src={images.arrowDown} alt='arrow_down' width={18} height={22} />
        </S.StickerCategoryContainer>

        {selectedCategory.length > 0 && (
          <Button
            sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}
            onClick={resetFilter}
          >
            <S.ResetFilterText>{t('search.stickerTab.resetFilter')}</S.ResetFilterText>
            <img src={images.refresh} alt='refreshIcon' width={16} height={16} />
          </Button>
        )}
      </G.StickerResultTopBar>
      <SearchResultHeader
        total={filteredData.length}
        showStorageOnly={showStorageOnly}
        setShowStorageOnly={onToggle}
      />

      {filteredData.length > 0 ? (
        <StickerData text={text} data={filteredData} />
      ) : (
        <NoResult text={text} showStorageOnly={showStorageOnly} />
      )}

      {open && (
        <StickerCategoryModal
          setOpen={setOpen}
          open={open}
          categories={categories}
          selectedCategory={selectedCategory}
          getFilteredList={(ids) => getFilteredList(ids, showStorageOnly)}
          filteredData={filteredData}
          onSubmit={onSubmit}
        />
      )}
    </G.AllContainer>
  );
};

export default StickerMenu;
