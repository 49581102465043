import { queryOptions } from '@tanstack/react-query';
import { getPurchasedProducts } from 'src/apis/user';
import { ProductCategory } from 'src/types/product';
import { getAccessToken, me } from 'src/utils/request';

const userQueryOptions = {
  all: () => ['user'],

  currentUser: () => [...userQueryOptions.all(), 'currentUser'],
  getCurrentUser: () =>
    queryOptions({
      queryKey: ['currentUser'],
      queryFn: me,
      select: (data) => data?.data.data,
      enabled: !!getAccessToken(),
      staleTime: Infinity,
    }),

  purchasedProducts: () => [...userQueryOptions.all(), 'purchasedProducts'],
  getPurchasedProducts: (filter: ProductCategory) =>
    queryOptions({
      queryKey: [...userQueryOptions.purchasedProducts(), filter],
      queryFn: () => getPurchasedProducts(filter),
      select: (data) => data.data.data,
      staleTime: Infinity,
    }),
};

export default userQueryOptions;
