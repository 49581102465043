import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { theme } from 'src/styles/theme';
import { eventNames } from 'src/utils/mixpanel';
import { checkForNickName } from '../../../../../apis/auth';
import { ReactComponent as ErrorOutline } from '../../../../../assets/images/store/errorOutline.svg';
import * as G from '../../../../../styles/commonStyles';
import { showToast } from '../../../../../utils/_common/showToast';
import { SignUpContext } from '../../SignUpContext';
import { NICK_NAME } from '../../validate';
import NextButton from '../NextButton';
import * as S from './NickNameScreen.styles';

const NickNameScreen = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { nickName, setNickName } = useContext(SignUpContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const nickNameLength = Array.from(nickName).length;

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const { mutate: checkForNickNameMutate, isPending } = useMutation({
    mutationFn: checkForNickName,
    onSuccess: onNext,
    onError: (error: AxiosError<{ message: string }>) => {
      if (error?.response?.data?.message === 'NickName already exists') {
        setErrorMessage(t('store.signUpPage.nickNameScreen.alreadyExistMessage'));
      } else {
        showToast({
          toastMessage: t('common.errorOccurred'),
          toastType: 'error',
        });
      }
    },
  });

  const handleNickNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    setNickName(inputValue);

    const isValidInput = /^[a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ]+$/.test(inputValue);
    if (isValidInput || inputValue === '') {
      setErrorMessage('');
    } else {
      setErrorMessage(t('store.signUpPage.nickNameScreen.invalidInputMessage'));
    }
  };

  const eraseNickName = () => {
    setNickName('');
    setErrorMessage('');
  };

  const isDisabled = !(nickNameLength > 0) || !!errorMessage;

  const onNextButtonClick = () => {
    if (isDisabled) {
      return;
    }

    checkForNickNameMutate({
      token: state.accessToken,
      data: { nickName },
    });
  };

  const handleNameInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onNextButtonClick();
    }
  };

  useEffect(() => {
    trackEvent(eventNames.signupNicknamePage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <G.Content>
        <G.SignUpTitle>{t('store.signUpPage.nickNameScreen.title')}</G.SignUpTitle>

        <G.Separator $separatorHeight={31} />

        <S.NameInputLengthContainer>
          {nickNameLength > 0 && (
            <S.NameInputLength>
              {nickNameLength}/{NICK_NAME.MAX_LENGTH}
            </S.NameInputLength>
          )}
        </S.NameInputLengthContainer>

        <G.Separator $separatorHeight={11} />

        <S.NameInputWrapper>
          <S.NameInput
            type='text'
            placeholder={t('store.signUpPage.nickNameScreen.placeholder')}
            onKeyDown={handleNameInputKeyDown}
            onChange={handleNickNameChange}
            value={nickName}
            maxLength={NICK_NAME.MAX_LENGTH}
            minLength={NICK_NAME.MIN_LENGTH}
            $onError={!!errorMessage}
          />
          {nickNameLength > 0 && <S.ClosedIconWrapper onClick={eraseNickName} />}
        </S.NameInputWrapper>
        <S.NickNameErrorWrapper>
          {errorMessage && (
            <>
              <ErrorOutline fill={theme.color.warning_500} />
              <S.NickNameErrorMessage>{errorMessage}</S.NickNameErrorMessage>
            </>
          )}
        </S.NickNameErrorWrapper>
      </G.Content>

      <G.ButtonContainer>
        <NextButton
          onClick={onNextButtonClick}
          buttonText={t('store.signUpPage.next')}
          disabled={isDisabled}
          isLoading={isPending}
        />
      </G.ButtonContainer>
    </>
  );
};

export default NickNameScreen;
