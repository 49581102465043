import { ReactNode, useLayoutEffect } from 'react';
import useAppleLoginMutation from '../../../hooks/apis/auth/useAppleLoginMutation';
import { BASE_URL } from '../../../utils/configs';
import { generateNonce } from '../../../utils/random';

type Props = {
  children: ReactNode;
  redirectPath?: string;
};

const AppleOAuthHandler = ({ children, redirectPath }: Props) => {
  const { mutate: appleLoginMutate } = useAppleLoginMutation(redirectPath);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onClick = async () => {
    window.AppleID.auth.init({
      clientId: 'com.hapl-shop-login.hapl',
      redirectURI: `${BASE_URL}/login`,
      scope: 'name email',
      usePopup: true,
      nonce: generateNonce(),
    });
    try {
      const appleAuthResponse = await window.AppleID.auth.signIn();
      appleLoginMutate({
        firstName: appleAuthResponse.user?.name?.firstName ?? '',
        lastName: appleAuthResponse.user?.name?.lastName ?? '',
        id_token: appleAuthResponse.authorization.id_token,
        appleUserId: appleAuthResponse.user?.identifier ?? '',
        token: appleAuthResponse.authorization.id_token,
        email: appleAuthResponse.user?.email ?? '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return <div onClick={onClick}>{children}</div>;
};

export default AppleOAuthHandler;
