import React from 'react';
import ExhibitionProductMobile from './ExhibitionProductMobile';
import ExhibitionProductTablet from './ExhibitionProductTablet';

type Props = {
  isMobile: boolean;
  index: number;
  isPurchased: boolean;
  onPurchaseButtonClick: () => void;
  onDetailButtonClick: () => void;
};

const ExhibitionProduct = ({
  isMobile,
  index,
  isPurchased,
  onPurchaseButtonClick,
  onDetailButtonClick,
}: Props) => {
  return (
    <>
      {isMobile ? (
        <ExhibitionProductMobile
          {...{
            index,
            isPurchased,
            onPurchaseButtonClick,
            onDetailButtonClick,
          }}
        />
      ) : (
        <ExhibitionProductTablet
          {...{
            index,
            isPurchased,
            onPurchaseButtonClick,
            onDetailButtonClick,
          }}
        />
      )}
    </>
  );
};

export default ExhibitionProduct;
