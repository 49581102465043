import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

interface IsOpenProps {
  $isOpen: boolean;
}

export const PurpleChargingComplete = styled.div<IsOpenProps>`
  display: inline-flex;
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  height: ${({ $isOpen }) => ($isOpen ? '316px' : '0px')};
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.color.white};
  position: fixed;
  bottom: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 1001;
`;

export const CompleteBottomSheetContent = styled.div`
  display: inline-flex;
  width: 95%;
  height: 252px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ChargingDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const ChargingComplete = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ChargingCompleteIconResize = styled.img`
  width: 24px;
  height: 24px;
`;

export const ChargingCompleteText = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_900};
  text-align: center;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.H2_BOLD}
`;

export const ChargingCompleteDetailText = styled.span`
  color: ${({ theme }) => theme.color.gray_500};
  text-align: center;
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
`;

export const AfterPurchaseBottomPart = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
`;

export const AfterPurchaseBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const AfterPurchaseText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
`;

export const AfterPurchaseContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AfterPurchaseIconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  position: relative;
`;

export const AfterPurchaseIconResize = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
`;

export const AfterPurchaseIconText = styled.span`
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.PURPLE_POINT_ICON}
  position: absolute;
  left: 9px;
  top: 7px;
`;

export const AfterPurchasePrice = styled.span`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H1_BOLD}
`;

export const ChargingCompleteButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  height: 56px;
`;

export const CheckStorageButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const CheckStorageText = styled.span`
  color: ${({ theme }) => theme.color.primary_500};
  ${({ theme }) => theme.font.TOAST}
`;

export const LookAroundStoreButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 6px;
  background: ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const LookAroundStoreText = styled.span`
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.TOAST}
`;
