export const NICK_NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 15,
};

export const isUnder14Year = (dateOfBirth: string) => {
  const currentDate = Number(new Date());
  const userDate = Number(dateOfBirth ? new Date(dateOfBirth) : new Date());
  const differenceMs = currentDate - userDate;
  const age = differenceMs / (1000 * 60 * 60 * 24 * 365.25);

  return age < 14;
};
