import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import ProgressBar from '../../../components/store/ExhibitionPageComponents/ProgressBar';
import RollingNumbers from '../../../components/store/ExhibitionPageComponents/RollingNumbers/RollingNumbers';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import * as S from './SalesProgress.styles';
import { useStickyState } from './useStickyState';

type Props = {
  progressInfo: {
    targetAmount: number;
    currentAmount: number;
    remainDays: number;
  };
  topOffset: number;
};

const FONT_SIZE = {
  TABLET: {
    NORMAL: 24,
    SMALL: 18,
  },
  MOBILE: {
    NORMAL: 18,
    SMALL: 16,
  },
};

const SalesProgress = ({ progressInfo, topOffset }: Props) => {
  const { targetAmount, currentAmount, remainDays } = progressInfo;
  const observerRef = useRef<HTMLElement>(null);
  const isIntersecting = useIntersectionObserver(observerRef);
  const progress =
    targetAmount > 0 ? Math.min(Math.floor((currentAmount / targetAmount) * 100)) : 0;
  const isMobile = !useMediaQuery({ minWidth: BREAK_POINTS.TABLET_SMALL });
  const fontSize = isMobile ? FONT_SIZE.MOBILE : FONT_SIZE.TABLET;
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useStickyState(stickyRef);
  const isWeb = !localStorage.getItem('platform');
  const isScreenUnder375 = !useMediaQuery({ minWidth: BREAK_POINTS.MOBILE });

  return (
    <>
      <S.Progress
        ref={observerRef}
        $isSticky={isSticky}
        $isWeb={isWeb}
        $topOffset={topOffset}
      >
        <ProgressBar progress={isIntersecting ? progress : 0} isSticky={isSticky} />
        <S.ProgressInfo>
          <S.LeftBox $isSticky={isSticky}>
            <S.CurrentAmount $fontSize={fontSize.NORMAL}>
              <RollingNumbers
                value={isIntersecting ? currentAmount : 0}
                fontSize={fontSize.NORMAL}
              />
              원
            </S.CurrentAmount>
            {(!isScreenUnder375 || !isSticky) && (
              <S.Achieve $fontSize={fontSize.SMALL}>
                <RollingNumbers
                  value={isIntersecting ? progress : 0}
                  fontSize={fontSize.SMALL}
                />
                % 달성
              </S.Achieve>
            )}
          </S.LeftBox>
          <S.RightBox $fontSize={fontSize.SMALL}>
            <S.TargetAmount $fontSize={fontSize.SMALL}>
              <RollingNumbers
                value={isIntersecting ? targetAmount : 0}
                fontSize={fontSize.SMALL}
              />
              원 목표
            </S.TargetAmount>
            <S.RemainDay $isSticky={isSticky}>
              <RollingNumbers
                value={isIntersecting ? remainDays : 0}
                fontSize={fontSize.SMALL}
              />
              일 남음
            </S.RemainDay>
          </S.RightBox>
        </S.ProgressInfo>
      </S.Progress>
      <div ref={stickyRef}></div>
    </>
  );
};

export default SalesProgress;
