import { CSSProperties, ReactNode, useEffect } from 'react';
import * as S from './Modal.styles';

type Props = {
  children: ReactNode;
  onClose?: () => void;
  modalSheetStyle?: CSSProperties;
};

const Modal = ({ children, onClose, modalSheetStyle }: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  return (
    <S.Container>
      <S.ModalSheet style={modalSheetStyle}>{children}</S.ModalSheet>
      <S.DimSection onClick={onClose} />
    </S.Container>
  );
};

export default Modal;
