import styled from 'styled-components';
import { ScrollTopButtonSize } from './ScrollTopButton';

export const ScrollTopButton = styled.div<{ $size: ScrollTopButtonSize }>`
  width: ${({ $size }) => ($size === 'small' ? '44px' : '60px')};
  height: ${({ $size }) => ($size === 'small' ? '44px' : '60px')};
  background-color: #ffffff;
  border-radius: 999px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 4px 4px 10px 0px rgba(25, 21, 69, 0.1);
`;

export const Icon = styled.img<{ $size: ScrollTopButtonSize }>`
  width: ${({ $size }) => ($size === 'small' ? '32px' : '40px')};
  height: ${({ $size }) => ($size === 'small' ? '32px' : '40px')};
`;
