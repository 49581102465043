import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as S from './index.styles';

type Props = {
  resetErrorBoundary: () => void;
  queryCacheReset: () => void;
};

const ErrorPage = ({ resetErrorBoundary, queryCacheReset }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRetry = () => {
    window.location.reload();
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      resetErrorBoundary();
      queryCacheReset();
      navigate(-1);
    } else {
      window.location.reload();
    }
  };

  return (
    <S.Container>
      <S.Title>{t('errorPage.title')}</S.Title>
      <S.Description>{t('errorPage.description')}</S.Description>

      <S.Actions>
        <S.Button buttonType='primary' onClick={handleRetry}>
          {t('errorPage.retry')}
        </S.Button>
        <S.Button buttonType='outline' onClick={handleGoBack}>
          {t('errorPage.goBack')}
        </S.Button>
      </S.Actions>
    </S.Container>
  );
};

export default ErrorPage;
