import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import { getPlatform } from 'src/utils/platformUtils';
import store_images from '../../../assets/images/store/images';
import { UserContext } from '../../../context/userContext';
import { calculatePricingDetail } from '../../../helpers/products';
import useCurrentUserQuery from '../../../hooks/apis/auth/useCurrentUserQuery';
import useModalManager from '../../../hooks/modal/useModalManager';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { thousandsFormate } from '../../../utils/amount.js';
import { eventNames, productProps } from '../../../utils/mixpanel';
import { addToWishlist, removeFromWishlist } from '../../../utils/request';
import * as S from './ProductCard.styles';

const ProductCard = (props) => {
  const { wishlist, refreshWishlist } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const showLoginPopUp = useModalManager('LoginPopUp');

  const handleFavoriteImageClick = async (item) => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ source: 'likeBtn' });
      return;
    }

    const isRemove = props?.isWishlistPage === true || wishlist[item._id];
    const platform = getPlatform();
    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    if (currentUser) {
      trackEvent(
        isRemove ? eventNames.productUnlikeClick : eventNames.productLikeClick,
        currentUser,
        {
          ...productProps(item),
          platformType,
        }
      );
    }

    const response = isRemove
      ? await removeFromWishlist(wishlist[item._id].id)
      : await addToWishlist(item._id);
    if (response?.data?.status) {
      refreshWishlist();
      if (props.onFavChange) props.onFavChange();
    }
  };

  const stickerUnFav = store_images.favoriteUnfilled;
  const stickerFav = store_images.favoriteFilled;

  const handleThumbnailClick = (navigateTo, item) => {
    const itemBrand = item.brand ?? item.brandId;
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
    const isUser = currentUser ? true : false;

    trackEvent(eventNames.storeProduct, currentUser, {
      productType: item.productType || '',
      productId: item._id || '',
      productName: item.korName || item.engName || '',
      brandName: itemBrand?.nameEN || itemBrand?.name || '',
      source: props?.source || '',
      platformType,
      isUser,
    });

    if (props.onNavigate) props.onNavigate();
    if (item && navigateTo !== '') {
      const sourceValue = props.source || '';
      navigate(`${PATH.STORE}/${navigateTo}/${item._id}`, {
        state: { source: sourceValue },
      });
    }
  };

  return props.items.map((item, index) => {
    const productImage =
      props?.pageInfo === 'sticker' || item?.sticker
        ? item?.sticker?.sticker
        : props?.pageInfo === 'template' || item?.template
        ? item?.template?.thumbnailSignUrl
        : '';
    const navigateTo =
      props?.pageInfo === 'sticker' || item?.sticker
        ? 'sticker/detail'
        : props?.pageInfo === 'template' || item?.template
        ? 'template/detail'
        : '';

    const itemBrand = item?.brand ?? item?.brandId;
    const { salePrice, isDiscount } = calculatePricingDetail(item);

    return (
      <S.StickerItemContainer
        key={item.id || item._id}
        onClick={() => {
          handleThumbnailClick(navigateTo, item);
        }}
      >
        <S.StickerImageContainer>
          <S.StyledImage
            src={productImage}
            alt={
              i18n.language === 'en'
                ? item.engName || 'product image'
                : item.korName || '상품 이미지'
            }
            $isSticker={item?.sticker}
          />
          {/* <ImageWithFallback src={productImage} fallbackSrc={moka} /> */}

          <S.StickerFavoriteImage
            src={
              props?.isWishlistPage === true || wishlist[item._id]
                ? stickerFav
                : stickerUnFav
            }
            alt='heart'
            onClick={(e) => {
              e.stopPropagation();
              handleFavoriteImageClick(item);
            }}
          />
        </S.StickerImageContainer>

        <S.StickerTextContainer>
          <S.StickerTextBrand>
            {i18n.language === 'en' ? itemBrand?.nameEN : itemBrand?.name}
          </S.StickerTextBrand>

          <S.StickerTextPd>
            {i18n.language === 'en' ? item?.engName : item?.korName}
          </S.StickerTextPd>

          {item?.productType === 'sticker' && item?.giftOnly === true ? (
            <S.StickerTextPriceContainer>
              <S.StickerTextPrice>
                {i18n.language === 'en' ? 'Gift' : '사은품'}
              </S.StickerTextPrice>
            </S.StickerTextPriceContainer>
          ) : (
            <>
              <S.StickerTextPriceContainer>
                {isDiscount && (
                  <S.StickerTextPercentage>
                    {Math.round(item?.discountPercentage)}%
                  </S.StickerTextPercentage>
                )}
                <S.StickerTextPrice>
                  {salePrice === 0
                    ? i18n.language === 'en'
                      ? 'Free'
                      : '무료'
                    : `${thousandsFormate(salePrice)}P`}
                </S.StickerTextPrice>
              </S.StickerTextPriceContainer>

              {item.review && (
                <S.ReviewInfo>
                  <S.RatingContainer>
                    <S.Star />
                    <S.AverageRating>{item.review.averageRating}</S.AverageRating>
                  </S.RatingContainer>
                  <S.ReviewCount>
                    {`(${item.review.reviewCount.toLocaleString()})`}
                  </S.ReviewCount>
                </S.ReviewInfo>
              )}
            </>
          )}
        </S.StickerTextContainer>
      </S.StickerItemContainer>
    );
  });
};

export default ProductCard;
