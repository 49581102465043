import { ReactComponent as CameraIcon } from 'src/assets/icons/camera.svg';
import styled from 'styled-components';

export const ImageUploadBox = styled.button<{ $isWarning: boolean }>`
  width: 100px;
  height: 100px;
  border-radius: 16px;
  border: 1px dashed
    ${({ $isWarning, theme }) =>
      $isWarning ? theme.color.warning_500 : theme.color.gray_400};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
`;

export const ImageCount = styled.div`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_MEDIUM};
`;

export const Camera = styled(CameraIcon)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.color.gray_600};
`;

export const ImageUploadInput = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
`;
