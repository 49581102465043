import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames, goStoreSources } from 'src/utils/mixpanel';
import store_images from '../../../assets/images/store/images';
import { PATH } from '../../../constants/_common/path';
import { ExhibitionContext } from '../../../context/exhibitionContext';
import useUserBalanceQuery from '../../../hooks/apis/auth/useUserBalanceQuery';
import useNavigation from '../../../hooks/useNavigation';
import smoothScrollToTop from '../../../utils/_common/smoothScrollToTop';
import { thousandsFormate } from '../../../utils/amount';
import { setScrollPosition } from '../../../utils/scroll';
import * as S from './StoreTopBar.styles';

function StoreTopBar() {
  const platform = localStorage.getItem('platform') ?? '';
  const { data: userBalance } = useUserBalanceQuery();
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUserQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateToAppScreen } = useNavigation();
  const { isExhibitionStarted } = useContext(ExhibitionContext);
  const { pathname } = useLocation();
  const showLoginPopUp = useModalManager('LoginPopUp');

  const onNavigate = (url) => {
    smoothScrollToTop();
    navigate(url);
  };

  const goToSearchScreen = () => {
    if (platform) {
      navigateToAppScreen({
        screen: 'SearchScreen',
        source: 'store',
      });
    } else {
      navigate(`${PATH.SEARCH}?source=store`);
    }
    trackEvent(eventNames.searchPage, currentUser, {
      source: goStoreSources.store,
      platformType: platform ? 'app' : 'web',
      isUser: !!currentUser,
    });
  };

  const onStoreTabPurplePartClick = () => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ source: 'purple' });
      return;
    }

    navigate(`${PATH.STORE}/purple`);
  };

  const onFavoriteIconClick = () => {
    if (isLoadingCurrentUser) return;
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ source: 'wishlist' });
      return;
    }

    setScrollPosition(pathname);
    navigate(`${PATH.STORE}/favorite`);
  };

  return (
    <>
      <S.EntireStoreTab>
        <S.StoreTabTopContainer>
          <S.StoreTabTitlePurple>
            <S.StoreTabTitle>{t('store.storeTopBar.store')}</S.StoreTabTitle>

            <S.PurpleWishContainer>
              <S.StoreTabPurplePart onClick={onStoreTabPurplePartClick}>
                <S.StoreTabPurplesContainer>
                  <S.StoreTabPurplesIcon src={store_images.purpleIcon} alt='purples' />
                  <S.StoreTabPurplesText>P</S.StoreTabPurplesText>
                </S.StoreTabPurplesContainer>

                <S.StoreTabHoldingPurples>
                  {thousandsFormate(userBalance)}
                </S.StoreTabHoldingPurples>
              </S.StoreTabPurplePart>
              <S.FavoriteSearchIcon>
                <S.FavoriteIcon
                  src={store_images.favoriteBlack}
                  alt='favorite-black'
                  onClick={onFavoriteIconClick}
                />
                <S.SearchIcon
                  role='img'
                  aria-label='search-icon'
                  onClick={goToSearchScreen}
                />
              </S.FavoriteSearchIcon>
            </S.PurpleWishContainer>
          </S.StoreTabTitlePurple>
        </S.StoreTabTopContainer>

        <S.StoreTabUnderContainer>
          <S.StoreTabTextContainer>
            <S.StoreTab
              $isSelected={pathname === '/store'}
              onClick={() => onNavigate(PATH.STORE)}
            >
              {t('store.storeTopBar.today')}
            </S.StoreTab>

            <S.StoreTab
              $isSelected={pathname.includes('/store/sticker')}
              onClick={() => onNavigate(`${PATH.STORE}/sticker`)}
            >
              {t('store.storeTopBar.sticker')}
            </S.StoreTab>

            <S.StoreTab
              $isSelected={pathname.includes('/store/template')}
              onClick={() => onNavigate(`${PATH.STORE}/template`)}
            >
              {t('store.storeTopBar.template')}
            </S.StoreTab>

            <S.StoreTab
              $isSelected={pathname.includes('/store/brand')}
              onClick={() => onNavigate(`${PATH.STORE}/brand`)}
            >
              {t('store.storeTopBar.brand')}
            </S.StoreTab>

            {isExhibitionStarted && (
              <S.StoreTab
                $isSelected={pathname.includes('/store/exhibition')}
                onClick={() => onNavigate(`${PATH.STORE}/exhibition`)}
              >
                기획전
              </S.StoreTab>
            )}
          </S.StoreTabTextContainer>
        </S.StoreTabUnderContainer>
      </S.EntireStoreTab>
      <Outlet />
    </>
  );
}

export default StoreTopBar;
