import BREAK_POINTS from '../../../constants/_common/breakPoints';
import styled from 'styled-components';

interface FavoriteTabProps {
  $selected: boolean;
}

export const FavoriteTabTextContainer = styled.div`
  max-width: 644px;
  width: 100%;
  min-height: 46px;
  height: 100%;
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;

  @media screen and (max-width: 744px) {
    margin: 0px 24px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}) {
    margin: 0px 16px;
  }
`;

export const FavoriteTab = styled.div<FavoriteTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  padding: 11px 0px;
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.gray_800 : theme.color.gray_400};
  border-bottom: ${({ $selected, theme }) =>
    $selected ? `2px solid ${theme.color.gray_800}` : 'none'};
`;
