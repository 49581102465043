import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { trackEvent } from '../../hooks/store/useMixpanel';
import useNavigate from '../../hooks/useNavigation';
import { bannerProps, eventNames } from '../../utils/mixpanel';
import * as S from './Advertisement.styles';

const Badge = ({ totalLength, arrowBadge, idx }) => {
  const displayIndex = idx + 1;

  return (
    <S.AdvertContainer>
      <S.AdvertItemContainer>
        <S.AdvertBadgeList>
          <S.AdvertBadgeCurrent>{displayIndex}</S.AdvertBadgeCurrent>
          <S.AdvertBadgeAll>/{totalLength}</S.AdvertBadgeAll>
          <S.AdvertBadgeArrow src={arrowBadge} alt='arrow' />
        </S.AdvertBadgeList>
      </S.AdvertItemContainer>
    </S.AdvertContainer>
  );
};

// Advertisement Component
const Advertisement = ({ banners, arrowBadge }) => {
  const [currIdx, setCurrIdx] = useState(0);
  const { i18n } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const { navigateToInternalLink } = useNavigate();

  const totalLength = banners.length;
  const minimumLength = 6;
  const slides = useMemo(
    () =>
      totalLength >= minimumLength
        ? banners
        : Array.from({ length: Math.ceil(minimumLength / (totalLength || 1)) })
            .map(() => banners)
            .flat(),
    [banners, totalLength, minimumLength]
  );

  const handleLinkClick = (item) => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    trackEvent(eventNames.bannerClick, currentUser, {
      ...bannerProps(item),
      platformType,
      isUser: !!currentUser,
    });

    navigateToInternalLink({ link: item?.link });
  };

  return (
    <S.AdvertViewContainer>
      {/* Render Badge Component */}
      <Badge totalLength={totalLength} arrowBadge={arrowBadge} idx={currIdx} />

      <Swiper
        onSlideChange={(swiper) => {
          setCurrIdx(swiper.realIndex % totalLength);
        }}
        spaceBetween={20}
        centeredSlides={true}
        autoplay={{ delay: 2000 }}
        modules={[Autoplay]}
        loop={true}
        slidesPerView={'auto'}
      >
        {slides.map((item, index) => (
          <S.AdvertItemContainer key={index} onClick={() => handleLinkClick(item)}>
            <S.AdvertImageResize>
              <img
                src={item.image}
                alt={
                  i18n.language === 'en'
                    ? item.titleEng || 'advert image'
                    : item.titleKor || '광고 이미지'
                }
              />
              <S.AdvertTextContainer>
                {item.showTitles && (
                  <S.AdvertImageText>
                    {i18n.language === 'en' ? item.titleEng : item.titleKor}
                  </S.AdvertImageText>
                )}
                {item.showSubTitles && (
                  <S.AdvertImageTextSub>
                    {i18n.language === 'en' ? item.subTitleEng : item.subTitleKor}
                  </S.AdvertImageTextSub>
                )}
              </S.AdvertTextContainer>
            </S.AdvertImageResize>
          </S.AdvertItemContainer>
        ))}
      </Swiper>
    </S.AdvertViewContainer>
  );
};

export default Advertisement;
