import Lottie from 'lottie-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { BASE_URL } from 'src/utils/configs';
import { getPlatform } from 'src/utils/platformUtils';
import store_images from '../../../assets/images/store/images';
import LottieDataEn from '../../../assets/lottie/shiningPurpleEn.json';
import LottieDataKr from '../../../assets/lottie/shiningPurpleKr.json';
import MyPurple from '../../../components/store/Purple/MyPurple';
import PurpleChargingCompleteModal from '../../../components/store/Purple/PurpleChargingCompleteModal';
import PurpleChargingModal from '../../../components/store/Purple/PurpleChargingModal';
import EachPurpleUnit from '../../../components/store/Purple/PurpleUnit';
import { UserContext } from '../../../context/userContext';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { thousandsFormate } from '../../../utils/amount';
import { eventNames } from '../../../utils/mixpanel.ts';
import { getPurplePointsList } from '../../../utils/request';
import * as S from './BuyPurple.styles';
import * as G from './PurplePage.styles';

const BuyPurplePage = () => {
  const { currentBalance, refreshUserBalance } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const is375Screen = useMediaQuery({ maxWidth: 375 });
  const is512Screen = useMediaQuery({ minWidth: 376, maxWidth: 512 });

  const [purplePoints, setPurplePoints] = useState([]);
  const [selected, setSelected] = useState(null);
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(-1);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  const platform = getPlatform();

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isWeb = platform !== 'ios' && platform !== 'android';
  const { data: currentUser } = useCurrentUserQuery();
  const isUser = currentUser ? true : false;

  const totalChargedPoints = thousandsFormate(
    currentUser?.purchaseCount === 0
      ? Number(purplePoints[selectedUnitIndex]?.points) * 2
      : Number(purplePoints[selectedUnitIndex]?.points) *
          Number(1 + purplePoints[selectedUnitIndex]?.bonusPoints / 100)
  );

  const toggleModal = () => {
    setBuyModalOpen((buyModalOpen) => !buyModalOpen);
  };

  const closeBuyModal = () => {
    setBuyModalOpen(false);
  };

  const toggleCompleteModal = () => {
    setCompleteModalOpen(!completeModalOpen);
  };

  const closeCompleteModal = () => {
    setCompleteModalOpen(false);
  };

  const onPurchase = (item) => {
    setSelected(item);
    toggleModal();

    if (currentUser)
      trackEvent(eventNames.pointProductClick, currentUser, {
        pointId: item.id,
        point: item.points || 0,
        bonusPoint: `${item.bonusPoints}%` || 0,
        price: isWeb ? item.priceInWeb : item.price,
        currency: 'KRW',
        platformType,
        isUser,
      });
  };

  const fetchPoints = async () => {
    const response = await getPurplePointsList();
    if (response?.data?.status) {
      const purplePoints = response?.data?.data;
      setPurplePoints(purplePoints ?? []);
    }
  };

  const showCompleteModal = () => {
    setIsPaymentComplete(true);
    setBuyModalOpen(false);
    setCompleteModalOpen(true);
  };

  useEffect(() => {
    refreshUserBalance();
    fetchPoints();

    const successPaymentFunction = (event) => {
      if (
        (event.origin === BASE_URL && event.data === 'Toss payment is completed!') ||
        event.data === 'Kakao payment is completed!'
      ) {
        showCompleteModal();
        trackEvent(eventNames.pointPaymentComplete, currentUser, {
          point: purplePoints[selectedUnitIndex].points,
          bonusPoint: purplePoints[selectedUnitIndex].bonusPoint || '0%',
          price:
            platformType === 'web'
              ? purplePoints[selectedUnitIndex].priceInWeb
              : purplePoints[selectedUnitIndex].price,
          currency: 'KRW',
          paymentMethod:
            event.data === 'Toss payment is completed!' ? 'TossPay' : 'KakaoPay',
          platformType,
        });
      }
    };
    window.addEventListener('message', successPaymentFunction);
    return () => {
      window.removeEventListener('message', successPaymentFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentComplete, selectedUnitIndex]);

  useEffect(() => {
    trackEvent(eventNames.pointsPage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isScrollDisabled = buyModalOpen || (isPaymentComplete && completeModalOpen);
  useEffect(() => {
    if (isScrollDisabled) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isScrollDisabled]);

  return (
    <G.PurpleFullView>
      <G.TabContentsContainer>
        <MyPurple purpleCount={thousandsFormate(currentBalance)} />
        <S.PurpleBanner>
          {currentUser?.purchaseCount === 0 && (
            <>
              {is375Screen || is512Screen ? (
                <img
                  src={store_images.mobileLottie}
                  width='100%'
                  height='auto'
                  alt='mobile_lottie'
                />
              ) : (
                <Lottie
                  animationData={i18n.language === 'en' ? LottieDataEn : LottieDataKr}
                  style={{ height: 'auto', width: '100%' }}
                />
              )}
            </>
          )}
        </S.PurpleBanner>

        <div>
          <EachPurpleUnit purplePoints={purplePoints} onPurchase={onPurchase} />
        </div>
      </G.TabContentsContainer>
      {buyModalOpen && (
        <>
          <S.PurpleChargingOverlay onClick={toggleModal} />
          <PurpleChargingModal
            data={selected}
            onClose={closeBuyModal}
            purpleCount={thousandsFormate(currentBalance)}
            selectedUnitIndex={selectedUnitIndex}
            setSelectedUnitIndex={setSelectedUnitIndex}
            showCompleteModal={showCompleteModal}
          />
        </>
      )}
      {isPaymentComplete && completeModalOpen && (
        <>
          <G.Overlay onClick={toggleCompleteModal} />
          <PurpleChargingCompleteModal
            data={purplePoints[selectedUnitIndex]}
            totalChargedPoints={totalChargedPoints}
            isOpen={completeModalOpen}
            onClose={closeCompleteModal}
          />
        </>
      )}
      <S.PurpleWarningBackground>
        <S.PurpleWarningContainer>
          <S.PurpleWarningTextContainer>
            <div>
              <S.Ul>
                <li>
                  <S.PurpleWarningContents>
                    {t('store.purpleChargingModal.termsOfUse1')}
                  </S.PurpleWarningContents>
                </li>
                <li>
                  <S.PurpleWarningContents>
                    {t('store.purpleChargingModal.termsOfUse2')}
                  </S.PurpleWarningContents>
                </li>
                <li>
                  <S.PurpleWarningContents>
                    {t('store.purpleChargingModal.termsOfUse3')}
                  </S.PurpleWarningContents>
                </li>
                <li>
                  <S.PurpleWarningContents>
                    {t('store.purpleChargingModal.termsOfUse4')}
                  </S.PurpleWarningContents>
                </li>
              </S.Ul>
            </div>
          </S.PurpleWarningTextContainer>
        </S.PurpleWarningContainer>
      </S.PurpleWarningBackground>
    </G.PurpleFullView>
  );
};

export default BuyPurplePage;
