import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './NoResultContainer.styles';

type Props = {
  text: string;
};

const NoResultContainer = ({ text }: Props) => {
  const { t, i18n } = useTranslation();

  const noResultContent = {
    en: (
      <S.NoResultTitleBlack>
        There is no result about&nbsp;
        <S.NoResultTitlePurple>'{text}'</S.NoResultTitlePurple>
      </S.NoResultTitleBlack>
    ),
    ko: (
      <S.NoResultTitleBlack>
        <S.NoResultTitlePurple>'{text}'&nbsp;</S.NoResultTitlePurple>에 대한 검색 결과가
        없어요
      </S.NoResultTitleBlack>
    ),
  };

  return (
    <>
      {noResultContent[i18n.language]}
      <S.NoResultDesc>{t('search.resultTab.description')}</S.NoResultDesc>
    </>
  );
};

export default NoResultContainer;
