import { ReactComponent as StarIcon } from 'src/assets/icons/Star.svg';
import { ReactComponent as StarHalfIcon } from 'src/assets/icons/StarHalf.svg';
import styled from 'styled-components';

export const Star = styled(StarIcon)<{ $isFilled: boolean; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  fill: ${({ $isFilled }) => ($isFilled ? '#FFC107' : '#E0E0E0')};
`;

export const StarHalf = styled(StarHalfIcon)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;
