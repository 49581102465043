import { useQuery } from '@tanstack/react-query';
import Watermark from '@uiw/react-watermark';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import store_images from 'src/assets/images/store/images';
import { Divider } from 'src/components/common/Divider';
import BrandDetailTopBar from 'src/components/store/Bar/BrandDetailTopBar';
import PurchaseUnderBar from 'src/components/store/Bar/PurchaseUnderBar';
import PinSticker from 'src/components/store/Card/PinSticker';
import ProductCard from 'src/components/store/Card/ProductCard';
import PaymentWarningList from 'src/components/store/Pay/PaymentWarningList';
import Category from 'src/components/store/SharedComponents/Category';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useKeywordSearch from 'src/hooks/search/useKeywordSearch';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useRelatedProducts from 'src/hooks/store/useRelatedProducts';
import productQueryOptions from 'src/queries/productQueryOptions';
import smoothScrollToTop from 'src/utils/_common/smoothScrollToTop';
import { thousandsFormate } from 'src/utils/amount';
import { APP_STORE, BASE_URL, GOOGLE_PLAY_STORE } from 'src/utils/configs';
import {
  eventNames,
  productProps,
  storeBrandDetailSources,
  storeProductDetailSources,
} from 'src/utils/mixpanel';
import { getAccessToken } from 'src/utils/request';
import NavigationBar from '../_components/Navigation';
import ReviewSection from '../_components/ReviewSection';
import * as G from '../Store.styles';
import { COMPONENT_IDS } from './constants';
import * as S from './index.styles';

const StickerDetail = (props) => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const params = useParams();
  const id = params?.id || props?.id;

  const accessToken = getAccessToken();
  const { data: reviews } = useQuery(
    accessToken
      ? productQueryOptions.getProductReviewsLoggedIn(id)
      : productQueryOptions.getProductReviews(id)
  );
  const { data: currentUser } = useCurrentUserQuery();
  const { data: reviewSummary } = useQuery(
    productQueryOptions.getProductReviewSummary(id)
  );

  const [product, setProduct] = useState<any>(null);
  const [productItems, setProductItems] = useState<any>([]);
  const { handleKeywordSearch } = useKeywordSearch();

  const source = location.state?.source || storeProductDetailSources.search;

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const title = i18n.language === 'en' ? product?.engName : product?.korName;
  const url = `${BASE_URL}/store/sticker/detail/${product?._id}?lang=${
    i18n.language
  }&type=shared&redirectTo=${platform === 'ios' ? APP_STORE : GOOGLE_PLAY_STORE}`;

  const { relatedProducts, moreProducts } = useRelatedProducts(
    setProduct,
    id,
    setProductItems
  );

  const onPurchase = () => {
    setProduct({ ...product, isPurchased: true });
  };

  const productDetailRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const recommendProductsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    smoothScrollToTop();
  }, [location.pathname]);

  useEffect(() => {
    if (product)
      trackEvent(eventNames.stickerDetailPage, currentUser, {
        ...productProps(product),
        source: source,
        platformType,
        isUser: !!currentUser,
      });
  }, [product, source, platformType, currentUser]);

  if (!product) return null;

  return (
    <S.DetailFullView>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
        <link rel='canonical' href={url} />
        <meta name='description' content={title} />
        <meta property='og:type' content={product?.productType || 'product'} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={product?.description || title} />
        <meta property='og:image' content={productItems?.[0]?.signUrl} />
        <meta property='og:url' content={url} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={product?.description || title} />
        <meta name='twitter:image' content={productItems?.[0]?.signUrl} />
      </Helmet>

      {product && (
        <BrandDetailTopBar
          barName={
            i18n.language === 'en' ? product?.brandId?.nameEN : product?.brandId?.name
          }
          brandId={product?.brandId ?? ''}
          product={product}
          onClose={props?.onClose}
          url={url}
          sourceScreen='stickerDetail'
          source={storeBrandDetailSources.stickerDetail}
        />
      )}

      <G.ProductDetailPart>
        <S.StickerTop style={{ marginTop: platform ? 30 : 0 }}>
          <S.ThumbnailContainer>
            <S.ThumbnailResize
              src={product?.featuredImage ?? productItems?.[0]?.signUrl}
              alt='featured-image'
            />
          </S.ThumbnailContainer>
          <S.TextContainer>
            <S.Title>
              {i18n.language === 'en' ? product?.engName : product?.korName}
            </S.Title>

            {product?.giftOnly === true ? (
              <S.PriceContainer>
                <S.Price>{i18n.language === 'en' ? 'Gift' : '사은품'}</S.Price>
              </S.PriceContainer>
            ) : (
              <>
                <S.PriceContainer>
                  {product?.isDiscount && (
                    <S.Percent>{Math.round(product.discountPercentage)}%</S.Percent>
                  )}

                  {product && (
                    <>
                      {product?.salePrice > 0 ? (
                        <S.Price>{`${thousandsFormate(product?.salePrice)}P`}</S.Price>
                      ) : (
                        <S.Price>{i18n.language === 'en' ? 'Free' : '무료'}</S.Price>
                      )}
                    </>
                  )}
                </S.PriceContainer>

                {product?.remainingSaleDays && product?.remainingSaleDays < 30 && (
                  <S.DiscountDate>
                    {i18n.language === 'en'
                      ? `Sale ends in D-${product?.remainingSaleDays}!`
                      : `할인 ${product?.remainingSaleDays}일 남았어요!`}
                  </S.DiscountDate>
                )}
              </>
            )}

            {reviewSummary && reviewSummary.reviewNum !== 0 && (
              <S.ReviewContainer>
                <S.Star />
                <S.RatingValue>{reviewSummary.averageRating}</S.RatingValue>
                <S.ReviewCount>{`(${t(
                  'store.productDetailPage.reviews'
                )} ${reviewSummary.reviewNum.toLocaleString()})`}</S.ReviewCount>
              </S.ReviewContainer>
            )}
          </S.TextContainer>
        </S.StickerTop>

        <NavigationBar
          tabs={[
            {
              id: COMPONENT_IDS.PRODUCT_DETAIL,
              label: t('store.productDetailPage.productDetail'),
              ref: productDetailRef,
            },
            {
              id: COMPONENT_IDS.REVIEW,
              label: `${t('store.productDetailPage.reviews')} ${
                reviewSummary?.reviewNum
              }`,
              ref: reviewRef,
            },
            {
              id: COMPONENT_IDS.RECOMMEND_PRODUCTS,
              label: t('store.productDetailPage.recommend'),
              ref: recommendProductsRef,
            },
          ]}
        />

        <Watermark
          rotate={-30}
          height={32}
          width={59}
          gapX={70}
          gapY={70}
          image={store_images.watermark}
        >
          <S.StickerBoard id={COMPONENT_IDS.PRODUCT_DETAIL} ref={productDetailRef}>
            <PinSticker items={productItems} />
          </S.StickerBoard>
        </Watermark>
        <S.WatermarkWarningContainer>
          <img src={store_images.downloadIcon} alt='download' width={24} height={24} />
          <S.WatermarkWarningText>
            <span>{t('store.stickerPage.watermarkWarningText1')}</span>
            <S.WatermarkWarningBoldText>
              {t('store.stickerPage.watermarkWarningBoldText')}
            </S.WatermarkWarningBoldText>
            <span>{t('store.stickerPage.watermarkWarningText2')}</span>
          </S.WatermarkWarningText>
        </S.WatermarkWarningContainer>
        {product?.tags?.length > 0 ? (
          <G.TagContainer>
            <G.PartTitle>{t('store.stickerPage.tags')}</G.PartTitle>
            <S.TagsRow>
              {product.tags.map((tag, index) => (
                <Category
                  onClick={() => {
                    handleKeywordSearch(tag);
                  }}
                  category={tag}
                  key={`type_${index}`}
                />
              ))}
            </S.TagsRow>
          </G.TagContainer>
        ) : null}

        <Divider height={12} />
        <ReviewSection
          productId={id}
          productName={product.korName}
          productType={product.productType}
          brandName={product.brandId.name}
          elementId={COMPONENT_IDS.REVIEW}
          totalReviewCount={reviewSummary?.reviewNum ?? 0}
          averageRating={reviewSummary?.averageRating ?? 0}
          reviews={reviews?.reviews ?? []}
          ref={reviewRef}
          isPurchased={product?.isPurchased ?? false}
          userReviewExists={reviews?.userReviewExists ?? false}
        />

        {(relatedProducts?.length > 0 || moreProducts?.length > 0) && (
          <>
            <Divider height={12} />
            <S.RecommendProducts
              ref={recommendProductsRef}
              id={COMPONENT_IDS.RECOMMEND_PRODUCTS}
            >
              {relatedProducts?.length > 0 ? (
                <G.OtherProductPart>
                  <S.OtherStickerPartBar>
                    <G.PartTitle>{t('store.stickerPage.similarProduct')}</G.PartTitle>
                  </S.OtherStickerPartBar>
                  <G.OtherProductShow>
                    <ProductCard
                      items={relatedProducts}
                      pageInfo={'sticker'}
                      source={storeProductDetailSources.stickerDetailRecommmendByCategory}
                    />
                  </G.OtherProductShow>
                </G.OtherProductPart>
              ) : null}

              {moreProducts?.length > 0 ? (
                <G.OtherProductPart>
                  <S.OtherStickerPartBar>
                    <G.PartTitle>{t('store.stickerPage.sameBrand')}</G.PartTitle>
                  </S.OtherStickerPartBar>
                  <G.OtherProductShow>
                    <ProductCard
                      items={moreProducts}
                      pageInfo={'sticker'}
                      source={storeProductDetailSources.stickerDetailRecommendByBrand}
                    />
                  </G.OtherProductShow>
                </G.OtherProductPart>
              ) : null}
            </S.RecommendProducts>
          </>
        )}
      </G.ProductDetailPart>

      <G.WarningContainer>
        <G.WarningTextContainer>
          <G.WarningTitle>{t('store.paymentModal.noteTitle')}</G.WarningTitle>

          <G.WarningContentsContainer>
            <PaymentWarningList />
          </G.WarningContentsContainer>
        </G.WarningTextContainer>
      </G.WarningContainer>

      <PurchaseUnderBar product={product} onPurchase={onPurchase} />
    </S.DetailFullView>
  );
};

export default StickerDetail;
