import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import {
  AppNavigateProps,
  LinkRedirectionProps,
  NavigateProps,
  RedirectToAppOrInternalLink,
} from '../types/navigateTypes';
import { getTemplateOrFolderId } from '../utils/request';

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const useNavigation = () => {
  const navigate = useNavigate();

  const navigateToStickerDetails = ({ itemId, state }: NavigateProps) => {
    navigate(`${PATH.STORE}/sticker/detail/${itemId}`, { state });
  };

  const navigateToTemplateDetails = ({ itemId, state }: NavigateProps) => {
    navigate(`${PATH.STORE}/template/detail/${itemId}`, { state });
  };

  const navigateToBrandDetails = ({ itemId }: NavigateProps) => {
    navigate(`${PATH.STORE}/brand/detail/${itemId}`);
  };

  const navigateToCreateReview = (id: string) => {
    navigate(`${PATH.CREATE_REVIEW}/${id}`);
  };

  const navigateToEditReview = (productId: string, reviewId: string) => {
    navigate(`${PATH.EDIT_REVIEW}/${productId}/${reviewId}`);
  };

  const navigateToPurchaseHistory = () => {
    navigate(PATH.PURCHASE_HISTORY);
  };

  const navigateTo = (path: string): any => {
    navigate(path);
  };

  const navigateToAppScreen = ({
    productId,
    productType,
    screen,
    comingFrom,
    resetWebView = false,
  }: AppNavigateProps) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'navigateTo',
        data: {
          comingFrom,
          screen,
          hideBottomTabs: false,
          tab: productType,
          productId,
          resetWebView,
        },
      })
    );
  };

  const navigateToInternalLink = ({ link }: LinkRedirectionProps) => {
    const isInternalLink = link.indexOf('hapl.shop/store') !== -1;
    if (isInternalLink) {
      let path = link.split('store', 2)?.[1];
      if (path.includes('redirect')) path = path.split('&redirectTo', 1)?.[0];
      navigate(`/store${path}`);
      return;
    }

    const platform = localStorage.getItem('platform');
    if (platform) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'openExternalLink',
          data: {
            link,
          },
        })
      );
    } else {
      window.open(link, '_blank');
    }
  };

  const navigateToStorageOrInternalLink = async ({
    item,
    platform,
    productType = 'sticker',
  }: RedirectToAppOrInternalLink) => {
    const userId = localStorage.getItem('userId');
    if (item?.isPurchased === true || item?.userId === userId) {
      if (platform) {
        let id = item?.folderId || null;
        if (!id) {
          const response = await getTemplateOrFolderId(item._id);
          id = response?.data?.data?.id;
        }
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'navigateTo',
            data: {
              screen: 'StorageScreen',
              hideBottomTabs: false,
              tab: productType,
              id,
            },
          })
        );
      }
    } else {
      if (productType === 'sticker')
        navigateToStickerDetails({ itemId: item?.productId || item._id });
      else if (productType === 'template')
        navigateToTemplateDetails({ itemId: item?.productId || item._id });
    }
  };

  const navigateToSearchScreen = (keyword?: string) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'navigateTo',
        data: {
          screen: 'SearchScreen',
          keyword: keyword ?? '',
          hideBottomTabs: false,
        },
      })
    );
  };

  return {
    navigateToStickerDetails,
    navigateToTemplateDetails,
    navigateToBrandDetails,
    navigateToCreateReview,
    navigateToEditReview,
    navigateToPurchaseHistory,
    navigateToInternalLink,
    navigateToStorageOrInternalLink,
    navigateToAppScreen,
    navigateToSearchScreen,
    navigateTo,
  };
};

export default useNavigation;
