import { useTranslation } from 'react-i18next';

import { images } from '../../../../../assets/images/search/images';
import store_images from '../../../../../assets/images/store/images';
import useNavigate from '../../../../../hooks/useNavigation';
import { ProductDataProps } from '../../../../../types/search/commonPropsTypes';
import { highlightedText } from '../../../../../utils/highlightedText';

import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from '../../../../../hooks/store/useMixpanel';
import { eventNames, storeProductDetailSources } from '../../../../../utils/mixpanel';
import * as G from '../../ProductCommon.styles';
import * as S from './BrandData.styles';

const BrandData = ({ text, data }: ProductDataProps) => {
  const { data: currentUser } = useCurrentUserQuery();
  const { i18n } = useTranslation();
  const { navigateTo, navigateToAppScreen } = useNavigate();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  return (
    <G.MainDiv>
      <G.ProductLayout>
        {data.map((item: any, index: number) => {
          const brandId = item?._id;
          const title = i18n.language === 'en' ? item?.nameEN : item?.name;

          return (
            <S.RenderStickerContainer
              key={item._id}
              onClick={() => {
                trackEvent(eventNames.searchResultItem, currentUser, {
                  categoryName: 'brand',
                  brandName: title,
                  sequence: index + 1,
                  platformType,
                  isUser,
                });

                trackEvent(eventNames.storeProduct, currentUser, {
                  brandName: title,
                  source: storeProductDetailSources.search,
                  platformType,
                  isUser,
                });

                if (platform) {
                  const payload = {
                    productId: brandId,
                    comingFrom: 'SearchScreen',
                    productType: 'brand/detail',
                    screen: 'StoreScreen',
                  };
                  navigateToAppScreen(payload);
                  return;
                }

                navigateTo(`${PATH.BRAND_DETAIL}/${brandId}`);
              }}
            >
              <S.BrandThumbnail>
                <S.BrandThumbnailResize
                  src={
                    item?.logoImageSignUrl ||
                    item?.logoImage?.location ||
                    store_images.defaultProfile
                  }
                  alt='thumbnail'
                />
              </S.BrandThumbnail>
              <S.BrandTextContainer>
                <G.ProductTitleContainer>
                  <G.ProductTitleContainer>
                    <G.ProductTitle>{highlightedText(title, text)}</G.ProductTitle>
                  </G.ProductTitleContainer>
                  <img src={images.arrowRight} alt='arrow_right' width={8} height={22} />
                </G.ProductTitleContainer>
              </S.BrandTextContainer>
            </S.RenderStickerContainer>
          );
        })}
      </G.ProductLayout>
    </G.MainDiv>
  );
};

export default BrandData;
