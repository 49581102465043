import styled from 'styled-components';
import BREAK_POINTS from '../../../../constants/_common/breakPoints';

export const FavoriteNoneContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FavoriteNoneItemContainer = styled.div`
  min-width: 287px;
  max-width: 300px;
  min-height: 116px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const FavoriteNoneTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

export const Notice1 = styled.span`
  color: #616161;
  font-family: 'Pretendard-SemiBold';
  font-size: 18px;
  line-height: 26px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Notice2 = styled.span`
  color: #bdbdbd;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 22px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const StoreButton = styled.div`
  display: flex;
  min-width: 89px;
  padding: 14px 26.5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 56px;
  background: #6638e6;
`;

export const StoreButtonText = styled.span`
  color: #fff;
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 100%;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    font-size: 12px;
    line-height: 100%;
  }
`;
