import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductReview } from 'src/apis/types/products.types';
import profileImage from 'src/assets/images/store/profileImage.svg';
import ReviewItem from 'src/components/store/Review/ReviewItem';
import ViewMoreButton from 'src/components/store/ViewMoreButton';
import { ProductTypes } from 'src/types/product';
import { ComponentId } from '../../StickerDetail/constants';
import Header from './Header';
import * as S from './index.style';
import NoReview from './NoReview';

type Props = {
  productId: string;
  productName: string;
  productType: ProductTypes;
  brandName: string;
  elementId: ComponentId;
  totalReviewCount: number;
  averageRating: number;
  reviews: ProductReview[];
  isPurchased: boolean;
  userReviewExists: boolean;
};

const REVIEWS_PER_PAGE = 5;

const ReviewSection = forwardRef(
  (
    {
      productId,
      productName,
      productType,
      brandName,
      elementId,
      totalReviewCount,
      averageRating,
      reviews,
      isPurchased,
      userReviewExists,
    }: Props,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const [showingReviewCount, setShowingReviewCount] = useState(REVIEWS_PER_PAGE);

    const handleLoadMoreReviews = () => {
      setShowingReviewCount((prevCount) => prevCount + REVIEWS_PER_PAGE);
    };

    return (
      <S.Container id={elementId} ref={ref}>
        <Header
          totalReviewCount={totalReviewCount}
          averageRating={averageRating}
          isPurchased={isPurchased}
          userReviewExists={userReviewExists}
          productType={productType}
          productName={productName}
          brandName={brandName}
        />

        {reviews.length > 0 ? (
          <>
            {reviews.slice(0, showingReviewCount).map((review) => (
              <ReviewItem
                key={review.id ?? review._id}
                productId={productId}
                productName={productName}
                productType={productType}
                brandName={brandName}
                reviewId={review.id ?? review._id}
                userProfileImage={review.userId.signUrl ?? profileImage}
                nickName={review.userId.nickName}
                reviewAuthorId={review.userId._id ?? review.userId.id}
                reviewDate={review.updatedAt}
                rating={review.rating}
                reviewText={review.content}
                images={review.image}
                reply={review.reply}
              />
            ))}

            {reviews.length > showingReviewCount && (
              <ViewMoreButton
                onClick={handleLoadMoreReviews}
                text={t('store.productDetailPage.reviewSection.moreReviews')}
              />
            )}
          </>
        ) : (
          <NoReview />
        )}
      </S.Container>
    );
  }
);

export default ReviewSection;
