import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../../../hooks/store/useMixpanel';
import smoothScrollToTop from '../../../../../utils/_common/smoothScrollToTop';
import { eventNames } from '../../../../../utils/mixpanel';
import * as G from '../../ProductCommon.styles';
import * as S from '../AllMenu.styles';

type Props = {
  label: string;
  total?: string;
  onViewMore: () => void;
  category: string;
  showButton: boolean;
  currentUser: any;
};

const DefaultInfoHeader = ({
  label,
  total = '',
  onViewMore,
  category = '',
  showButton,
  currentUser,
}: Props) => {
  const { t } = useTranslation();

  const onClick = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
    const isUser = currentUser ? true : false;

    trackEvent(eventNames.searchResultViewMoreClick, currentUser, {
      categoryName: category,
      platformType,
      isUser,
    });

    onViewMore();
    smoothScrollToTop();
  };

  return (
    <G.StickerResultTopBar>
      <G.SearchResultText>
        {label}
        <S.SearchResultNum>{total}</S.SearchResultNum>
      </G.SearchResultText>

      {showButton && (
        <S.ViewMoreContainer variant='text' onClick={onClick}>
          <S.ViewMoreText>{t('search.resultTab.viewMore')}</S.ViewMoreText>
        </S.ViewMoreContainer>
      )}
    </G.StickerResultTopBar>
  );
};

export default DefaultInfoHeader;
