import { useMutation } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { updateSignUpUserInfo } from '../../../../../apis/auth';
import * as G from '../../../../../styles/commonStyles';
import { showToast } from '../../../../../utils/_common/showToast';
import { SignUpContext } from '../../SignUpContext';
import NextButton from '../NextButton';
import * as S from './GenderScreen.styles';

const GenderScreen = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const { nickName, dateOfBirth, termAgreements, gender, setGender } =
    useContext(SignUpContext);

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const handleGenderSelection = (selectedGender: string) => {
    if (gender === selectedGender) {
      setGender('');
      return;
    }
    setGender(selectedGender);
  };

  const { mutate: updateSignupUserInfoMutate, isPending } = useMutation({
    mutationFn: updateSignUpUserInfo,
    onSuccess: () => {
      onNext();
      trackEvent(eventNames.signupComplete, currentUser, { platformType });
    },
    onError: () => {
      showToast({
        toastMessage: t('common.errorOccurred'),
        toastType: 'error',
      });
    },
  });

  const onSignUp = () => {
    updateSignupUserInfoMutate({
      token: state.accessToken,
      data: {
        nickName,
        dob: dateOfBirth,
        gender,
        terms_conditions: {
          terms1: termAgreements[0],
          terms2: termAgreements[1],
          terms3: termAgreements[2],
          terms4: termAgreements[3],
          terms5: termAgreements[4],
        },
        terms_conditions_accepted_at: new Date(),
        signupCompleted: true,
      },
    });
  };

  useEffect(() => {
    trackEvent(eventNames.signupGenderPage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <G.Content>
        <div style={{ width: '100%', minWidth: '327px' }}>
          <G.SignUpTitle>{t('store.signUpPage.genderTitle')}</G.SignUpTitle>

          <G.Separator $separatorHeight={64} />

          <S.GenderBtnWrapper>
            <S.GenderBtn
              $isSelected={gender === t('store.signUpPage.female')}
              onClick={() => handleGenderSelection(t('store.signUpPage.female'))}
            >
              <S.GenderBtnText $isSelected={gender === t('store.signUpPage.female')}>
                {t('store.signUpPage.female')}
              </S.GenderBtnText>
            </S.GenderBtn>
            <S.GenderBtn
              $isSelected={gender === t('store.signUpPage.male')}
              onClick={() => handleGenderSelection(t('store.signUpPage.male'))}
            >
              <S.GenderBtnText $isSelected={gender === t('store.signUpPage.male')}>
                {t('store.signUpPage.male')}
              </S.GenderBtnText>
            </S.GenderBtn>
          </S.GenderBtnWrapper>
        </div>
      </G.Content>

      <G.ButtonContainer>
        <NextButton
          onClick={onSignUp}
          buttonText={t('store.signUpPage.complete')}
          isLoading={isPending}
        />
      </G.ButtonContainer>
    </>
  );
};

export default GenderScreen;
