import { useEffect, useId, useRef } from 'react';
import * as S from './Textarea.styles';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  characterLimit?: number;
  disabled?: boolean;
  autoHeight?: boolean;
  isError?: boolean;
};

const Textarea = ({
  value,
  onChange,
  placeholder,
  characterLimit,
  disabled,
  autoHeight,
  isError,
}: Props) => {
  const id = useId();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dummyTextareaRef = useRef<HTMLDivElement>(null); // dummy textarea for autoHeight

  useEffect(() => {
    if (autoHeight && textareaRef.current && dummyTextareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      dummyTextareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, autoHeight]);

  const handleChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <S.TextareaContainer htmlFor={id} disabled={disabled} $isError={isError}>
      <S.Textarea
        id={id}
        ref={textareaRef}
        placeholder={placeholder}
        value={value}
        onChange={handleChangeTextarea}
        disabled={disabled}
        $autoHeight={autoHeight}
      />
      {autoHeight && <S.DummyTextarea ref={dummyTextareaRef} />}
      {characterLimit && (
        <S.CharacterCount
          $isError={isError}
        >{`${value.length.toLocaleString()}/${characterLimit.toLocaleString()}`}</S.CharacterCount>
      )}
    </S.TextareaContainer>
  );
};

export default Textarea;
