import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { ONE_LINK_URL } from '../../constants/_common/url';
import { IS_USER_ON_MOBILE_DEVICE } from '../../constants/_common/userAgent';
import { PUBLIC_URL } from '../../utils/configs';
import * as S from './AppInstallBanner.styles';

const AppInstallBanner = () => {
  const { t } = useTranslation();
  const isMobileWidth = !useMediaQuery({ minWidth: 500 });
  const showAppInstallQrPopUp = useModalManager('AppInstallQrPopUp');
  const { data: currentUser } = useCurrentUserQuery();

  const onDownloadButtonClick = () => {
    trackEvent(eventNames.clickAppInstallBanner, currentUser, { isUser: !!currentUser });

    if (IS_USER_ON_MOBILE_DEVICE) {
      window.open(ONE_LINK_URL, '_blank');
    } else {
      showAppInstallQrPopUp();
    }
  };

  return (
    <S.Container>
      <S.LeftSection>
        <S.LogoImage src={`${PUBLIC_URL}/android-chrome-512x512.png`} alt='hapl-logo' />
        <S.Text>
          {t(`store.appInstallBanner.text.${isMobileWidth ? 'short' : 'long'}`)}
        </S.Text>
      </S.LeftSection>
      <S.RightSection>
        <S.Button onClick={onDownloadButtonClick}>
          {t('store.appInstallBanner.buttonText')}
        </S.Button>
      </S.RightSection>
    </S.Container>
  );
};

export default AppInstallBanner;
