import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  EXHIBITION,
  isExhibitionStarted as isExhibitionStartedInitialValue,
  remainingSaleDays,
} from '../constants/exhibition';
import {
  ExhibitionData,
  getExhibitionData,
  getExhibitionPeriodStatus,
} from '../utils/exhibitionRequest';

type Props = {
  children: React.ReactNode;
};

type ExhibitionContextType = {
  isLoading: boolean;
  exhibitionData?: ExhibitionData;
  progressInfo: {
    targetAmount: number;
    currentAmount: number;
    remainDays: number;
  };
  exhibitionProductIds: string[];
  isExhibitionStarted: boolean;
  refetchExhibitionData: () => void;
};

export const ExhibitionContext = createContext<ExhibitionContextType>({
  isLoading: false,
  exhibitionData: undefined,
  progressInfo: {
    targetAmount: 0,
    currentAmount: 0,
    remainDays: 0,
  },
  exhibitionProductIds: [],
  isExhibitionStarted: isExhibitionStartedInitialValue,
  refetchExhibitionData: () => {},
});

export const ExhibitionContextProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [exhibitionData, setExhibitionData] = useState<ExhibitionData>();
  const [isExhibitionStarted, setIsExhibitionStarted] = useState(
    isExhibitionStartedInitialValue
  );
  const userId = localStorage.getItem('userId');

  const progressInfo = {
    targetAmount: EXHIBITION.TARGET_AMOUNT,
    currentAmount: exhibitionData?.salesCount
      ? exhibitionData.salesCount * EXHIBITION.DONATION_RATIO
      : 0,
    remainDays: remainingSaleDays,
  };

  const exhibitionProductIds =
    exhibitionData?.products.map((product) => product._id) || [];

  const fetchExhibitionData = useCallback(() => {
    setIsLoading(true);
    (async () => {
      try {
        const data = await getExhibitionData(userId);

        setExhibitionData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userId]);

  useEffect(() => {
    fetchExhibitionData();
  }, [fetchExhibitionData]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV_MODE !== 'prod') {
      // In the development environment, it is always assumed that the exhibition has started.
      setIsExhibitionStarted(false);

      return;
    }

    (async () => {
      try {
        const exhibitionPeriod = await getExhibitionPeriodStatus();

        setIsExhibitionStarted(exhibitionPeriod);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <ExhibitionContext.Provider
      value={{
        isLoading,
        exhibitionData,
        progressInfo,
        exhibitionProductIds,
        isExhibitionStarted,
        refetchExhibitionData: fetchExhibitionData,
      }}
    >
      {children}
    </ExhibitionContext.Provider>
  );
};
