import React from 'react';
import store_images from '../../../assets/images/store/images';
import { useTranslation } from 'react-i18next';
import * as S from './MyPurple.styles.ts';

const MyPurple = (props) => {
  const { t } = useTranslation();
  return (
    <S.MyPurplesPart>
      <S.MyPurple>{t('store.purpleStation.myPurple')}</S.MyPurple>
      <S.PurpleCountPart>
        <S.PurpleIconContainer>
          <S.PurpleIconResize src={store_images.purpleIcon} alt='purple icon' />
          <S.PurpleIconText>P</S.PurpleIconText>
        </S.PurpleIconContainer>
        <S.PurpleCount>{props.purpleCount}</S.PurpleCount>
      </S.PurpleCountPart>
    </S.MyPurplesPart>
  );
};

export default MyPurple;
