import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'src/components/store/SharedComponents/Color';
import * as S from './DetailItem.styles';
import { COMPONENT_IDS } from './constants';

type Props = {
  pdfDetails: {
    hyperLink: boolean;
    startingDay: {
      engName: string;
      korName: string;
    };
    colors: {
      color: string;
      _id: string;
    }[];
    date: {
      engName: string;
      korName: string;
    };
    direction: {
      engName: string;
      korName: string;
    };
  };
};

const DetailItem = forwardRef(
  ({ pdfDetails }: Props, ref: React.RefObject<HTMLDivElement>) => {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === 'en';

    return (
      <S.DetailItemContainer ref={ref} id={COMPONENT_IDS.PRODUCT_DETAIL}>
        <S.DetailItemLeftContainer>
          <S.DetailItemBox>
            <S.DetailItemKey>{isEnglish ? 'Hyperlink' : '하이퍼링크'}</S.DetailItemKey>

            <S.DetailItemValue>
              {pdfDetails?.hyperLink === true
                ? `${t('store.template.hyperlink.yes')}`
                : `${t('store.template.hyperlink.no')}`}
            </S.DetailItemValue>
          </S.DetailItemBox>

          <S.DetailItemBox>
            <S.DetailItemKey>{t('store.template.startDate')}</S.DetailItemKey>

            <S.DetailItemValue>
              {isEnglish
                ? pdfDetails?.startingDay?.engName === 'Monday'
                  ? `${t('store.template.monday')}`
                  : `${t('store.template.sunday')}`
                : pdfDetails?.startingDay?.korName === '월요일'
                ? `${t('store.template.monday')}`
                : `${t('store.template.sunday')}`}
            </S.DetailItemValue>
          </S.DetailItemBox>

          <S.DetailItemBox>
            <S.DetailItemKey>{t('store.template.color')}</S.DetailItemKey>

            {pdfDetails?.colors?.map((color, i) => (
              <Color color={color.color} key={`color_${i}_${color._id}`} />
            ))}
          </S.DetailItemBox>
        </S.DetailItemLeftContainer>

        <S.DetailItemRightContainer>
          <S.DetailItemBox>
            <S.DetailItemKey>{t('store.template.date')}</S.DetailItemKey>

            <S.DetailItemValue>
              {!pdfDetails?.date
                ? t('store.template.undated')
                : isEnglish
                ? pdfDetails?.date?.engName
                : pdfDetails?.date?.korName}
            </S.DetailItemValue>
          </S.DetailItemBox>

          <S.DetailItemBox>
            <S.DetailItemKey>{t('store.template.direction')}</S.DetailItemKey>

            <S.DetailItemValue>
              {isEnglish
                ? pdfDetails?.direction?.engName
                : pdfDetails?.direction?.korName}
            </S.DetailItemValue>
          </S.DetailItemBox>
        </S.DetailItemRightContainer>
      </S.DetailItemContainer>
    );
  }
);

export default DetailItem;
