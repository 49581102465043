import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const ContentLoader = () => (
  <ContentLoaderWrapper>
    <ContentLoaderStyle size={64} />
  </ContentLoaderWrapper>
);

const ContentLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
`;

const ContentLoaderStyle = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.color.primary_500};
  }
`;

export default ContentLoader;
