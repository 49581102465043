import { css } from 'styled-components';

type Font = {
  fontFamily: string;
  size: number;
  height: number;
};

/*
    Usage:

    import typography from './typography';

    FONT_NAME: typography({
            fontFamily: 'Pretendard-SemiBold',
            size: 24,
            height:20
        })
    `;
 */

const typography = ({ fontFamily, size, height }: Font) => {
  const pixelToRem = (size: number) => `${size / 16}rem`;
  return css`
    font-family: ${fontFamily};
    font-size: ${pixelToRem(size)};
    line-height: ${pixelToRem(height)};
  `;
};

export default typography;
