import { useEffect, useState } from 'react';

type UseScrollThresholdProps = {
  isBrandCoverVisible: boolean;
};

export const useScrollThreshold = (threshold: number): UseScrollThresholdProps => {
  const [isBelowThreshold, setIsBelowThreshold] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsBelowThreshold(window.scrollY > threshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  return { isBrandCoverVisible: !isBelowThreshold };
};
