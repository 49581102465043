import { useTranslation } from 'react-i18next';
import CommonButton from 'src/components/common/CommonButton';
import * as S from './SubmitButton.styles';

type Props = {
  onClick: () => void;
  disabled: boolean;
};

const SubmitButton = ({ onClick, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <S.SubmitButton>
      <CommonButton
        onClick={onClick}
        buttonType={'warning'}
        height={56}
        disabled={disabled}
      >
        {t('store.reviewReport.submitButtonText')}
      </CommonButton>
    </S.SubmitButton>
  );
};

export default SubmitButton;
