import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
interface IsOpenProps {
  $isOpen: boolean;
}

export const BottomSheetComplete = styled.div<IsOpenProps>`
  display: inline-flex;
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.color.white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${({ $isOpen }) => ($isOpen ? 'auto' : '0')};
  padding-top: ${({ $isOpen }) => ($isOpen ? '40px' : '')};
  padding-bottom: ${({ $isOpen }) => ($isOpen ? '20px' : '')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 1001;
`;

export const PaymentCompleteBottomSheetContent = styled.div`
  width: 95%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

export const PurchaseDetailContainer = styled.div`
  display: flex;
  height: 88px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 60px;
`;

export const PurchaseComplete = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const CompleteIconResize = styled.img`
  width: 24px;
  height: 24px;
`;

export const CompleteText = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_900};
  text-align: center;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.H2_BOLD}
`;

export const CompleteDetailText = styled.span`
  color: ${({ theme }) => theme.color.gray_500};
  text-align: center;
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
`;

export const PaymentCompleteButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  height: 56px;
`;

export const GoStorageButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const GoStorageText = styled.span`
  color: ${({ theme }) => theme.color.primary_500};
  ${({ theme }) => theme.font.TOAST}
`;

export const GoShoppingButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 6px;
  background: ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const GoShoppingText = styled.span`
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.TOAST}
`;

export const Overlay = styled.div`
  margin: 0 -24px;
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease-in-out;

  position: fixed;
  top: 0;
  z-index: 1;

  &:active {
    opacity: 0.6;
    pointer-events: auto;
  }
`;
