import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import store_images from '../../../assets/images/store/images';
import { storeProductDetailSources } from '../../../utils/mixpanel';
import { setScrollPosition } from '../../../utils/scroll';
import * as S from './BrandCardCollection.styles.ts';
import ProductCard from './ProductCard';

function BrandCardCollection(props) {
  const { brand } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <S.BrandEachCard>
      <S.BrandCardTitlePart
        onClick={() => {
          setScrollPosition(props.pageName);
          const sourceValue = props.source || '';
          navigate(`${PATH.STORE}/brand/detail/${brand?._id}`, {
            state: { source: sourceValue },
          });
        }}
      >
        <S.BrandCardTextContainer>
          <S.BrandCardImageResize
            src={
              brand?.logoImage?.location ||
              brand?.logoImageSignUrl ||
              store_images.defaultProfile
            }
            alt={
              i18n.language === 'en'
                ? brand?.nameEN || 'brand card'
                : brand?.name || '브랜드 카드'
            }
          />
          <S.BrandCardNameBar>
            <S.BrandCardBrandName>
              {i18n.language === 'en' ? brand?.nameEN : brand?.name}
            </S.BrandCardBrandName>
            <S.BrandCardBrandDetail>
              {brand.introduction ?? brand.description}
            </S.BrandCardBrandDetail>
          </S.BrandCardNameBar>
          <S.BrandCardArrow src={store_images.arrowRight} alt='arrow' />
        </S.BrandCardTextContainer>
      </S.BrandCardTitlePart>
      <S.BrandCardProdBoard>
        <ProductCard
          items={brand?.products}
          onNavigate={() => setScrollPosition(props.pageName)}
          source={storeProductDetailSources.brand}
        />
      </S.BrandCardProdBoard>
    </S.BrandEachCard>
  );
}

export default BrandCardCollection;
