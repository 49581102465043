import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import Modal from '../../../../../../components/common/Modal';
import * as S from './LanguageModal.styles';

type Props = {
  onClose: () => void;
};

const LanguageModal = ({ onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const koreanChangeHandler = () => {
    i18n.changeLanguage('ko');
    trackEvent(eventNames.changeLang, currentUser, {
      source: 'mypage',
      type: 'kor',
      platformType,
    });
    onClose();
  };

  const englishChangeHandler = () => {
    i18n.changeLanguage('en');
    trackEvent(eventNames.changeLang, currentUser, {
      source: 'mypage',
      type: 'eng',
      platformType,
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} modalSheetStyle={{ width: '490px' }}>
      <S.ModalContainer>
        <S.ModalTitle>{t('store.languageModal.title')}</S.ModalTitle>
        <S.ModalButtonContainer>
          <S.ModalButton $hasBorder onClick={koreanChangeHandler}>
            <S.ModalButtonText $isSelected={i18n.language === 'ko'}>
              {t('store.languageModal.korean')}
            </S.ModalButtonText>
          </S.ModalButton>
          <S.ModalButton onClick={englishChangeHandler}>
            <S.ModalButtonText $isSelected={i18n.language === 'en'}>
              {t('store.languageModal.english')}
            </S.ModalButtonText>
          </S.ModalButton>
          <div style={{ marginBottom: '16px' }} />
        </S.ModalButtonContainer>
      </S.ModalContainer>
    </Modal>
  );
};

export default LanguageModal;
