import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { ReactComponent as AppleIcon } from '../../../../../assets/images/store/appleCircle.svg';
import { ReactComponent as AuthorizedCreator } from '../../../../../assets/images/store/authorizedCreator.svg';
import { ReactComponent as GoogleIcon } from '../../../../../assets/images/store/googleCircle.svg';
import { ReactComponent as KakaoIcon } from '../../../../../assets/images/store/kakaoCircle.svg';
import profileImage from '../../../../../assets/images/store/profileImage.svg';
import * as S from './index.styles';

const Profile = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const isCreator = currentUser?.connectedHaplStudio === true;

  return (
    <S.Container>
      <S.ProfileImage src={currentUser?.profileImgSignUrl ?? profileImage} />
      <S.UserDescription>
        <S.UserNameContainer>
          <S.UserName>{currentUser?.nickName}</S.UserName>
          {isCreator && <AuthorizedCreator />}
        </S.UserNameContainer>
        <S.UserEmailContainer>
          <S.UserEmail>{currentUser?.email}</S.UserEmail>
          <S.UserSocialIconContainer>
            {currentUser?.socialAccountTypes?.includes('apple') && <AppleIcon />}
            {currentUser?.socialAccountTypes?.includes('google') && <GoogleIcon />}
            {currentUser?.socialAccountTypes?.includes('kakao') && <KakaoIcon />}
          </S.UserSocialIconContainer>
        </S.UserEmailContainer>
      </S.UserDescription>
    </S.Container>
  );
};

export default Profile;
