import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useReactNativeEvents from 'src/hooks/useReactNativeEvents';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatform } from 'src/utils/platformUtils';
import Modal from '../../../components/common/Modal';
import { PATH } from '../../../constants/_common/path';
import * as S from './LoginPopUp.styles';

type Props = {
  onClose: () => void;
  hideBottomTabs?: boolean;
  source?: string;
};

const LoginPopUp = ({ onClose, hideBottomTabs = true, source }: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: currentUser } = useCurrentUserQuery();
  const { postMessageToRN } = useReactNativeEvents();

  const onButtonClick = () => {
    const platform = getPlatform();
    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    trackEvent(eventNames.clickGoToLogin, currentUser, {
      source,
      platformType,
    });

    onClose();

    if (platformType === 'app') {
      postMessageToRN('navigateTo', {
        screen: 'LoginScreen',
        params: { hasBackButton: true },
      });
    } else {
      navigate(PATH.LOGIN, {
        state: {
          redirectPath: pathname,
        },
      });
    }
  };

  useLayoutEffect(() => {
    if (hideBottomTabs) {
      postMessageToRN('', { hideBottomTabs });

      return () => {
        postMessageToRN('', { hideBottomTabs: false });
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal modalSheetStyle={{ width: '490px' }} onClose={onClose}>
      <S.Container>
        <S.CloseButton onClick={onClose} />
        <S.MainText>
          {i18n.language === 'en' ? (
            <>
              <span>{t('store.loginPopUp.text')}</span>
              <S.HighlightedText>{t('store.loginPopUp.highlightText')}</S.HighlightedText>
            </>
          ) : (
            <>
              <S.HighlightedText>{t('store.loginPopUp.highlightText')}</S.HighlightedText>
              <span>{t('store.loginPopUp.text')}</span>
            </>
          )}
        </S.MainText>

        <S.SubText>{t('store.loginPopUp.subText')}</S.SubText>

        <S.Button onClick={onButtonClick}>
          <span>{t('store.loginPopUp.buttonText')}</span>
        </S.Button>
      </S.Container>
    </Modal>
  );
};

export default LoginPopUp;
