import { ProblemType } from 'src/apis/types/products.types';
import * as S from './ProblemTypeRadio.styles';

type Props = {
  value: ProblemType;
  checked: boolean;
  onChange: () => void;
  label: string;
};

const ProblemTypeRadio = ({ value, checked, onChange, label }: Props) => (
  <S.List>
    <S.RadioInput
      type='radio'
      id={value}
      name='problemType'
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <S.Label htmlFor={value}>
      {checked ? <S.CheckOnCircleIcon /> : <S.CheckOffCircleIcon />}
      {label}
    </S.Label>
  </S.List>
);

export default ProblemTypeRadio;
