import styled from 'styled-components';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoResultContainerWrapper = styled(CenteredContainer)`
  width: 100%;
  max-width: 576px;
  margin-top: 16px;
  height: 50dvh;
`;

export const NoResultContent = styled(CenteredContainer)``;
