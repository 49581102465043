import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

export const Container = styled.div`
  padding: 56px 24px 24px 24px;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;

  width: 28px;
  height: 28px;
  padding: 5px;
  box-sizing: border-box;
  fill: ${({ theme }) => theme.color.black};

  position: absolute;
  top: 28px;
  right: 28px;
`;

export const MainText = styled.div`
  margin-bottom: 12px;
  ${({ theme }) => theme.font.H3_BOLD}
  line-height: 26px;
  color: ${({ theme }) => theme.color.black};
  white-space: pre-wrap;
  text-align: center;
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.color.primary_500};
`;

export const SubText = styled.div`
  ${({ theme }) => theme.font.B2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_600};
  text-align: center;
  margin-bottom: 41px;
`;

export const Button = styled.button`
  width: 100%;
  ${({ theme }) => theme.font.BUTTON};
  color: ${({ theme }) => theme.color.white};
  padding: 20px 0;
  background-color: ${({ theme }) => theme.color.primary_500};
  border-radius: 6px;
  border: none;
  cursor: pointer;
`;
