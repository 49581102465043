import ImageUpload from './ImageUpload';
import * as S from './index.styles';

type Props = {
  previewImages: string[];
  handleImageChange: (images: File[]) => void;
  handleImageRemove: (targetIndex: number) => void;
  isAllImagesRemoved: boolean;
};

const ImageSection = ({
  previewImages,
  handleImageChange,
  handleImageRemove,
  isAllImagesRemoved,
}: Props) => {
  return (
    <S.Container>
      <ImageUpload
        uploadedImageCount={previewImages.length}
        handleImageChange={handleImageChange}
        isAllImagesRemoved={isAllImagesRemoved}
      />
      {previewImages.map((image, index) => (
        <S.ImageWrapper key={image}>
          <S.Image src={image} alt='review' />
          <S.CircleClose onClick={() => handleImageRemove(index)}>
            <S.Close />
          </S.CircleClose>
        </S.ImageWrapper>
      ))}
    </S.Container>
  );
};

export default ImageSection;
