import styled from 'styled-components';
import BREAK_POINTS from './../../../constants/_common/breakPoints';

export const FavoritePageContainer = styled.div`
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
`;

export const FavoriteProductBoard = styled.div`
  gap: 20px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    gap: 12px;
  }
`;

export const FavoriteProductPart = styled.div`
  max-width: 644px;
  width: 90%;
  margin: 20px auto 0 auto;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FavoriteProductCount = styled.span`
  width: 100%;
  color: ${({ theme }) => theme.color.gray_400};
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 374px) {
    margin-left: 10px;
  }
`;

export const FavoriteHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 1;

  position: sticky;
  top: 0px;
`;

export const FavoriteTabMenu = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
`;
