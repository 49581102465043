import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { googleLogin } from '../../../apis/auth';
import { PATH } from '../../../constants/_common/path';
import { UserContext } from '../../../context/userContext';
import { showToast } from '../../../utils/_common/showToast';
import { setUserInfoInLocalStorage } from '../../../utils/request';
import useCurrentUserQuery from './useCurrentUserQuery';

const useGoogleLoginMutation = (redirectPath?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { refreshWishlist, refreshUserBalance, getLoginUser } = useContext(UserContext);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  return useMutation({
    mutationFn: googleLogin,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });

      const accessToken = data.data.data.jwtToken;
      const userId = data.data.data.user.id;
      const signupCompleted = data.data.data.user.signupCompleted;
      if (signupCompleted === false) {
        trackEvent(eventNames.socialConnectComplete, currentUser, {
          type: 'signup',
          method: 'google',
          platformType,
        });
        navigate(PATH.SIGN_UP, {
          state: {
            oAuthType: 'google',
            redirectPath,
            variables,
            accessToken,
          },
        });
      } else {
        // TODO: tanstack query로 모두 대체하면 지워야 합니다.
        // TODO: We should remove this if we replace all with tanstack query.
        trackEvent(eventNames.socialConnectComplete, currentUser, {
          type: 'login',
          method: 'google',
          platformType,
        });
        refreshWishlist();
        refreshUserBalance();
        getLoginUser();

        setUserInfoInLocalStorage({
          accessToken,
          userId,
        });
        navigate(redirectPath ?? PATH.STORE);
      }
    },
    onError: () => {
      showToast({
        toastMessage: t('common.errorOccurred'),
        toastType: 'error',
      });
    },
  });
};

export default useGoogleLoginMutation;
