import styled from 'styled-components';

export const DonateAuthCardContainer = styled.div`
  padding: 40px 16px 24px 16px;
`;

export const Text = styled.p`
  margin-bottom: 24px;
  color: #212121;
  font-family: 'Pretendard-Bold';
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`;

export const HighlightText = styled.span`
  color: #8064fc;
`;

export const Image = styled.img`
  width: 295px;
  height: 430px;
`;

export const Button = styled.button<{ $fill?: boolean }>`
  width: 100%;
  padding: 16px 0;
  margin-top: 16px;
  border: none;
  border-radius: 6px;
  background-color: ${({ $fill }) => ($fill ? '#6638E6' : 'transparent')};
  color: ${({ $fill }) => ($fill ? '#ffffff' : '#6638E6')};
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
