import CircularProgress from '@mui/material/CircularProgress';
import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const BottomSheetCharging = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  max-height: 660px;
  z-index: 4;
  bottom: 0;

  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.color.white};
  cursor: pointer;
  transition: 0.3s ease-in-out;
`;

export const PurpleChargingContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
`;

export const PurpleChargingContentsContainer = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  max-width: 1416px;
  padding-left: 24px;
  padding-bottom: 12px;
  height: 24px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    gap: 5px;
  }
`;

export const CurrentBalance = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const ModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  margin-bottom: 104px;
  margin-left: 24px;
  margin-right: 24px;
`;

export const DivideBar = styled.div`
  display: flex;
  height: 8px;
  align-self: stretch;
  background: ${({ theme }) => theme.color.gray_100};
`;

export const PointCardUnitContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;
  margin-bottom: 16px;

  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PurpleChargingPurpleIconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
`;

export const PurpleChargingPurpleIconResize = styled.img`
  display: flex;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
`;

export const PurpleChargingPurpleIconText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.PURPLE_POINT_ICON}
  position: absolute;
  left: 7px;
  top: 5px;
`;

export const PurpleChargingPurplePrice = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_900};
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.H2_SEMIBOLD}
`;

export const MyPurpleBeforeCharging = styled.span`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.H3_MEDIUM};
`;

export const PurpleChargingWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
`;

export const PurpleChargingWarningTitle = styled.span`
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.B2_SEMIBOLD}
  text-align: start;
  padding-top: 8px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }
`;

export const PurpleChargingWarningText = styled.div`
  display: flex;
  width: 98%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 12px;
  text-align: left;
`;

export const PurpleChargingWarningTextList = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_MEDIUM}
  align-self: stretch;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_MEDIUM}
  }
`;

export const ChargingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
`;

export const PartTitle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_MEDIUM};
`;

export const PurpleChargingChargeBar = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 15px;
`;

export const PurpleChargingPriceBar = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodTitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  margin: 16px 0px;
`;

export const PaymentMethodButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 24px;
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 100%;
  }
`;

export const PaymentMethodBtn = styled.button<{ $selected: boolean }>`
  display: flex;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid
    ${({ $selected, theme }) => ($selected ? theme.color.gray_800 : theme.color.gray_300)};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.color.gray_800 : theme.color.white};
`;

export const PaymentMethodBtnText = styled.span<{ $selected: boolean }>`
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.white : theme.color.gray_700};
  ${({ theme }) => theme.font.B2_SEMIBOLD_SHORT};
`;

export const PurpleChargingText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
`;

export const PurpleChargingPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const PurpleChargingPoint = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H2_SEMIBOLD}
`;

export const PurpleChargingPriceText = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
`;

export const PurpleChargingPrice = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.H1_BOLD}
`;

export const PurpleChargingButtonContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  width: 100%;
  max-width: 1392px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${({ theme }) => theme.color.white};
  bottom: 0;
  height: 104px;
  box-shadow: -4px 4px 20px 0px rgba(15, 11, 31, 0.2);
`;

export const PurpleChargingButton = styled.button`
  display: flex;
  position: fixed;
  width: calc(100% - 48px);
  max-width: 1392px;
  bottom: 24px;
  padding: 20px 68px;
  justify-content: center;
  border-radius: 6px;
  border: 0px;
  background: ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const PurpleChargingButtonText = styled.span`
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.TOAST}
`;

export const BottomSheetHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const HeaderHandle = styled.div`
  display: flex;
  width: 36px;
  height: 4px;
  border-radius: 99px;
  background-color: ${({ theme }) => theme.color.gray_200};
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export const PointCardUnitRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
`;

export const PointCardUnit = styled.div<{ $selected: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.color.gray_50};
  border-radius: 16px;
  border: ${({ $selected, theme }) =>
    $selected
      ? `2px solid ${theme.color.button_purple}`
      : `2px solid ${theme.color.gray_50}`};
`;

export const PointCardContentsBar = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0px;
  align-items: center;
  justify-content: space-between;
`;

export const PointCardUnitLeft = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  gap: 8px;
  align-items: center;
  @media screen and (max-width: 350px) {
    margin-left: 10px;
  }
`;

export const BonusPurpleContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.color.primary_50};
  border-radius: 999px;
  padding: 2px 8px;
  align-items: center;

  // ${({ theme }) => theme.color.primary_400};
`;

export const BonusPurpleText = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B2_SEMIBOLD}

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }
`;

export const PurpleNumText = styled.span`
  ${({ theme }) => theme.font.H2_MEDIUM}

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    ${({ theme }) => theme.font.H3_MEDIUM}
  }

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.H3_MEDIUM}
  }
`;

export const PointCardUnitRight = styled.div`
  display: flex;
  margin-right: 24px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 350px) {
    margin-right: 10px;
  }
`;

export const PurplePriceText = styled.span<{ $selected: boolean }>`
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.primary_400 : theme.color.gray_600};
  ${({ theme }) => theme.font.H3_MEDIUM}

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    ${({ theme }) => theme.font.SUB2_MEDIUM}
  }

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_MEDIUM}
  }
`;

export const Progress = styled(CircularProgress)``;
