import { useTranslation } from 'react-i18next';
import * as S from './index.styles';

const Title = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.TitleText>{t('store.myPage.title')}</S.TitleText>
    </S.Container>
  );
};

export default Title;
