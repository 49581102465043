import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import store_images from '../../../assets/images/store/images';
import { UserContext } from '../../../context/userContext';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import {
  brandProps,
  eventNames,
  storeProductDetailSources,
} from '../../../utils/mixpanel';
import { addToWishlist, removeFromWishlist } from '../../../utils/request';
import { setScrollPosition } from '../../../utils/scroll';
import * as S from './BrandCard.styles';

const BrandCard = (props) => {
  const { wishlist, refreshWishlist } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const showLoginPopUp = useModalManager('LoginPopUp');

  const handleImageClick = async (item) => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ source: 'likeBtn' });
      return;
    }

    const isRemove = props?.isWishlistPage === true || wishlist[item?._id];
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');
    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    trackEvent(
      isRemove ? eventNames.brandUnlikeClick : eventNames.brandLikeClick,
      currentUser,
      {
        ...brandProps(item),
        platformType,
      }
    );

    const response = isRemove
      ? await removeFromWishlist(wishlist[item?._id].id)
      : await addToWishlist(null, item?._id);

    if (response?.data?.status) {
      refreshWishlist();
      if (props.onFavChange) props.onFavChange();
    }
  };

  const stickerUnFav = store_images.favoriteUnfilled;
  const stickerFav = store_images.favoriteFilled;

  return props.items.map((item, index) => (
    <S.BrandItemContainer
      key={index}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setScrollPosition(props.pageName);
        navigate(`${PATH.STORE}/brand/detail/${item?._id}`, {
          state: {
            source:
              props.pageName === 'brands'
                ? storeProductDetailSources.brandPopular
                : storeProductDetailSources.wishlist,
          },
        });
      }}
    >
      <S.BrandImageContainer>
        <S.BrandImageResize
          src={
            item?.logoImageSignUrl ||
            item?.logoImage?.location ||
            store_images.defaultProfile
          }
          alt={
            i18n.language === 'en'
              ? item?.nameEN || 'brand image'
              : item?.name || '브랜드 이미지'
          }
        />

        <S.BrandFavoriteImage
          src={
            props?.isWishlistPage === true || wishlist[item?._id]
              ? stickerFav
              : stickerUnFav
          }
          alt={i18n.language === 'en' ? 'add wishlist' : '위시리스트에 추가'}
          onClick={(e) => {
            e.stopPropagation();
            handleImageClick(item);
          }}
        />
      </S.BrandImageContainer>
      <S.BrandTextContainer>
        <S.BrandTextName>
          {i18n.language === 'en' ? item?.nameEN : item?.name}
        </S.BrandTextName>
        <S.BrandTextDetail>{item?.description}</S.BrandTextDetail>
      </S.BrandTextContainer>
    </S.BrandItemContainer>
  ));
};

export default BrandCard;
