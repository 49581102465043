import React from 'react';
import * as S from './PinSticker.styles.ts';

const PinSticker = (props) => {
  const productItems = props.items.map((item, index) => (
    <S.GiftImageContainer key={index}>
      <S.GiftImageResize src={item.signUrl} alt='gift-sticker' />
    </S.GiftImageContainer>
  ));
  return productItems;
};
export default PinSticker;
