import * as ChannelIO from '@channel.io/channel-web-sdk-loader';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useNavigation from 'src/hooks/useNavigation';
import { ADMIN_URL } from 'src/utils/configs';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatform } from 'src/utils/platformUtils';
import ProfileMenuItem from './ProfileMenuItem/ProfileMenuItem';
import * as S from './index.styles';

const ProfileMenuList = () => {
  const { t } = useTranslation();
  const { navigateToPurchaseHistory } = useNavigation();
  const { data: currentUser } = useCurrentUserQuery();
  const showLanguageModal = useModalManager('LanguageModal');
  const showLogoutModal = useModalManager('LogoutModal');

  const platform = getPlatform();
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isCreator = currentUser?.connectedHaplStudio === true;

  return (
    <S.MenuList>
      <ProfileMenuItem
        title={t('store.myPage.purchaseHistory')}
        onClick={navigateToPurchaseHistory}
      />
      {isCreator && (
        <ProfileMenuItem
          title={t('store.myPage.goToCreatorStudio')}
          onClick={() => {
            window.open(ADMIN_URL, '_blank');

            trackEvent(eventNames.clickGoToCreatorStudio, currentUser, {
              source: 'mypage',
              platformType,
            });
          }}
        />
      )}
      <ProfileMenuItem
        title={t('store.myPage.contactUs')}
        onClick={() => {
          ChannelIO.showMessenger();
        }}
      />
      <ProfileMenuItem
        title={t('store.myPage.languageSetting')}
        onClick={showLanguageModal}
      />
      <ProfileMenuItem
        title={t('store.myPage.logout')}
        onClick={showLogoutModal}
        isBlur
      />
    </S.MenuList>
  );
};

export default ProfileMenuList;
