import { LottieRef } from 'lottie-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import storageDemoEn from '../../../assets/lottie/StorageDemo/storage_demo_en.json';
import storageDemoKr from '../../../assets/lottie/StorageDemo/storage_demo_kr.json';
import Modal from '../../../components/common/Modal';
import { ONE_LINK_URL } from '../../../constants/_common/url';
import { IS_USER_ON_MOBILE_DEVICE } from '../../../constants/_common/userAgent';
import * as S from './UseStorageInAppPopUp.styles';

type Props = {
  onClose: () => void;
  source?: string;
};

const UseStorageInAppPopUp = ({ onClose: onPopUpClose, source }: Props) => {
  const { t, i18n } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const lottieRef: LottieRef = useRef(null);
  const showAppInstallQrPopUp = useModalManager('AppInstallQrPopUp');

  const onButtonClick = () => {
    trackEvent(eventNames.clickAppInstallModalButton, currentUser, {
      source: source ?? 'storageTab',
      isUser: !!currentUser,
    });
    onPopUpClose();

    if (IS_USER_ON_MOBILE_DEVICE) {
      window.open(ONE_LINK_URL, '_blank');
    } else {
      showAppInstallQrPopUp();
    }
  };

  return (
    <Modal onClose={onPopUpClose} modalSheetStyle={{ width: '490px' }}>
      <S.LottieContainer>
        <S.Lottie
          lottieRef={lottieRef}
          animationData={i18n.language === 'en' ? storageDemoEn : storageDemoKr}
          onDOMLoaded={() => {
            lottieRef.current?.setSpeed(2.5);
          }}
        />
      </S.LottieContainer>

      <S.Content>
        <S.BoldText>{t('store.useStorageInAppPopUp.borderText')}</S.BoldText>
        <S.Text>{t('store.useStorageInAppPopUp.text')}</S.Text>
        <S.Button onClick={onButtonClick}>
          {t('store.useStorageInAppPopUp.buttonText')}
        </S.Button>
      </S.Content>
    </Modal>
  );
};

export default UseStorageInAppPopUp;
