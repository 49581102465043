import { ReactComponent as CheckOffCircle } from 'src/assets/icons/CheckOffCircle.svg';
import { ReactComponent as CheckOnCircle } from 'src/assets/icons/CheckOnCircle.svg';
import styled from 'styled-components';

export const List = styled.li`
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};

  display: flex;
  gap: 12px;
  cursor: pointer;
`;

export const CheckOffCircleIcon = styled(CheckOffCircle)`
  flex-shrink: 0;
  stroke: ${({ theme }) => theme.color.gray_300};
`;

export const CheckOnCircleIcon = styled(CheckOnCircle)`
  flex-shrink: 0;
  fill: ${({ theme }) => theme.color.warning_400};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
