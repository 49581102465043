import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BASE_URL } from 'src/utils/configs';

const helmetContext = {};

const OG_URL = `${BASE_URL}/store`;
const OG_TITLE = '하플 HAPL';
const OG_DESCRIPTION = '디지털 굿즈는 하플에서 가장 편리하게!';
const OG_IMAGE =
  'https://hapl-static.s3.ap-northeast-2.amazonaws.com/hapl-store-meta-image.png';

type Props = {
  children: React.ReactNode;
};

const HelmetContextProvider = ({ children }: Props) => {
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{OG_TITLE}</title>
        <link rel='canonical' href={OG_URL} />
        <meta name='description' content={OG_TITLE} />
        <meta property='og:type' content={'website'} />
        <meta property='og:title' content={OG_TITLE} />
        <meta property='og:description' content={OG_DESCRIPTION} />
        <meta property='og:image' content={OG_IMAGE} />
        <meta property='og:url' content={OG_URL} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={OG_TITLE} />
        <meta name='twitter:description' content={OG_DESCRIPTION} />
        <meta name='twitter:image' content={OG_IMAGE} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default HelmetContextProvider;
