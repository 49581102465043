import React, { Fragment } from 'react';
import RollingNumber from './RollingNumber';
import * as S from './RollingNumbers.styles';

type Props = {
  value: number;
  fontSize: number;
};

const RollingNumbers = ({ value, fontSize }: Props) => {
  const numbers = value.toString().split('');

  return (
    <S.RollingNumbers>
      {numbers.map((number, index, array) => {
        const isComma = (array.length - index - 1) % 3 === 0;
        const isLast = index === array.length - 1;
        const comma = isComma && !isLast && <div>,</div>;

        return (
          <Fragment key={index}>
            <RollingNumber displayNumber={Number(number)} fontSize={fontSize} />
            {comma}
          </Fragment>
        );
      })}
    </S.RollingNumbers>
  );
};

export default RollingNumbers;
