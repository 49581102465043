import React, { useEffect, useState } from 'react';
import * as S from './RollingNumber.styles';

type Props = {
  displayNumber: number;
  fontSize: number;
};

const RollingNumber = ({ displayNumber, fontSize }: Props) => {
  const [rollingNumber, setRollingNumber] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setRollingNumber(displayNumber);
    }, 100);
  }, [displayNumber]);

  return (
    <S.RollingNumberContainer $fontSize={fontSize}>
      <S.RollingNumberWindow $rollingNumber={rollingNumber} $fontSize={fontSize}>
        {Array.from({ length: 10 }, (_, i) => (
          <S.Num $fontSize={fontSize} key={i}>
            {i}
          </S.Num>
        ))}
      </S.RollingNumberWindow>
    </S.RollingNumberContainer>
  );
};

export default RollingNumber;
