import * as ChannelIO from '@channel.io/channel-web-sdk-loader';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ErrorBoundary from './ErrorBoundary';
import MainComponent from './MainComponent';
import GlobalModalContainer from './components/common/GlobalModalContainer';
import InAppBrowserBlocker from './components/common/InAppBrowserBlocker';
import ToastWrapper from './components/common/ToastWrapper';
import WebViewWrapper from './components/common/WebViewWrapper';
import HelmetContextProvider from './context/HelmetContext';
import { ExhibitionContextProvider } from './context/exhibitionContext';
import { ModalContextProvider } from './context/modalContext';
import UserContextProvider from './context/userContext';
import useDisablePinchZoomEffect from './hooks/_common/useDisablePinchZoomEffect';
import ErrorPage from './screens/ErrorPage';
import { theme } from './styles/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
    },
    mutations: {
      throwOnError: true,
    },
  },
});

function App() {
  useDisablePinchZoomEffect();
  ChannelIO.loadScript();

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <HelmetContextProvider>
            <InAppBrowserBlocker>
              <WebViewWrapper>
                <UserContextProvider>
                  <ExhibitionContextProvider>
                    <ModalContextProvider>
                      <Router>
                        <QueryErrorResetBoundary>
                          {({ reset }) => (
                            <ErrorBoundary
                              fallback={<ErrorPage queryCacheReset={reset} />}
                            >
                              <MainComponent />
                              <ToastWrapper />
                              <GlobalModalContainer />
                            </ErrorBoundary>
                          )}
                        </QueryErrorResetBoundary>
                      </Router>
                    </ModalContextProvider>
                  </ExhibitionContextProvider>
                </UserContextProvider>
              </WebViewWrapper>
            </InAppBrowserBlocker>
          </HelmetContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
