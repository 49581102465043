import styled from 'styled-components';

export const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 9998;
  opacity: 0.6;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  border-radius: 24px 24px 0 0;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;
