import { KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../../assets/images/search/images';

import * as S from './SearchInput.styles';

type Props = {
  onEnterPress: () => void;
  handleSearchTermChange: (value: string) => void;
  searchTerm: string;
};

const SearchInput = ({ onEnterPress, handleSearchTermChange, searchTerm }: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') onEnterPress();
  };

  const clearInputAndFocus = () => {
    handleSearchTermChange('');
    inputRef.current?.focus();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <S.SearchBoxContainer>
      <S.SearchIcon src={images.search} alt='search' onClick={onEnterPress} />
      <S.SearchInput
        $hasValue={!!searchTerm}
        ref={inputRef}
        placeholder={t('search.mainPage.inputPlaceholder')}
        onKeyDown={onKeyDown}
        value={searchTerm}
        onChange={(event) => handleSearchTermChange(event.target.value)}
      />

      {searchTerm && (
        <S.ClearAllIcon
          src={images.closeSearchTag}
          alt='clearAll'
          onClick={clearInputAndFocus}
        />
      )}
    </S.SearchBoxContainer>
  );
};

export default SearchInput;
