import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './FavoriteTabBar.styles';

function FavoriteTabBar({ favoriteSelectedTab, setFavoriteSelectedTab }) {
  const { t } = useTranslation();

  return (
    <S.FavoriteTabTextContainer>
      <S.FavoriteTab
        $selected={favoriteSelectedTab === t('store.favoritePage.sticker')}
        onClick={() => {
          setFavoriteSelectedTab(t('store.favoritePage.sticker'));
        }}
      >
        {t('store.favoritePage.sticker')}
      </S.FavoriteTab>

      <S.FavoriteTab
        $selected={favoriteSelectedTab === t('store.favoritePage.template')}
        onClick={() => {
          setFavoriteSelectedTab(t('store.favoritePage.template'));
        }}
      >
        {t('store.favoritePage.template')}
      </S.FavoriteTab>
      <S.FavoriteTab
        $selected={favoriteSelectedTab === t('store.favoritePage.brand')}
        onClick={() => {
          setFavoriteSelectedTab(t('store.favoritePage.brand'));
        }}
      >
        {t('store.favoritePage.brand')}
      </S.FavoriteTab>
    </S.FavoriteTabTextContainer>
  );
}

export default FavoriteTabBar;
