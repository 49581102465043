import { TOP_BAR_HEIGHT } from 'src/components/store/Bar/CommonTopBar.styles';
import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import colors from 'src/utils/colors';
import styled, { css } from 'styled-components';

export const NAVIGATION_BAR_HEIGHT = 52;

export const Navigation = styled.nav`
  width: 100%;
  height: ${NAVIGATION_BAR_HEIGHT}px;
  background-color: ${colors.$white};

  position: sticky;
  top: ${TOP_BAR_HEIGHT}px;
  z-index: 10;

  ul {
    max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;

    display: flex;
    gap: 24px;

    @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
      padding: 0 16px;
    }
  }

  &::after {
    width: 100vw;
    max-width: 1440px;
    border-bottom: 1px solid ${colors.$gray200};
    content: '';
    transform: translateX(-50%);

    position: absolute;
    bottom: -1px;
    left: 50%;
  }
`;

export const NavigationTab = styled.li<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    padding: 12px 8px;
    ${theme.font.SUB1_SEMIBOLD};
    color: ${$isSelected ? theme.color.gray_900 : theme.color.gray_400};
    ${$isSelected && `border-bottom: 2px solid ${theme.color.gray_900}`};
    cursor: pointer;
  `}
`;
