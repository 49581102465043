import styled from 'styled-components';

export const GenderBtnWrapper = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 43px;
`;

export const GenderBtn = styled.button<{ $isSelected?: boolean }>`
  border-radius: 6px;
  border: ${({ theme, $isSelected }) =>
    $isSelected
      ? `2px solid ${theme.color.primary_400}`
      : `2px solid ${theme.color.gray_500}`};
  background: none;
  width: 50%;
  height: 100%;
  cursor: pointer;
`;

export const GenderBtnText = styled.p<{ $isSelected?: boolean }>`
  ${({ theme }) => theme.font.SUB2_SEMIBOLD};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.color.primary_400 : theme.color.gray_500};
`;
