import * as S from './Category.styles';

type Props = {
  category?: string;
  onClick?: () => void;
};

const Category = ({ category = 'Weekly', onClick }: Props) => {
  return <S.CategoryRounded onClick={onClick}>{category}</S.CategoryRounded>;
};

export default Category;
