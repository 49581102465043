import { ReactComponent as ArrowDown } from 'src/assets/images/store/arrowDown.svg';
import colors from 'src/utils/colors';
import * as S from './ViewMoreButton.styles';

type Props = {
  onClick: () => void;
  text: string;
};

const ViewMoreButton = ({ onClick, text }: Props) => {
  return (
    <S.ViewMoreContainer>
      <S.ViewMoreButton onClick={onClick}>
        <span>{text}</span>
        <ArrowDown fill={colors.$gray700} />
      </S.ViewMoreButton>
    </S.ViewMoreContainer>
  );
};

export default ViewMoreButton;
