import styled from 'styled-components';

export const ExhibitionProductMobile = styled.div`
  position: relative;
`;

export const PurchaseButtonOverlay = styled.div`
  width: 87%;
  height: 13.3%;
  position: absolute;
  top: 68.7%;
  left: 6.5%;
  cursor: pointer;
`;

export const ProductDetailButtonOverlay = styled.div`
  width: 87%;
  height: 14%;
  position: absolute;
  top: 84%;
  left: 6.5%;
  cursor: pointer;
`;

export const ProductImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const ButtonImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 6px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
