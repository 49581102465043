import { ChangeEvent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { theme } from 'src/styles/theme';
import { eventNames } from 'src/utils/mixpanel';
import { ReactComponent as ErrorOutline } from '../../../../../assets/images/store/errorOutline.svg';
import * as G from '../../../../../styles/commonStyles';
import { SignUpContext } from '../../SignUpContext';
import { isUnder14Year } from '../../validate';
import NextButton from '../NextButton';
import * as S from './DateOfBirthScreen.styles';

const DateOfBirthScreen = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();
  const { nickName, dateOfBirth, setDateOfBirth } = useContext(SignUpContext);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDateOfBirth(event.target.value);
  };

  const isUnder14 = isUnder14Year(dateOfBirth);

  const onNextButtonClick = () => {
    if (isUnder14) {
      return;
    }
    onNext();
  };

  const showErrorMessage = dateOfBirth !== '' && isUnder14;

  useEffect(() => {
    trackEvent(eventNames.signupBirthdayPage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <G.Content>
        <S.DateInputContainer>
          <G.SignUpTitle>
            {t('store.signUpPage.dateOfBirthTitle', { nickName })}
          </G.SignUpTitle>
          <G.Separator $separatorHeight={63} />
          <S.DateInput
            type='date'
            value={dateOfBirth}
            onChange={handleDateChange}
            $isError={showErrorMessage}
          />
          {showErrorMessage && (
            <S.DateOfBirthErrorMessageContainer>
              <ErrorOutline fill={theme.color.warning_500} />
              <S.DateOfBirthErrorMessage>
                {t('store.signUpPage.ageRestrictionMessage')}
              </S.DateOfBirthErrorMessage>
            </S.DateOfBirthErrorMessageContainer>
          )}
        </S.DateInputContainer>
      </G.Content>

      <G.ButtonContainer>
        <NextButton
          onClick={onNextButtonClick}
          buttonText={t('store.signUpPage.next')}
          disabled={dateOfBirth === '' || isUnder14}
        />
      </G.ButtonContainer>
    </>
  );
};

export default DateOfBirthScreen;
