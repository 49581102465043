import { useTranslation } from 'react-i18next';
import StarRating from 'src/components/common/StarRating';
import useNavigation from 'src/hooks/useNavigation';
import * as S from './StarRatingSection.styles';

type Props = {
  rating?: number;
  size: number;
  isReviewed: boolean;
  reviewText?: string;
  productId: string;
};

const StarRatingSection = ({
  rating = 0,
  size,
  isReviewed,
  reviewText,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const { navigateToCreateReview } = useNavigation();

  const handleStarRatingClick = () => {
    navigateToCreateReview(productId);
  };

  return (
    <S.Container>
      <S.StarRatingContainer onClick={!isReviewed ? handleStarRatingClick : undefined}>
        <StarRating rating={rating} size={size} />
      </S.StarRatingContainer>
      {isReviewed ? (
        <S.ReviewText>{reviewText}</S.ReviewText>
      ) : (
        <S.PurpleText>{t('store.purchaseHistory.item.reward')}</S.PurpleText>
      )}
    </S.Container>
  );
};

export default StarRatingSection;
