import styled from 'styled-components';

export const TopBar = styled.div<{ $topOffset: number }>`
  width: 100%;
  position: fixed;
  top: ${({ $topOffset }) => $topOffset}px;
  left: 0;
  transition: top 0.3s;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
  z-index: 1000;
  text-align: start;
`;

export const ArrowImage = styled.img`
  width: 24px;
  height: 24px;
  padding: 12px 24px;
  cursor: pointer;
  vertical-align: middle;
`;
