import React from 'react';
import { useTranslation } from 'react-i18next';
import './ComingSoonPage.css';
import ComingSoon from '../../assets/images/store/Campaign.svg';
import LazyImage from '../../components/common/LazyImage/LazyImage';

const ComingSoonPage = () => {
  const { i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') || i18n.language;

  return (
    <div className='coming-soon'>
      <LazyImage src={ComingSoon} alt='coming-soon' />
      <div className='text'>
        <h2>Coming Soon!</h2>
        <p>
          {lang === 'en'
            ? 'Update coming up in January.'
            : '1월 중에 업데이트 될 예정입니다.'}
        </p>
        <p>{lang === 'en' ? 'See you soon!' : '곧 만나요!'}</p>
      </div>
    </div>
  );
};
export default ComingSoonPage;
