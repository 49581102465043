import styled from 'styled-components';
import { NoContentBoxProps } from './NoContentBox';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const NoContentBoxWrapper = styled.div<Omit<NoContentBoxProps, 'children'>>`
  width: 100%;
  padding: ${({ padding }) => padding}px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  background-color: ${({ bgColor }) => bgColor};
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;

  ${({ isHorizontallyCentered }) =>
    isHorizontallyCentered &&
    `
      display: flex;
      justify-content: center;
    `}
  ${({ isVerticallyCentered }) =>
    isVerticallyCentered &&
    `
      display: flex;
      align-items: center;
    `}
    ${({ isHorizontallyCentered, isVerticallyCentered }) =>
    isHorizontallyCentered &&
    isVerticallyCentered &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const NoContentMainText = styled.span`
  display: block;

  font-family: Pretendard-SemiBold;
  color: #616161;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.625rem;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

export const NoContentSubText = styled.span`
  display: block;
  margin-top: 0.37rem;

  color: #bdbdbd;
  font-family: Pretendard-Medium;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.375rem;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 0.875rem;
  }
`;

export const NoContentMainButton = styled.button`
  font-family: Pretendard-SemiBold;
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  margin-top: 1.25rem;

  background-color: #6638e6;
  border-radius: 3.5rem;
  padding: 0.87rem 1.66rem;
  border: none;
  cursor: pointer;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 0.75rem;
  }
`;
