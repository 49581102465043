import styled from 'styled-components';

export const DateInputContainer = styled.div`
  width: 100%;
  max-width: 327px;
`;

export const DateInput = styled.input<{ $isError?: boolean }>`
  display: flex;
  width: 327px;
  height: 37px;
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  border: none;
  border-bottom: ${({ theme }) => `2px solid ${theme.color.gray_400}`};
  ${({ theme }) => theme.font.H2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_900};
  outline: none;

  ${({ $isError, theme }) =>
    $isError &&
    `
  border-bottom: 2px solid ${theme.color.warning_500}
  `}
`;

export const DateOfBirthErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  column-gap: 8px;
`;

export const DateOfBirthErrorMessage = styled.p`
  ${({ theme }) => theme.font.B2_REGULAR}
  color:${({ theme }) => theme.color.warning_500}
`;
