import styled from 'styled-components';

export const CategoryRounded = styled.div<{ onClick?: () => void }>`
  padding: 5px 10px;
  border-radius: 18px;
  font-size: 14px;
  background-color: white;
  border: 1px solid rgb(112, 60, 222);
  color: #703cde;
  font-family: 'Pretendard-Regular';

  ${({ onClick }) => onClick && `cursor: pointer;`};
`;
