import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ErrorOutline } from 'src/assets/images/store/errorOutline.svg';
import { Divider } from 'src/components/common/Divider';
import MixpanelScreenLogger from 'src/components/Logger/MixpanelScreenLogger';
import {
  useRegisterProductReviewMutation,
  useUpdateProductReviewMutation,
} from 'src/mutations/productMutations';
import productQueryOptions from 'src/queries/productQueryOptions';
import { theme } from 'src/styles/theme';
import { showToast } from 'src/utils/_common/showToast';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import { MAX_IMAGE_COUNT, MAX_REVIEW_LENGTH, MIN_REVIEW_LENGTH } from './_constants';
import * as S from './index.styles';
import ProductInfo from './ProductInfo';
import ReviewForm from './ReviewForm';
import ReviewSuccessModal from './ReviewSuccessModal';
import SubmitButton from './SubmitButton';
import TopBar from './TopBar';
import { Mode } from './types';
import WarningText from './WarningText';

type Props = {
  mode: Mode;
};

const ReviewSubmitPage = ({ mode }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const productId = params.productId as string;
  const reviewId = params.reviewId;

  const [rating, setRating] = useState(0);
  const [reviewImages, setReviewImages] = useState<
    {
      image?: File;
      previewImage: string;
      key?: string;
    }[]
  >([]);
  const [reviewText, setReviewText] = useState('');
  const [isInvalidText, setIsInvalidText] = useState(false);
  const [isReviewSuccessModalOpen, setIsReviewSuccessModalOpen] = useState(false);

  const { data: salesData } = useQuery(productQueryOptions.getSalesData(productId));
  const { data: existingReviewData } = useQuery({
    ...productQueryOptions.getExistingReviews(reviewId!),
    enabled: mode === 'edit' && !!reviewId,
  });
  const { mutate: registerProductReviewMutate, isPending: isSubmitPending } =
    useRegisterProductReviewMutation();
  const { mutate: updateProductReviewMutate, isPending: isUpdatePending } =
    useUpdateProductReviewMutation();

  useEffect(
    function updateReviewData() {
      if (existingReviewData) {
        const { rating, content, image: images } = existingReviewData;
        setRating(rating);
        setReviewText(content);
        setReviewImages(
          images.map((image) => ({
            previewImage: image.url,
            key: image.key,
          }))
        );
      }
    },
    [existingReviewData]
  );

  const isPhotoReview = reviewImages.length > 0;
  const hasExistingReview = !!existingReviewData;
  const hasExistingReviewImages =
    hasExistingReview && existingReviewData.image.length !== 0;
  const isAllImagesRemoved = hasExistingReviewImages && reviewImages.length === 0;
  const isReviewUnchanged =
    hasExistingReview &&
    existingReviewData.rating === rating &&
    existingReviewData.content === reviewText &&
    existingReviewData.image.length === reviewImages.length &&
    existingReviewData.image.every((image) =>
      reviewImages.some((reviewImage) => reviewImage.previewImage === image.url)
    );

  const submitButtonDisabled =
    rating === 0 ||
    reviewText === '' ||
    isInvalidText ||
    reviewImages.length > MAX_IMAGE_COUNT ||
    isAllImagesRemoved ||
    isReviewUnchanged;

  const resetForm = () => {
    setRating(0);
    setReviewImages([]);
    setReviewText('');
    setIsInvalidText(false);
  };

  const handleResetButtonClick = () => {
    resetForm();
    if (hasExistingReviewImages) {
      showToast({
        toastMessage: t('store.submitReview.photoReviewUpdateMessage'),
        toastType: 'info',
        icon: <ErrorOutline fill={theme.color.white} />,
      });
    }
  };

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleImageChange = (images: File[]) => {
    const newImages = [
      ...reviewImages,
      ...images.map((image) => ({
        image,
        previewImage: URL.createObjectURL(image),
      })),
    ];

    const isImageCountExceeded = newImages.length > MAX_IMAGE_COUNT;
    if (isImageCountExceeded) {
      showToast({
        toastMessage: t('store.submitReview.imageCountExceeded', {
          maxCount: MAX_IMAGE_COUNT,
        }),
        toastType: 'info',
        icon: <ErrorOutline fill={theme.color.white} />,
      });
      setReviewImages(newImages.slice(0, MAX_IMAGE_COUNT));
      return;
    }

    setReviewImages(newImages);
  };

  const handleImageRemove = (targetIndex: number) => {
    const filteredImages = reviewImages.filter((_, index) => index !== targetIndex);
    setReviewImages(filteredImages);

    if (hasExistingReviewImages && filteredImages.length === 0) {
      showToast({
        toastMessage: t('store.submitReview.photoReviewUpdateMessage'),
        toastType: 'info',
        icon: <ErrorOutline fill={theme.color.white} />,
      });
    }
  };

  const handleReviewTextChange = (value: string) => {
    const isTooShort = value.length < MIN_REVIEW_LENGTH;
    setIsInvalidText(isTooShort);

    const isTooLong = value.length > MAX_REVIEW_LENGTH;
    if (!isTooLong) {
      setReviewText(value);
    }
  };

  const handleReviewSubmit = () => {
    const images = reviewImages
      .map((reviewImage) => reviewImage.image)
      .filter((file): file is File => file != null);
    const variables = {
      productId,
      image: images,
      content: reviewText,
      rating,
    };

    if (mode === 'create') {
      createReview(variables);
      return;
    }
    if (mode === 'edit') {
      editReview(variables);
    }
  };

  const createReview = (variables: {
    productId: string;
    image: File[];
    content: string;
    rating: number;
  }) => {
    registerProductReviewMutate(variables, {
      onSuccess: () => {
        setIsReviewSuccessModalOpen(true);
      },
      onError: showErrorToast,
    });
  };

  const editReview = (variables: {
    productId: string;
    image: File[];
    content: string;
    rating: number;
  }) => {
    const previousImagesKeys = reviewImages
      .map((reviewImage) => reviewImage.key)
      .filter((key) => key != null);
    const removedImageKeys = existingReviewData?.image
      .filter((image) => !previousImagesKeys.includes(image.key))
      .map((image) => image.key);

    updateProductReviewMutate(
      { ...variables, removedImageKeys, reviewId: reviewId! },
      {
        onSuccess: () => {
          showToast({
            toastMessage: t('store.submitReview.reviewEdited'),
            toastType: 'info',
          });
          navigate(-1);
        },
        onError: showErrorToast,
      }
    );
  };

  const showErrorToast = () => {
    showToast({
      toastMessage: t('common.errorOccurred'),
      toastType: 'info',
      icon: <ErrorOutline fill={theme.color.white} />,
    });
  };

  const previewImages = reviewImages.map((reviewImage) => reviewImage.previewImage);

  if (!salesData) {
    return null;
  }

  return (
    <MixpanelScreenLogger
      params={{
        eventName:
          mode === 'create' ? eventNames.postReviewScreen : eventNames.editReviewScreen,
        options: {
          productName: salesData.korName,
          productType: salesData.salesType,
          brandName: salesData.brandName,
          platformType: getPlatformType(),
        },
      }}
    >
      <S.Container>
        <TopBar mode={mode} handleResetButtonClick={handleResetButtonClick} />
        <ProductInfo salesData={salesData} />
        <Divider height={1} />
        <ReviewForm
          rating={rating}
          handleRatingChange={handleRatingChange}
          reviewText={reviewText}
          handleReviewTextChange={handleReviewTextChange}
          isInvalidText={isInvalidText}
          previewImages={previewImages}
          handleImageChange={handleImageChange}
          handleImageRemove={handleImageRemove}
          isAllImagesRemoved={isAllImagesRemoved}
        />
        <Divider height={12} />
        <WarningText />
        <SubmitButton
          mode={mode}
          disabled={submitButtonDisabled}
          onClick={handleReviewSubmit}
          isLoading={isSubmitPending || isUpdatePending}
          buttonType={submitButtonDisabled ? 'disabled' : 'primary'}
        />
      </S.Container>
      {isReviewSuccessModalOpen && (
        <ReviewSuccessModal onClose={() => navigate(-1)} isPhotoReview={isPhotoReview} />
      )}
    </MixpanelScreenLogger>
  );
};

export default ReviewSubmitPage;
