import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const MyPurplesPart = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const MyPurple = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B2_SEMIBOLD}
  }
`;

export const PurpleCountPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const PurpleIconContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 36px;
    height: 36px;
  }
`;

export const PurpleIconResize = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
`;

export const PurpleIconText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.PURPLE_POINT_BIG}
  position: absolute;
  left: 14px;
  top: 10px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    left: 12px;
    top: 8px;
  }
`;

export const PurpleCount = styled.span`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.PURPLE_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.MOBILE_PURPLE_BOLD}
  }
`;
