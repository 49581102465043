import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import styled from 'styled-components';
import {
  Swiper as OriginalSwiper,
  SwiperSlide as OriginalSwiperSlide,
} from 'swiper/react';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
`;

export const DimSection = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.gray_900};
  opacity: 0.8;

  position: absolute;
  top: 0;
  left: 0;
`;

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 50px;
  right: 22px;
  cursor: pointer;
  fill: ${({ theme }) => theme.color.white};
  z-index: 1;
`;

export const Swiper = styled(OriginalSwiper)`
  width: 80%;
  height: 80%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SwiperSlide = styled(OriginalSwiperSlide)`
  display: flex;
  justify-content: center;
  cursor: grab;
`;

export const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  object-fit: contain;
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;

  &:hover {
    cursor: pointer;
  }

  > svg {
    width: 32px;
    height: 32px;
  }

  &.swiper-prev {
    left: 0;
    rotate: 90deg;
  }

  &.swiper-next {
    right: 0;
    rotate: -90deg;
  }

  &.swiper-button-disabled {
    opacity: 0.2;
  }
`;
