import { useEffect, useState } from 'react';
import { TOP_BAR_HEIGHT } from 'src/components/store/Bar/BrandDetailTopBar.styles';
import { COMPONENT_IDS } from '../TemplateDetail/constants';
import * as S from './Navigation.styles';

type Props = {
  tabs: {
    id: string;
    label: string;
    ref: React.RefObject<HTMLDivElement>;
  }[];
};

const NavigationBar = ({ tabs }: Props) => {
  const [visibleSection, setVisibleSection] = useState<string>(
    COMPONENT_IDS.PRODUCT_DETAIL
  );

  useEffect(() => {
    const sections = tabs.map((tab) => tab.ref);
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      if (section.current) {
        observer.observe(section.current);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section.current) {
          observer.unobserve(section.current);
        }
      });
    };
  }, [tabs]);

  const handleScroll = (sectionRef: React.RefObject<HTMLDivElement>) => {
    if (!sectionRef.current) return;

    window.scrollTo({
      top:
        Number(sectionRef.current.offsetTop) - TOP_BAR_HEIGHT - S.NAVIGATION_BAR_HEIGHT,
      behavior: 'smooth',
    });
  };

  return (
    <S.Navigation>
      <S.Ul>
        {tabs.map((tab) => (
          <S.NavigationTab
            key={tab.id}
            $isSelected={visibleSection === tab.id}
            onClick={() => handleScroll(tab.ref)}
          >
            {tab.label}
          </S.NavigationTab>
        ))}
      </S.Ul>
    </S.Navigation>
  );
};

export default NavigationBar;
