import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { ReactNode } from 'react';
import { getGoogleUserInfo } from '../../../apis/auth';
import useGoogleLoginMutation from '../../../hooks/apis/auth/useGoogleLoginMutation';
import { GOOGLE_OAUTH_TOKEN } from '../../../utils/configs';

type Props = {
  children: ReactNode;
  redirectPath?: string;
};

const GoogleOAuthHandler = ({ children, redirectPath }: Props) => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_TOKEN}>
      <GoogleLoginButton redirectPath={redirectPath}>{children}</GoogleLoginButton>
    </GoogleOAuthProvider>
  );
};

const GoogleLoginButton = ({ children, redirectPath }: Props) => {
  const { mutate: googleLoginMutate } = useGoogleLoginMutation(redirectPath);
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const result = await getGoogleUserInfo(tokenResponse.access_token);
      googleLoginMutate({
        google_access_token: tokenResponse.access_token,
        googleId: result.data.sub,
        platform: 'web',
        name: result?.data?.name,
        email: result?.data?.email,
      });
    },
  });

  return <div onClick={() => login()}>{children}</div>;
};

export default GoogleOAuthHandler;
