import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from '../../../../../hooks/apis/auth/useCurrentUserQuery';
import useNavigation from '../../../../../hooks/useNavigation';
import {
  eventNames,
  goStorageSources,
  Mixpanel,
  mixpanelProps,
} from '../../../../../utils/mixpanel';
import * as S from './GoToStoreContainer.styles';

const GoToStoreContainer = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const { navigateToAppScreen } = useNavigation();

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const onGoToStore = () => {
    if (currentUser) {
      Mixpanel.track(
        eventNames.goToStorageClick,
        mixpanelProps(currentUser, {
          source: goStorageSources.search,
          platformType,
        })
      );
    }

    if (platform) {
      navigateToAppScreen({
        screen: 'StoreScreen',
        hideBottomTabs: false,
      });
    } else {
      window.location.href = '/store';
    }
  };

  return (
    <>
      <S.StoreViewTitle>{t('search.resultTab.storageView.title')}</S.StoreViewTitle>

      <S.StoreViewDescription>
        {t('search.resultTab.storageView.description')}
      </S.StoreViewDescription>

      <S.StoreViewButton onClick={onGoToStore}>
        {t('search.resultTab.storageView.button')}
      </S.StoreViewButton>
    </>
  );
};

export default GoToStoreContainer;
