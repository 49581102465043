import React from 'react';
import * as S from './StarRating.styles';

type Props = {
  rating: number;
  size: number;
};

const StarRating = ({ rating, size }: Props) => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => {
        const fullStar = index < Math.floor(rating);
        const halfStar = !fullStar && index < Math.ceil(rating);

        return (
          <React.Fragment key={index}>
            {fullStar && <S.Star size={size} $isFilled={true} />}
            {halfStar && <S.StarHalf size={size} />}
            {!fullStar && !halfStar && <S.Star size={size} $isFilled={false} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default StarRating;
