import { MAX_IMAGE_COUNT } from '../../_constants';
import * as S from './ImageUpload.styles';

type Props = {
  uploadedImageCount: number;
  handleImageChange: (images: File[]) => void;
  isAllImagesRemoved: boolean;
};

const ImageUpload = ({
  uploadedImageCount,
  handleImageChange,
  isAllImagesRemoved,
}: Props) => {
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      handleImageChange(files);
    }
    event.target.value = '';
  };

  return (
    <S.ImageUploadBox $isWarning={isAllImagesRemoved}>
      <S.Camera />
      <S.ImageCount>{`${uploadedImageCount}/${MAX_IMAGE_COUNT}`}</S.ImageCount>
      <S.ImageUploadInput
        type='file'
        accept='image/*'
        onChange={handleImageUpload}
        multiple
      />
    </S.ImageUploadBox>
  );
};

export default ImageUpload;
