import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const ImageSlider = styled.div`
  width: 100%;
`;

export const SliderContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  gap: 8px;

  padding: 0 24px;
  box-sizing: border-box;
`;

export const Slide = styled.img`
  width: 134px;
  height: 134px;
  object-fit: cover;
  border-radius: 16px;
  cursor: pointer;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 118px;
    height: 118px;
  }
`;
