import { useTranslation } from 'react-i18next';
import Textarea from 'src/components/common/Textarea';
import { MAX_REVIEW_LENGTH } from '../../_constants';

type Props = {
  reviewText: string;
  handleReviewTextChange: (value: string) => void;
  isInvalidText: boolean;
};

const TextareaSection = ({
  reviewText,
  handleReviewTextChange,
  isInvalidText,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Textarea
      value={reviewText}
      onChange={handleReviewTextChange}
      placeholder={t('store.submitReview.reviewHint')}
      characterLimit={MAX_REVIEW_LENGTH}
      autoHeight
      isError={isInvalidText}
    />
  );
};

export default TextareaSection;
