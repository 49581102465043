import { ProductTypes } from 'src/types/product';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: 27px;
  padding-bottom: 8px;
  background-color: ${({ theme }) => theme.color.gray_50};
`;

export const Image = styled.img<{ $productType: ProductTypes }>`
  width: 101px;
  height: 101px;

  border-radius: 20px;
  overflow: hidden;

  object-fit: ${({ $productType }) => ($productType === 'sticker' ? 'contain' : 'cover')};
`;

export const TextContainer = styled.div`
  padding: 0 93.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  ${({ theme }) => theme.font.H3_BOLD};
`;

export const Description = styled.div`
  max-width: 303px;
  color: ${({ theme }) => theme.color.gray_600};
  text-align: center;
  ${({ theme }) => theme.font.B2_MEDIUM};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 13px 24px 21px 24px;
  box-sizing: border-box;
`;

export const CancelButton = styled.button`
  flex: 1;
  ${({ theme }) => theme.font.BUTTON};
  color: ${({ theme }) => theme.color.gray_600};
  border: ${({ theme }) => `1px solid ${theme.color.gray_600}`};
  border-radius: 6px;
  padding: 20px 0;
`;

export const ConfirmButton = styled.button`
  flex: 1.5;
  ${({ theme }) => theme.font.BUTTON};
  background-color: ${({ theme }) => theme.color.gray_800};
  color: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `1px solid ${theme.color.gray_600}`};
  border-radius: 6px;
  padding: 20px 0;
`;
