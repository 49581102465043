import { calculateRemainingDays, getKRCurrentTime } from '../utils/dateTime';

export const EXHIBITION = {
  TARGET_AMOUNT: 1000000, // 목표 금액을 적어주세요.
  DONATION_RATIO: 2, // 기부 비율을 적어주세요.
  START_DAY: '2024-03-18 00:00', // 시작 날짜를 적어주세요.
  LAST_DAY: '2024-04-07 23:59', // 마지막 날짜를 적어주세요.
  POINTS_TO_WON: 10, // 1P당 가격을 적어주세요.

  PRODUCT_IDS: [
    '65dc214f975b3d1bfe359d3a', // 가족을 찾아줘요
    '65a66b0eabdc9e9f7ca0d166', // 감성 빈티지 파일 다이어리
    '6554f1b7ea43a2bd1a535590', // 난 응애 제제
  ],

  CLUBJEJE_ID: '64cd4f0ca3feea13871b0136', // 클럽제제 id
} as const;

const krCurrentTime = getKRCurrentTime();

// 남은 기획전 기간
export const remainingSaleDays = calculateRemainingDays(
  krCurrentTime,
  new Date(EXHIBITION.LAST_DAY)
);

// 기획전 여부
export const isExhibitionStarted =
  process.env.REACT_APP_ENV_MODE !== 'prod'
    ? true
    : krCurrentTime >= new Date(EXHIBITION.START_DAY) &&
      krCurrentTime <= new Date(EXHIBITION.LAST_DAY);

export const MIXPANEL_EVENT_PROP = {
  EXHIBITION_NAME: 'clubjejeDogDay',
  BRAND_NAME: 'clubjeje',
};
