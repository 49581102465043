import axios from 'axios';
import { ProductTypes } from '../types/product';
import { API_URL } from './configs';
import { getQueryString } from './getQueryString';

export type ExhibitionProduct = {
  _id: string;
  korName: string;
  price: number;
  sellingPrice: number;
  productType: ProductTypes;
  discountPercentage: number | null;

  brand: {
    name: string;
  };
  sticker?: {
    sticker: string; // sticker image url
  };
  template?: {
    thumbnailSignUrl: string; // template image url
  };

  isProductPurchased?: boolean;
};

export type ExhibitionData = {
  products: ExhibitionProduct[];
  salesCount: number;
};

export const getExhibitionData = async (
  userId?: string | null
): Promise<ExhibitionData> => {
  const queryString = getQueryString({ userId });
  const response = await axios.get(
    `${API_URL}/api/products/exhibition/all${queryString}`
  );

  return response.data.data;
};

export const getExhibitionPeriodStatus = async (): Promise<boolean> => {
  const response = await axios.get(`${API_URL}/api/exhibition/validate`);

  return response.data.data.isExhibitionRunning;
};
