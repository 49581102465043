import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const SubmitButton = styled.div`
  width: 100%;
  margin: 24px 0;

  ${responsivePadding};

  position: relative;
  bottom: 0;
`;
