import { useTranslation } from 'react-i18next';
import { ReactComponent as SpeechBubble } from 'src/assets/images/store/SpeechBubble.svg';
import * as S from './NoReview.styles';

const NoReview = () => {
  const { t } = useTranslation();

  return (
    <>
      <SpeechBubble />
      <S.NoReviewMessage>
        {t('store.productDetailPage.reviewSection.noReview.noReviewMessage')}
      </S.NoReviewMessage>
      <S.ReviewPrompt>
        {t('store.productDetailPage.reviewSection.noReview.reviewPrompt')}
      </S.ReviewPrompt>
    </>
  );
};

export default NoReview;
