import { calculateDaysDifference } from '../utils/dateTime';

const calSellingPrice = (isDiscount, price, percentage) => {
  return price > 0
    ? isDiscount
      ? price - parseFloat(((price * percentage) / 100).toFixed(2))
      : price
    : 0;
};

export const calculatePricingDetail = (product) => {
  let {
    freeProduct,
    discountPercentage,
    sellingPrice,
    price,
    discountFromDate,
    discountToDate,
  } = product;

  price = price || 0;
  let salePrice = 0;
  let isDiscount = false;
  let remainingSaleDays = '';

  if (freeProduct === true || price === 0)
    return { salePrice, isDiscount, remainingSaleDays };

  // discount
  const today = new Date();
  isDiscount = !!(
    discountPercentage &&
    discountPercentage > 0 &&
    (!discountFromDate ||
      !discountToDate ||
      (today >= new Date(discountFromDate) && today <= new Date(discountToDate)))
  );

  // remaining days
  if (isDiscount && discountFromDate && discountToDate)
    remainingSaleDays = calculateDaysDifference(today, new Date(discountToDate));

  if (sellingPrice == null)
    sellingPrice = calSellingPrice(isDiscount, price, discountPercentage);

  // price in Points
  salePrice = parseFloat(isDiscount ? sellingPrice : price);
  if (salePrice > 0) salePrice = Math.floor(parseFloat((salePrice / 10).toFixed(2)));

  return {
    salePrice,
    isDiscount,
    remainingSaleDays,
  };
};

export const productLabel = (product, i18n) => {
  const productName = product
    ? i18n.language === 'en'
      ? product?.engName ?? ''
      : product?.korName ?? ''
    : '';

  let brand = product?.brandId
    ? i18n.language === 'en'
      ? product?.brandId?.nameEN ?? ''
      : product?.brandId?.name ?? ''
    : '';
  brand = brand ? `[${brand}] ` : '';

  return `${brand}${productName}`;
};
