import styled from 'styled-components';
import { Button } from '@mui/material';

export const ResentSearchLayout = styled.div`
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const ResentSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin: 20px 20px 0px 20px;
  }
`;

export const ResentSearchTitle = styled.p`
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  color: ${({ theme }) => theme.color.gray_900};
`;

export const ResetAllBtn = styled(Button)``;

export const ResetAllBtnText = styled.p`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_REGULAR}
  text-transform: none;

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.B3_REGULAR}
  }
`;
