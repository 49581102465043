import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  border-top: 1px solid ${colors.$gray200};
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
