import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ProblemType } from 'src/apis/types/products.types';
import MixpanelScreenLogger from 'src/components/Logger/MixpanelScreenLogger';
import { useReportProductReviewMutation } from 'src/mutations/productMutations';
import { ProductTypes } from 'src/types/product';
import { showToast } from 'src/utils/_common/showToast';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import { validateReportText } from './_validate';
import Content from './Content';
import * as S from './index.styles';
import SubmitButton from './SubmitButton';
import TopBar from './TopBar';

type Props = {
  reviewIdToReport: string;
  productName: string;
  productType: ProductTypes;
  brandName: string;
  authorId: string;
  authorNickname: string;
  onBackButtonClick: () => void;
};

const ReviewReportModal = ({
  reviewIdToReport,
  productName,
  productType,
  brandName,
  authorId,
  authorNickname,
  onBackButtonClick,
}: Props) => {
  const platformType = getPlatformType();
  const { t } = useTranslation();

  const [reportText, setReportText] = useState('');
  const [problemType, setProblemType] = useState<ProblemType | null>(null);

  const handleReportTextChange = (text: string) => {
    setReportText(text);
  };

  const handleProblemTypeChange = (type: ProblemType) => {
    setProblemType(type);
  };

  const { mutate: reportProductReview } = useReportProductReviewMutation({
    productName,
    productType,
    brandName,
    authorId,
    authorNickname,
    platformType,
  });

  const onSubmit = () => {
    if (!problemType) {
      return;
    }

    const variables = {
      reviewId: reviewIdToReport,
      problemType,
      reportText,
    };
    const options = {
      onSuccess: () => {
        showToast({
          toastMessage: t('store.reviewReport.submitSuccess'),
          toastType: 'info',
        });
        onBackButtonClick();
      },
    };

    reportProductReview(variables, options);
  };

  const buttonDisabled =
    !problemType || (problemType === 'other' && !validateReportText(reportText));

  return createPortal(
    <MixpanelScreenLogger
      params={{
        eventName: eventNames.reportReviewScreen,
        options: {
          productName,
          productType,
          brandName,
          authorId,
          authorNickname,
          platformType,
        },
      }}
    >
      <S.Container>
        <TopBar onBackButtonClick={onBackButtonClick} />
        <Content
          reportText={reportText}
          handleReportTextChange={handleReportTextChange}
          problemType={problemType}
          handleProblemTypeChange={handleProblemTypeChange}
        />
        <SubmitButton onClick={onSubmit} disabled={buttonDisabled} />
      </S.Container>
    </MixpanelScreenLogger>,
    document.body
  );
};

export default ReviewReportModal;
