import { ReactNode, useEffect, useState } from 'react';
import useUserAgent from '../../hooks/_common/useUserAgent';

type InAppBrowserBlockerProps = {
  children: ReactNode;
};

type AppOrRedirectToStoreProps = {
  targetUrl: string;
  redirectUrl: string;
  lang: string;
};

const openAppOrRedirectToStore = ({
  targetUrl,
  redirectUrl,
  lang,
}: AppOrRedirectToStoreProps) => {
  const path = new URL(targetUrl);
  const location = `hapl://app${path.pathname}?lang=${lang || 'kor'}`;
  window.location.replace(location);

  setTimeout(function () {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, 1000);

  // setTimeout(() => {
  //   window.close();
  // }, 3000)
};

const InAppBrowserBlocker = ({ children }: InAppBrowserBlockerProps) => {
  const userAgent = useUserAgent();

  const [childrenToShow, setChildren] = useState(children);

  useEffect(() => {
    const handleExternalBrowserRedirect = () => {
      const targetUrl = window.location.href;

      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirectTo') ?? '';

      if (userAgent.match(/kakaotalk/i)) {
        const encodedUrl = encodeURIComponent(targetUrl);
        if (userAgent.match(/iphone|ipad|ipod/i)) {
          window.location.href = 'kakaoweb://closeBrowser';
          setTimeout(() => {
            window.location.href = `kakaotalk://web/openExternal?url=${encodedUrl}`;
          }, 500);
        } else if (userAgent.match(/android/i)) {
          window.location.href = `kakaotalk://web/openExternal?url=${encodedUrl}`;
          setTimeout(() => {
            window.location.href = 'kakaotalk://inappbrowser/close';
          }, 500);
        }
      } else if (userAgent.match(/instagram/i) || userAgent.match(/facebook/i)) {
        openAppOrRedirectToStore({
          targetUrl,
          redirectUrl,
          lang: urlParams.get('lang') || 'kor',
        });
        // setTimeout(() => {
        //   window?.close();
        // }, 2000);
      } else if (userAgent.match(/line/i)) {
        const newUrl = targetUrl.includes('?')
          ? `${targetUrl}&openExternalBrowser=1`
          : `${targetUrl}?openExternalBrowser=1`;
        window.location.href = newUrl;
      } else if (redirectUrl && targetUrl?.indexOf('/store') !== -1) {
        openAppOrRedirectToStore({
          targetUrl,
          redirectUrl,
          lang: urlParams.get('lang') || 'kor',
        });
      }

      if (redirectUrl) {
        setChildren(<h1>Site is restricted due to IP Protection!</h1>);
      }
    };

    const handleContextMenu = (event: any) => event.preventDefault();

    document.addEventListener('contextmenu', handleContextMenu);
    handleExternalBrowserRedirect();

    return () => document.removeEventListener('contextmenu', handleContextMenu);
  }, [userAgent]);

  return <>{childrenToShow}</>;
};

export default InAppBrowserBlocker;
