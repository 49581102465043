import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/userContext';
import { eventNames } from '../../../utils/mixpanel';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import * as S from './CategoryBar.styles';
const CategoryBar = ({ setSelected, items, selected, pageName }) => {
  const { i18n } = useTranslation();
  const { currentUser } = useContext(UserContext);

  const eventNameObj = {
    sticker: eventNames.stickerCategoryClick,
    template: eventNames.templateCategoryClick,
    brand: eventNames.brandCategoryClick,
  };
  const eventName = eventNameObj[pageName];

  const handleClick = (idx, item) => {
    if (currentUser) {
      trackEvent(eventName, currentUser, {
        categoryName: item?.engName || item?.korName || '',
      });
    }

    setSelected(idx);
  };

  const itemsContent = items?.map((item, idx) => (
    <S.TemplateCategory
      $selected={idx === selected}
      onClick={() => handleClick(idx, item)}
      key={idx}
    >
      <S.TemplateCategoryText $selected={idx === selected}>
        {i18n.language === 'en' ? item?.engName : item?.korName}
      </S.TemplateCategoryText>
    </S.TemplateCategory>
  ));

  return <S.TemplateCategoryBar>{itemsContent}</S.TemplateCategoryBar>;
};

export default CategoryBar;
