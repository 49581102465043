import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const ViewMoreContainer = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  padding: 0 24px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const ViewMoreButton = styled.button`
  width: 100%;
  padding: 19px 0;
  border-radius: 6px;
  border: 1px solid ${colors.$gray300};
  ${({ theme }) => theme.font.BUTTON};
  color: ${colors.$gray700};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
