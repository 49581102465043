import { Switch } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { eventNames } from '../../../utils/mixpanel';
import * as S from './SearchResultHeader.styles';

type Props = {
  total: number;
  showStorageOnly?: boolean;
  setShowStorageOnly?: Dispatch<SetStateAction<boolean>>;
  tab?: string;
};

const SearchResultHeader = ({
  total,
  showStorageOnly,
  setShowStorageOnly,
  tab,
}: Props) => {
  const { data: currentUser } = useCurrentUserQuery();
  const { t } = useTranslation();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const onChange = () => {
    const newValue = !showStorageOnly;
    setShowStorageOnly?.(newValue);
    if (currentUser)
      trackEvent(
        newValue ? eventNames.toggleMyStorageOnly_on : eventNames.toggleMyStorageOnly_off,
        currentUser,
        {
          platformType,
        }
      );
  };

  return (
    <S.StickerResultTopBar>
      <S.SearchResultText>
        {t('search.resultTab.searchResult')} {total}
      </S.SearchResultText>

      {currentUser && tab !== 'brand' && (
        <S.ShowStorageContainer>
          <S.ShowStorageText>{t('search.resultTab.showStorage')}</S.ShowStorageText>
          <Switch checked={showStorageOnly} onChange={onChange} />
        </S.ShowStorageContainer>
      )}
    </S.StickerResultTopBar>
  );
};

export default SearchResultHeader;
