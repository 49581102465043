import CircularProgress from '@mui/material/CircularProgress';
import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../constants/_common/breakPoints';

export const Overlay = styled.div`
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease-in-out;

  position: fixed;
  top: 0;
  z-index: 1;

  &:active {
    opacity: 0.6;
    pointer-events: auto;
  }
`;

export const WarningCheckbox = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  gap: 2px;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
`;

export const WarningCheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const WarningCheckboxIcon = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const WarningCheckboxText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
  text-align: left;

  @media screen and (max-width: 350px) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;

export const WarningCheckboxCaution = styled.span`
  display: flex;
  padding: 0 32px;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.warning_500};
  ${({ theme }) => theme.font.B2_REGULAR}
`;

export const Ul = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
  white-space: wrap;
  list-style-type: disc;
  color: ${({ theme }) => theme.color.gray_400};
  margin-left: 16px;
`;

export const WarningTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const WarningTextList = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR}
  white-space: wrap;
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_MEDIUM}
  }
`;

export const WarningTextHighlight = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_BOLD}
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.color.primary_400};
`;
