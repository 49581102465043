export const exhibitionImages = {
  progressDog: require('./progress-bar-dog.png'),

  products: {
    tablet: {
      product: {
        1: require('./products/tablet/products/product1.png'),
        2: require('./products/tablet/products/product2.png'),
        3: require('./products/tablet/products/product3.png'),
      },
      price: {
        1: require('./products/tablet/price/price1.png'),
        2: require('./products/tablet/price/price2.png'),
        3: require('./products/tablet/price/price3.png'),
      },
      buttons: {
        detail: require('./products/tablet/buttons/detail.png'),
        purchased: require('./products/tablet/buttons/purchased.png'),
        purchase: {
          1: require('./products/tablet/buttons/purchase1.png'),
          2: require('./products/tablet/buttons/purchase2.png'),
          3: require('./products/tablet/buttons/purchase3.png'),
        },
      },
    },

    mobile: {
      product: {
        1: require('./products/mobile/products/product1.png'),
        2: require('./products/mobile/products/product2.png'),
        3: require('./products/mobile/products/product3.png'),
      },
      buttons: {
        detail: require('./products/mobile/buttons/detail.png'),
        purchased: require('./products/mobile/buttons/purchased.png'),
        purchase: {
          1: require('./products/mobile/buttons/purchase1.png'),
          2: require('./products/mobile/buttons/purchase2.png'),
          3: require('./products/mobile/buttons/purchase3.png'),
        },
      },
    },
  },

  tablet: {
    10: require('./tablet/10.png'),
    20: require('./tablet/20.png'),
    30: require('./tablet/30.png'),
    40: require('./tablet/40.png'),
    50: require('./tablet/50.png'),
    60: require('./tablet/60.png'),
    70: require('./tablet/70.png'),
    80: require('./tablet/80.png'),
    90: require('./tablet/90.png'),
    100: require('./tablet/100.png'),
    110: require('./tablet/110.png'),
    120: require('./tablet/120.png'),
    130: require('./tablet/130.png'),
    140: require('./tablet/140.png'),
    150: require('./tablet/150.png'),
  },
  tabletImageSize: {
    10: { width: 2048, height: 1290 },
    20: { width: 3072, height: 2400 },
    30: { width: 3072, height: 1458 },
    40: { width: 1024, height: 850 },
    50: { width: 3072, height: 6600 },
    60: { width: 3072, height: 7008 },
    70: { width: 3072, height: 2613 },
    80: { width: 3072, height: 1926 },
    90: { width: 2048, height: 988 },
    100: { width: 2048, height: 1722 },
    110: { width: 2048, height: 988 },
    120: { width: 2048, height: 988 },
    130: { width: 2048, height: 1704 },
    140: { width: 3072, height: 2349 },
    150: { width: 3072, height: 1863 },
  },
  mobile: {
    10: require('./mobile/10.png'),
    20: require('./mobile/20.png'),
    30: require('./mobile/30.png'),
    40: require('./mobile/40.png'),
    50: require('./mobile/50.png'),
    60: require('./mobile/60.png'),
    70: require('./mobile/70.png'),
    80: require('./mobile/80.png'),
    90: require('./mobile/90.png'),
    100: require('./mobile/100.png'),
    110: require('./mobile/110.png'),
    120: require('./mobile/120.png'),
    130: require('./mobile/130.png'),
    140: require('./mobile/140.png'),
    150: require('./mobile/150.png'),
  },
  mobileImageSize: {
    10: { width: 750, height: 1362 },
    20: { width: 1125, height: 1749 },
    30: { width: 1125, height: 993 },
    40: { width: 750, height: 708 },
    50: { width: 1125, height: 3756 },
    60: { width: 1125, height: 3801 },
    70: { width: 1125, height: 1020 },
    80: { width: 1125, height: 1323 },
    90: { width: 1125, height: 810 },
    100: { width: 1125, height: 1359 },
    110: { width: 750, height: 540 },
    120: { width: 750, height: 540 },
    130: { width: 750, height: 848 },
    140: { width: 1125, height: 1275 },
    150: { width: 1125, height: 1062 },
  },
};
