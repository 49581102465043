import React, { useState } from 'react';
import { ExhibitionProduct } from '../../../../utils/exhibitionRequest';
import Categories from './Categories';
import * as S from './ExhibitionProducts.styles';
import { Header } from './Header';
import Products from './Products';
import { storeProductDetailSources } from '../../../../utils/mixpanel';

type Props = {
  products: ExhibitionProduct[];
};

export type CategoryOptions = 'all' | 'sticker' | 'template';

const ExhibitionProducts = ({ products }: Props) => {
  const [currentCategory, setCurrentCategory] = useState<CategoryOptions>('all');

  const onCategoryClick = (category: CategoryOptions) => {
    setCurrentCategory(category);
  };

  const filteredProducts = products.filter((product) => {
    if (currentCategory === 'all') return true;
    return product.productType === currentCategory;
  });

  return (
    <S.Container>
      <Header />
      <Categories
        products={products}
        currentCategory={currentCategory}
        onCategoryClick={onCategoryClick}
      />
      <Products
        products={filteredProducts}
        source={storeProductDetailSources.exhibition}
      />
    </S.Container>
  );
};

export default ExhibitionProducts;
