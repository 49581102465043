import styled from 'styled-components';
import LazyImage from '../../../../common/LazyImage/LazyImage';

export const StickerThumbnail = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.gray_100};
`;

export const ThumbnailResize = styled(LazyImage)`
  width: 90%;
  height: 90%;
  padding: 5px;
  object-fit: contain;
  position: absolute;
`;

export const StickerTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
