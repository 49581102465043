import { ProductTypes } from '../types/product';
import { BASE_URL } from './configs';

export const generateProductLink = ({
  productId,
  productType,
  isApp,
}: {
  productId: string;
  productType: ProductTypes | 'brand';
  isApp?: boolean;
}) => {
  const redirect = isApp
    ? ''
    : '&redirectTo=https://apps.apple.com/us/app/hapl-digital-goods-platform/id1588993589';

  return `${BASE_URL}/store/${productType}/detail/${productId}?lang=kor${redirect}`;
};
