import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoContentBox from 'src/components/common/NoContentBox/NoContentBox';
import {
  NoContentMainText,
  NoContentSubText,
} from 'src/components/common/NoContentBox/NoContentBox.styles';
import CategoryBar from 'src/components/store/Bar/CategoryBar';
import ProductCard from 'src/components/store/Card/ProductCard';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useFetchAppConfigs from 'src/hooks/store/useFetchAppConfigs';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useResolveData from 'src/hooks/store/useResolveData';
import { eventNames, storeProductDetailSources } from 'src/utils/mixpanel';
import * as G from '../Store.styles';
import * as S from './index.styles';

const pageName = 'sticker';
const SORT_BY_ENUM = {
  resent: 'resent',
  popular: 'popular',
};

const StickerPage = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { appConfigs } = useFetchAppConfigs('product-category');
  const { filterData, sortBy, setSortBy } = useResolveData(
    true,
    data,
    selectedCategory,
    appConfigs,
    'product-category'
  );
  const { data: currentUser } = useCurrentUserQuery();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  useEffect(() => {
    trackEvent(eventNames.stickerTab, currentUser, { platformType, isUser });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <G.FullView>
      {/* <Advertisement arrowBadge={store_images.arrowBadge} banners={data?.banners || []} /> */}

      <G.Part>
        <div>
          <S.StickerPartBar>
            <S.StickerPartTitle>{t('store.common.popularSticker')}</S.StickerPartTitle>
          </S.StickerPartBar>

          <S.StickerViewContainer>
            <S.StickerViewBoard>
              <ProductCard
                items={
                  data?.popularStickers?.length > 0
                    ? data?.popularStickers?.slice(0, 6)
                    : []
                }
                pageInfo={pageName}
                source={storeProductDetailSources.stickerPopular}
              />
            </S.StickerViewBoard>

            <S.StickerViewBoard>
              <ProductCard
                items={
                  data?.popularStickers?.length > 0
                    ? data?.popularStickers?.slice(6, 12)
                    : []
                }
                pageInfo={pageName}
                source={storeProductDetailSources.stickerPopular}
              />
            </S.StickerViewBoard>
          </S.StickerViewContainer>
        </div>

        <div>
          <CategoryBar
            items={appConfigs['product-category'] || []}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            pageName={pageName}
          />

          <S.StickerAlignmentContainer>
            <S.StickerAlignment
              selected={sortBy === SORT_BY_ENUM.resent}
              onClick={() => setSortBy(SORT_BY_ENUM.resent)}
            >
              {t('store.common.mostRecent')}
            </S.StickerAlignment>

            <S.StickerAlignment
              selected={sortBy === SORT_BY_ENUM.popular}
              onClick={() => setSortBy(SORT_BY_ENUM.popular)}
            >
              {t('store.common.mostPopular')}
            </S.StickerAlignment>
          </S.StickerAlignmentContainer>

          {filterData.length > 0 && (
            <G.TodayNewProductBoard>
              <ProductCard
                items={filterData}
                pageInfo={pageName}
                source={storeProductDetailSources.stickerCategory}
              />
            </G.TodayNewProductBoard>
          )}

          {!filterData.length && (
            <NoContentBox height={288} paddingTop={60} paddingBottom={174}>
              <NoContentMainText>
                {t('store.noItem.sticker.noItemInCategory')}
              </NoContentMainText>
              <NoContentSubText>{t('store.noItem.sticker.comment')}</NoContentSubText>
            </NoContentBox>
          )}
        </div>
      </G.Part>
    </G.FullView>
  );
};

export default StickerPage;
