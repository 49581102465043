declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

type hideBottomProps = {
  hide?: Boolean;
};

type NavigateFunctions = {
  hideBottomTabs: (props: hideBottomProps) => void;
  postMessageToRN: (type: string, data: any) => void;
}

const useReactNativeEvents = (): NavigateFunctions => {
  const hideBottomTabs = (hide: hideBottomProps) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        data: {
          hideBottomTabs: hide || false,
        },
      })
    );
  };

  const postMessageToRN = (type: string, data: any) => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type,
        data
      })
    );
  }

  return {
    hideBottomTabs,
    postMessageToRN
  };
};

export default useReactNativeEvents;
