import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  margin-bottom: 24px;
`;

export const TitleText = styled.p`
  ${({ theme }) => theme.font.H2_BOLD}
  color: ${({ theme }) => theme.color.black};
`;
