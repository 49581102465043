import LazyImage from '../../common/LazyImage/LazyImage';
import styled from 'styled-components';

export const RecommendItemContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  position: relative;
  width: 265px;
`;

export const RecommendImageResize = styled(LazyImage)`
  width: 265px;
  height: 167px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colorgray_100};
  margin-bottom: 16px;
  cursor: pointer;
`;

export const RecommendImageResizeContainer = styled.div`
  width: 265px;
  height: 167px;
  border-radius: 20px;
  background: ${({ theme }) => theme.color.gray_100};
  margin-bottom: 16px;
  cursor: pointer;
`;

export const RecommendTextContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 24px;
  width: 225px;
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  cursor: pointer;
`;

export const RecommendTextBrand = styled.span`
  color: ${({ theme }) => theme.color.gray_500};
  ${({ theme }) => theme.font.B2_REGULAR}
  white-space: pre-wrap;
`;

export const RecommendTextDetail = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_SEMIBOLD}
  white-space: pre-wrap;
`;
