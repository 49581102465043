import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled, { css } from 'styled-components';

export const Separator = styled.div<{ $separatorHeight: number }>`
  margin-top: ${({ $separatorHeight }) => `${$separatorHeight}px`};
`;

export const ButtonContainer = styled.div`
  padding: 24px;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SignUpTitle = styled.p`
  ${({ theme }) => theme.font.H1_BOLD};
  color: ${({ theme }) => theme.color.black};
  text-align: left;
  white-space: pre-wrap;
`;

export const Content = styled.div`
  position: relative;
  overflow-y: auto;
  margin: auto;
`;
export const responsivePadding = css`
  padding: 0 24px;
  box-sizing: border-box;

  @media (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;
