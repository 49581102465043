import { PlatformType } from 'src/types/product';

export const getPlatform = () => {
  return (
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform')
  );
};

export const getPlatformType = (): PlatformType => {
  const platform = getPlatform();

  return platform === 'ios' || platform === 'android' ? 'app' : 'web';
};
