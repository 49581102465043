import { REVIEW_SUBMIT_CONTENT_MAX_WIDTH } from 'src/constants/_common/layout';
import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${REVIEW_SUBMIT_CONTENT_MAX_WIDTH}px;
  margin-bottom: 24px;

  display: flex;
  gap: 12px;

  ${responsivePadding};
`;

export const ProductImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 16px;
  flex-shrink: 0;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 6px;
`;

export const ProductName = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
`;

export const ProductPrice = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H2_BOLD};
`;

export const ProductPurchaseDate = styled.div`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
`;
