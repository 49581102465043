import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import useNavigation from 'src/hooks/useNavigation';
import { ProductTypes } from 'src/types/product';
import { formateDateTime } from 'src/utils/dateTime';
import { eventNames, storeProductDetailSources } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';

import * as S from './PurchaseItemCard.styles';

type Props = {
  productId: string;
  productType: ProductTypes;
  image: string;
  brandName: string;
  soldAt: string;
  title: string;
  purchasePrice: number;
};

const PurchaseItemCard = ({
  productId,
  productType,
  image,
  brandName,
  soldAt,
  title,
  purchasePrice,
}: Props) => {
  const { navigateToStickerDetails, navigateToTemplateDetails } = useNavigation();
  const navigateObject: Record<
    ProductTypes,
    (args: { itemId: string; state?: { source?: string } }) => void
  > = {
    sticker: navigateToStickerDetails,
    template: navigateToTemplateDetails,
  };
  const { data: currentUser } = useCurrentUserQuery();
  const isUser = currentUser ? true : false;

  const handleCardClick = () => {
    const platformType = getPlatformType();
    const isWeb = platformType === 'web';

    trackEvent(eventNames.storeProduct, currentUser, {
      productType: 'sticker',
      productId,
      productName: title,
      brandName: brandName || '',
      source: storeProductDetailSources.purchaseHistory,
      platformType,
      isUser,
    });

    if (isWeb) {
      navigateObject[productType]({
        itemId: productId,
        state: { source: storeProductDetailSources.purchaseHistory },
      });
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'navigateTo',
          data: {
            screen: 'StoreScreen',
            hideBottomTabs: false,
            params: {
              productId,
              productType: `${productType}/detail`,
              comingFrom: 'PurchaseHistory',
              source: storeProductDetailSources.purchaseHistory,
            },
          },
        })
      );
    }
  };

  return (
    <S.Container onClick={handleCardClick}>
      <S.ImageContainer>
        <S.Image src={image} alt={`${title} 상품 이미지`} $productType={productType} />
      </S.ImageContainer>
      <S.Info>
        <S.Date>{formateDateTime(soldAt, 'YY.MM.DD')}</S.Date>
        <S.Title>{title}</S.Title>
        <S.Point>{purchasePrice.toLocaleString()}P</S.Point>
      </S.Info>
    </S.Container>
  );
};

export default PurchaseItemCard;
