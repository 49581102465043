import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../../../assets/images/search/images';
import { UserContext } from '../../../../context/userContext';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import useReactNativeEvents from '../../../../hooks/useReactNativeEvents';
import TemplateCategoryModal from '../../../../shared/components/Modals/search/TemplateCategoryModal/TemplateCategoryModal';
import { ProductDataProps } from '../../../../types/search/commonPropsTypes';
import { eventNames } from '../../../../utils/mixpanel';
import NoResult from '../../NoResult/NoResult';
import SearchResultHeader from '../../SearchResultHeader/SearchResultHeader';
import * as G from '../ProductCommon.styles';
import TemplateData from './components/TemplateData';
import * as S from './components/TemplateData.styles';

const filterTypes = {
  category: 'category',
  hyperlink: 'hyperlink',
  dated: 'dated',
  startingDay: 'startingDay',
  direction: 'direction',
  color: 'color',
};

type Props = ProductDataProps & {
  appConfigs: any;
};

const TemplateMenu = ({ text, data, appConfigs }: Props) => {
  const { currentUser } = useContext(UserContext);
  const { t, i18n } = useTranslation();

  // const total = data?.total || 0;
  const allData = useMemo(() => (data?.data ? [...data?.data] : []), [data]);

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [showStorageOnly, setShowStorageOnly] = useState(false);
  const [filteredData, setFilteredData] = useState([...allData]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [hyperlink, setHyperlink] = useState('');
  const [dated, setDated] = useState<any>(null);
  const [startingDay, setStartingDay] = useState<any>(null);
  const [direction, setDirection] = useState<any>(null);
  const [color, setColor] = useState<any>([]);

  const { hideBottomTabs } = useReactNativeEvents();

  const filterByStorage = (list, isStorage) => {
    if (isStorage) return list.filter((item) => item?.isPurchased === true);
    return list;
  };

  const getFilteredList = (filters, isStorage) => {
    if (allData.length === 0) return [];
    let dataList = filterByStorage([...allData], isStorage);

    // filter by category
    if (filters?.selectedCategory?.length > 0) {
      const list: any = [];
      dataList.forEach((product) => {
        const status = product?.categories?.some((category) =>
          filters.selectedCategory.includes(category._id)
        );
        if (status) list.push(product);
      });
      dataList = [...list];
    }

    // filter by hyperlink
    if (filters?.hyperlink) {
      dataList = dataList.filter(
        (item) =>
          (filters.hyperlink === 'yes' && item.template?.hyperLink === true) ||
          (filters.hyperlink === 'no' && item.template?.hyperLink === false)
      );
    }

    // filter by dated
    if (filters?.dated) {
      dataList = dataList.filter((item) => item.template?.date === filters.dated._id);
    }

    // Filter by starting day
    if (filters?.startingDay) {
      dataList = dataList.filter(
        (item) => item.template?.startingDay === filters.startingDay._id
      );
    }

    // Filter by direction
    if (filters?.direction) {
      dataList = dataList.filter(
        (item) => item.template?.direction === filters.direction._id
      );
    }

    // Filter by color
    if (filters?.color?.length > 0) {
      const list: any = [];
      dataList.forEach((product) => {
        const status = product?.template?.colors?.some((item) =>
          filters.color.includes(item)
        );
        if (status) list.push(product);
      });
      dataList = [...list];
    }

    return dataList;
  };

  const onSubmit = (filters, filtered) => {
    setSelectedCategory([...filters.selectedCategory]);
    setHyperlink(filters.hyperlink);
    setDated(filters.dated);
    setStartingDay(filters.startingDay);
    setDirection(filters.direction);
    setColor([...filters.color]);

    setFilteredData(filtered);
    setOpen(false);
    hideBottomTabs({ hide: false });

    // if (currentUser) {
    const payLoad: any = {};
    if (filters.selectedCategory.length > 0) {
      const selectedIds = appConfigs.types.filter((item) =>
        filters.selectedCategory.includes(item._id)
      );
      const categoryName = selectedIds
        .map((s) => (i18n.language === 'en' ? s.engName : s.korName))
        .toString();
      payLoad.categoryName = categoryName;
    }

    if (filters?.hyperlink) payLoad.hyperlink = filters.hyperlink === 'yes';

    if (filters?.dated) {
      payLoad.dated =
        i18n.language === 'en' ? filters.dated.engName : filters.dated.korName;
    }

    if (filters?.startingDay) {
      payLoad.startingDay =
        i18n.language === 'en'
          ? filters.startingDay.engName
          : filters.startingDay.korName;
    }

    if (filters?.direction) {
      payLoad.direction =
        i18n.language === 'en' ? filters.direction.engName : filters.direction.korName;
    }

    if (filters?.color?.length > 0) {
      const selectedIds = appConfigs.color.filter((item) =>
        filters.color.includes(item._id)
      );
      const colorName = selectedIds.map((s) => s.color).toString();
      payLoad.color = colorName;
    }

    trackEvent(eventNames.searchTemplateFilter, currentUser, {
      payLoad,
      platformType,
      isUser,
    });
    // }
  };

  const onToggle = (value) => {
    setShowStorageOnly(value);
    setFilteredData(
      getFilteredList(
        { selectedCategory, hyperlink, dated, startingDay, direction, color },
        value
      )
    );
  };

  const resetFilter = () => {
    setSelectedCategory([]);
    setHyperlink('');
    setDated(null);
    setStartingDay(null);
    setDirection(null);
    setColor([]);
    setFilteredData(filterByStorage([...allData], showStorageOnly));
  };

  const handleOpen = () => {
    setOpen(true);
    hideBottomTabs({ hide: true });
  };

  useEffect(() => {
    setFilteredData(allData);
  }, [allData]);

  return (
    <G.AllContainer>
      <G.StickerResultTopBar>
        <S.CategorySelectionContainer>
          <S.CategorySelection>
            <S.TemplateCategoryContainer
              $selected={selectedCategory.length > 0}
              onClick={handleOpen}
            >
              <S.TemplateCategoryText $selected={selectedCategory.length > 0}>
                {t('search.stickerCategoryModal.title')}{' '}
                {selectedCategory.length > 0 ? selectedCategory.length : ''}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>

            <S.TemplateCategoryContainer
              onClick={handleOpen}
              $selected={hyperlink === 'yes' || hyperlink === 'no'}
            >
              <S.TemplateCategoryText
                $selected={hyperlink === 'yes' || hyperlink === 'no'}
              >
                {t('search.templateCategoryModal.hyperlink')}
                {hyperlink === 'yes' ? ' O' : hyperlink === 'no' ? ' X' : ''}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>

            <S.TemplateCategoryContainer $selected={dated} onClick={handleOpen}>
              <S.TemplateCategoryText $selected={dated}>
                {dated
                  ? i18n.language === 'en'
                    ? dated.engName
                    : dated.korName
                  : t('search.templateCategoryModal.datedTitle')}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>

            <S.TemplateCategoryContainer $selected={startingDay} onClick={handleOpen}>
              <S.TemplateCategoryText $selected={startingDay}>
                {startingDay
                  ? i18n.language === 'en'
                    ? startingDay.engName
                    : startingDay.korName
                  : t('search.templateCategoryModal.startingDay')}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>

            <S.TemplateCategoryContainer $selected={direction} onClick={handleOpen}>
              <S.TemplateCategoryText $selected={direction}>
                {direction
                  ? i18n.language === 'en'
                    ? direction.engName
                    : direction.korName
                  : t('search.templateCategoryModal.direction')}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>

            <S.TemplateCategoryContainer
              $selected={color.length > 0}
              onClick={handleOpen}
            >
              <S.TemplateCategoryText $selected={color.length > 0}>
                {t('search.templateCategoryModal.color')}{' '}
                {color.length > 0 ? color.length : ''}
              </S.TemplateCategoryText>
              <S.ArrowDownIcon src={images.arrowDown} alt='arrow_down' />
            </S.TemplateCategoryContainer>
          </S.CategorySelection>
        </S.CategorySelectionContainer>

        {(selectedCategory.length > 0 ||
          hyperlink ||
          dated ||
          startingDay ||
          direction ||
          color.length > 0) && (
          <S.ResetFilterBtn onClick={resetFilter}>
            <S.ResetFilterText>{t('search.stickerTab.resetFilter')}</S.ResetFilterText>
            <S.ResetIcon src={images.refresh} alt='refreshIcon' />
          </S.ResetFilterBtn>
        )}
      </G.StickerResultTopBar>

      <SearchResultHeader
        total={filteredData.length}
        showStorageOnly={showStorageOnly}
        setShowStorageOnly={onToggle}
      />

      {filteredData.length > 0 ? (
        <TemplateData text={text} data={filteredData} />
      ) : (
        <NoResult text={text} showStorageOnly={showStorageOnly} />
      )}

      {open && (
        <TemplateCategoryModal
          setOpen={setOpen}
          open={open}
          setScroll={setScroll}
          scroll={scroll}
          appConfigs={appConfigs}
          filters={{ selectedCategory, hyperlink, dated, startingDay, direction, color }}
          getFilteredList={(ids) => getFilteredList(ids, showStorageOnly)}
          filteredData={filteredData}
          onSubmit={onSubmit}
          filterTypes={filterTypes}
        />
      )}
    </G.AllContainer>
  );
};

export default TemplateMenu;
