import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const UnitBox = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.gray_50};
  margin-inline: auto;
  margin-bottom: 8px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    max-width: 95%;
  }
`;

export const UnitItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    height: 34px;
  }
`;

export const PurplePointContainer = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UnitPurpleIconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
  justify-content: space-between;
`;

export const UnitPurpleIconResize = styled.img`
  display: flex;
  aspect-ratio: 1;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
`;

export const UnitPurpleIconText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.PURPLE_POINT_ICON}
  position: absolute;
  left: 7px;
  top: 5px;
`;

export const UnitPurplePrice = styled.span`
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.H2_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.H3_BOLD}
  }
`;

export const UnitPurpleExtra = styled.div`
  background: ${({ theme }) => theme.color.warning_50};
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
`;

export const UnitPurpleExtraText = styled.span`
  color: ${({ theme }) => theme.color.warning_500};
  ${({ theme }) => theme.font.B2_SEMIBOLD}

  @media screen and (max-width: 430px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }
`;

export const PriceButton = styled.button`
  display: flex;
  min-width: 142px;
  min-height: 44px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 0px;
  background: ${({ theme }) => theme.color.button_purple};

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding-inline: 10px;
    min-width: 103px;
    min-height: 34px;
    border-radius: 4px;
  }
`;

export const PriceButtonText = styled.span`
  display: flex;
  width: auto;
  min-height: 16px;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.SUB2_MEDIUM}

  @media screen and (max-width: 430px) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;
