import styled from 'styled-components';

const NoResultTitle = styled.p`
  ${({ theme }) => theme.font.H2_SEMIBOLD}

  @media screen and (max-width:600px) {
    ${({ theme }) => theme.font.H3_SEMIBOLD}
  }
`;

export const NoResultTitleBlack = styled(NoResultTitle)`
  color: ${({ theme }) => theme.color.gray_700};
`;

export const NoResultTitlePurple = styled.span`
  color: ${({ theme }) => theme.color.primary_500};
`;

export const NoResultDesc = styled.p`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B1_REGULAR}
  margin-top: 8px;

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.B2_REGULAR}
  }
`;
