import axios from 'axios';
import { ProductCategory } from 'src/types/product';
import { API_URL } from 'src/utils/configs';
import { getAccessToken } from 'src/utils/request';
import { BaseResponse } from './types/common.types';
import { PurchasedProducts } from './types/user.types';

export const getPurchasedProducts = async (filter: ProductCategory) => {
  const url = `${API_URL}/api/products/purchased-products?filter=${filter}`;
  const options = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };

  return axios.get<BaseResponse<PurchasedProducts>>(url, options);
};
