import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

interface SecondaryProps {
  $secondary: boolean;
}

export const UnderBarContainer = styled.div`
  width: 100%;
  height: 100px;
  max-width: ${STORE_MAX_WIDTH}px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.1);

  display: flex;

  position: fixed;
  z-index: 999;
  bottom: 0;
`;

export const UnderBarButtonContainer = styled.div`
  width: 100%;
  padding: 20px 24px 24px 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    margin-inline: 12px;
  }
`;

export const UnderBarLikeButton = styled.div`
  width: 35%;
  padding: 19px 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.gray_300};
  background: ${({ theme }) => theme.color.white};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const UnderBarLikeResize = styled.img`
  width: 16px;
  height: 16px;
`;

export const UnderBarLikeText = styled.span`
  color: ${({ theme }) => theme.color.gray_800};
  ${({ theme }) => theme.font.BUTTON}
`;

export const UnderBarPurchaseButton = styled.button<SecondaryProps>`
  width: 65%;
  padding: 20px 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 0px;
  background: ${({ $secondary, theme }) =>
    $secondary ? theme.color.primary_50 : theme.color.primary_500};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:disabled {
    background: ${({ theme }) => theme.color.gray_400};
    cursor: not-allowed;
    span {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const UnderBarPurchaseText = styled.span<SecondaryProps>`
  color: ${({ $secondary, theme }) =>
    $secondary ? theme.color.primary_400 : theme.color.white};
  ${({ theme }) => theme.font.BUTTON}
`;
