import { useContext, useEffect } from 'react';
import { UserContext } from '../../context/userContext';
import { Mixpanel, eventNames, mixpanelProps } from '../../utils/mixpanel';
import { sendMixpanelEvent } from '../../utils/request';

export type EventName = (typeof eventNames)[keyof typeof eventNames];
type UserInfo = {
  name: string;
  email: string;
};

export const trackEvent = async (
  eventName: EventName,
  userContext: any,
  options?: any
) => {
  Mixpanel.track(eventName, mixpanelProps(userContext, options));

  const userInfo: UserInfo = {
    email: userContext?.email,
    name: userContext?.name ?? '',
  };
  await sendMixpanelEvent(eventName, userInfo, options);
};

export const useMixpanel = (
  eventName: EventName,
  options?: any,
  trackCondition?: any
) => {
  const { currentUser }: any = useContext(UserContext);
  useEffect(() => {
    if (currentUser) {
      trackEvent(eventName, currentUser, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, eventName, trackCondition]);
};
