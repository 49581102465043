import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: ${STORE_MAX_WIDTH}px;
  z-index: 999;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;
