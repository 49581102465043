import React from 'react';
import { exhibitionImages } from '../../../assets/images/store/exhibition/images';
import * as S from './ProgressBar.styles';

type Props = {
  progress: number;
  isSticky: boolean;
};

const MAX_PROGRESS = 100;

const ProgressBar = ({ progress, isSticky }: Props) => {
  const clampedProgress = Math.min(progress, MAX_PROGRESS);

  return (
    <S.ProgressBarContainer>
      <S.ProgressBar>
        {!isSticky && (
          <S.ProgressBarDog
            src={exhibitionImages.progressDog}
            alt='dog'
            $progress={clampedProgress}
          />
        )}
        <S.TotalSection>
          <S.DoneSection $progress={clampedProgress} />
        </S.TotalSection>
      </S.ProgressBar>
    </S.ProgressBarContainer>
  );
};

export default ProgressBar;
