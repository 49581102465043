import * as ChannelIO from '@channel.io/channel-web-sdk-loader';
import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import buttonImage from 'src/assets/images/channelTalk/buttonImage.png';
import styled from 'styled-components';
import { PLUGIN_KEY } from '../../constants/_common/channelTalk';
import { ChannelTalkContextProvider } from './ChannelTalkContext';

const ChannelTalkLayout = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isCustomChannelTalk =
    !localStorage.getItem('platform') ??
    !new URLSearchParams(window.location.search).get('platform');
  // web일 때 커스텀 채널톡을 사용
  // use custom channel talk when it is web

  useEffect(() => {
    const option = isCustomChannelTalk
      ? {
          pluginKey: PLUGIN_KEY,
          customLauncherSelector: '#channel-talk',
          hideChannelButtonOnBoot: true,
        }
      : {
          pluginKey: PLUGIN_KEY,
        };
    ChannelIO.boot(option);

    return () => {
      ChannelIO.shutdown();
    };
  }, [isCustomChannelTalk]);

  return (
    <ChannelTalkContextProvider buttonRef={buttonRef}>
      <Outlet />
      {isCustomChannelTalk && (
        <Button id='channel-talk' ref={buttonRef}>
          <ButtonImage src={buttonImage} alt='' />
        </Button>
      )}
    </ChannelTalkContextProvider>
  );
};

const Button = styled.button`
  @keyframes jBcSpD {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  border: none;
  background-color: transparent;
  position: fixed;
  bottom: 87px;
  right: 15px;
  z-index: 10000000 !important;
  cursor: pointer;
  animation: 400ms cubic-bezier(0.36, 0, 0, 1) 0s 1 normal both running jBcSpD;
  transition: scale 0.2s;

  &:active {
    scale: 0.9;
  }
`;

const ButtonImage = styled.img`
  width: 65px;
  height: 65px;
`;

export default ChannelTalkLayout;
