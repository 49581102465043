import LottieReact from 'lottie-react';
import styled from 'styled-components';

export const LottieContainer = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 1px;
  background-color: ${({ theme }) => theme.color.gray_50};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Lottie = styled(LottieReact)`
  width: 192px;
`;

export const Content = styled.div`
  padding: 24px;
  margin-top: -24px;
  background-color: ${({ theme }) => theme.color.white};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoldText = styled.div`
  ${({ theme }) => theme.font.H3_BOLD};
  margin-bottom: 12px;
`;

export const Text = styled.div`
  ${({ theme }) => theme.font.B2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_600};
  margin-bottom: 41px;
  white-space: pre-wrap;
`;

export const Button = styled.button`
  width: 100%;
  ${({ theme }) => theme.font.BUTTON};
  color: ${({ theme }) => theme.color.white};
  padding: 20px 0;
  background-color: ${({ theme }) => theme.color.primary_500};
  border-radius: 6px;
  border: none;
  cursor: pointer;
`;
