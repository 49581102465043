import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { ReactComponent as SignUpCompletedImage } from '../../../../../assets/images/auth/signUpCompleted.svg';
import useAppleLoginMutation from '../../../../../hooks/apis/auth/useAppleLoginMutation';
import useGoogleLoginMutation from '../../../../../hooks/apis/auth/useGoogleLoginMutation';
import useKakaoLoginMutation from '../../../../../hooks/apis/auth/useKakaoLoginMutation';
import * as G from '../../../../../styles/commonStyles';
import { SignUpContext } from '../../SignUpContext';
import NextButton from '../NextButton';
import * as S from './SignUpComplete.styles';

const SignUpCompleted = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const { nickName } = useContext(SignUpContext);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const { mutate: appleLogin, isPending: isAppleLoginPending } = useAppleLoginMutation(
    state.redirectPath
  );
  const { mutate: googleLogin, isPending: isGoogleLoginPending } = useGoogleLoginMutation(
    state.redirectPath
  );
  const { mutate: kakaoLogin, isPending: isKakaoLoginPending } = useKakaoLoginMutation(
    state.redirectPath
  );
  const login = {
    apple: appleLogin,
    google: googleLogin,
    kakao: kakaoLogin,
  };

  const onNextButtonClick = () => {
    login[state.oAuthType](state.variables);
  };

  useEffect(() => {
    trackEvent(eventNames.signupCompletePage, currentUser, {
      platformType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <G.Content>
        <S.WelcomeText>
          {t('store.signUpPage.welcomeText', { nickName: nickName })}
        </S.WelcomeText>
        <G.Separator $separatorHeight={40} />
        <SignUpCompletedImage />
        <G.Separator $separatorHeight={40} />
        <S.Title>{t('store.signUpPage.signUpCompleteTitle')}</S.Title>
        <G.Separator $separatorHeight={12} />
        <S.SubTitle>{t('store.signUpPage.signUpCompleteSubTitle')}</S.SubTitle>
      </G.Content>

      <G.ButtonContainer>
        <NextButton
          onClick={onNextButtonClick}
          buttonText={t('store.signUpPage.haplStart')}
          isLoading={isAppleLoginPending || isGoogleLoginPending || isKakaoLoginPending}
        />
      </G.ButtonContainer>
    </>
  );
};

export default SignUpCompleted;
