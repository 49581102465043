import React from 'react';
import { useNavigate } from 'react-router-dom';
import store_images from '../../../assets/images/store/images';
import * as S from './BackTextTopBar.styles.ts';

function PurpleTopBar(props) {
  const navigate = useNavigate();
  return (
    <S.TopBarContainer>
      <S.TopBar>
        <S.ArrowResize
          src={store_images.arrowLeft}
          alt='arrow'
          onClick={() => navigate(-1)} // props.beforePage
        />
        <S.BarText>{props.barName}</S.BarText>
      </S.TopBar>
    </S.TopBarContainer>
  );
}

export default PurpleTopBar;
