import DotsLoader from '../../../../shared/components/Loader/DotsLoader';
import * as S from './NextButton.styles';

type Props = {
  onClick: () => void;
  buttonText: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const NextButton = ({ isLoading, onClick, buttonText, disabled = false }: Props) => {
  return (
    <S.NextButton onClick={onClick} disabled={disabled}>
      <S.NextButtonText disabled={disabled}>
        {isLoading ? <DotsLoader /> : buttonText}
      </S.NextButtonText>
    </S.NextButton>
  );
};

export default NextButton;
