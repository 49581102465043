import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import styled from 'styled-components';

type Props = {
  value: string;
  handleTabChange: (event: SyntheticEvent, newValue: string) => void;
};

const SearchMenu = ({ value, handleTabChange }: Props) => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();

  return (
    <TabMenuWrapper
      value={value}
      onChange={handleTabChange}
      variant='scrollable'
      textColor='inherit'
      scrollButtons={false}
    >
      <TabMenu value='all' label={t('search.tab.all')} />
      {currentUser && (
        <TabMenu value='fragmentSticker' label={t('search.tab.fragmentSticker')} />
      )}
      <TabMenu value='sticker' label={t('search.tab.sticker')} />
      <TabMenu value='template' label={t('search.tab.template')} />
      <TabMenu value='brand' label={t('search.tab.brand')} />
    </TabMenuWrapper>
  );
};

const TabMenuWrapper = styled(Tabs)`
  && .MuiTabs-indicator {
    background: ${({ theme }) => theme.color.gray_800};
  }
`;

const TabMenu = styled(Tab)`
  && {
    ${({ theme }) => theme.font.H2_SEMIBOLD}
    text-transform: none;

    @media screen and (max-width: 600px) {
      ${({ theme }) => theme.font.H3_SEMIBOLD}
    }
  }
`;

export default SearchMenu;
