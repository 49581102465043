import styled from 'styled-components';

interface BackgroundColorProps {
  $backgroundColor: string;
}

export const ColorCircle = styled.div<BackgroundColorProps>`
  width: 18px;
  height: 18px;
  border-radius: 10px;
  margin-right: 8px;
  background-color: ${({ $backgroundColor }) => `${$backgroundColor}`};
`;
