import Category from '../../../../components/common/Category';
import { ExhibitionProduct } from '../../../../utils/exhibitionRequest';
import * as S from './Categories.styles';
import { CategoryOptions } from './ExhibitionProducts';

type Props = {
  products?: ExhibitionProduct[];
  currentCategory: CategoryOptions;
  onCategoryClick: (category: CategoryOptions) => void;
};

const Categories = ({ products, currentCategory, onCategoryClick }: Props) => {
  return (
    <S.Categories>
      <Category
        $isSelected={currentCategory === 'all'}
        $selectedColor='#424242'
        onClick={() => onCategoryClick('all')}
      >
        {`전체 ${products?.length ?? 0}`}
      </Category>
      <Category
        $isSelected={currentCategory === 'sticker'}
        $selectedColor='#424242'
        onClick={() => onCategoryClick('sticker')}
      >{`스티커 ${
        products?.reduce(
          (acc, cur) => (cur.productType === 'sticker' ? acc + 1 : acc),
          0
        ) ?? 0
      }`}</Category>
      <Category
        $isSelected={currentCategory === 'template'}
        $selectedColor='#424242'
        onClick={() => onCategoryClick('template')}
      >{`템플릿 ${
        products?.reduce(
          (acc, cur) => (cur.productType === 'template' ? acc + 1 : acc),
          0
        ) ?? 0
      }`}</Category>
    </S.Categories>
  );
};

export default Categories;
