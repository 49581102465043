import { useEffect } from 'react';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { EventName, trackEvent } from 'src/hooks/store/useMixpanel';

type Props = {
  children: React.ReactNode;
  params: {
    eventName: EventName;
    options?: any;
  };
};

const MixpanelScreenLogger = ({ children, params }: Props) => {
  const { data: currentUser } = useCurrentUserQuery();

  useEffect(() => {
    if (currentUser) {
      trackEvent(params.eventName, currentUser, params.options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return <>{children}</>;
};

export default MixpanelScreenLogger;
