import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 88px;
  max-width: ${STORE_MAX_WIDTH}px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
