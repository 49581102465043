import { BASE_URL } from '../../../utils/configs';

export const BREAK_POINTS = {
  MOBILE: 512,
};

export const TOP_BAR_HEIGHT = 49;
export const TOP_BAR_VISIBLE_OFFSET = 0;
export const TOP_BAR_HIDDEN_OFFSET = -TOP_BAR_HEIGHT;

export const OG_TITLE = '가족을 찾아줘요 (하플 x 클럽제제 x 동물자유연대)';
export const OG_URL = `${BASE_URL}/store/exhibition`;
export const OG_DESCRIPTION = '하플과 함께 유기견 기부에 동참해주세요.';
export const OG_IMAGE =
  'https://hapl-static.s3.ap-northeast-2.amazonaws.com/og-image-exhibition.png';
