import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

export const Container = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.black};
  align-self: flex-end;
`;

export const QRCodeImage = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 25px;
`;

export const BoldText = styled.div`
  ${({ theme }) => theme.font.H3_BOLD};
  margin-bottom: 12px;
`;

export const Text = styled.div`
  ${({ theme }) => theme.font.B2_MEDIUM};
  color: ${({ theme }) => theme.color.gray_600};
  margin-bottom: 41px;
  white-space: pre-wrap;
  text-align: center;
`;

export const Button = styled.button`
  width: 100%;
  ${({ theme }) => theme.font.BUTTON};
  color: ${({ theme }) => theme.color.white};
  padding: 20px 0;
  background-color: ${({ theme }) => theme.color.primary_500};
  border-radius: 6px;
  border: none;
  cursor: pointer;
`;
