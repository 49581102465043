import styled, { keyframes } from 'styled-components';

const DotsLoader = () => {
  return (
    <Loader>
      <Dot />
      <Dot />
      <Dot />
    </Loader>
  );
};

const loaderAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  60% {
    opacity: 0.5;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.white};
  animation: ${loaderAnimation} 750ms infinite linear;

  &:nth-child(1) {
    animation-delay: 500ms;
  }

  &:nth-child(3) {
    animation-delay: 250ms;
  }
`;

export default DotsLoader;
