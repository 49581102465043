import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const ProgressBarContainer = styled.div`
  width: 100%;
`;

export const ProgressBar = styled.div`
  width: 100%;
  text-align: start;
`;

export const TotalSection = styled.div`
  width: 100%;
  height: 12px;
  background-color: #e9e9e9;
  border-radius: 99px;
  overflow: hidden;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    height: 18px;
  }
`;

export const DoneSection = styled(TotalSection)<{ $progress: number }>`
  width: ${({ $progress }) => $progress}%;
  background-color: #8064fc;
  transition: width 1s;
`;

export const ProgressBarDog = styled.img<{ $progress: number }>`
  width: 24px;
  height: 33px;
  position: relative;
  top: 0;
  left: ${({ $progress }) => $progress}%;
  transform: translateX(-50%);
  transition: left 1s;
`;
