import All from '../SearchMenu/AllMenu/AllMenu';
import BrandMenu from '../SearchMenu/BrandMenu/BrandMenu';
import FragmentSticker from '../SearchMenu/FragmentStickerMenu/FragmentStickerMenu';
import Sticker from '../SearchMenu/StickerMenu/StickerMenu';
import TemplateMenu from '../SearchMenu/TemplateMenu/TemplateMenu';
import ContentLoader from '../_common/ContentLoader/ContentLoader';

const SearchResult = ({
  tab,
  searchedKeyword,
  allLoading,
  allData,
  fragmentStickerLoading,
  fragmentStickers,
  stickerLoading,
  stickers,
  templateLoading,
  templates,
  brandLoading,
  brands,
  appConfigs,
  setValue,
}) => {
  const counts = {
    totalFragments: fragmentStickers?.total ?? '',
    totalStickers: stickers?.total ?? '',
    totalTemplates: templates?.total ?? '',
    totalBrands: brands?.total ?? '',
  };

  const renderTabContent = {
    all: () =>
      allLoading ? (
        <ContentLoader />
      ) : (
        <All text={searchedKeyword} data={allData} counts={counts} setValue={setValue} />
      ),
    fragmentSticker: () =>
      fragmentStickerLoading ? (
        <ContentLoader />
      ) : (
        <FragmentSticker text={searchedKeyword} data={fragmentStickers} />
      ),
    sticker: () =>
      stickerLoading ? (
        <ContentLoader />
      ) : (
        <Sticker
          text={searchedKeyword}
          data={stickers}
          categories={appConfigs['product-category'] ?? []}
        />
      ),
    template: () =>
      templateLoading ? (
        <ContentLoader />
      ) : (
        <TemplateMenu text={searchedKeyword} data={templates} appConfigs={appConfigs} />
      ),
    brand: () =>
      brandLoading ? (
        <ContentLoader />
      ) : (
        <BrandMenu text={searchedKeyword} data={brands} />
      ),
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
      {renderTabContent[tab] ? renderTabContent[tab]() : <ContentLoader />}
    </div>
  );
};

export default SearchResult;
