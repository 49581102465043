export const setScrollPosition = (pageName) => {
  sessionStorage.setItem(
    `${pageName}-scrollPosition`,
    document.documentElement.scrollTop || document.body.scrollTop,
  );
};

export const handleScrollPosition = (pageName) => {
  const sessionName = `${pageName}-scrollPosition`;
  const scrollPosition = sessionStorage.getItem(sessionName);
  if (scrollPosition) {
    window.scrollTo(0, parseInt(scrollPosition));
    sessionStorage.removeItem(sessionName);
  }
};
