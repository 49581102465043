import styled from 'styled-components';

export const TextareaContainer = styled.label<{ disabled?: boolean; $isError?: boolean }>`
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, $isError }) =>
      $isError ? theme.color.warning_400 : theme.color.gray_200};
  background-color: ${({ theme, disabled }) => disabled && theme.color.gray_100};
  cursor: text;
  overflow: hidden;

  display: block;
  position: relative;
`;

export const Textarea = styled.textarea<{ $autoHeight?: boolean }>`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 36px;
  overflow-y: auto;
  color: ${({ theme }) => theme.color.gray_800};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  border: none;
  resize: none;

  display: block;
  position: ${({ $autoHeight }) => ($autoHeight ? 'absolute' : 'relative')};

  &::placeholder {
    color: ${({ theme }) => theme.color.gray_400};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const DummyTextarea = styled.div`
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 36px;
`;

export const CharacterCount = styled.div<{ $isError?: boolean }>`
  color: ${({ theme, $isError }) =>
    $isError ? theme.color.warning_400 : theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR};
  user-select: none;
  cursor: default;

  position: absolute;
  right: 12px;
  bottom: 12px;
`;
