import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackTextTopBar from '../../../components/store/Bar/BackTextTopBar';
import FavoriteTabBar from '../../../components/store/FavoritePage/FavoriteTabBar';
import FavoriteBrand from './Brand/FavoriteBrand';
import * as S from './FavoritePage.styles';
import FavoriteSticker from './Sticker/FavoriteSticker';
import FavoriteTemplate from './Template/FavoriteTemplate';

const FavoritePage = () => {
  const { t } = useTranslation();
  const [favoriteSelectedTab, setFavoriteSelectedTab] = useState(
    t('store.favoritePage.sticker')
  );

  return (
    <S.FavoritePageContainer>
      <S.FavoriteHeader>
        <BackTextTopBar
          barName={t('store.storeTopBar.favoritePage')}
          beforePage='/store'
        />

        <S.FavoriteTabMenu>
          <FavoriteTabBar
            favoriteSelectedTab={favoriteSelectedTab}
            setFavoriteSelectedTab={setFavoriteSelectedTab}
          />
        </S.FavoriteTabMenu>
      </S.FavoriteHeader>

      {favoriteSelectedTab === t('store.favoritePage.sticker') && <FavoriteSticker />}
      {favoriteSelectedTab === t('store.favoritePage.template') && <FavoriteTemplate />}
      {favoriteSelectedTab === t('store.favoritePage.brand') && <FavoriteBrand />}
    </S.FavoritePageContainer>
  );
};

export default FavoritePage;
