import React from 'react';
import * as S from './PurpleChargingModal.styles';
import { thousandsFormate } from '../../../utils/amount';
import { useTranslation } from 'react-i18next';
import store_images from '../../../assets/images/store/images';

const PointCardUnit = ({ item, index, selectedUnitIndex, setSelectedUnitIndex }) => {
  const { t } = useTranslation();
  const platform = localStorage.getItem('platform');

  const handleItemClick = (index) => {
    setSelectedUnitIndex(index);
  };

  return (
    <S.PointCardUnit
      onClick={() => handleItemClick(index)}
      $selected={selectedUnitIndex === index}
    >
      <S.PointCardContentsBar>
        <S.PointCardUnitLeft>
          <S.PurpleChargingPurpleIconContainer>
            <S.PurpleChargingPurpleIconResize
              src={store_images.purpleIcon}
              alt='purple icon'
            />
            <S.PurpleChargingPurpleIconText>P</S.PurpleChargingPurpleIconText>
          </S.PurpleChargingPurpleIconContainer>
          <S.PurpleNumText>{thousandsFormate(item.points)}</S.PurpleNumText>
          {item.bonusPoints !== 0 && (
            <S.BonusPurpleContainer>
              <S.BonusPurpleText>
                +{item.bonusPoints}
                {t('store.purpleChargingModal.BonusPercent')}
              </S.BonusPurpleText>
            </S.BonusPurpleContainer>
          )}
        </S.PointCardUnitLeft>
        <S.PointCardUnitRight>
          <S.PurplePriceText $selected={selectedUnitIndex === index}>
            {platform === 'ios' || platform === 'android'
              ? thousandsFormate(item.price)
              : thousandsFormate(item.priceInWeb)}
            {t('store.purpleChargingModal.krw')}
          </S.PurplePriceText>
        </S.PointCardUnitRight>
      </S.PointCardContentsBar>
    </S.PointCardUnit>
  );
};

export default PointCardUnit;
