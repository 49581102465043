import CommonTopBar from 'src/components/store/Bar/CommonTopBar';
import * as S from './TopBar.styles';

type Props = {
  onBackButtonClick: () => void;
};

const TopBar = ({ onBackButtonClick }: Props) => {
  return (
    <S.Container>
      <CommonTopBar onBackButtonClick={onBackButtonClick} />
    </S.Container>
  );
};

export default TopBar;
