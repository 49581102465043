import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import * as S from './GiftCard.styles.ts';

const GiftCard = (props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const giftItems = props.items.map((item, index) => (
    <S.TemplateGiftsContent key={item.id ?? item._id}>
      <S.TemplateGiftsImageContainer>
        <S.TemplateGiftsImageResize
          key={`img_${index}`}
          src={item?.signUrl}
          alt={i18n.language === 'en' ? item.engName : item.korName}
          onClick={() => {
            navigate(`${PATH.STORE}/sticker/detail/${item?._id}`);
          }}
        />
      </S.TemplateGiftsImageContainer>
      <S.TemplagteGiftsText>
        {i18n.language === 'en' ? item.engName : item.korName}
      </S.TemplagteGiftsText>
    </S.TemplateGiftsContent>
  ));

  return giftItems;
};
export default GiftCard;
