export const throttle = <T extends (...args: any[]) => any>(
  func: T,
  interval: number
): ((...args: Parameters<T>) => void) => {
  let lastExecTime = 0;
  let timer: ReturnType<typeof setTimeout> | null = null;
  let pendingArgs: Parameters<T> | null = null;

  const execute = () => {
    if (pendingArgs) {
      func(...pendingArgs);
      lastExecTime = Date.now();
      pendingArgs = null;
      timer = setTimeout(execute, interval);
    }
  };

  return (...args: Parameters<T>): void => {
    const now = Date.now();

    if (!lastExecTime || now - lastExecTime >= interval) {
      // If the time since last execution exceeds interval, execute immediately
      func(...args);
      lastExecTime = now;
    } else {
      // If within the interval, save the arguments and execute after interval
      pendingArgs = args;
      if (!timer) {
        timer = setTimeout(execute, interval);
      }
    }
  };
};
