import store_images from '../../../assets/images/store/images';
import { EXHIBITION } from '../../../constants/exhibition';
import * as S from './ProductCard.styles';

type Props = {
  imageSrc: string;
  brandName: string;
  name: string;
  sellingPrice: number;
  onClick?: () => void;
  onFavoriteIconClick?: () => void;
  isProductInWishlist?: boolean;
  remainingSaleDays?: number;
  discountPercentage?: number;
  isSticker?: boolean;
};

const ProductCard = ({
  imageSrc,
  brandName,
  name,
  sellingPrice,
  onClick,
  onFavoriteIconClick,
  isProductInWishlist,
  remainingSaleDays,
  discountPercentage,
  isSticker,
}: Props) => {
  return (
    <S.ProductCard onClick={onClick}>
      <S.ImageContainer>
        <S.ProductImage src={imageSrc} alt={name} $isSticker={!!isSticker} />
        {onFavoriteIconClick && (
          <S.FavoriteIcon
            src={
              isProductInWishlist
                ? store_images.favoriteFilled
                : store_images.favoriteUnfilled
            }
            alt='heart'
            onClick={(event) => {
              event.stopPropagation();
              onFavoriteIconClick();
            }}
          />
        )}
      </S.ImageContainer>

      <S.TextContainer>
        <S.BrandName>{brandName}</S.BrandName>
        <S.Name>{name}</S.Name>

        <S.PriceContainer>
          {discountPercentage && discountPercentage > 0 && (
            <S.DiscountPercentage>{discountPercentage}%</S.DiscountPercentage>
          )}
          <S.Price>{`${(
            sellingPrice / EXHIBITION.POINTS_TO_WON
          ).toLocaleString()}P`}</S.Price>
        </S.PriceContainer>
        {remainingSaleDays && remainingSaleDays >= 0 && remainingSaleDays < 30 && (
          <S.RemainingDcDate>{`기부 ${remainingSaleDays}일 남았어요!`}</S.RemainingDcDate>
        )}
      </S.TextContainer>
    </S.ProductCard>
  );
};

export default ProductCard;
