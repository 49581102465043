import { useTranslation } from 'react-i18next';
import useModalManager from 'src/hooks/modal/useModalManager';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames, goStoreSources } from 'src/utils/mixpanel';
import { ReactComponent as StorageIcon } from '../../assets/icons/Storage.svg';
import { ReactComponent as StoreIcon } from '../../assets/icons/Storefront.svg';
import { ReactComponent as MypageIcon } from '../../assets/icons/person.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { PATH } from '../../constants/_common/path';
import useCurrentUserQuery from '../../hooks/apis/auth/useCurrentUserQuery';
import * as S from './TabBar.styles';

const TabBar = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');
  const platform = localStorage.getItem('platform');

  const onSearchClick = () => {
    trackEvent(eventNames.searchPage, currentUser, {
      source: goStoreSources.store,
      platformType: platform ? 'app' : 'web',
      isUser: !!currentUser,
    });
  };

  return (
    <S.Nav>
      <S.Ul>
        <li>
          <S.Button onClick={showUseStorageInAppPopUp}>
            <StorageIcon />
            <span>{t('store.tabBar.storage')}</span>
          </S.Button>
        </li>
        <li>
          <S.TabNavLink to={PATH.SEARCH} onClick={onSearchClick}>
            <SearchIcon />
            <span>{t('store.tabBar.search')}</span>
          </S.TabNavLink>
        </li>
        <li>
          <S.TabNavLink to={PATH.STORE}>
            <StoreIcon />
            <span>{t('store.tabBar.store')}</span>
          </S.TabNavLink>
        </li>
        <li>
          <S.TabNavLink to={currentUser ? PATH.MYPAGE : PATH.LOGIN}>
            <MypageIcon />
            <span>{t('store.tabBar.mypage')}</span>
          </S.TabNavLink>
        </li>
      </S.Ul>
    </S.Nav>
  );
};

export default TabBar;
