import { ReactNode, useEffect } from 'react';
import KakaoLogin from 'react-kakao-login';
import useKakaoLoginMutation from '../../../hooks/apis/auth/useKakaoLoginMutation';
import { KAKAO_OAUTH_TOKEN } from '../../../utils/configs';

type Props = {
  render: ({ onClick }: { onClick: () => void }) => ReactNode;
  redirectPath?: string;
};

const KakaoOAuthHandler = ({ render, redirectPath }: Props) => {
  const { mutate: kakaoLoginMutate } = useKakaoLoginMutation(redirectPath);

  useEffect(() => {
    return () => {
      document.getElementById('kakao-sdk')?.remove();
    };
  }, []);

  return (
    <KakaoLogin
      token={KAKAO_OAUTH_TOKEN}
      onSuccess={({ response, profile }) => {
        kakaoLoginMutate({
          accessToken: response.access_token,
          name: profile?.properties.nickname ?? '',
          email: profile?.kakao_account.email ?? '',
          gender: profile?.kakao_account.gender ?? '',
          nickname: profile?.properties.nickname ?? '',
          profileImageUrl: profile?.properties.profile_image ?? '',
          kakaoId: profile?.id ?? 0,
        });
      }}
      onFail={console.error}
      onLogout={console.info}
      render={render}
    />
  );
};

export default KakaoOAuthHandler;
