import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  padding-bottom: 14px;
  background-color: rgba(255, 255, 255, 0.94);
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 16px 16px 0 0;
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;

  padding: 6px;
  ${({ theme }) => theme.font.PURPLE_POINT_ICON};
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  color: ${({ theme }) => theme.color.gray_400};

  svg {
    fill: ${({ theme }) => theme.color.gray_400};
  }
`;

export const TabNavLink = styled(NavLink)`
  padding: 6px;
  ${({ theme }) => theme.font.PURPLE_POINT_ICON};
  text-decoration: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  color: ${({ theme }) => theme.color.gray_400};
  svg {
    fill: ${({ theme }) => theme.color.gray_400};
  }

  &.active {
    color: ${({ theme }) => theme.color.gray_800};
    svg {
      fill: ${({ theme }) => theme.color.gray_800};
    }
  }
`;
