import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  width: 100%;
  max-width: 576px;
  height: 48px;
  background-color: ${({ theme }) => theme.color.gray_100};
  border-radius: 99px;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 95%;
    height: 40px;
  }
`;

export const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

export const SearchInput = styled.input<{ $hasValue: boolean }>`
  width: ${({ $hasValue }) => ($hasValue ? '87%' : '100%')};
  margin-left: 8px;
  margin-right: 8px;
  border: none;
  background: none;
  outline: none;
  ${({ theme }) => theme.font.SUB1_MEDIUM}

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.SUB2_MEDIUM}
  }
`;

export const ClearAllIcon = styled(SearchIcon)`
  position: absolute;
  right: 16px;
`;
