import styled from 'styled-components';

export const ExhibitionProductTablet = styled.div`
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: #000000;
  display: flex;
  gap: 40px;
`;

export const LeftContainer = styled.div`
  width: 57%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.div`
  width: 43%;
`;

export const ProductImageWrapper = styled.div`
  background-color: #ffffff;
  border: 3px solid #ffffff;
  border-radius: 54px;
  overflow: hidden;
`;

export const Image = styled.img<{ $isSticker?: boolean }>`
  width: 100%;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
`;

export const PriceImageWrapper = styled.div``;

export const ButtonImageWrapper = styled.div`
  cursor: pointer;
`;
