import styled from 'styled-components';

export const List = styled.li`
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
  &:last-child {
    border-bottom: none;
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;
