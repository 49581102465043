import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './ReviewText.styles';

type Props = {
  reviewText: string;
};

const TextLimit = 150;

const ReviewText = ({ reviewText }: Props) => {
  const { t } = useTranslation();

  const isOverLimit = reviewText.length >= TextLimit;
  const [isExpanded, setIsExpanded] = useState(!isOverLimit);

  const handleExpandButtonClick = () => {
    setIsExpanded(true);
  };

  const text = isExpanded ? reviewText : reviewText.slice(0, TextLimit) + '...';

  return (
    <S.TextContainer>
      <S.Text>
        <span>{text}</span>
        {!isExpanded && (
          <S.ExpandButton onClick={handleExpandButtonClick}>
            {t('store.productDetailPage.reviewSection.more')}
          </S.ExpandButton>
        )}
      </S.Text>
    </S.TextContainer>
  );
};

export default ReviewText;
