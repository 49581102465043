import { ReactComponent as StarIcon } from 'src/assets/icons/Star.svg';
import { TOP_BAR_HEIGHT } from 'src/components/store/Bar/BrandDetailTopBar.styles';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import colors from 'src/utils/colors';
import styled, { css } from 'styled-components';
import LazyImage from '../../../components/common/LazyImage/LazyImage';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import { FullView } from '../Store.styles';

export const DetailFullView = styled(FullView)`
  justify-content: center;
  align-items: center;
`;

export const StickerTop = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  padding: 0 16px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Navigation = styled.nav`
  background-color: ${colors.$white};
  z-index: 10;
  position: sticky;
  top: ${TOP_BAR_HEIGHT}px;

  ul {
    display: flex;
    gap: 24px;
  }

  &::after {
    width: 100vw;
    max-width: 1440px;
    border-bottom: 1px solid ${colors.$gray200};
    content: '';
    transform: translateX(-50%);

    position: absolute;
    bottom: -1px;
    left: 50%;
  }
`;

export const NavigationTab = styled.li<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    padding: 12px 8px;
    ${$isSelected && `border-bottom: 2px solid ${theme.color.gray_900}`};

    a {
      text-decoration: none;
      ${theme.font.SUB1_SEMIBOLD};
      color: ${$isSelected ? theme.color.gray_900 : theme.color.gray_400};
    }
  `}
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  max-width: 134px;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.color.gray_100};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.color.gray_100};

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    max-width: 108px;
  }
`;

export const ThumbnailResize = styled(LazyImage)`
  width: 90%;
  height: 90%;
  padding: 5%;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: absolute;
`;

export const TextContainer = styled.div`
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    margin-left: 8px;
    padding-left: 10px;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Star = styled(StarIcon)`
  fill: ${({ theme }) => theme.color.review_star};
`;

export const RatingValue = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  margin-left: 4px;
`;

export const ReviewCount = styled.div`
  color: ${({ theme }) => theme.color.gray_500};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  margin-left: 6px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.color.gray_700};
  white-space: normal;
  ${({ theme }) => theme.font.H3_MEDIUM}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_MEDIUM}
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

export const Price = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H1_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.H2_BOLD}
  }
`;

export const Percent = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B2_SEMIBOLD}
`;

export const DiscountDate = styled.span`
  overflow: hidden;
  color: ${({ theme }) => theme.color.warning_500};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.font.B2_MEDIUM}
`;

export const StickerBoard = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 24px 36px;
  border-radius: 16px;
  background: ${({ theme }) => theme.color.gray_100};
  row-gap: 32px;
  column-gap: 38px;
  flex-wrap: wrap;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 24px 32px;
    row-gap: 24px;
    column-gap: 38px;
  }
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 20px 24px;
    row-gap: 16px;
    column-gap: 18px;
  }
`;

export const WatermarkWarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  width: 100%;
  border-radius: 16px;
  background-color: #fbfbfb;
  box-sizing: border-box;
  column-gap: 10px;
`;

export const WatermarkWarningText = styled.div`
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.B1_REGULAR};
  text-align: left;
`;

export const WatermarkWarningBoldText = styled.span`
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
`;

export const TagsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
`;

export const OtherStickerPartBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: space-between;
`;

export const RecommendProducts = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
