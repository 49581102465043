import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import store_images from '../../../assets/images/store/images';
import MyPurple from '../../../components/store/Purple/MyPurple';
import PurpleHistoryUnit from '../../../components/store/Purple/PurpleHistoryUnit';
import { UserContext } from '../../../context/userContext';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { thousandsFormate } from '../../../utils/amount';
import { eventNames, goStoreSources } from '../../../utils/mixpanel.ts';
import { getPurpleHistory } from '../../../utils/request.ts';
import * as S from './PurpleHistory.styles';
import * as G from './PurplePage.styles';

const PurpleHistory = ({ setAlignmentSelectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentBalance, refreshUserBalance } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const [purpleHistory, setPurpleHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const fetchPurpleHistory = async () => {
    setLoading(true);
    const response = await getPurpleHistory();
    if (response?.data?.status) setPurpleHistory(response?.data?.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    refreshUserBalance();
    fetchPurpleHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGoToStore = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
    const isUser = currentUser ? true : false;

    trackEvent(eventNames.goToStoreClick, currentUser, {
      source: goStoreSources.purpleHistory,
      platformType,
      isUser,
    });

    navigate('/store');
  };

  useEffect(() => {
    trackEvent(eventNames.pointHistoryPage, currentUser, {
      platformType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <G.PurpleFullView>
      <G.TabContentsContainer>
        <S.MyPurplePart>
          <MyPurple purpleCount={thousandsFormate(currentBalance)} />

          <S.PurpleChargingContainer>
            <S.PurpleChargingText
              onClick={() => {
                setAlignmentSelectedTab(t('store.purpleStation.buy'));
              }}
            >
              {t('store.purpleStation.charge')}
            </S.PurpleChargingText>

            <S.PurpleArrowResize src={store_images.arrowBadge} alt='arrow-dark' />
          </S.PurpleChargingContainer>
        </S.MyPurplePart>

        {loading === false && purpleHistory.length === 0 ? (
          <S.NoneBoard>
            <S.NoneBoardItemContainer>
              <S.NoneBoardText>
                {t('store.purpleStation.noPurpleHistory')}
              </S.NoneBoardText>

              <S.NoneBoardButton onClick={onGoToStore}>
                <S.NoneBoardButtonText>
                  {t('store.purpleStation.goStore')}
                </S.NoneBoardButtonText>
              </S.NoneBoardButton>
            </S.NoneBoardItemContainer>
          </S.NoneBoard>
        ) : (
          <S.HistoryUnitContainer>
            <PurpleHistoryUnit purpleHistoryData={purpleHistory} />
          </S.HistoryUnitContainer>
        )}
      </G.TabContentsContainer>
    </G.PurpleFullView>
  );
};

export default PurpleHistory;
