import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from 'src/constants/_common/url';
import * as S from './index.styles';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <S.Footer>
      <S.Details>
        <S.Summary>
          <p>{t('store.myPage.footer.summary')}</p>
          <S.ArrowUp />
        </S.Summary>

        <S.Content>
          <S.ContentInner>
            <S.BusinessInfo>
              <p>{t('store.myPage.footer.businessInfo')}</p>
            </S.BusinessInfo>
            <S.TermsAndConditions>
              <a
                target='_blank'
                href={TERMS_AND_CONDITIONS_URL[i18n.language]}
                rel='noreferrer'
              >
                {t('store.myPage.footer.termsAndConditions')}
              </a>
              <span> | </span>
              <a
                target='_blank'
                href={PRIVACY_POLICY_URL[i18n.language]}
                rel='noreferrer'
              >
                {t('store.myPage.footer.privacyPolicy')}
              </a>
            </S.TermsAndConditions>
            <S.Instructions>
              <p>{t('store.myPage.footer.instructions')}</p>
            </S.Instructions>
          </S.ContentInner>
        </S.Content>
      </S.Details>
    </S.Footer>
  );
};

export default Footer;
