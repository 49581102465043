import styled from 'styled-components';

export const StickerResultTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const SearchResultText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_MEDIUM}
`;
export const ShowStorageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
`;

export const ShowStorageText = styled.span`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_MEDIUM}
`;
