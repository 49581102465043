import { ReactElement } from 'react';
import * as S from '../../LoginPage.styles';

type Props = {
  backgroundColor: string;
  label: string;
  labelColor: string;
  hasBorder?: boolean;
  icon: ReactElement;
  onClick?: () => void;
};

const LoginButtonContainer = ({
  backgroundColor,
  label,
  labelColor,
  hasBorder,
  icon,
  onClick,
}: Props) => {
  return (
    <>
      <S.LoginButton
        $backgroundColor={backgroundColor}
        $hasBorder={hasBorder}
        onClick={onClick}
      >
        {icon}
        <S.LoginButtonLabel $labelColor={labelColor}>{label}</S.LoginButtonLabel>
      </S.LoginButton>
    </>
  );
};

export default LoginButtonContainer;
