import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import store_images from '../../../../assets/images/store/images';
import ProductCard from '../../../../components/store/ExhibitionPageComponents/ProductCard';
import { remainingSaleDays } from '../../../../constants/exhibition';
import { UserContext } from '../../../../context/userContext';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import { ExhibitionProduct } from '../../../../utils/exhibitionRequest';
import { generateProductLink } from '../../../../utils/links';
import { eventNames, productProps } from '../../../../utils/mixpanel';
import { addToWishlist, removeFromWishlist } from '../../../../utils/request';
import * as S from './Products.styles';

type Props = {
  products: ExhibitionProduct[];
  source: string;
};

const Products = ({ products, source }: Props) => {
  const userInfo = useContext(UserContext) as {
    wishlist: { [key: string]: { [key: string]: any } };
    currentUser: { [key: string]: string };
    refreshWishlist: () => Promise<void>;
  };

  const navigate = useNavigate();
  const isWeb = !localStorage.getItem('platform');

  return (
    <S.Products>
      {products.map((product) => {
        const {
          productType: type,
          brand: { name: brandName },
          korName: name,
          sellingPrice,
          price,
          _id,
        } = product;

        const isSticker = type === 'sticker';
        const imageSrc =
          (type === 'sticker'
            ? product.sticker?.sticker
            : product.template?.thumbnailSignUrl) ?? store_images.defaultProfile;
        const onProductClick = () => {
          trackEvent(eventNames.storeProduct, userInfo.currentUser, {
            productType: type,
            productId: _id || '',
            brandName: brandName || name || '',
            page: 'exhibition',
          });

          if (isWeb) {
            const link = generateProductLink({
              productId: _id,
              productType: type,
              isApp: !isWeb,
            });

            window.open(link, '_blank');
            return;
          }
          const sourceValue = source || '';
          navigate(`${PATH.STORE}/${type}/detail/${_id}?lang=kor`, {
            state: { source: sourceValue },
          });
        };
        const discountPercentage = product.discountPercentage || 0;
        const isProductInWishlist = userInfo?.wishlist.hasOwnProperty(_id);
        const onFavoriteIconClick = async () => {
          const platform =
            localStorage.getItem('platform') ??
            new URLSearchParams(window.location.search).get('platform');
          const platformType =
            platform === 'ios' || platform === 'android' ? 'app' : 'web';

          if (userInfo.currentUser) {
            trackEvent(
              isProductInWishlist
                ? eventNames.productUnlikeClick
                : eventNames.productLikeClick,
              userInfo.currentUser,
              {
                ...productProps(product),
                platformType,
              }
            );
          }

          const response = isProductInWishlist
            ? await removeFromWishlist(userInfo.wishlist[product._id].id)
            : await addToWishlist(_id);
          if (response?.data?.status) {
            userInfo.refreshWishlist();
          }
        };

        return (
          <ProductCard
            key={product._id}
            {...{
              imageSrc,
              brandName,
              name,
              sellingPrice: sellingPrice || price,
              onClick: onProductClick,
              isSticker,
              isProductInWishlist,
              onFavoriteIconClick,
              remainingSaleDays,
              discountPercentage,
            }}
          />
        );
      })}
    </S.Products>
  );
};

export default Products;
