import { MouseEventHandler, memo, useRef } from 'react';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

type LazyImageProps = {
  src: string;
  alt: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
};

const LazyImage = ({ src, alt, onClick, ...rest }: LazyImageProps) => {
  const observerRef = useRef<HTMLImageElement & HTMLDivElement>(null);
  const isIntersecting = useIntersectionObserver(observerRef);

  return (
    <>
      {isIntersecting && src ? (
        <img
          ref={observerRef}
          src={isIntersecting ? src : ''}
          alt={isIntersecting ? alt : ''}
          {...rest}
          onClick={onClick}
        />
      ) : (
        <div ref={observerRef} />
        // div를 렌더링하는 이유는 img 태그의 기본 테두리가 있어 테두리가 보이는 것을 방지하기 위함입니다.
        // The reason for rendering a div is to prevent the default border of the img tag from being visible.
      )}
    </>
  );
};

export default memo(LazyImage);
