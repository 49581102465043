import { useTranslation } from 'react-i18next';
import CommonButton, { ButtonType } from 'src/components/common/CommonButton';
import * as S from './SubmitButton.styles';
import { Mode } from './types';

type Props = {
  mode: Mode;
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
  buttonType?: ButtonType;
};

const SubmitButton = ({ mode, onClick, disabled, isLoading, buttonType }: Props) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <CommonButton
        onClick={onClick}
        height={56}
        disabled={disabled}
        isLoading={isLoading}
        buttonType={buttonType}
      >
        {mode === 'create'
          ? t('store.submitReview.submitButton')
          : t('store.submitReview.submitButtonEdit')}
      </CommonButton>
    </S.Container>
  );
};

export default SubmitButton;
