import { useTranslation } from 'react-i18next';
import { useNavigate as navigation } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import store_images from '../../../assets/images/store/images';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useNavigate from '../../../hooks/useNavigation';
import { usePreviousLocation } from '../../../hooks/usePreviousLocation';
import useReactNativeEvents from '../../../hooks/useReactNativeEvents';
import { copyToClipboard } from '../../../utils/_common/clipboard';
import { showToast } from '../../../utils/_common/showToast';
import {
  eventNames,
  productProps,
  storeBrandDetailSources,
} from '../../../utils/mixpanel';
import * as S from './BrandDetailTopBar.styles';

type Props = {
  product: any;
  url: string;
  brandId: any;
  barName: string;
  onClose: any;
  sourceScreen: 'stickerDetail' | 'templateDetail';
  source: (typeof storeBrandDetailSources)[keyof typeof storeBrandDetailSources];
};

const BrandDetailTopBar = (props: Props) => {
  const { product, url, sourceScreen, brandId, barName, onClose } = props;
  const { t } = useTranslation();
  const navigate = navigation();
  const { handleBack } = usePreviousLocation();
  const { navigateToAppScreen } = useNavigate();
  const { postMessageToRN } = useReactNativeEvents();

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const urlParams = new URLSearchParams(window.location.search);
  const comingFrom = urlParams.get('comingFrom');

  const onShare = async () => {
    if (platform) {
      if (product) {
        trackEvent(eventNames.productLinkShareClick, currentUser, {
          ...productProps(product),
          link: url || '',
          platformType,
          isUser: !!currentUser,
        });
      }

      postMessageToRN('onShareButtonClicked', {
        url: url,
      });
    } else {
      const isSuccess = await copyToClipboard(url);
      trackEvent(eventNames.productLinkShareClick, currentUser, {
        ...productProps(product),
        link: url || '',
        platformType,
        isUser: !!currentUser,
      });
      showToast(
        isSuccess
          ? { toastMessage: t('store.linkCopiedMessage'), toastType: 'success' }
          : { toastMessage: t('store.linkNotCopiedMessage'), toastType: 'error' }
      );
    }
  };

  const navigateToStore = () => {
    if (onClose && platform) {
      postMessageToRN('navigateTo', {
        screen: 'StoreScreen',
        hideBottomTabs: false,
      });
    } else {
      navigate('/store');
    }
    trackEvent(eventNames.todayTab, currentUser, {
      platformType: platform ? 'app' : 'web',
      isUser: !!currentUser,
    });
  };

  const onBack = () => {
    if (platform && comingFrom) {
      navigateToAppScreen({ screen: comingFrom, resetWebView: true });
      trackEvent(eventNames.clickGoBack, currentUser, {
        source: sourceScreen,
      });
    } else if (onClose) {
      onClose();
    } else if (window.history.length > 1) {
      // has previous page
      handleBack();
    } else {
      navigateToStore();
    }
  };

  return (
    <S.BrandDetailTopBarContainer>
      <S.BrandDetailTopBar>
        <S.ArrowResize src={store_images.arrowLeft} alt='arrow' onClick={onBack} />

        <S.BarContainer
          onClick={() => {
            const sourceValue = props.source || '';
            if (brandId?._id)
              navigate(`${PATH.STORE}/brand/detail/${brandId?._id}`, {
                state: { source: sourceValue },
              });
          }}
        >
          <S.BrandProfile
            src={
              brandId?.logoImage?.location ||
              brandId?.logoImageSignUrl ||
              store_images.defaultProfile
            }
            alt='brand_profile'
          />

          <S.BarText>{barName}</S.BarText>
        </S.BarContainer>
        <S.IconsContainer>
          <S.GoToStoreHome type='button' onClick={navigateToStore}>
            <img src={store_images.storeHome} alt='store_home' />
          </S.GoToStoreHome>
          <S.ShareResize type='button' onClick={onShare}>
            <img
              src={
                localStorage.getItem('platform') === 'ios'
                  ? store_images.iOSShare
                  : store_images.shareBlack
              }
              alt='share'
            />
          </S.ShareResize>
        </S.IconsContainer>
      </S.BrandDetailTopBar>
    </S.BrandDetailTopBarContainer>
  );
};

export default BrandDetailTopBar;
