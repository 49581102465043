import { useState, useEffect } from 'react';
import {
  getProductByBrandId,
  getProductDetails,
  getProductsYouMayLike,
} from '../../utils/request';
import { calculatePricingDetail } from '../../helpers/products';

const useRelatedProducts = (setProduct, id: string, setProductItems?, setPdfDetails?) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [moreProducts, setMoreProducts] = useState([]);

  const getRelatedProducts = (categories: object, productName?: string) => {
    getProductsYouMayLike(categories)
      .then((response) => {
        let products = response?.data?.data || [];

        productName
          ? (products = products.filter((item) => item._id !== id))
          : (products = products.filter(
              (p) => p._id !== id && p.engName !== productName
            ));
        setRelatedProducts(products);
      })
      .catch((error) => console.log({ error }));
  };

  const getMoreBrandProducts = (brandId: string, productName?: string) => {
    getProductByBrandId(brandId)
      .then((response) => {
        let products = response?.data?.data || [];
        productName
          ? (products = products.filter((item) => item._id !== id))
          : (products = products.filter(
              (p) => p._id !== id && p.engName !== productName
            ));
        setMoreProducts(products);
      })
      .catch((error) => console.log({ error }));
  };

  useEffect(() => {
    getProductDetails(id).then((response) => {
      const { product, data } = response.data.data;

      if (product) {
        const { salePrice, isDiscount, remainingSaleDays } =
          calculatePricingDetail(product);
        product.salePrice = salePrice;
        product.isDiscount = isDiscount;
        product.remainingSaleDays = remainingSaleDays;
      }

      setProduct(product);
      if (setProductItems) {
        setProductItems(data);
      } else {
        setPdfDetails(data);
      }

      getMoreBrandProducts(product?.brandId?.id, product?.engName);
      if (product?.categories?.length) {
        const categories = product?.categories.map((category) => category.id?.toString());
        getRelatedProducts(categories, product?.engName);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { relatedProducts, moreProducts };
};

export default useRelatedProducts;
