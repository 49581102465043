import React from 'react';
import NoResult from '../../NoResult/NoResult';
import SearchResultHeader from '../../SearchResultHeader/SearchResultHeader';
import BrandData from './components/BrandData';

import * as G from '../ProductCommon.styles';
import { ProductDataProps } from '../../../../types/search/commonPropsTypes';

const BrandMenu = ({ text, data }: ProductDataProps) => {
  const total = data?.total || 0;

  return (
    <G.AllContainer>
      <SearchResultHeader total={total} tab='brand' />

      {total > 0 ? (
        <BrandData text={text} data={data?.data || []} />
      ) : (
        <NoResult text={text} showStorageOnly={false} />
      )}
    </G.AllContainer>
  );
};

export default BrandMenu;
