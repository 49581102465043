import typography from './typography';

export const theme = {
  font: {
    PURPLE_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 40,
      height: 40,
    }),
    MOBILE_PURPLE_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 36,
      height: 28,
    }),
    H1_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 24,
      height: 30,
    }),
    H2_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 20,
      height: 24,
    }),
    H2_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 20,
      height: 24,
    }),
    H2_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 20,
      height: 24,
    }),
    H3_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 18,
      height: 20,
    }),
    H3_BOLD_LONG: typography({
      fontFamily: 'Pretendard-Bold',
      size: 18,
      height: 28,
    }),
    H3_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 18,
      height: 20,
    }),
    H3_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 18,
      height: 20,
    }),
    SUB1_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 18,
      height: 26,
    }),
    SUB1_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 18,
      height: 26,
    }),
    SUB2_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 16,
      height: 24,
    }),
    SUB2_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 16,
      height: 24,
    }),
    SUB2_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 16,
      height: 24,
    }),
    B1_REGULAR: typography({
      fontFamily: 'Pretendard-Regular',
      size: 16,
      height: 24,
    }),
    B2_BOLD: typography({
      fontFamily: 'Pretendard-Bold',
      size: 14,
      height: 21,
    }),
    B2_SEMIBOLD_SHORT: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 14,
      height: 14,
    }),
    B2_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 14,
      height: 21,
    }),
    B2_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 14,
      height: 21,
    }),
    B2_REGULAR: typography({
      fontFamily: 'Pretendard-Regular',
      size: 14,
      height: 21,
    }),
    B3_REGULAR: typography({
      fontFamily: 'Pretendard-Regular',
      size: 12,
      height: 18,
    }),
    B3_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 12,
      height: 18,
    }),
    B3_MEDIUM_SHORT: typography({
      fontFamily: 'Pretendard-Medium',
      size: 12,
      height: 12,
    }),
    B3_SEMIBOLD: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 12,
      height: 18,
    }),
    TOOLTIP: typography({
      fontFamily: 'Pretendard-Regular',
      size: 14,
      height: 20,
    }),
    TOAST: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 16,
      height: 16,
    }),

    PURPLE_POINT_BIG: typography({
      fontFamily: 'Pretendard-Medium',
      size: 20,
      height: 20,
    }),
    PURPLE_POINT_ICON: typography({
      fontFamily: 'Pretendard-Medium',
      size: 10,
      height: 10,
    }),
    TAG_MEDIUM: typography({
      fontFamily: 'Pretendard-Medium',
      size: 16,
      height: 16,
    }),
    TAG_REGULAR: typography({
      fontFamily: 'Pretendard-Medium',
      size: 14,
      height: 14,
    }),
    BUTTON: typography({
      fontFamily: 'Pretendard-SemiBold',
      size: 16,
      height: 16,
    }),
  },
  color: {
    primary_bg: '#F9F7FF',
    primary_50: '#ece5ff',
    primary_100: '#d9ccff',
    primary_200: '#c7b3ff',
    primary_300: '#a180ff',
    primary_400: '#7c4dff',
    primary_500: '#6638e6',
    primary_600: '#4819ca',

    warning_600: '#ff264d',
    warning_500: '#ff4163',
    warning_400: '#FF6682',
    warning_50: '#ffe5ea',

    gray_50: '#fbfbfb',
    gray_100: '#f6f6f6',
    gray_200: '#eee',
    gray_300: '#e0e0e0',
    gray_400: '#bdbdbd',
    gray_500: '#9e9e9e',
    gray_600: '#757575',
    gray_700: '#616161',
    gray_800: '#424242',
    gray_900: '#212121',

    white: '#fff',
    black: '#121212',
    button_purple: '#8064fc',
    bonus_background: '#f9f7ff',
    review_star: '#FBBC05',
  },
} as const;
