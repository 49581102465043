import * as S from './Images.styles';

type Props = {
  images: string[];
  handleImageClick: (imageIndex: number, images: string[]) => void;
};

const Images = ({ images, handleImageClick }: Props) => {
  return (
    <S.ImageSlider>
      <S.SliderContainer>
        {images.map((image, index) => (
          <S.Slide
            key={index}
            src={image}
            onClick={() => handleImageClick(index, images)}
          />
        ))}
      </S.SliderContainer>
    </S.ImageSlider>
  );
};

export default Images;
