import axios from 'axios';
import { API_URL } from '../utils/configs';
import {
  AppleLoginRequest,
  AppleLoginResponse,
  KakaoLoginRequest,
  UpdateSignupUserInfoRequest,
} from './types/auth.types';
import { BaseResponse } from './types/common.types';

type GoogleLoginRequest = {
  google_access_token: string;
  googleId: string;
  platform: string;
  name?: string;
  email?: string;
};

export const googleLogin = async (data: GoogleLoginRequest) => {
  return axios.post(`${API_URL}/api/social-auth/google/login`, data);
};

export const getGoogleUserInfo = async (token: string) => {
  return axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`);
};

export const kakaoLogin = async (data: KakaoLoginRequest) => {
  return axios.post(`${API_URL}/api/social-auth/kakao/login`, data);
};

export const appleLogin = async (data: AppleLoginRequest) => {
  return axios.post<BaseResponse<AppleLoginResponse>>(
    `${API_URL}/api/social-auth/apple/login`,
    data
  );
};

export const updateSignUpUserInfo = async ({
  token,
  data,
}: UpdateSignupUserInfoRequest) => {
  return axios.patch(`${API_URL}/api/users/updateUser`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const checkForNickName = async ({
  token,
  data,
}: {
  token: string;
  data: { nickName: string };
}) => {
  return axios.patch(`${API_URL}/api/users/checkNickname`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
