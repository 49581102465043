import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';

interface IsSelectedProps {
  $isSelected: boolean;
}

export const EntireStoreTab = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 900;
  transition: 0.1s ease;
  background-color: ${({ theme }) => theme.color.white};
`;

export const StoreTabTopContainer = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 0px 0px;
  flex-direction: row;
  justify-content: center;
`;

export const StoreTabTitlePurple = styled.div`
  display: flex;
  min-height: 28px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  flex-direction: row;

  .MuiSelect-select {
    padding: 7px 14px !important;
  }

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0px 16px;
  }
`;

export const StoreTabTitle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H2_BOLD}
`;

export const PurpleWishContainer = styled.div`
  display: flex;
  max-width: 280px;
  align-items: center;
  justify-content: flex-end;
`;

export const StoreTabPurplePart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 16px;
  gap: 4px;
  cursor: pointer;
`;

export const StoreTabPurplesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const StoreTabPurplesIcon = styled.img`
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
`;

export const StoreTabPurplesText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.PURPLE_POINT_ICON}
  position: absolute;
`;

export const StoreTabHoldingPurples = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  text-align: center;
  ${({ theme }) => theme.font.B2_SEMIBOLD}
`;

export const FavoriteSearchIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;

export const FavoriteIcon = styled.img`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const SearchIcon = styled(Search)`
  width: 20px;
  height: 20px;
  fill: #121212;
`;

export const StoreTabUnderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 56px;
  align-items: flex-end;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
`;

export const StoreTabTextContainer = styled.div`
  min-height: 46px;
  display: inline-flex;
  padding: 0px 24px;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;
  overflow-x: scroll;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0px 16px;
    gap: 16px;
  }
`;

export const StoreTab = styled.span<IsSelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.gray_800 : theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}
  padding: 12px 8px;
  border-bottom: ${({ $isSelected, theme }) =>
    $isSelected ? `2px solid ${theme.color.gray_800}` : ''};
`;
