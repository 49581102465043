import styled from 'styled-components';
import BREAK_POINTS from '../../../../constants/_common/breakPoints';

export const Container = styled.div`
  padding: 70px 40px;
  box-sizing: border-box;
  background-color: #fff;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 40px 16px;
  }
`;

export const Filters = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
`;

export const Filter = styled.div<{ $isSelected?: boolean }>`
  color: ${({ $isSelected }) => ($isSelected ? '#616161' : '#BDBDBD')};
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;

  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
`;
