import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StarRatingContainer = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const ReviewText = styled.p`
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_MEDIUM};

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const PurpleText = styled.p`
  color: ${({ theme }) => theme.color.primary_400};
  ${({ theme }) => theme.font.B2_SEMIBOLD};
`;
