import React from 'react';
import { useNavigate } from 'react-router-dom';
import store_images from '../../../assets/images/store/images';
import * as S from './TopBar.styles';

type Props = {
  topOffset: number;
};

const TopBar = ({ topOffset }: Props) => {
  const navigate = useNavigate();

  const handleArrowButtonClick = () => {
    navigate('/store');
  };

  return (
    <S.TopBar $topOffset={topOffset}>
      <S.ArrowImage
        src={store_images.arrowLeft}
        alt='arrow'
        onClick={handleArrowButtonClick}
      />
    </S.TopBar>
  );
};

export default TopBar;
