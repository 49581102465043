import styled from 'styled-components';

export const ListWrapper = styled.button`
  display: flex;
  column-gap: 8px;
  align-items: center;
  overflow: auto;
  background: none;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    width: 95%;
  }
`;

export const BulletPoint = styled.p`
  margin-left: 23px;
  color: ${({ theme }) => theme.color.primary_400};
`;

export const commonListText = styled.span<{}>`
  ${({ theme }) => theme.font.B2_REGULAR}
  white-space: nowrap;
`;

export const ListTypeText = styled(commonListText)`
  color: ${({ theme }) => theme.color.gray_500};
`;

export const ListLabelText = styled(commonListText)<{
  $hasLink: boolean;
  $isDescription: boolean;
}>`
  color: ${({ theme, $isDescription }) =>
    $isDescription ? `${theme.color.primary_400}` : `${theme.color.gray_800}`};
  text-decoration: ${({ $hasLink }) => ($hasLink ? 'underline' : 'none')};
  @media screen and (max-width: 450px) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
