import React, { useContext } from 'react';
import { ModalContext } from '../../context/modalContext';

const GlobalModalContainer = () => {
  const { modals } = useContext(ModalContext);

  return (
    <>
      {modals.map(({ component, id }) => (
        <React.Fragment key={id}>{component}</React.Fragment>
      ))}
    </>
  );
};

export default GlobalModalContainer;
