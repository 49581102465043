import styled from 'styled-components';

export const TagLayout = styled.div<{ $noTag: boolean }>`
  display: flex;
  margin-top: 10px;
  gap: 6px;
  white-space: wrap;
  flex-wrap: wrap;
  justify-content: ${({ $noTag }) => ($noTag ? 'center' : 'flex-start')};

  @media screen and (max-width: 600px) {
    margin: 10px 20px;
  }
`;

export const TagContainer = styled.button`
  display: flex;
  border-radius: 99px;
  border: 1px solid ${({ theme }) => theme.color.gray_300};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  background: none;
  height: 32px;
  padding: 0px 16px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    height: 24px;
    padding: 0px 12px;
  }
`;

export const TagText = styled.p`
  ${({ theme }) => theme.font.TAG_MEDIUM}
  color: ${({ theme }) => theme.color.gray_700};

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.TAG_REGULAR}
  }
`;

export const DeleteTagIcon = styled.img`
  width: 14px;
  height: 14px;

  @media screen and (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`;

export const NoTagText = styled.p`
  ${({ theme }) => theme.font.B2_REGULAR}
  color: ${({ theme }) => theme.color.gray_600};

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.B3_REGULAR}
  }
`;
