import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

interface TempCategoryProps {
  $selected: boolean;
}
export const TemplateCategory = styled.div<TempCategoryProps>`
  display: flex;
  padding: 8px 14px;
  align-items: flex-start;
  border-radius: 999px;
  background: ${({ $selected, theme }) =>
    $selected ? `${theme.color.primary_400}` : `${theme.color.white}`};
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? `${theme.color.primary_400}` : `${theme.color.gray_300}`};
`;

export const TemplateCategoryText = styled.span<TempCategoryProps>`
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.white : theme.color.gray_700};
  text-align: center;
  ${({ theme }) => theme.font.SUB2_MEDIUM}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;

export const TemplateCategoryBar = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: auto;
  cursor: pointer;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
`;
