import styled from 'styled-components';
import BREAK_POINTS from './../../../constants/_common/breakPoints';

export const MyPurplePart = styled.div`
  display: flex;
  height: 78px;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
`;

export const PurpleChargingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
  align-items: flex-end;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 10px;
`;

export const PurpleChargingText = styled.span`
  display: flex;
  color: #757575;
  text-align: center;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`;

export const PurpleArrowResize = styled.img`
  width: 12px;
  height: 24px;
`;

export const NoneBoard = styled.div`
  display: flex;
  height: 495px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fbfbfb;
  padding: 24px;
`;

export const NoneBoardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    gap: 16px;
  }
`;

export const NoneBoardText = styled.span`
  color: #616161;
  font-family: 'Pretendard-SemiBold';
  font-size: 18px;
  line-height: 26px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const NoneBoardButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 142px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 56px;
  background: #6638e6;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 126px;
    height: 32px;
  }
`;

export const NoneBoardButtonText = styled.span`
  color: #fff;
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 100%;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 12px;
    line-height: 100%;
  }
`;

export const HistoryUnitContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
