import CommonButton from 'src/components/common/CommonButton';
import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const Buttons = styled.div`
  flex: 1 1 0;

  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    flex-direction: row;
    flex-basis: 100%;
  }
`;

export const Button = styled(CommonButton)`
  ${({ theme }) => theme.font.B2_MEDIUM};
`;
