import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '../../../constants/_common/path';
import { SignUpContextProvider } from './SignUpContext';
import * as S from './SignUpPage.styles';
import DateOfBirthScreen from './components/DateOfBirthScreen/DateOfBirthScreen';
import GenderScreen from './components/GenderScreen/GenderScreen';
import NickNameScreen from './components/NickNameScreen/NickNameScreen';
import SignUpCompleted from './components/SignUpComplete/SignUpComplete';
import TermsAgreement from './components/TermsAgreementScreen/TermsAgreementPage';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [signUpStep, setSignUpStep] = useState(0);
  const { state } = useLocation();

  const onArrowLeftClick = () => {
    if (signUpStep === 0) {
      navigate(-1);
    } else {
      setSignUpStep((prevState) => prevState - 1);
    }
  };

  if (!state) {
    return <Navigate to={PATH.ACCESS_DENIED} replace />;
  }

  return (
    <SignUpContextProvider>
      <S.FullPage>
        <S.TopBar>
          {signUpStep !== 4 && <S.ArrowLeft onClick={onArrowLeftClick} />}
        </S.TopBar>

        {/* sign up process by step */}
        {signUpStep === 0 && <TermsAgreement onNext={() => setSignUpStep(1)} />}
        {signUpStep === 1 && <NickNameScreen onNext={() => setSignUpStep(2)} />}
        {signUpStep === 2 && <DateOfBirthScreen onNext={() => setSignUpStep(3)} />}
        {signUpStep === 3 && <GenderScreen onNext={() => setSignUpStep(4)} />}
        {signUpStep === 4 && <SignUpCompleted />}
      </S.FullPage>
    </SignUpContextProvider>
  );
};

export default SignUpPage;
