import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

export const ModalWrapper = styled(Dialog)``;

export const ModalTitle = styled(DialogTitle)`
  && {
    ${({ theme }) => theme.font.H3_BOLD}
    color: ${({ theme }) => theme.color.gray_900};
  }
`;

export const ModalContent = styled(DialogContent)`
  border: 1px solid ${({ theme }) => theme.color.gray_200};
`;

export const ModalContentWrapper = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalContentTitle = styled.p`
  ${({ theme }) => theme.font.B2_SEMIBOLD}
  letter-spacing: -0.21px;
  color: ${({ theme }) => theme.color.gray_400};
`;

export const MultipleSelectionText = styled.p`
  ${({ theme }) => theme.font.B3_REGULAR}
  color: ${({ theme }) => theme.color.gray_400};
`;

export const CategoryContainer = styled.div`
  margin-top: 16px;
  row-gap: 10px;
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CategoryItemBtn = styled.button<{ $selectedCategory: boolean }>`
  border-radius: 99px;
  padding: 8px 10px;
  background: none;
  border: ${({ $selectedCategory, theme }) =>
    $selectedCategory
      ? `1px solid ${theme.color.primary_400}`
      : `1px solid ${theme.color.gray_400}`};
`;

export const CategoriesText = styled.p<{ $selectedCategory: boolean }>`
  ${({ theme }) => theme.font.TAG_MEDIUM}

  color: ${({ $selectedCategory, theme }) =>
    $selectedCategory ? theme.color.primary_400 : theme.color.gray_400};

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.TAG_REGULAR}
  }
`;

export const SubmitBtn = styled.button<{ $selected: boolean }>`
  width: 100%;
  margin-top: 52px;
  padding: 20px 0px;
  border-radius: 6px;
  ${({ theme }) => theme.font.BUTTON}
  border: none;
  background: ${({ $selected, theme }) =>
    $selected ? theme.color.primary_400 : theme.color.gray_200};
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.white : theme.color.gray_500};
  transition: background-color 0.3s ease;
`;
