import styled, { css } from 'styled-components';

const Category = styled.button<{
  $isSelected?: boolean;
  $selectedColor?: string;
  $borderColor?: string;
}>`
  ${({ $isSelected, $selectedColor, $borderColor }) => css`
    background-color: ${$isSelected ? $selectedColor : '#fff'};
    border: 1px solid ${$isSelected ? $selectedColor : $borderColor};
    color: ${$isSelected ? '#fff' : '#616161'};
    padding: 8px 14px;
    border-radius: 999px;
    white-space: nowrap;
    cursor: pointer;

    font-family: 'Pretendard-Medium';
    font-size: 16px;
    line-height: 22px;
  `}
`;

Category.defaultProps = {
  $selectedColor: '#7c4dff',
  $borderColor: '#e0e0e0',
};

export default Category;
