/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import store_images from '../../../assets/images/store/images.js';
import NoContentBox from '../../../components/common/NoContentBox/NoContentBox';
import {
  NoContentMainText,
  NoContentSubText,
} from '../../../components/common/NoContentBox/NoContentBox.styles';
// import Advertisement from '../../../components/store/Advertisement.js';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import BrandCard from '../../../components/store/Card/BrandCard';
import BrandCardCollection from '../../../components/store/Card/BrandCardCollection';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useResolveData from '../../../hooks/store/useResolveData';
import { eventNames, storeBrandDetailSources } from '../../../utils/mixpanel';
import { handleScrollPosition } from '../../../utils/scroll.js';

import * as C from './../../../components/store/Bar/CategoryBar.styles.ts';
import * as G from './../Store.styles';
import * as S from './BrandPage.styles';

const pageName = 'brands';
const SORT_BY_ENUM = {
  name: 'name',
  resent: 'resent',
  popular: 'popular',
};

const CategoryBar = (props) => {
  const handleClick = (i, it) => {
    if (props.currentUser) {
      trackEvent(eventNames.brandCategoryClick, props.currentUser, { categoryName: it });
    }

    props.setSelected(i);
  };

  const items = props?.items?.map((it, i) => (
    <C.TemplateCategory
      $selected={i === props.selected}
      onClick={() => handleClick(i, it)}
      key={i}
    >
      <C.TemplateCategoryText $selected={i === props.selected}>
        {it}
      </C.TemplateCategoryText>
    </C.TemplateCategory>
  ));

  return <C.TemplateCategoryBar>{items}</C.TemplateCategoryBar>;
};

const BrandPage = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { data: currentUser } = useCurrentUserQuery();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const [selectedCategory, setSelectedCategory] = useState(0);

  const { filterData, sortBy, setSortBy, brandCategories } = useResolveData(
    false,
    data,
    selectedCategory,
    {}
  );

  useEffect(() => {
    setSelectedCategory(0);
  }, [i18n.language]);

  useEffect(() => {
    setTimeout(() => {
      handleScrollPosition(pageName);
    }, 100);
  }, []);

  useEffect(() => {
    trackEvent(eventNames.brandsTab, currentUser, { platformType, isUser });
  }, []);

  return (
    <G.FullView>
      {/* <Advertisement arrowBadge={store_images.arrowBadge} banners={data?.banners || []} /> */}

      <G.Part>
        <div>
          <S.BrandPartBar>
            <S.BrandPartTitle>{t('store.common.newJoinedBrand')}</S.BrandPartTitle>
          </S.BrandPartBar>

          <S.BrandRecommendViewContainer>
            <S.BrandRecommendViewBoard>
              <BrandCard
                items={data?.newBrands?.length > 0 ? data?.newBrands?.slice(0, 6) : []}
                pageName={pageName}
                source={storeBrandDetailSources.brandPopular}
              />
            </S.BrandRecommendViewBoard>

            <S.BrandRecommendViewBoard>
              <BrandCard
                items={data?.newBrands?.length > 0 ? data?.newBrands?.slice(6, 12) : []}
                pageName={pageName}
                source={storeBrandDetailSources.brandPopular}
              />
            </S.BrandRecommendViewBoard>
          </S.BrandRecommendViewContainer>
        </div>

        <div>
          <CategoryBar
            items={brandCategories}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            currentUser={currentUser}
          />

          <S.BrandAlignmentContainer>
            <S.BrandAlignment
              $selected={sortBy === SORT_BY_ENUM.name}
              onClick={() => setSortBy(SORT_BY_ENUM.name)}
            >
              {t('store.brand.nameAlign')}
            </S.BrandAlignment>

            <S.BrandAlignment
              $selected={sortBy === SORT_BY_ENUM.resent}
              onClick={() => setSortBy(SORT_BY_ENUM.resent)}
            >
              {t('store.common.mostRecent')}
            </S.BrandAlignment>

            <S.BrandAlignment
              $selected={sortBy === SORT_BY_ENUM.popular}
              onClick={() => setSortBy(SORT_BY_ENUM.popular)}
            >
              {t('store.common.mostPopular')}
            </S.BrandAlignment>
          </S.BrandAlignmentContainer>

          <S.AllBrandViewBoard>
            <S.BrandCardPartRow>
              {filterData.length > 0 ? (
                filterData.map((brand) => (
                  <BrandCardCollection
                    brand={brand}
                    key={brand?.id || brand?._id}
                    pageName={pageName}
                    source={storeBrandDetailSources.brandCategory}
                  />
                ))
              ) : (
                <NoContentBox height={288} paddingTop={60} paddingBottom={174}>
                  <NoContentMainText>{t('store.noItem.brand.title')}</NoContentMainText>
                  <NoContentSubText>{t('store.noItem.brand.comment')}</NoContentSubText>
                </NoContentBox>
              )}
            </S.BrandCardPartRow>
          </S.AllBrandViewBoard>
        </div>
      </G.Part>
    </G.FullView>
  );
};

export default BrandPage;
