import { useTranslation } from 'react-i18next';
import useSearchHistory from '../../../hooks/useSearchHistory';
import TagContainer from '../TagContainer/TagContainer';
import * as S from './RecentSearch.styles';

type Props = {
  onTagSelect: (tag: string) => void;
};

const RecentSearch = ({ onTagSelect }: Props) => {
  const { history, removeUserHistory, clearUserAllHistory } = useSearchHistory();
  const { t } = useTranslation();

  return (
    <S.ResentSearchLayout>
      <S.ResentSearchContainer>
        <S.ResentSearchTitle>{t('search.mainPage.recentSearch')}</S.ResentSearchTitle>
        <S.ResetAllBtn onClick={clearUserAllHistory}>
          <S.ResetAllBtnText>{t('search.mainPage.deleteAll')}</S.ResetAllBtnText>
        </S.ResetAllBtn>
      </S.ResentSearchContainer>

      <TagContainer
        tags={history}
        deleteTag={removeUserHistory}
        onTagSelect={onTagSelect}
      />
    </S.ResentSearchLayout>
  );
};

export default RecentSearch;
