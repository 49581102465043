import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

interface IsWarningProps {
  $isWarning: boolean;
}

export const BottomSheet = styled.div<{ $isMounted: boolean }>`
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.color.white};
  overflow: hidden;
  cursor: pointer;
  transition: bottom 0.3s ease-out;

  position: fixed;
  bottom: ${({ $isMounted }) => ($isMounted ? '0' : '-100%')};
  z-index: 2;

  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const BottomSheetContent = styled.div`
  width: 100%;
  height: auto;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const BottomSheetTitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const BottomSheetTitle = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.font.H2_BOLD}
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const BottomSheetContentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const PurchaseWarning = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const WarningTitle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.gray_700};
  ${({ theme }) => theme.font.B2_SEMIBOLD}
  text-align: start;
  padding-top: 24px;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_SEMIBOLD}
  }
`;

export const WarningText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_200};
  text-align: left;
`;

export const AboutPurples = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 12px;
  flex-direction: column;
`;

export const PurplePointBox = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const PurplesText = styled.div`
  display: flex;
  height: 26px;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    height: 22px;
  }
`;

export const HoldingPurplesText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;

export const HoldingPurplesAmount = styled.span<IsWarningProps>`
  color: ${({ $isWarning, theme }) =>
    $isWarning ? theme.color.warning_500 : theme.color.gray_500};
  ${({ theme }) => theme.font.H3_BOLD}
`;

export const HoldingPurplesAmountWarning = styled.span`
  ${({ theme }) => theme.font.B3_SEMIBOLD}
`;

export const HoldingPurplesAmountWarningP = styled.span`
  margin-left: 12px;
  ${({ theme }) => theme.font.SUB2_BOLD}
`;

export const PurchasedPurplesText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.SUB2_MEDIUM}
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B2_MEDIUM}
  }
`;

export const PurchasedPurplesAmount = styled.span`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H2_BOLD}
`;

export const PurchasingPurplesButton = styled.div`
  display: flex;
  padding: 20px 68px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: ${({ theme }) => theme.color.primary_500};
  cursor: pointer;
`;

export const PurpleChargingInPaymentOverlay = styled.div`
  display: flex;
  position: fixed;
  max-width: ${STORE_MAX_WIDTH}px;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const PurchasingPurplesText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.font.TOAST}
`;
