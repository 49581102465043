import Hangul from 'hangul-js';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BRAND_CATEGORY_EN,
  BRAND_CATEGORY_KOR,
} from '../../constants/store/brandCategories';
import { calculatePricingDetail } from '../../helpers/products.js';

const useResolveData = (
  isProduct: boolean,
  data: any,
  selectedCategory: number,
  appConfigs: object,
  types?: string
) => {
  const SORT_BY_ENUM = {
    name: 'name',
    resent: 'resent',
    popular: 'popular',
  };
  const [sortBy, setSortBy] = useState(
    isProduct ? SORT_BY_ENUM.resent : SORT_BY_ENUM.name
  );
  // const [filterData, setFilterData] = useState([]);
  const [filterData, setFilterData] = useState(
    data?.mostRecent ? [...data?.mostRecent] : []
  );

  const { i18n } = useTranslation();
  const brandCategories = i18n.language === 'en' ? BRAND_CATEGORY_EN : BRAND_CATEGORY_KOR;

  // category filter functions
  const productCategoryFilter = (list: any, filterBy: string) => {
    list = list.filter((item: any) => {
      if (filterBy === 'free') {
        const { salePrice } = calculatePricingDetail(item);
        return salePrice === 0;
      } else return item.categories.includes(filterBy);
    });
    return list;
  };

  const brandCategoryFilter = (list: any, filterBy: string) => {
    list = /^[a-zA-Z]+$/.test(filterBy)
      ? list.filter(
          (item) => item.nameEN?.charAt(0).toLowerCase() === filterBy.toLowerCase()
        )
      : _.filter(list, (d) => {
          const firstChar = Hangul.disassemble(d.name)[0];
          if (firstChar && firstChar.toLowerCase() === filterBy) return d;
        });

    return list;
  };

  // sort functions
  const sortByName = (list: any) => {
    const sortName = 'name';
    list.sort((a: any, b: any) =>
      a[sortName]?.localeCompare(b[sortName], 'en', { numeric: true })
    );
  };

  const sortByDate = (list: any) => {
    list.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  };

  const sortBySalesCount = (list: any) => {
    if (isProduct) {
      list.sort((a: any, b: any) => b.salesCount - a.salesCount);
    } else {
      list.sort((a: any, b: any) => b.totalSales - a.totalSales);
    }
  };

  useEffect(() => {
    let list = data?.mostRecent ? [...data?.mostRecent] : [];

    // Filter
    let filterBy: any;
    if (isProduct) {
      const categoriesList = types ? appConfigs[types] : [];
      filterBy = categoriesList?.[selectedCategory]?._id ?? 'all';
    } else {
      list = list.filter((item) => item?.products?.length > 0);
      filterBy = brandCategories[selectedCategory] ?? 'all';
    }

    if (filterBy !== 'all' && filterBy !== 'All' && filterBy !== '전체') {
      isProduct
        ? (list = productCategoryFilter(list, filterBy))
        : (list = brandCategoryFilter(list, filterBy));
    }

    //Sort
    if (sortBy === SORT_BY_ENUM.name) {
      sortByName(list);
    } else if (sortBy === SORT_BY_ENUM.resent) {
      sortByDate(list);
    } else if (sortBy === SORT_BY_ENUM.popular) {
      sortBySalesCount(list);
    }

    setFilterData(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, selectedCategory, data]);

  return { filterData, sortBy, setSortBy, brandCategories };
};
export default useResolveData;
