import styled from 'styled-components';
import LazyImage from '../../common/LazyImage/LazyImage';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const BrandItemContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const BrandImageContainer = styled.div`
  aspect-ratio: 1;
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 50%;
`;

export const BrandImageResize = styled(LazyImage)`
  border: 1px solid ${({ theme }) => theme.color.gray_200};
  border-radius: 999px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  cursor: pointer;
`;

export const BrandFavoriteImage = styled(LazyImage)`
  position: absolute;
  display: flex;
  right: 6px;
  bottom: 6px;
  width: 25%;
  cursor: pointer;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    right: 4px;
    bottom: 4px;
  }
`;

export const BrandTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: 134px;

  @media screen and (max-width: 990px) {
    width: 100px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: 90px;
  }
`;
export const BrandTextName = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_BOLD}
  text-align: center;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;

export const BrandTextDetail = styled.span`
  width: 100%;
  height: 22px;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_400};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.font.B2_REGULAR}
  text-align: center;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_REGULAR}
  }
`;
