import { useTranslation } from 'react-i18next';
import * as S from './WarningText.styles';

const WarningText = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Heading>{t('store.submitReview.warningHeading')}</S.Heading>
      <S.WarningUl>
        <S.WarningLi>{t('store.submitReview.warningContent1')}</S.WarningLi>
        <S.WarningLi>{t('store.submitReview.warningContent2')}</S.WarningLi>
        <S.WarningLi>{t('store.submitReview.warningContent3')}</S.WarningLi>
        <S.WarningLi>{t('store.submitReview.warningContent4')}</S.WarningLi>
        <S.WarningLi>{t('store.submitReview.warningContent5')}</S.WarningLi>
      </S.WarningUl>
    </S.Container>
  );
};

export default WarningText;
