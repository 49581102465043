import BREAK_POINTS from 'src/constants/_common/breakPoints';
import colors from 'src/utils/colors';
import styled from 'styled-components';

export const TextContainer = styled.div`
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const Text = styled.div`
  color: ${colors.$gray800};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  text-align: left;
`;

export const ExpandButton = styled.button`
  color: ${colors.$gray400};
  margin-left: 5px;
`;
