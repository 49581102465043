import React from 'react';
import { exhibitionImages } from '../../../../assets/images/store/exhibition/images';
import * as S from './ExhibitionProductMobile.styles';

type Props = {
  index: number;
  isPurchased: boolean;
  onPurchaseButtonClick: () => void;
  onDetailButtonClick: () => void;
};

const ExhibitionProductMobile = ({
  index,
  isPurchased,
  onPurchaseButtonClick,
  onDetailButtonClick,
}: Props) => {
  return (
    <S.ExhibitionProductMobile>
      <S.PurchaseButtonOverlay
        onClick={() => {
          if (isPurchased) {
            return;
          }

          onPurchaseButtonClick();
        }}
      />
      <S.ProductDetailButtonOverlay onClick={onDetailButtonClick} />

      <S.ProductImageWrapper>
        <S.Image
          src={exhibitionImages.products.mobile.product[index]}
          alt='상품 이미지'
        />
      </S.ProductImageWrapper>
      <S.ButtonImageWrapper>
        <S.Image
          src={
            isPurchased
              ? exhibitionImages.products.mobile.buttons.purchased
              : exhibitionImages.products.mobile.buttons.purchase[index]
          }
          alt=''
        />
      </S.ButtonImageWrapper>
      <S.ButtonImageWrapper>
        <S.Image
          src={exhibitionImages.products.mobile.buttons.detail}
          alt='상품 자세히 보기'
        />
      </S.ButtonImageWrapper>
    </S.ExhibitionProductMobile>
  );
};

export default ExhibitionProductMobile;
