import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from 'src/constants/_common/url';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import checked from '../../../../../assets/images/store/checked.svg';
import unChecked from '../../../../../assets/images/store/unChecked.svg';
import * as G from '../../../../../styles/commonStyles';
import { SignUpContext } from '../../SignUpContext';
import NextButton from '../NextButton';
import TermsAgreementList from './TermsAgreementList';
import * as S from './TermsAgreementPage.styles';

const TermsAgreement = ({ onNext }: { onNext: () => void }) => {
  const { t, i18n } = useTranslation();
  const { termAgreements, setTermAgreements } = useContext(SignUpContext);
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const { data: currentUser } = useCurrentUserQuery();

  const toggleAgreement = (index: number) => {
    setTermAgreements((prevAgreements) => {
      const newAgreements = [...prevAgreements];
      newAgreements[index] = !newAgreements[index];
      return newAgreements;
    });
  };

  const onCheckAllButtonClick = () => {
    setTermAgreements((prevAgreements) => prevAgreements.map(() => !isAllSelected));
  };

  const isAllSelected = termAgreements.every((agreement) => agreement);
  const hasAgreedToTerms = termAgreements.slice(0, 3).every((agreement) => agreement);

  const onNextButtonClick = () => {
    if (hasAgreedToTerms) {
      onNext();
    }
  };

  useEffect(() => {
    trackEvent(eventNames.signupAgreementPage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <G.Content>
        <S.Title>
          {i18n.language === 'ko' ? (
            <>
              더 쉬운 디지털 라이프, <S.PurpleTitle>하플</S.PurpleTitle>로 시작해보세요
            </>
          ) : (
            <>
              Easier Paperless Life, Get Started with <S.PurpleTitle>HAPL </S.PurpleTitle>
              !
            </>
          )}
        </S.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '19px',
          }}
        >
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement1')}
            isRequired
            selected={termAgreements[0]}
            onSelect={() => toggleAgreement(0)}
          />
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement2')}
            isDescription
          />
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement3')}
            isRequired
            hasLink
            selected={termAgreements[1]}
            onSelect={() => toggleAgreement(1)}
            onLinkClick={() => {
              window.open(TERMS_AND_CONDITIONS_URL[i18n.language], '_blank');
            }}
          />
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement4')}
            isRequired
            hasLink
            selected={termAgreements[2]}
            onSelect={() => toggleAgreement(2)}
            onLinkClick={() => {
              window.open(PRIVACY_POLICY_URL[i18n.language], '_blank');
            }}
          />
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement5')}
            selected={termAgreements[3]}
            onSelect={() => toggleAgreement(3)}
          />
          <TermsAgreementList
            label={t('store.signUpPage.termsAgreement6')}
            selected={termAgreements[4]}
            onSelect={() => toggleAgreement(4)}
          />
        </div>
      </G.Content>

      <G.ButtonContainer>
        <S.CheckAllButton onClick={onCheckAllButtonClick} $isAllSelected={isAllSelected}>
          <img src={isAllSelected ? checked : unChecked} alt='' width={24} height={24} />
          <S.AgreeAllBtnText $isAllSelected={isAllSelected}>
            {t('store.signUpPage.agreeAll')}
          </S.AgreeAllBtnText>
        </S.CheckAllButton>
        <NextButton
          onClick={onNextButtonClick}
          buttonText={t('store.signUpPage.signUpStart')}
          disabled={!hasAgreedToTerms}
        />
      </G.ButtonContainer>
    </>
  );
};

export default TermsAgreement;
