import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { exhibitionImages } from '../../../assets/images/store/exhibition/images';
import ContentLoader from '../../../components/Search/_common/ContentLoader/ContentLoader';
import Button from '../../../components/store/ExhibitionPageComponents/Button';
import ScrollTopButton from '../../../components/store/ExhibitionPageComponents/ScrollTopButton';
import TopBar from '../../../components/store/ExhibitionPageComponents/TopBar';
// import PaymentModal from '../../../components/store/Pay/PaymentModal';
import { PATH } from 'src/constants/_common/path';
import * as G from '../../../components/store/StoreComponent.styles';
import { EXHIBITION, MIXPANEL_EVENT_PROP } from '../../../constants/exhibition';
import { ExhibitionContext } from '../../../context/exhibitionContext';
import { UserContext } from '../../../context/userContext';
import useModalControl from '../../../hooks/_common/useModalControl';
import { trackEvent, useMixpanel } from '../../../hooks/store/useMixpanel';
import useNavigation from '../../../hooks/useNavigation';
import { ProductTypes } from '../../../types/product';
import { throttle } from '../../../utils/_common/throttle';
import { generateProductLink } from '../../../utils/links';
import { eventNames, storeProductDetailSources } from '../../../utils/mixpanel';
import * as S from './ExhibitionPage.styles';
import ExhibitionProduct from './ExhibitionProduct/ExhibitionProduct';
import ExhibitionProducts from './ExhibitionProducts/ExhibitionProducts';
import SalesProgress from './SalesProgress';
import {
  BREAK_POINTS,
  OG_DESCRIPTION,
  OG_IMAGE,
  OG_TITLE,
  OG_URL,
  TOP_BAR_HIDDEN_OFFSET,
  TOP_BAR_VISIBLE_OFFSET,
} from './constants';
import { useStickyState } from './useStickyState';

type SelectedExhibitionProduct = {
  _id: string;
  salePrice: number;
  korName: string;
  brandId: {
    name: string;
  };
  productType: ProductTypes;
};

const ExhibitionPage = () => {
  const { navigateTo, navigateToInternalLink } = useNavigation();
  const navigate = useNavigate();
  const { isLoading, exhibitionData, progressInfo, isExhibitionStarted } =
    useContext(ExhibitionContext);
  const { currentUser }: any = useContext(UserContext);
  const isMobile = !useMediaQuery({ minWidth: BREAK_POINTS.MOBILE });
  const {
    isOpen: isPaymentModalOpen,
    open: openPaymentModal,
    close: closePaymentModal,
  } = useModalControl();
  // eslint-disable-next-line
  const [selectedProduct, setSelectedProduct] = useState<SelectedExhibitionProduct>();
  const [topOffset, setTopOffset] = useState(TOP_BAR_VISIBLE_OFFSET);
  const observerRef = useRef<HTMLDivElement | null>(null);
  const isScrollTopButtonShow = useStickyState(observerRef);
  const isWeb = !localStorage.getItem('platform');

  useMixpanel(eventNames.exhibitionPage, {
    exhibitionName: MIXPANEL_EVENT_PROP.EXHIBITION_NAME,
    platform: isWeb ? 'web' : 'app',
  });

  const handleExhibitionProductClick = (product: SelectedExhibitionProduct) => () => {
    if (isWeb) {
      const link = generateProductLink({
        productId: product._id,
        productType: product.productType,
        isApp: !isWeb,
      });
      window.open(link);
    } else {
      setSelectedProduct(product);
      openPaymentModal();
    }
  };

  const [exhibitionProduct1, exhibitionProduct2, exhibitionProduct3] =
    EXHIBITION.PRODUCT_IDS.map((id) =>
      exhibitionData?.products.find((product) => product._id === id)
    );

  const handleSampleImgClick = (productName?: string, productId?: string) => {
    trackEvent(eventNames.clickSampleImage, currentUser, {
      exhibitionName: MIXPANEL_EVENT_PROP.EXHIBITION_NAME,
      productName: productName || '상품명 없음',
    });

    if (productId) {
      const sourceValue = storeProductDetailSources.exhibition;
      navigate(`${PATH.STORE}/sticker/detail/${productId}`, {
        state: { source: sourceValue },
      });
    }
  };

  const handleGoToBrandClick = () => {
    trackEvent(eventNames.goToExhibitionBrand, currentUser, {
      exhibitionName: MIXPANEL_EVENT_PROP.EXHIBITION_NAME,
      brandName: MIXPANEL_EVENT_PROP.BRAND_NAME,
    });
  };

  const handleGoToExternalClick = (externalUrl: string) => {
    trackEvent(eventNames.GoToExternal, currentUser, {
      exhibitionName: MIXPANEL_EVENT_PROP.EXHIBITION_NAME,
      externalPageUrl: externalUrl,
    });
    navigateToInternalLink({
      link: externalUrl,
    });
  };

  const imageSrc = isMobile ? exhibitionImages.mobile : exhibitionImages.tablet;
  const imageSize = isMobile
    ? exhibitionImages.mobileImageSize
    : exhibitionImages.tabletImageSize;

  useEffect(() => {
    if (isExhibitionStarted === false) {
      alert('기획전 기간이 아닙니다.');
      navigateTo(PATH.DEFAULT);
    }
  }, [navigateTo, isExhibitionStarted]);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScrollDown = throttle(() => {
      const currentScroll = window.scrollY;
      const scrolledDown = currentScroll > lastScrollTop;
      if (scrolledDown) {
        setTopOffset(TOP_BAR_VISIBLE_OFFSET);
      } else {
        setTopOffset(TOP_BAR_HIDDEN_OFFSET);
      }
      lastScrollTop = currentScroll;
    }, 100);

    window.addEventListener('scroll', handleScrollDown);
    return () => {
      window.removeEventListener('scroll', handleScrollDown);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{OG_TITLE}</title>
        <link rel='canonical' href={OG_URL} />
        <meta name='description' content={OG_TITLE} />
        <meta property='og:type' content={'exhibition'} />
        <meta property='og:title' content={OG_TITLE} />
        <meta property='og:description' content={OG_DESCRIPTION} />
        <meta property='og:image' content={OG_IMAGE} />
        <meta property='og:url' content={OG_URL} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={OG_TITLE} />
        <meta name='twitter:description' content={OG_DESCRIPTION} />
        <meta name='twitter:image' content={OG_IMAGE} />
      </Helmet>

      <S.Backdrop>
        <S.Content>
          {!isWeb && <TopBar topOffset={topOffset} />}

          <S.ScrollTopButtonContainer
            $isShow={isScrollTopButtonShow}
            $isMobile={isMobile}
          >
            <ScrollTopButton size={isMobile ? 'small' : 'large'} />
          </S.ScrollTopButtonContainer>

          <S.ImageWrapper $imageSize={imageSize[10]}>
            <S.Image src={imageSrc[10]} alt='' />
          </S.ImageWrapper>

          {isLoading ? (
            <ContentLoader />
          ) : (
            <SalesProgress progressInfo={progressInfo} topOffset={topOffset} />
          )}
          <div ref={observerRef} />

          <S.ImageWrapper $imageSize={imageSize[20]}>
            <S.Image src={imageSrc[20]} alt='' />
          </S.ImageWrapper>
          <S.ImageWrapper $imageSize={imageSize[30]}>
            <S.Image src={imageSrc[30]} alt='' />
          </S.ImageWrapper>

          <div
            onClick={() =>
              handleSampleImgClick(exhibitionProduct1?.korName, exhibitionProduct1?._id)
            }
          >
            <S.ImageWrapper $imageSize={imageSize[40]}>
              <S.Image src={imageSrc[40]} alt='' $hasLink />
            </S.ImageWrapper>
          </div>

          <S.ImageWrapper $imageSize={imageSize[50]}>
            <S.Image src={imageSrc[50]} alt='' />
          </S.ImageWrapper>

          <S.ButtonContainer>
            <Button
              onClick={() => {
                handleGoToExternalClick('https://www.animals.or.kr/center/adopt/65931');
              }}
              $borderColor='#ED6C00'
              $isMobile={isMobile}
            >
              찰리의 이야기 보러가기
            </Button>
          </S.ButtonContainer>

          <S.ImageWrapper $imageSize={imageSize[60]}>
            <S.Image src={imageSrc[60]} alt='' />
          </S.ImageWrapper>

          <S.ButtonContainer>
            <Button
              onClick={() => {
                handleGoToExternalClick('https://www.animals.or.kr/center/adopt/62651');
              }}
              $borderColor='#ED6C00'
              $isMobile={isMobile}
            >
              뭉치의 이야기 보러가기
            </Button>
          </S.ButtonContainer>

          <S.ImageWrapper $imageSize={imageSize[70]}>
            <S.Image src={imageSrc[70]} alt='' />
          </S.ImageWrapper>
          <S.ImageWrapper $imageSize={imageSize[80]}>
            <S.Image src={imageSrc[80]} alt='' />
          </S.ImageWrapper>

          <S.ButtonContainer>
            <Link
              to={generateProductLink({
                productId: EXHIBITION.CLUBJEJE_ID,
                productType: 'brand',
                isApp: !isWeb,
              })}
              target='_blank'
            >
              <Button $isMobile={isMobile} onClick={() => handleGoToBrandClick()}>
                클럽제제 보러가기
              </Button>
            </Link>
          </S.ButtonContainer>

          <div
            onClick={() =>
              handleSampleImgClick(exhibitionProduct1?.korName, exhibitionProduct1?._id)
            }
          >
            <S.ImageWrapper $imageSize={imageSize[90]}>
              <S.Image src={imageSrc[90]} alt='' $hasLink />
            </S.ImageWrapper>
          </div>

          <S.ImageWrapper $imageSize={imageSize[100]}>
            <S.Image src={imageSrc[100]} alt='' />
          </S.ImageWrapper>

          {exhibitionProduct1 && (
            <ExhibitionProduct
              isMobile={isMobile}
              index={1}
              isPurchased={!!exhibitionProduct1.isProductPurchased}
              onPurchaseButtonClick={handleExhibitionProductClick({
                _id: exhibitionProduct1._id,
                salePrice:
                  (exhibitionProduct1.sellingPrice || exhibitionProduct1.price) /
                  EXHIBITION.POINTS_TO_WON,
                korName: exhibitionProduct1.korName,
                brandId: { name: exhibitionProduct1.brand.name },
                productType: 'sticker',
              })}
              onDetailButtonClick={() => {
                const link = generateProductLink({
                  productId: exhibitionProduct1._id,
                  productType: 'sticker',
                  isApp: !isWeb,
                });
                window.open(link, '_blank');
              }}
            />
          )}

          <div style={{ height: '180px', backgroundColor: '#000000' }} />

          <div
            onClick={() =>
              handleSampleImgClick(exhibitionProduct2?.korName, exhibitionProduct2?._id)
            }
          >
            <S.ImageWrapper $imageSize={imageSize[110]}>
              <S.Image src={imageSrc[110]} alt='' $hasLink />
            </S.ImageWrapper>
          </div>

          <div style={{ height: '120px', backgroundColor: '#000000' }} />

          <div
            onClick={() =>
              handleSampleImgClick(exhibitionProduct3?.korName, exhibitionProduct3?._id)
            }
          >
            <S.ImageWrapper $imageSize={imageSize[120]}>
              <S.Image src={imageSrc[120]} alt='' $hasLink />
            </S.ImageWrapper>
          </div>

          <S.ImageWrapper $imageSize={imageSize[130]}>
            <S.Image src={imageSrc[130]} alt='' />
          </S.ImageWrapper>

          {exhibitionProduct2 && (
            <ExhibitionProduct
              isMobile={isMobile}
              index={2}
              isPurchased={!!exhibitionProduct2.isProductPurchased}
              onPurchaseButtonClick={handleExhibitionProductClick({
                _id: exhibitionProduct2._id,
                salePrice:
                  (exhibitionProduct2.sellingPrice || exhibitionProduct2.price) /
                  EXHIBITION.POINTS_TO_WON,
                korName: exhibitionProduct2.korName,
                brandId: { name: exhibitionProduct2.brand.name },
                productType: 'template',
              })}
              onDetailButtonClick={() => {
                const link = generateProductLink({
                  productId: exhibitionProduct2._id,
                  productType: 'template',
                  isApp: !isWeb,
                });
                window.open(link, '_blank');
              }}
            />
          )}

          <S.ImageWrapper $imageSize={imageSize[140]}>
            <S.Image src={imageSrc[140]} alt='' />
          </S.ImageWrapper>

          {exhibitionProduct3 && (
            <ExhibitionProduct
              isMobile={isMobile}
              index={3}
              isPurchased={!!exhibitionProduct3.isProductPurchased}
              onPurchaseButtonClick={handleExhibitionProductClick({
                _id: exhibitionProduct3._id,
                salePrice:
                  (exhibitionProduct3.sellingPrice || exhibitionProduct3.price) /
                  EXHIBITION.POINTS_TO_WON,
                korName: exhibitionProduct3.korName,
                brandId: { name: exhibitionProduct3.brand.name },
                productType: 'sticker',
              })}
              onDetailButtonClick={() => {
                const link = generateProductLink({
                  productId: exhibitionProduct3._id,
                  productType: 'sticker',
                  isApp: !isWeb,
                });
                window.open(link, '_blank');
              }}
            />
          )}

          <S.ImageWrapper $imageSize={imageSize[150]}>
            <S.Image src={imageSrc[150]} alt='' />
          </S.ImageWrapper>

          {!exhibitionData ? (
            <ContentLoader />
          ) : (
            <ExhibitionProducts products={exhibitionData.products} />
          )}
        </S.Content>
      </S.Backdrop>

      {isPaymentModalOpen && <G.Overlay onClick={closePaymentModal} />}
      {/* <PaymentModal
        product={selectedProduct}
        isOpen={isPaymentModalOpen}
        onClose={closePaymentModal}
        onPurchase={closePaymentModal}
        updateOrderNumber={updateOrderNumbe}
      /> */}
    </>
  );
};

export default ExhibitionPage;
