import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import { FullView, PartTitle } from '../Store.styles';

export const DetailFullView = styled(FullView)`
  justify-content: center;
  align-items: center;
`;

export const TagsRow = styled.div`
  display: flex;
  width: min-content;
  white-space: nowrap;
  align-items: flex-start;
  gap: 8px;
`;

export const TemplatePartTitle = styled(PartTitle)`
  text-align: left;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 16px;
  }
`;

export const RecommendProducts = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TemplateDetailPartBar = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: left;

  padding: 0 24px;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    padding: 0 16px;
  }
`;
