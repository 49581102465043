import { useEffect, useState } from 'react';

type History = {
  userId: string;
  history: string[];
};

const useSearchHistory = () => {
  const userId = localStorage.getItem('userId') ?? 'anonymous';

  const [history, setHistory] = useState<History['history']>([]);

  const getAll = () => {
    const allHistory = localStorage.getItem('searchHistory');
    return allHistory ? JSON.parse(allHistory) : {};
  };

  const updateHistory = (updated) => {
    localStorage.setItem('searchHistory', JSON.stringify(updated));
  };

  const getUserHistory = () => {
    const allHistory = getAll();
    return allHistory[userId] ?? [];
  };

  const addUserHistory = (item) => {
    if (!item) return;
    const allHistory = getAll();
    let list = allHistory[userId] ? [...allHistory[userId]] : [];
    if (list.includes(item)) list = list.filter((e) => e !== item);

    list.unshift(item);
    setHistory(list);

    allHistory[userId] = list;
    updateHistory(allHistory);
  };

  const removeUserHistory = (item) => {
    const allHistory = getAll();
    let list = allHistory[userId] ? [...allHistory[userId]] : [];
    if (!list.includes(item)) return;

    list = list.filter((e) => e !== item);
    setHistory(list);

    allHistory[userId] = list;
    updateHistory(allHistory);
  };

  const clearUserAllHistory = () => {
    const allHistory = getAll();
    allHistory[userId] = [];
    setHistory([]);
    updateHistory(allHistory);
  };

  useEffect(() => {
    if (userId) setHistory(getUserHistory());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return { history, addUserHistory, removeUserHistory, clearUserAllHistory };
};

export default useSearchHistory;
