import { Children, cloneElement } from 'react';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { EventName, trackEvent } from 'src/hooks/store/useMixpanel';

type Props = {
  children: React.ReactElement;
  params: {
    eventName: EventName;
    options?: any;
  };
};

const MixpanelClickLogger = ({ children, params }: Props) => {
  const child = Children.only(children);

  const { data: currentUser } = useCurrentUserQuery();

  return cloneElement(child, {
    onClick: (...args: any[]) => {
      const { eventName, options } = params;
      trackEvent(eventName, currentUser, options);

      if (child.props && typeof child.props['onClick'] === 'function') {
        return child.props['onClick'](...args);
      }
    },
  });
};

export default MixpanelClickLogger;
