import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import store_images from '../../../assets/images/store/images.js';
import Advertisement from '../../../components/store/Advertisement';
import ProductCard from '../../../components/store/Card/ProductCard';
import RecommendCard from '../../../components/store/Recommend/RecommendCard';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import { eventNames, storeProductDetailSources } from './../../../utils/mixpanel';
import * as G from './../Store.styles';
import * as S from './Today.styles';

const Today = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser, isLoading } = useCurrentUserQuery();

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;
  useEffect(() => {
    if (!isLoading && platformType === 'web') {
      trackEvent(eventNames.todayTab, currentUser, { platformType, isUser });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      {data?.banners?.length > 0 && (
        <Advertisement arrowBadge={store_images.arrowBadge} banners={data.banners} />
      )}
      <G.FullView>
        <G.Part>
          {/* sticker top10 */}
          <div>
            <G.PartBar>
              <G.PartTitle>{t('store.today.popularSticker')}</G.PartTitle>
              <S.SeeMore
                onClick={() => {
                  navigate(`${PATH.STORE}/sticker`);
                }}
              >
                {t('store.today.more')}
              </S.SeeMore>
            </G.PartBar>
            <S.Top10ViewContainer>
              <ProductCard
                items={data?.popularStickers || []}
                pageInfo={'sticker'}
                source={storeProductDetailSources.todayPopular}
              />
            </S.Top10ViewContainer>
          </div>

          {/* template top10 */}
          <div>
            <G.PartBar>
              <G.PartTitle>{t('store.today.popularTemplate')}</G.PartTitle>
              <S.SeeMore onClick={() => navigate(`${PATH.STORE}/template`)}>
                {t('store.today.more')}
              </S.SeeMore>
            </G.PartBar>
            <S.Top10ViewContainer>
              <ProductCard
                items={data?.popularTemplates || []}
                pageInfo={'template'}
                source={storeProductDetailSources.todayPopular}
              />
            </S.Top10ViewContainer>
          </div>

          {data?.recommendations?.length > 0 && (
            <div>
              <G.PartBar>
                <G.PartTitle>{t('store.today.haplRecommands')}</G.PartTitle>
              </G.PartBar>

              <S.RecommendViewContainer>
                {(data?.recommendations || []).map((item) => (
                  <RecommendCard key={item.id} item={item} />
                ))}
              </S.RecommendViewContainer>
            </div>
          )}

          <div>
            <G.PartBar>
              <G.PartTitle>{t('store.today.newArrivals')}</G.PartTitle>
            </G.PartBar>
            <G.TodayNewProductBoard>
              <ProductCard
                items={data?.newArrivals || []}
                source={storeProductDetailSources.todayNew}
              />
            </G.TodayNewProductBoard>
          </div>
        </G.Part>
      </G.FullView>
    </>
  );
};

export default Today;
