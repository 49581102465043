import { REVIEW_SUBMIT_CONTENT_MAX_WIDTH } from 'src/constants/_common/layout';
import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${REVIEW_SUBMIT_CONTENT_MAX_WIDTH}px;
  margin: 24px 0;
  text-align: left;

  ${responsivePadding};
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_SEMIBOLD};
  margin-bottom: 16px;
`;

export const WarningUl = styled.ul`
  list-style: disc;
  padding-left: 16px;
`;

export const WarningLi = styled.li`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_MEDIUM};
`;
