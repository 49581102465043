import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { approveKakaoPay } from '../../../utils/request';

const KakaoComplete = () => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pg_token = urlParams.get('pg_token');
  const orderNum = urlParams.get('orderNum');
  const productId = urlParams.get('productId');

  useEffect(() => {
    if (orderNum && pg_token) {
      approveKakaoPay(pg_token, orderNum, productId).then(() => {
        window.opener.postMessage('Kakao payment is completed!');
        window.close();
      });
    }
  }, [pg_token, orderNum, productId]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100wh',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          fontFamily: 'Pretendard-Medium',
          fontSize: '16px',
          lineHeight: '22px',
        }}
      >
        {t('store.purpleChargingModal.tossPayComplete')}
      </span>
    </div>
  );
};

export default KakaoComplete;
