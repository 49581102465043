import { theme } from './../../../styles/theme';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import styled from 'styled-components';

interface BrandAlignProps {
  $selected: boolean;
}

export const BrandPartBar = styled.div`
  display: flex;
  height: 28px;
  width: 100%;
  margin-right: 0px;
  flex-direction: row;
  justify-content: space-between;
`;

export const BrandPartTitle = styled.span`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H3_BOLD_LONG}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;

export const BrandRecommendViewContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 24px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    margin-right: 16px;
  }
`;

export const BrandRecommendViewBoard = styled.div`
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
`;

export const BrandAlignmentContainer = styled.div`
  width: 300px;
  height: 22px;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: flex-start;
  gap: 12px;
`;

export const BrandAlignment = styled.span<BrandAlignProps>`
  color: ${(props) =>
    props.$selected ? `${theme.color.primary_400}` : `${theme.color.gray_400}`};
  ${({ theme }) => theme.font.B2_SEMIBOLD}
  cursor: pointer;
`;

export const AllBrandViewBoard = styled.div`
  width: calc(100% - 24px);

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    width: calc(100% - 16px);
  }
`;

export const BrandCardPartRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
