/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import FavoriteNone from '../None/FavoriteNone';
import BrandCard from './../../../../components/store/Card/BrandCard';
import { UserContext } from './../../../../context/userContext';
import { eventNames } from './../../../../utils/mixpanel.ts';
import { getWishlistByType } from './../../../../utils/request';
import * as G from './../FavoritePage.styles';

const FavoriteBrand = () => {
  const { t } = useTranslation();
  const { wishlist: useFav } = useContext(UserContext);

  const [wishList, setWishList] = useState([]);
  const [loading, setLoading] = useState(false);

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const { data: currentUser } = useCurrentUserQuery();

  const fetchWishList = async () => {
    setLoading(true);
    const response = await getWishlistByType('brand');
    if (response?.data?.status) {
      const list = response?.data?.data ?? []; // (response?.data?.data ?? []).map((item) => item.brand);
      setWishList(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (useFav && Object.keys(useFav).length > 0) fetchWishList();
  }, []);

  useEffect(() => {
    trackEvent(eventNames.wishListPage, currentUser, { platformType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading === false && wishList.length === 0 ? (
        <FavoriteNone />
      ) : (
        <G.FavoriteProductPart>
          <G.FavoriteProductCount>
            {t('store.favoritePage.brands')} {wishList.length}
          </G.FavoriteProductCount>

          <G.FavoriteProductBoard>
            <BrandCard
              items={wishList}
              isWishlistPage={true}
              onFavChange={fetchWishList}
              pageName='favoriteBrand'
            />
          </G.FavoriteProductBoard>
        </G.FavoriteProductPart>
      )}
    </>
  );
};
export default FavoriteBrand;
