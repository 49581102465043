import { useContext, useEffect } from 'react';
import { ChannelTalkContext } from 'src/components/Layout/ChannelTalkContext';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatform } from 'src/utils/platformUtils';
import useCurrentUserQuery from '../../../hooks/apis/auth/useCurrentUserQuery';
import * as S from './MyPage.styles';
import Footer from './components/Footer';
import Profile from './components/Profile';
import ProfileMenuList from './components/ProfileMenuList';
import Title from './components/Title';

const MyPage = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const { hideChannelButton, showChannelButton } = useContext(ChannelTalkContext);

  useEffect(() => {
    hideChannelButton();
    return () => {
      showChannelButton();
    };
  }, [hideChannelButton, showChannelButton]);

  useEffect(() => {
    const platform = getPlatform();
    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    trackEvent(eventNames.myPage, currentUser, {
      platformType: platformType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.ColumnContainer>
      <S.StretchContainer>
        <Title />
        <Profile />
        <ProfileMenuList />
      </S.StretchContainer>
      <Footer />
    </S.ColumnContainer>
  );
};

export default MyPage;
