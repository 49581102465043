import store_images from '../../../assets/images/store/images';
import useNavigate from '../../../hooks/useNavigation';
import { usePreviousLocation } from '../../../hooks/usePreviousLocation';
import { useScrollThreshold } from '../../../hooks/useScrollThreshold';
import * as S from './BackTabBar.styles.ts';

const BackTabBar = (props) => {
  const { isBrandCoverVisible } = useScrollThreshold(185);
  const { handleBack } = usePreviousLocation();
  const platform = localStorage.getItem('platform');
  const urlParams = new URLSearchParams(window.location.search);
  const comingFrom = urlParams.get('comingFrom');
  const { navigateToAppScreen } = useNavigate();

  const conditionalArrowImage = isBrandCoverVisible
    ? store_images.whiteArrowLeft
    : store_images.arrowLeft;

  const onBack = () => {
    if (platform && comingFrom) {
      navigateToAppScreen({ screen: comingFrom, resetWebView: true });
    } else if (props?.onClose) {
      props?.onClose();
    } else {
      handleBack();
    }
  };

  return (
    <S.BackBarContainer $isBrandCoverVisible={isBrandCoverVisible}>
      <S.BackBar>
        <S.ArrowResize src={conditionalArrowImage} alt='arrow' onClick={onBack} />
      </S.BackBar>
    </S.BackBarContainer>
  );
};

export default BackTabBar;
