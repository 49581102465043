import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { eventNames } from 'src/utils/mixpanel';
import { calculatePricingDetail } from '../../../helpers/products';
import { thousandsFormate } from '../../../utils/amount';
import * as S from './RecommendEachProduct.styles.ts';

const RecommendEachProduct = ({ recommendItem, source }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUserQuery();
  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');
  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  const clickRecommendCard = (navigateTo, item, index) => {
    const itemBrand = recommendItem[index].brand ?? recommendItem[index].brandId;
    const sourceValue = source || '';
    navigate(`${PATH.STORE}/${navigateTo}/${item?._id}`, {
      state: { source: sourceValue },
    });
    trackEvent(eventNames.storeProduct, currentUser, {
      productType: recommendItem[index].productType || '',
      productId: recommendItem[index]._id || '',
      productName: recommendItem[index].korName || recommendItem[index].engName || '',
      brandName: itemBrand?.name || itemBrand?.nameEN || '',
      source,
      platformType,
      isUser,
    });
  };

  return recommendItem.map((item, index) => {
    const productImage =
      item?.productType === 'sticker'
        ? item?.sticker?.sticker
        : item?.productType === 'template'
        ? item?.template?.thumbnailSignUrl
        : '';

    const navigateTo =
      item?.productType === 'sticker'
        ? 'sticker/detail'
        : item?.productType === 'template'
        ? 'template/detail'
        : '';

    const { salePrice, isDiscount } = calculatePricingDetail(item);

    return (
      <S.RecommendProductBox
        key={index}
        onClick={() => clickRecommendCard(navigateTo, item, index)}
      >
        <S.RecommendProductImage>
          <S.RecommendProductImageResize
            src={productImage}
            alt={
              i18n.language === 'en'
                ? item?.engName || 'thumbnail image'
                : item?.korName || '썸네일 이미지'
            }
          />
        </S.RecommendProductImage>

        <S.RecommendProductTextBox>
          <S.RecommendBrandName>
            {i18n.language === 'en' ? item?.brand?.nameEN : item?.brand?.name}
          </S.RecommendBrandName>
          <S.RecommendProductTextName>
            {i18n.language === 'en' ? item?.engName : item?.korName}
          </S.RecommendProductTextName>

          {item?.productType === 'sticker' && item?.giftOnly === true ? (
            <S.RecommendTextPriceContainer>
              <S.RecommendTextPrice>Gift</S.RecommendTextPrice>
            </S.RecommendTextPriceContainer>
          ) : (
            <S.RecommendTextPriceContainer>
              <S.RecommendTextPercentage>
                {isDiscount ? `${item?.discountPercentage}%` : ''}
              </S.RecommendTextPercentage>

              <S.RecommendTextPrice>
                {salePrice === 0
                  ? i18n.language === 'en'
                    ? 'Free'
                    : '무료'
                  : `${thousandsFormate(salePrice)}P`}
              </S.RecommendTextPrice>
            </S.RecommendTextPriceContainer>
          )}
        </S.RecommendProductTextBox>
      </S.RecommendProductBox>
    );
  });
};

export default RecommendEachProduct;
