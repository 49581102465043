import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  registerProductReview,
  reportProductReview,
  updateProductReview,
} from 'src/apis/products';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import productQueryOptions from 'src/queries/productQueryOptions';
import userQueryOptions from 'src/queries/userQueryOptions';
import { PlatformType, ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';

export const useReportProductReviewMutation = (eventParams: {
  productName: string;
  productType: ProductTypes;
  brandName: string;
  authorId: string;
  authorNickname: string;
  platformType: PlatformType;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reportProductReview,
    onSuccess: () => {
      (async function logReportReviewSuccess() {
        const user = await queryClient.fetchQuery(userQueryOptions.getCurrentUser());
        const userContext = user?.data.data;
        trackEvent(eventNames.reportReview, userContext, eventParams);
      })();
    },
  });
};

export const useRegisterProductReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: registerProductReview,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: userQueryOptions.purchasedProducts() });

      (async function logRegisterReviewSuccess() {
        const [user, salesData] = await Promise.all([
          queryClient.fetchQuery(userQueryOptions.getCurrentUser()),
          queryClient.fetchQuery(productQueryOptions.getSalesData(variables.productId)),
        ]);

        const userContext = user?.data.data;
        const { korName, salesType, brandName } = salesData.data.data;
        trackEvent(eventNames.postReview, userContext, {
          productName: korName,
          productType: salesType,
          brandName: brandName,
          platformType: getPlatformType(),
        });
      })();
    },
  });
};

export const useUpdateProductReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductReview,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: userQueryOptions.purchasedProducts() });

      (async function logUpdateReviewSuccess() {
        const [user, salesData] = await Promise.all([
          queryClient.fetchQuery(userQueryOptions.getCurrentUser()),
          queryClient.fetchQuery(productQueryOptions.getSalesData(variables.productId)),
        ]);
        const userContext = user?.data.data;
        const { korName, salesType, brandName } = salesData.data.data;
        trackEvent(eventNames.editReview, userContext, {
          productName: korName,
          productType: salesType,
          brandName: brandName,
          platformType: getPlatformType(),
        });
      })();
    },
  });
};
