import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 100000000;
  top: 0;
  left: 0;
`;

export const DimSection = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.gray_900};
  opacity: 0.6;

  position: fixed;
`;

export const ModalSheet = styled.div`
  max-width: 90%;
  max-height: 90%;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 24px;
  overflow-y: auto;

  position: absolute;
  z-index: 1;
`;
