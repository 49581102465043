const colors = {
  $primaryPurple: '#6638E6',
  $gray: '#EEE',
  $white: '#FFF',
  $black: '#121212',
  $gray900: '#212121',
  $gray800: '#424242',
  $gray700: '#616161',
  $gray600: '#757575',
  $gray500: '#9E9E9E',
  $gray400: '#BDBDBD',
  $gray300: '#E0E0E0',
  $gray200: '#EEEEEE',
  $gray100: '#F6F6F6',
  $gray50: '#FBFBFB',
  $purple900: '#200B5B',
  $purple800: '#280E71',
  $purple700: '#3E16AE',
  $purple600: '#4819CA',
  $purple400: '#7C4DFF',
  $purple300: '#A180FF',
  $purple200: '#C7B3FF',
  $purple100: '#D9CCFF',
  $purple50: '#ECE5FF',
  $bgPurple: '#F9F7FF',
  $warning900: '#AB011F',
  $warning800: '#CD0529',
  $warning700: '#EE0932',
  $warning600: '#FF264D',
  $warning500: '#FF4163',
  $warning400: '#FF6682',
  $warning300: '#FF8096',
  $warning200: '#FFA6B6',
  $warning100: '#FFBFCB',
  $warning50: '#FFE5EA',

  $reviewStar: '#FBBC05',
} as const;

export default colors;
