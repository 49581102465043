import { useTranslation } from 'react-i18next';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import useNavigation from 'src/hooks/useNavigation';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './EditReviewButton.styles';

type Props = {
  productType: ProductTypes;
  productName: string;
  brandName: string;
  productId: string;
  reviewId: string;
};

const EditReviewButton = ({
  productType,
  productName,
  brandName,
  productId,
  reviewId,
}: Props) => {
  const { t } = useTranslation();
  const { navigateToEditReview } = useNavigation();
  const onButtonClick = () => {
    navigateToEditReview(productId, reviewId);
  };

  return (
    <MixpanelClickLogger
      params={{
        eventName: eventNames.clickEditReview,
        options: {
          source:
            productType === 'sticker' ? '/storeStickerDetail' : '/storeTemplateDetail',
          productName,
          productType,
          brandName,
          platformType: getPlatformType(),
        },
      }}
    >
      <S.Action onClick={onButtonClick}>
        {t('store.productDetailPage.reviewSection.edit')}
      </S.Action>
    </MixpanelClickLogger>
  );
};

export default EditReviewButton;
