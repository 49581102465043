import store_images from '../../assets/images/store/images';

const BAR_ITEMS = [
  {
    barName: 'all',
    korBarName: '전체',
    enBarName: 'All',
  },
  {
    barName: 'sticker',
    korBarName: '스티커',
    enBarName: 'Sticker',
  },
  {
    barName: 'template',
    korBarName: '템플릿',
    enBarName: 'Template',
  },
];

const BRAND = [
  {
    defaultBackground: store_images.defaultBackground,
    defaultProfile: store_images.defaultProfile,
    twitterX: store_images.twitterX,
    share: store_images.share,
    youtube: store_images.youtube,
    instagram: store_images.instagram,
    favoriteFilled: store_images.brandFavorite,
  },
];

export { BAR_ITEMS, BRAND };
