import styled from 'styled-components';

export const TextContainer = styled.div`
  padding: 24px 48px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H3_SEMIBOLD};
  margin-bottom: 12px;
  white-space: pre-line;
`;

export const Description = styled.div`
  width: 294px;
  color: ${({ theme }) => theme.color.gray_600};
  ${({ theme }) => theme.font.B2_MEDIUM};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    border-top: 1px solid ${({ theme }) => theme.color.gray_200};
    border-right: 1px solid ${({ theme }) => theme.color.gray_200};
  }

  & > button:last-child {
    border-right: none;
  }
`;

export const Button = styled.button<{ $isWarning?: boolean }>`
  flex: 1;
  padding: 16px 0;

  color: ${({ theme, $isWarning }) =>
    $isWarning ? theme.color.warning_500 : theme.color.gray_600};
  ${({ theme }) => theme.font.SUB2_SEMIBOLD};
`;
