import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../context/userContext';
import { trackEvent } from '../../../../hooks/store/useMixpanel';
import { eventNames, goStoreSources } from '../../../../utils/mixpanel';
import * as S from './FavoriteNone.styles';

const FavoriteNone = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const onGoToStore = () => {
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');

    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
    const isUser = currentUser ? true : false;

    trackEvent(eventNames.goToStoreClick, currentUser, {
      source: goStoreSources.wishlist,
      platformType,
      isUser,
    });

    navigate('/store');
  };

  return (
    <S.FavoriteNoneContainer>
      <S.FavoriteNoneItemContainer>
        <S.FavoriteNoneTextContainer>
          <S.Notice1>{t('store.favoritePage.noLikesTitle')}</S.Notice1>
          <S.Notice2>{t('store.favoritePage.noLikesDescription')}</S.Notice2>
        </S.FavoriteNoneTextContainer>

        <S.StoreButton onClick={onGoToStore} style={{ cursor: 'pointer' }}>
          <S.StoreButtonText>{t('store.favoritePage.goStore')}</S.StoreButtonText>
        </S.StoreButton>
      </S.FavoriteNoneItemContainer>
    </S.FavoriteNoneContainer>
  );
};
export default FavoriteNone;
