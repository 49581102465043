import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StarRating from 'src/components/common/StarRating';
import MixpanelClickLogger from 'src/components/Logger/MixpanelClickLogger';
import useNavigation from 'src/hooks/useNavigation';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import * as S from './Header.styles';

type Props = {
  totalReviewCount: number;
  averageRating: number;
  isPurchased: boolean;
  userReviewExists: boolean;
  productType: ProductTypes;
  productName: string;
  brandName: string;
};

const Header = ({
  totalReviewCount,
  averageRating,
  isPurchased,
  userReviewExists,
  productType,
  productName,
  brandName,
}: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const id = params.id as string;

  const { navigateToCreateReview } = useNavigation();

  const canWriteReview = isPurchased && !userReviewExists;

  return (
    <S.Container>
      <S.TitleContainer>
        <S.ReviewTitle>
          <span>{t('store.productDetailPage.reviewSection.title')}</span>
          <S.ReviewTotalCount>{totalReviewCount}</S.ReviewTotalCount>
        </S.ReviewTitle>
        {canWriteReview && (
          <MixpanelClickLogger
            params={{
              eventName: eventNames.clickPostReview,
              options: {
                source:
                  productType === 'sticker'
                    ? '/storeStickerDetail'
                    : '/storeTemplateDetail',
                productName,
                productType,
                brandName,
                platformType: getPlatformType(),
              },
            }}
          >
            <S.WriteReviewButton onClick={() => navigateToCreateReview(id)}>
              {t('store.productDetailPage.reviewSection.postReview')}
              <S.ArrowRight />
            </S.WriteReviewButton>
          </MixpanelClickLogger>
        )}
      </S.TitleContainer>

      {totalReviewCount > 0 && (
        <S.AverageRatingContainer>
          <S.StarRatingContainer>
            <StarRating rating={averageRating} size={24} />
          </S.StarRatingContainer>
          <S.AverageRatingText>
            {averageRating}/<S.AverageRatingBoldText>5</S.AverageRatingBoldText>
          </S.AverageRatingText>
        </S.AverageRatingContainer>
      )}
    </S.Container>
  );
};

export default Header;
