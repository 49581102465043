import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from './../../../constants/_common/breakPoints';

interface PurpleTabProps {
  selected: boolean;
}

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const PurpleFullView = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    max-width: ${BREAK_POINTS.MOBILE};
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    max-width: ${BREAK_POINTS.TABLET_SMALL};
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    max-width: ${BREAK_POINTS.TABLET_LARGE};
  }
  @media screen and (max-width: ${BREAK_POINTS.DESKTOP}px) {
    max-width: ${BREAK_POINTS.DESKTOP};
  }
`;

export const TabContentsContainer = styled.div`
  display: flex;
  width: 720px;

  margin-top: 24px;
  margin-bottom: 40px;
  flex-direction: column;
  gap: 24px;
  margin-inline: auto;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_LARGE}px) {
    width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const PurpleTabMenuUnderLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  background: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: center;
`;

export const PurpleTabMenuCustomTab = styled.div`
  display: flex;
  width: 100%;
  max-width: 720px;
  align-items: flex-start;
`;

export const PurpleTabTextContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 744px) {
    margin: 0px 24px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    margin: 0px 16px;
  }
`;

export const PurpleTab = styled.span<PurpleTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  line-height: 22px;
  padding: 12px 8px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#424242' : '#bdbdbd')};
  border-bottom: ${(props) => (props.selected ? '2px solid #424242' : 'none')};
`;

export const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: ${STORE_MAX_WIDTH}px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  transition: opacity 0.3s ease-in-out;

  &:active {
    opacity: 0.6;
    pointer-events: auto;
  }
`;
