import colors from 'src/utils/colors';
import styled from 'styled-components';

export const Action = styled.button`
  color: ${colors.$gray500};
  ${({ theme }) => theme.font.SUB2_MEDIUM};
  position: relative;
  padding: 0 8px;

  &:not(:last-child)::after {
    width: 1px;
    height: 16px;
    background-color: ${colors.$gray200};
    content: '';
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
  }
`;
