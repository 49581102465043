export const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomValueFromArray = <T>(arr: T[]): T => {
  const randomIndex = generateRandomNumber(0, arr.length - 1);

  return arr[randomIndex];
};

export const generateNonce = () => {
  const timestamp = Math.floor(Date.now() / 1000);
  const randomBytes = crypto.randomUUID();

  return `${timestamp}-${randomBytes}`;
};
