import { useTranslation } from 'react-i18next';
import { ProductReviewSummary } from 'src/apis/types/products.types';
import { thousandsFormate } from 'src/utils/amount';
import * as S from './TemplateTopPart.styles';

type Props = {
  product: {
    engName?: string;
    korName?: string;
    isDiscount?: boolean;
    discountPercentage?: number;
    salePrice?: number;
    remainingSaleDays?: number;
  };

  pdfDetails?: {
    thumbnailSignUrl: string;
    pdfOtherInfo?: {
      originalname: string;
    };
  };

  reviewSummary?: ProductReviewSummary;
};

const TemplateTopPart = ({ product, pdfDetails, reviewSummary }: Props) => {
  const { t, i18n } = useTranslation();

  const isEnglish = i18n.language === 'en';

  return (
    <S.Container>
      {pdfDetails?.thumbnailSignUrl && (
        <S.ImageResize
          src={pdfDetails.thumbnailSignUrl}
          alt={pdfDetails?.pdfOtherInfo?.originalname || 'pdf-thumbnail'}
        />
      )}

      <S.ProductInfo>
        <S.ProductName>{isEnglish ? product.engName : product.korName}</S.ProductName>
        <S.PriceContainer>
          {product.isDiscount && product.discountPercentage && (
            <S.DiscountPercentage>
              {Math.round(product.discountPercentage)}%
            </S.DiscountPercentage>
          )}
          <S.ProductPrice>
            {product.salePrice && product.salePrice > 0
              ? `${thousandsFormate(product.salePrice)}P`
              : isEnglish
              ? 'Free'
              : '무료'}
          </S.ProductPrice>
        </S.PriceContainer>
        {product.remainingSaleDays && product.remainingSaleDays < 30 && (
          <S.RemainingSaleDays>
            {isEnglish
              ? `Sale ends in D-${product.remainingSaleDays}!`
              : `할인 ${product.remainingSaleDays}일 남았어요!`}
          </S.RemainingSaleDays>
        )}
        {reviewSummary && reviewSummary.reviewNum !== 0 && (
          <S.ReviewContainer>
            <S.Star />
            <S.RatingValue>{reviewSummary.averageRating}</S.RatingValue>
            <S.ReviewCount>
              {`(${t(
                'store.productDetailPage.reviews'
              )} ${reviewSummary.reviewNum.toLocaleString()})`}
            </S.ReviewCount>
          </S.ReviewContainer>
        )}
      </S.ProductInfo>
    </S.Container>
  );
};

export default TemplateTopPart;
