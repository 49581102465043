import { useQuery } from '@tanstack/react-query';
import { FetchSearchResultParams, fetchSearchResult } from '../../../utils/request';

const useSearchQuery = (params: FetchSearchResultParams) => {
  return useQuery({
    queryKey: ['search', params],
    queryFn: () => fetchSearchResult(params),
    select: (data) => data?.data.data,
    staleTime: 1000 * 60 * 5,
  });
};

export default useSearchQuery;
