import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
} from '@mui/material';
import styled from 'styled-components';
import { theme } from '../../../../../styles/theme';

export const TemplateCategoryModalWrapper = styled(Dialog)`
  & .MuiPaper-root {
    max-width: 490px;
    overflow-x: hidden;
  }
`;

export const TemplateCategoryModalTitle = styled(DialogTitle)`
  && {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
    white-space: nowrap;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const TemplateCategoryModalTitleText = styled.p<{ $isActive: boolean }>`
  ${({ theme }) => theme.font.H3_BOLD}
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.color.gray_900 : theme.color.gray_300};
  cursor: pointer;
  transition: color 0.3s ease;

  @media screen and (max-width: 600px) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;

export const TemplateCategoryModalContentWrapper = styled(DialogContent)`
  && {
    border-top: 1px solid ${theme.color.gray_200};
    border-bottom: none;
    height: 364px;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
export const TemplateCategoryModalContent = styled.div<{ $isFirst: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: ${({ $isFirst }) => ($isFirst ? '0px' : '40px')};
`;

export const TemplateCategoryModalContentTitle = styled.span`
  ${({ theme }) => theme.font.B2_SEMIBOLD}
  color: ${({ theme }) => theme.color.gray_400};
`;

export const TemplateCategoryMultipleSelection = styled.span`
  ${({ theme }) => theme.font.B3_REGULAR}
  color: ${({ theme }) => theme.color.gray_400};
`;

export const CategoryContainer = styled.div`
  margin-top: 16px;
  column-gap: 8px;
  row-gap: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CategoryBtn = styled.button<{ $isSelected: boolean }>`
  border-radius: 99px;
  padding: 8px 10px;
  background: none;
  border: ${({ $isSelected, theme }) =>
    $isSelected
      ? `1px solid ${theme.color.primary_400}`
      : `1px solid ${theme.color.gray_400}`};
  transition: border 0.3s ease;
`;

export const CategoryBtnText = styled.p<{ $isSelected: boolean }>`
  ${({ theme }) => theme.font.TAG_MEDIUM}
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.primary_400 : theme.color.gray_400};
  text-transform: none;
  transition: color 0.3s ease;
`;

export const SelectionWrapper = styled.div`
  margin-top: 16px;
`;

export const HyperlinkContainerWrapper = styled.div<{ $isDirection: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ $isDirection }) => ($isDirection ? 'center' : '')};
`;

export const RadioWrapper = styled(RadioGroup)<{ $isFirst: boolean }>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${({ $isFirst }) => ($isFirst ? '16px' : '0px')};
  }
`;

export const RadioText = styled.span<{ $isSelected: boolean }>`
  ${({ theme }) => theme.font.B1_REGULAR}
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.gray_600 : theme.color.gray_400};
`;

export const DirectionImg = styled.img<{ $isHorizontal: boolean; $isVertical: boolean }>`
  width: ${({ $isHorizontal, $isVertical }) =>
    $isHorizontal ? '67px' : $isVertical ? '50px' : '50px'};
  height: ${({ $isHorizontal, $isVertical }) =>
    $isHorizontal ? '50px' : $isVertical ? '67px' : '50px'};
`;

export const ColorWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 20px;
`;

export const ModalButtonWrapper = styled(DialogActions)``;

export const ModalBtn = styled.button<{ $isEnabled: boolean }>`
  background-color: ${({ $isEnabled, theme }) =>
    $isEnabled ? theme.color.primary_400 : theme.color.gray_200};
  color: ${({ $isEnabled, theme }) =>
    $isEnabled ? theme.color.white : theme.color.gray_500};
  transition: background-color 0.3s ease;
  width: 100%;
  padding: 20px;
  text-transform: none;
  border-radius: 6px;
  border: none;
  ${({ theme }) => theme.font.BUTTON}
`;
