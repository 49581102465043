import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommonTopBar from 'src/components/store/Bar/CommonTopBar';
import useModalControl from 'src/hooks/_common/useModalControl';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import { trackEvent } from 'src/hooks/store/useMixpanel';
import { getPlatform } from 'src/utils/platformUtils';
import CloseConfirmModal from './CloseConfirmModal';
import * as S from './TopBar.styles';
import { Mode } from './types';

type Props = {
  mode: Mode;
  handleResetButtonClick: () => void;
};

const TopBar = ({ mode, handleResetButtonClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: currentUser } = useCurrentUserQuery();

  const {
    isOpen: isCloseConfirmModalOpen,
    close: hideCloseConfirmModal,
    open: openCloseConfirmModal,
  } = useModalControl();

  const platform = getPlatform();
  const isCreateMode = mode === 'create';

  const onBackButtonClick = () => {
    openCloseConfirmModal();
    trackEvent('clickGoBack', currentUser, {
      source: isCreateMode ? '/postReview' : '/editReview',
      platformType: Boolean(platform) ? 'app' : 'web',
    });
  };

  return (
    <>
      <CommonTopBar
        barName={
          isCreateMode
            ? t('store.submitReview.barName')
            : t('store.submitReview.barNameEdit')
        }
        onBackButtonClick={onBackButtonClick}
        rightSide={
          <button type='button' onClick={handleResetButtonClick}>
            <S.ResetText>{t('common.reset')}</S.ResetText>
          </button>
        }
      />
      {isCloseConfirmModalOpen && (
        <CloseConfirmModal
          onClose={hideCloseConfirmModal}
          onConfirm={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default TopBar;
