import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastWrapper = () => {
  return (
    <ToastContainer
      closeButton={false}
      hideProgressBar={true}
      position='bottom-center'
      theme='colored'
      autoClose={2000}
      closeOnClick={true}
      pauseOnFocusLoss={false}
    />
  );
};

export default ToastWrapper;
