import React, { ReactNode } from 'react';
import * as S from './NoContentBox.styles';

export type NoContentBoxProps = {
  children: ReactNode;
  padding?: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'inherit';
  isHorizontallyCentered?: boolean;
  isVerticallyCentered?: boolean;
  bgColor?: string;
  height?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

const NoContentBox = ({
  children,
  textAlign,
  padding,
  bgColor,
  isVerticallyCentered,
  isHorizontallyCentered,
  height,
  paddingTop,
  paddingBottom,
}: NoContentBoxProps) => {
  return (
    <S.NoContentBoxWrapper
      textAlign={textAlign}
      padding={padding}
      bgColor={bgColor}
      isVerticallyCentered={isVerticallyCentered}
      isHorizontallyCentered={isHorizontallyCentered}
      height={height}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {children}
    </S.NoContentBoxWrapper>
  );
};

export default NoContentBox;
