import React from 'react';
import GoToStoreContainer from './components/GoToStoreContainer/GoToStoreContainer';
import NoResultContainer from './components/NoResultContainer/NoResultContainer';

import * as S from './NoResult.styles';

type Props = {
  text: string;
  showStorageOnly: boolean;
};

const NoResult = ({ text, showStorageOnly }: Props) => {
  return (
    <S.NoResultContainerWrapper>
      <S.NoResultContent>
        {showStorageOnly ? <GoToStoreContainer /> : <NoResultContainer text={text} />}
      </S.NoResultContent>
    </S.NoResultContainerWrapper>
  );
};

export default NoResult;
