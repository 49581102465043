import { useTranslation } from 'react-i18next';
import checked from '../../../../../assets/images/store/checked.svg';
import unChecked from '../../../../../assets/images/store/unChecked.svg';
import * as S from './TermsAgreementList.styles';

type Props = {
  label: string;
  isDescription?: boolean;
  isRequired?: boolean;
  hasLink?: boolean;
  selected?: boolean;
  onSelect?: () => void;
  onLinkClick?: () => void;
};

const TermsAgreementList = ({
  label,
  isDescription = false,
  isRequired = false,
  hasLink = false,
  selected = false,
  onSelect,
  onLinkClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <S.ListWrapper onClick={onSelect}>
      {!isDescription ? (
        <img src={selected ? checked : unChecked} alt='' width={22} height={22} />
      ) : (
        <S.BulletPoint>•</S.BulletPoint>
      )}
      <S.ListTypeText>
        {isRequired
          ? t('store.signUpPage.required')
          : isDescription
          ? ''
          : t('store.signUpPage.optional')}
      </S.ListTypeText>
      <S.ListLabelText
        $isDescription={isDescription}
        $hasLink={hasLink}
        onClick={onLinkClick}
      >
        {label}
      </S.ListLabelText>
    </S.ListWrapper>
  );
};

export default TermsAgreementList;
