import styled from 'styled-components';
import { Button } from '@mui/material';
import LazyImage from '../../../../common/LazyImage/LazyImage';

export const TemplateThumbnail = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.gray_100};
`;

export const ThumbnailResize = styled(LazyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const TemplateTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

//for index.js

export const CategorySelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const CategorySelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const TemplateCategoryContainer = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${({ $selected, theme }) =>
    $selected
      ? `1px solid ${theme.color.gray_400}`
      : `1px solid ${theme.color.gray_200}`};
  border-radius: 99px;
  padding: 6px 10px;
  height: 22px;
`;

export const TemplateCategoryText = styled.span<{ $selected: boolean }>`
  ${({ theme }) => theme.font.B2_MEDIUM}
  color: ${({ $selected, theme }) =>
    $selected ? theme.color.gray_600 : theme.color.gray_400};
  display: flex;
  text-transform: none;
  justify-content: center;
  align-items: center;
`;

export const ArrowDownIcon = styled.img`
  width: 18px;
  height: 22px;
`;

export const ResetFilterBtn = styled(Button)`
  display: flex;
  align-items: center;
  gap: 2px;
  white-space: nowrap;
  margin-left: 10px;
`;

export const ResetFilterText = styled.span`
  ${({ theme }) => theme.font.B2_REGULAR}
  color: ${({ theme }) => theme.color.gray_600};
  text-transform: none;
`;

export const ResetIcon = styled.img`
  width: 16px;
  height: 16px;
`;
