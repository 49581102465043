import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const HistoryUnitBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 16px;
  background: ${({ theme }) => theme.color.gray_50};
`;

export const HistoryUnitItemContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 24px;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
`;

export const HistoryUnitDetailContainer = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  align-content: flex-start;
  gap: 2px;
  justify-content: flex-start;
`;

export const HistoryUnitDetailSmall = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  gap: 8px;
`;

export const HistoryUnitDetailText = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_MEDIUM}
  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B3_MEDIUM}
  }
`;

export const HistoryUnitDetailBig = styled.span`
  display: flex;
  color: ${({ theme }) => theme.color.gray_900};
  text-align: left;
  ${({ theme }) => theme.font.SUB2_SEMIBOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.B2_SEMIBOLD}
  }
`;

export const HistoryUnitPointCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const PlusPointContainer = styled.div`
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 2px;
`;

export const PlusPointText = styled.span`
  color: ${({ theme }) => theme.color.primary_400};
  text-align: right;
  ${({ theme }) => theme.font.H3_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;

export const MinusPointText = styled.span`
  color: ${({ theme }) => theme.color.gray_800};
  text-align: right;
  ${({ theme }) => theme.font.H3_BOLD}

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    ${({ theme }) => theme.font.SUB2_BOLD}
  }
`;
