import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';

type SignUpContextType = {
  termAgreements: boolean[];
  setTermAgreements: Dispatch<SetStateAction<boolean[]>>;
  nickName: string;
  setNickName: Dispatch<SetStateAction<string>>;
  dateOfBirth: string;
  setDateOfBirth: Dispatch<SetStateAction<string>>;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
};

export const SignUpContext = createContext<SignUpContextType>({
  termAgreements: [],
  setTermAgreements: () => {},
  nickName: '',
  setNickName: () => {},
  dateOfBirth: '',
  setDateOfBirth: () => {},
  gender: '',
  setGender: () => {},
});

export const SignUpContextProvider = ({ children }: { children: ReactNode }) => {
  // TermOfAgreement Screen
  const [termAgreements, setTermAgreements] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // NickName Screen
  const [nickName, setNickName] = useState('');

  // DateOfBirth Screen
  const [dateOfBirth, setDateOfBirth] = useState('');

  // GenderScreen
  const [gender, setGender] = useState<string>('');

  return (
    <SignUpContext.Provider
      value={{
        termAgreements,
        setTermAgreements,
        nickName,
        setNickName,
        dateOfBirth,
        setDateOfBirth,
        gender,
        setGender,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
