import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';
import LazyImage from '../../common/LazyImage/LazyImage';

export const TemplateGiftsContent = styled.div`
  flex-direction: column;
  margin-right: 16px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const TemplateGiftsImageContainer = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.color.gray_200};
  background: ${({ theme }) => theme.color.gray_100};

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 72px;
    height: 72px;
  }
`;

export const TemplateGiftsImageResize = styled(LazyImage)`
  width: 85%;
  height: 85%;
  object-fit: contain;
  flex-shrink: 0;
  cursor: pointer;
`;

export const TemplagteGiftsText = styled.span`
  width: 72px;
  height: 36px;
  overflow: hidden;
  color: ${({ theme }) => theme.color.gray_600};
  text-overflow: ellipsis;
  white-space: normal;
  ${({ theme }) => theme.font.B3_MEDIUM}
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
