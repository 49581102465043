import { useEffect, useState } from 'react';

const useIntersectionObserver = (
  observerRef: React.RefObject<HTMLElement>,
  options?: IntersectionObserverInit
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!observerRef.current || isIntersecting) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsIntersecting(true);
      }
    }, options);

    observer.observe(observerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [observerRef, isIntersecting, options]);

  return isIntersecting;
};

export default useIntersectionObserver;
