import styled from 'styled-components';

export const Header = styled.div`
  text-align: start;
`;

export const SubHeader = styled.h3`
  font-family: 'GmarketSans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const MainHeader = styled.h2`
  color: #000;
  margin-bottom: 58px;
  font-family: 'GmarketSansBold';
  font-size: 40px;
  line-height: 150%;
`;
