import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { ProductTypes } from 'src/types/product';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 0;
  overflow: hidden;

  display: flex;
  gap: 12px;
  align-items: center;
  text-align: start;

  cursor: pointer;
`;

export const ImageContainer = styled.div`
  width: 90px;
  height: 90px;
  flex-shrink: 0;

  @media (max-width: ${BREAK_POINTS.MOBILE}px) {
    width: 80px;
    height: 80px;
  }
`;

export const Image = styled.img<{ $productType: ProductTypes }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $productType }) => ($productType === 'sticker' ? 'contain' : 'cover')};
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.gray_100};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B1_REGULAR};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.SUB2_MEDIUM};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Point = styled.div`
  color: ${({ theme }) => theme.color.gray_900};
  ${({ theme }) => theme.font.H2_BOLD};
`;
