import React, { useContext, useId } from 'react';
import LanguageModal from 'src/screens/store/MyPage/components/ProfileMenuList/LanguageModal/LanguageModal';
import LogoutModal from 'src/screens/store/MyPage/components/ProfileMenuList/LogoutModal/LogoutModal';
import { ModalContext } from '../../context/modalContext';
import AppInstallQrPopUp from '../../shared/components/Modals/AppInstallQRPopUp';
import LoginPopUp from '../../shared/components/Modals/LoginPopUp';
import UseStorageInAppPopUp from '../../shared/components/Modals/UseStorageInAppPopUp';

const useModalManager = (modalName: keyof typeof modals) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const id = useId();

  const showModal = (data = {}) => {
    openModal({
      component: React.cloneElement(modals[modalName], {
        ...data,
        onClose: () => closeModal(id),
      }),
      id,
    });
  };

  const modals = {
    LoginPopUp: <LoginPopUp onClose={() => closeModal(id)} />,
    AppInstallQrPopUp: <AppInstallQrPopUp onClose={() => closeModal(id)} />,
    UseStorageInAppPopUp: <UseStorageInAppPopUp onClose={() => closeModal(id)} />,
    LanguageModal: <LanguageModal onClose={() => closeModal(id)} />,
    LogoutModal: <LogoutModal onClose={() => closeModal(id)} />,
  };

  return showModal;
};

export default useModalManager;
