import styled, { css } from 'styled-components';
import { ReactComponent as ClosedIcon } from '../../../../../assets/images/store/closedIcon.svg';

export const NameInputLengthContainer = styled.div`
  display: flex;
  height: 24px;
`;

export const NameInputLength = styled.p`
  ${({ theme }) => theme.font.B1_REGULAR};
  color: ${({ theme }) => theme.color.gray_400};
`;

export const NameInputWrapper = styled.div`
  min-width: 327px;
  box-sizing: border-box;

  position: relative;
`;

export const NameInput = styled.input<{ $onError: boolean }>`
  ${({ theme, $onError }) => css`
    width: 100%;
    height: 37px;
    background-color: transparent;
    border: none;
    ${theme.font.H2_MEDIUM};
    outline: none;
    border-bottom: 2px solid ${theme.color.gray_400};
    color: ${theme.color.gray_900};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;

    &:focus {
      border-bottom: ${`2px solid ${
        $onError ? theme.color.warning_500 : theme.color.gray_600
      }`};
    }

    ${$onError && `border-bottom: 2px solid ${theme.color.warning_500}`};

    &::placeholder {
      ${theme.font.H2_MEDIUM};
      color: ${theme.color.gray_400};
    }
  `}
`;

export const ClosedIconWrapper = styled(ClosedIcon)`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 12px;
  cursor: pointer;
`;

export const NickNameErrorWrapper = styled.div`
  height: 24px;

  display: flex;
  align-items: center;
  margin-top: 8px;
  column-gap: 8px;
`;

export const NickNameErrorMessage = styled.p`
  ${({ theme }) => theme.font.B2_REGULAR}
  color:${({ theme }) => theme.color.warning_500}
`;
