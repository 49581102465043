import styled from 'styled-components';
import { TOP_BAR_HEIGHT } from './constants';

export const Progress = styled.section<{
  $isSticky: boolean;
  $isWeb: boolean;
  $topOffset: number;
}>`
  width: 100%;
  background-color: #ffffff;
  position: ${({ $isSticky }) => ($isSticky ? 'fixed' : 'static')};
  top: ${({ $isWeb, $topOffset }) => ($isWeb ? `0` : `${TOP_BAR_HEIGHT + $topOffset}px`)};
  transition: top 0.3s;
  left: 0;
  padding: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: ${({ $isSticky }) => ($isSticky ? '4px 4px 10px 0px #1915451a' : 'none')};
  z-index: ${({ $isSticky }) => $isSticky && '999'};
`;

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftBox = styled.div<{ $isSticky: boolean }>`
  font-family: 'Pretendard-Bold';
  display: flex;
  flex-direction: ${({ $isSticky }) => ($isSticky ? 'row' : 'column')};
  gap: 8px;
`;

export const CurrentAmount = styled.div<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: ${({ $fontSize }) => $fontSize}px;
  display: flex;
`;

export const TargetAmount = styled.div<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: ${({ $fontSize }) => $fontSize}px;
  color: #7b7b7b;
  display: flex;
`;

export const RightBox = styled.div<{ $fontSize: number }>`
  font-family: 'Pretendard-Regular';
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: ${({ $fontSize }) => $fontSize}px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
`;

export const Achieve = styled.div<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: ${({ $fontSize }) => $fontSize}px;
  color: #8064fc;
  display: flex;
  align-items: center;
`;

export const RemainDay = styled.div<{ $isSticky: boolean }>`
  font-family: 'Pretendard-Bold';
  display: ${({ $isSticky }) => ($isSticky ? 'none' : 'flex')};
  justify-content: flex-end;
`;
