import React, { useMemo } from 'react';
import { ReactComponent as Download } from '../../../../assets/images/store/download_ios.svg';
import useReactNativeEvents from '../../../../hooks/useReactNativeEvents';
import { BASE_URL } from '../../../../utils/configs';
import { generateRandomNumber } from '../../../../utils/random';
import BottomModalSheet from '../../../common/BottomModalSheet';
import * as S from './DonateAuthCard.styles';
import { PATH } from 'src/constants/_common/path';

type Props = {
  onBackDropClick?: () => void;
};

const certificateUrl = `https://hapl-static.s3.ap-northeast-2.amazonaws.com/donation-cert-white.png`;
const certificateUrlBlack = `https://hapl-static.s3.ap-northeast-2.amazonaws.com/donation-cert-black.png`;

const DonateAuthCard = ({ onBackDropClick }: Props) => {
  const { postMessageToRN } = useReactNativeEvents();
  const certificateImageUrl = useMemo(
    () => (generateRandomNumber(0, 1) === 0 ? certificateUrl : certificateUrlBlack),
    []
  );

  const downloadCertificate = () => {
    postMessageToRN('shareCertificate', {
      url: certificateImageUrl,
    });
  };

  const shareExhibitionLink = () => {
    postMessageToRN('onShareButtonClicked', {
      url: `${BASE_URL}/${PATH.STORE}/exhibition`,
    });
  };

  return (
    <BottomModalSheet onBackDropClick={onBackDropClick}>
      <S.DonateAuthCardContainer>
        <S.Text>
          유기견에게 선한 마음을 선물한
          <br />
          당신을 <S.HighlightText>봄 같은 하플러</S.HighlightText>로 임명합니다!
        </S.Text>
        <S.Image src={certificateImageUrl} alt='선플임명장' />
        <S.Button type='button' onClick={downloadCertificate}>
          <Download width='24' height='24' stroke='#6638E6' />
          <span>이미지 저장</span>
        </S.Button>
        <S.Button type='button' $fill onClick={shareExhibitionLink}>
          기부이벤트 공유
        </S.Button>
      </S.DonateAuthCardContainer>
    </BottomModalSheet>
  );
};

export default DonateAuthCard;
