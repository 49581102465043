import BREAK_POINTS from 'src/constants/_common/breakPoints';
import styled from 'styled-components';

export const ColumnContainer = styled.main`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const StretchContainer = styled.div`
  box-sizing: border-box;
  padding: 20px 16px 0 16px;

  @media screen and (min-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 20px 24px 0 24px;
  }

  flex-grow: 1;
`;
