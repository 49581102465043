import BREAK_POINTS from 'src/constants/_common/breakPoints';
import { PRODUCT_DETAIL_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';

export const TemplateGifts = styled.div`
  width: 100%;
  max-width: ${PRODUCT_DETAIL_MAX_WIDTH}px;
  margin-bottom: 20px;
  padding: 0 24px;
  box-sizing: border-box;

  display: flex;

  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    padding: 0 16px;
  }
`;

export const TemplateGiftsKey = styled.span`
  color: ${({ theme }) => theme.color.gray_400};
  min-width: 36px;
  height: 22px;
  margin-right: 20px;
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.21px;
  display: flex;
  justify-content: flex-start;
`;

export const TemplateGiftsSlideContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;
