import { useEffect, useState } from 'react';
import { getAppConfigs } from '../../utils/request';

const useFetchAppConfigs = (types: string) => {
  const [appConfigs, setAppConfigs] = useState({});

  useEffect(() => {
    const fetchAppConfigs = async () => {
      const response = await getAppConfigs();
      if (response?.data?.data) {
        let data = response?.data?.data;
        data = JSON.parse(JSON.stringify(data));

        data[types].unshift({ _id: 'free', korName: '무료', engName: 'Free' });
        data[types].unshift({ _id: 'all', korName: '전체', engName: 'All' });
        setAppConfigs(data);
      }
    };

    fetchAppConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { appConfigs };
};

export default useFetchAppConfigs;
