import React from 'react';
import { exhibitionImages } from '../../../../assets/images/store/exhibition/images';
import * as S from './ExhibitionProductTablet.styles';

type Props = {
  index: number;
  isPurchased: boolean;
  onPurchaseButtonClick: () => void;
  onDetailButtonClick: () => void;
};

const ExhibitionProductTablet = ({
  index,
  isPurchased,
  onPurchaseButtonClick,
  onDetailButtonClick,
}: Props) => {
  const handlePurchaseButtonClick = () => {
    if (isPurchased) {
      return;
    }

    onPurchaseButtonClick();
  };

  return (
    <S.ExhibitionProductTablet>
      <S.LeftContainer>
        <S.ProductImageWrapper>
          <S.Image
            src={exhibitionImages.products.tablet.product[index]}
            alt={'상품 이미지'}
          />
        </S.ProductImageWrapper>
      </S.LeftContainer>

      <S.Info>
        <S.PriceImageWrapper>
          <S.Image src={exhibitionImages.products.tablet.price[index]} alt='상품 가격' />
        </S.PriceImageWrapper>
        <S.ButtonImageWrapper
          style={{ marginBottom: '16px' }}
          onClick={handlePurchaseButtonClick}
        >
          {isPurchased ? (
            <S.Image
              src={exhibitionImages.products.tablet.buttons.purchased}
              alt='구매완료'
            />
          ) : (
            <S.Image
              src={exhibitionImages.products.tablet.buttons.purchase[index]}
              alt='구매하기'
            />
          )}
        </S.ButtonImageWrapper>
        <S.ButtonImageWrapper onClick={onDetailButtonClick}>
          <S.Image src={exhibitionImages.products.tablet.buttons.detail} alt='상세보기' />
        </S.ButtonImageWrapper>
      </S.Info>
    </S.ExhibitionProductTablet>
  );
};

export default ExhibitionProductTablet;
