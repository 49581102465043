import { useEffect, useRef, useState } from 'react';
import ViewMoreButton from 'src/components/store/ViewMoreButton';
import * as S from './DescriptionView.styles';

type Props = {
  dangerouslySetInnerHTML:
    | {
        __html: string | TrustedHTML;
      }
    | undefined;
};

export const VIEW_MORE_HEIGHT = 430;

const DescriptionView = ({ dangerouslySetInnerHTML }: Props) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const [showViewMoreButton, setShowViewMoreButton] = useState(false);

  const handleViewMoreButtonClick = () => {
    setShowViewMoreButton(false);
  };

  useEffect(() => {
    if (!viewRef.current) {
      return;
    }

    const viewHeight = viewRef.current.clientHeight;
    if (viewHeight >= VIEW_MORE_HEIGHT) {
      setShowViewMoreButton(true);
    }
  }, [dangerouslySetInnerHTML]);

  return (
    <>
      <S.DescriptionView
        ref={viewRef}
        $isCollapsed={showViewMoreButton}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
      {showViewMoreButton && (
        <ViewMoreButton onClick={handleViewMoreButtonClick} text='상품 상세 더보기' />
      )}
    </>
  );
};

export default DescriptionView;
