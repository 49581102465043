import styled from 'styled-components';
import BREAK_POINTS from '../../../constants/_common/breakPoints';

export const TopBarContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  background: ${({ theme }) => theme.color.white};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;

  @media screen and (${BREAK_POINTS.MOBILE}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ArrowResize = styled.img`
  width: 24px;
  height: 24px;
  left: 0px;
  position: absolute;
  cursor: pointer;
`;

export const BarText = styled.span`
  display: flex;
  width: auto;
  height: 24px;
  color: ${({ theme }) => theme.color.black};
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.font.H3_BOLD}
`;
