export const images = {
  search: require('../search/search.png'),
  closeRight: require('../search/closeRight.png'),
  closeSearchTag: require('../search/closeSearchTag.png'),
  defaultImage: require('../search/defaultImage.png'),
  Horizontal: require('../search/horizontal.png'),
  Vertical: require('../search/vertical.png'),
  Square: require('../search/square.png'),
  arrowRight: require('../search/arrowRight.png'),
  arrowDown: require('../search/arrowDown.png'),
  arrowLeft: require('../search/arrowLeft.png'),
  refresh: require('../search/refresh.png'),
  brand: require('../search/brand.png'),
  profile: require('../search/profile.png'),
  whiteTick: require('./WhiteCheck.png'),
  blackTick: require('./BlackCheck.png'),
  darkArrowDown: require('./dark_arrow_down.png'),
};
