import { useTranslation } from 'react-i18next';
import { PurchasedProducts } from 'src/apis/types/user.types';
import * as S from './index.styles';
import PurchaseItem from './PurchaseItem';

type Props = {
  items: PurchasedProducts;
};

const PurchaseItems = ({ items }: Props) => {
  const { i18n } = useTranslation();

  return (
    <S.Ul>
      {items.map((item) => (
        <PurchaseItem
          key={item._id}
          image={item.thumbnailSignUrl}
          soldAt={item.soldAt}
          productName={i18n.language === 'ko' ? item.korName : item.engName}
          purchasePrice={item.purchasePrice}
          productType={item.salesType}
          brandName={item.brandName}
          productId={item.productId}
          review={item.review}
        />
      ))}
    </S.Ul>
  );
};

export default PurchaseItems;
