import dayjs, { OpUnitType, QUnitType } from 'dayjs';

export const calculateDaysDifference = (from, to) => {
  const diffTime = Math.abs(to - from);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formateDateTime = (date, formate = 'YY.MM.DD HH:mm') =>
  date ? dayjs(date).format(formate) : '';

export const calDiff = (
  date1: string | number | Date | dayjs.Dayjs,
  date2: string | number | Date | dayjs.Dayjs,
  interval: QUnitType | OpUnitType = 'year'
) => dayjs(date1).diff(dayjs(date2), interval);

export const calculateRemainingDays = (from: Date, to: Date) => {
  const diffTime = to.getTime() - from.getTime();
  const remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return remainingDays > 0 ? remainingDays : 0;
};

export const getKRCurrentTime = () => {
  const date = new Date();
  const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

  return new Date(utc + KR_TIME_DIFF);
};
