import { STORE_MAX_WIDTH } from 'src/constants/_common/layout';
import styled from 'styled-components';
import BREAK_POINTS from './../../../constants/_common/breakPoints';

export const PurpleBanner = styled.div`
  display: flex;
  justify-content: center;
`;

export const PurpleChargingOverlay = styled.div`
  display: flex;
  position: fixed;
  max-width: ${STORE_MAX_WIDTH}px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
`;

export const PurpleWarningBackground = styled.div`
  background-color: #fbfbfb;
`;

export const PurpleWarningContainer = styled.div`
  max-width: 720px;
  padding: 0px;
  margin-inline: auto;
  padding-top: 24px;
  padding-bottom: 40px;

  @media screen and (max-width: 744px) {
    margin: 0px 24px;
  }
  @media screen and (max-width: ${BREAK_POINTS.TABLET_SMALL}px) {
    margin: 0px 16px;
  }
`;

export const PurpleWarningTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  gap: 20px;
`;

export const PurpleWarningContents = styled.div`
  color: #bdbdbd;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  line-height: 22px;
  align-self: stretch;

  @media screen and (max-width: ${BREAK_POINTS.MOBILE}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Ul = styled.ul`
  list-style-type: disc;
  color: #bdbdbd;
  margin-left: 16px;
`;
