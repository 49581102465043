import { responsivePadding } from 'src/styles/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;

  ${responsivePadding};
`;

export const Inner = styled.div`
  max-width: 460px;
  margin: auto;
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.color.black};
  ${({ theme }) => theme.font.H1_BOLD};
  margin-bottom: 63px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.color.gray_400};
  ${({ theme }) => theme.font.B2_REGULAR};
  margin-bottom: 11px;
`;
