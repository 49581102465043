import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import favorite from '../../../assets/images/store/favorite.svg';
import filled from '../../../assets/images/store/favorite_filled.png';
import { UserContext } from '../../../context/userContext';
import useCurrentUserQuery from '../../../hooks/apis/auth/useCurrentUserQuery';
import useModalManager from '../../../hooks/modal/useModalManager';
import { trackEvent } from '../../../hooks/store/useMixpanel';
import useReactNativeEvents from '../../../hooks/useReactNativeEvents';
import { eventNames, goStorageSources, productProps } from '../../../utils/mixpanel';
import {
  addToWishlist,
  getTemplateOrFolderId,
  removeFromWishlist,
} from '../../../utils/request';
import PaymentModal from '../Pay/PaymentModal';
import * as G from '../StoreComponent.styles';
import * as S from './PurchaseUnderBar.styles';

type Props = {
  product: any;
  onPurchase: () => void;
};

function PurchaseUnderBar({ product, onPurchase }: Props) {
  const userId = localStorage.getItem('userId') ?? '';
  const { wishlist, refreshWishlist } = useContext(UserContext);
  const { data: currentUser } = useCurrentUserQuery();
  const { t } = useTranslation();
  const { postMessageToRN } = useReactNativeEvents();
  const showLoginPopUp = useModalManager('LoginPopUp');
  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');
  const isWeb = !localStorage.getItem('platform');

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const platform =
    localStorage.getItem('platform') ??
    new URLSearchParams(window.location.search).get('platform');

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

  const handleOpenBottomSheet = async () => {
    if (
      product.userId === userId ||
      product?.brandId?.connectedHaplAccount?._id === userId ||
      product.isPurchased
    ) {
      if (currentUser) {
        trackEvent(eventNames.goToStorageClick, currentUser, {
          source: goStorageSources.productDetail,
          platformType,
        });
      }

      const response = await getTemplateOrFolderId(product._id);

      postMessageToRN('navigateTo', {
        screen: 'StorageScreen',
        hideBottomTabs: false,
        tab: product.productType,
        id: response?.data.data?.id,
      });
    } else {
      trackEvent(eventNames.productBuyNowClick, currentUser, {
        ...productProps(product),
        platformType,
        isUser: !!currentUser,
      });

      setIsBottomSheetOpen(!isBottomSheetOpen);
    }
  };

  const handleCloseBottomSheet = () => {
    setIsBottomSheetOpen(false);
  };

  const toggleWishList = async () => {
    const isRemove = wishlist[product._id];
    const platform =
      localStorage.getItem('platform') ??
      new URLSearchParams(window.location.search).get('platform');
    const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';

    if (currentUser) {
      trackEvent(
        isRemove ? eventNames.productUnlikeClick : eventNames.productLikeClick,
        currentUser,
        {
          ...productProps(product),
          platformType,
        }
      );
    }

    const response = isRemove
      ? await removeFromWishlist(wishlist[product._id].id)
      : await addToWishlist(product._id);
    if (response?.data?.status) refreshWishlist();
  };

  const handleLikeButtonClick = () => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ hideBottomTabs: false, source: 'likeBtn' });
      return;
    }
    toggleWishList();
  };

  const handlePurchaseButtonClick = () => {
    if (!currentUser || currentUser.isAnonymous) {
      showLoginPopUp({ hideBottomTabs: false, source: 'clickPurchaseProduct' });
      return;
    }

    if (
      isWeb &&
      (product?.userId === userId ||
        product?.brandId?.connectedHaplAccount?._id === userId)
    ) {
      showUseStorageInAppPopUp({ source: 'purchaseProduct_complete' });
      return;
    }

    handleOpenBottomSheet();
  };

  useEffect(() => {
    if (isBottomSheetOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isBottomSheetOpen]);

  return (
    <S.UnderBarContainer>
      <S.UnderBarButtonContainer>
        <S.UnderBarLikeButton onClick={handleLikeButtonClick}>
          <S.UnderBarLikeResize
            src={wishlist[product?._id] ? filled : favorite}
            alt='like'
          />

          <S.UnderBarLikeText>{t('store.stickerPage.wishlist')}</S.UnderBarLikeText>
        </S.UnderBarLikeButton>

        <S.UnderBarPurchaseButton
          disabled={
            product?.userId !== userId &&
            product?.brandId?.connectedHaplAccount?._id !== userId &&
            product?.giftOnly
          }
          $secondary={
            product?.userId === userId ||
            product?.brandId?.connectedHaplAccount?._id === userId ||
            product?.isPurchased
          }
          onClick={handlePurchaseButtonClick}
        >
          <S.UnderBarPurchaseText
            $secondary={
              product?.userId === userId ||
              product?.brandId?.connectedHaplAccount?._id === userId ||
              product?.isPurchased
            }
          >
            {t(
              product?.userId === userId ||
                product?.brandId?.connectedHaplAccount?._id === userId
                ? 'store.stickerPage.download'
                : product?.giftOnly === true
                ? 'store.stickerPage.giftOnly'
                : product?.isPurchased === true
                ? 'store.stickerPage.goStorage'
                : 'store.stickerPage.buy'
            )}
          </S.UnderBarPurchaseText>
        </S.UnderBarPurchaseButton>

        {isBottomSheetOpen && <G.Overlay onClick={handleCloseBottomSheet} />}
        <PaymentModal
          product={product}
          isOpen={isBottomSheetOpen}
          onClose={handleCloseBottomSheet}
          onPurchase={onPurchase}
        />
      </S.UnderBarButtonContainer>
    </S.UnderBarContainer>
  );
}

export default PurchaseUnderBar;
