import { useEffect } from 'react';
import { Slide, toast, ToastContent, TypeOptions } from 'react-toastify';
import { ToastIcon } from 'react-toastify/dist/types';
import { theme } from 'src/styles/theme';
import colors from '../colors';

type Props = {
  toastMessage: ToastContent;
  toastType: TypeOptions;
  icon?: ToastIcon;
  style?: React.CSSProperties;
};

export const showToast = ({ toastMessage, toastType, icon = false, style }: Props) => {
  const toastStyle = {
    ...BASE_TOAST_STYLE,
    ...TOAST_STYLES[toastType],
    ...style,
  };

  return toast(<ToastMessageWrapper>{toastMessage}</ToastMessageWrapper>, {
    toastId: toastMessage?.toString(),
    type: toastType,
    style: toastStyle,
    icon,
    transition: Slide,
    // autoClose: false, // for debugging
  });
};

const TOAST_STYLES = {
  info: {
    background: theme.color.gray_600,
    color: theme.color.white,
    fontFamily: 'Pretendard-Medium',
    lineHeight: '24px',
  },
  success: {
    background: 'rgba(236, 229, 255, 0.85)',
    color: colors.$primaryPurple,
    fontFamily: 'Pretendard-SemiBold',
  },
  error: {
    background: 'rgba(255, 229, 234, 0.85)',
    color: colors.$warning500,
    fontFamily: 'Pretendard-SemiBold',
  },
};

const BASE_TOAST_STYLE = {
  fontSize: '16px',
  lineHeight: '16px',
  padding: '0 24px',
  borderRadius: '99px',
  boxShadow: '0px 2px 8px 0px rgba(58, 59, 65, 0.10)',
  backdropFilter: 'blur(2px)',
};

const ToastMessageWrapper = ({ children }: { children: ToastContent }) => {
  useEffect(() => {
    const handleResize = () => {
      const element = document.querySelector('.Toastify__toast-container');
      if (element instanceof HTMLElement) {
        const { style } = element;
        style.width = 'max-content';
        style.maxWidth = '90%';

        if (window.innerWidth <= 480) {
          style.width = '100%';
          style.maxWidth = '';
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>{children}</>;
};
