import LazyImage from '../../common/LazyImage/LazyImage';
import styled from 'styled-components';

export const GiftImageContainer = styled.div`
  aspect-ratio: 1;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const GiftImageResize = styled(LazyImage)`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
`;
